import React from "react";
import { Grid, Button, Typography, Tooltip } from "@mui/material";
// import CardBase from "../../components/organisms/CardBase";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { useNavigate } from "react-router-dom";
import Section from "../organisms/Section/Section";
import HomeIcon from "@mui/icons-material/Home";

const ReportPlotter = () => {
  return (
    <Grid container spacing={3}>
      <Section>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
           
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Typography variant="h5">Analytics</Typography>
          </GridItem>
        </GridContainer>
      </Section>
    </Grid>
  );
};

export default ReportPlotter;
