import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { getWalkinSalesInvoiceDetailsById } from "../../../../actions/walkinSaleInvoiceAction";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import CustomBackdropSpinner from "../../../CustomBackdropSpinner.js";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { addDecimals } from "../../../../util/Utility.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const WalkinSalesInvoiceDetailsScreen = () => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const [invoiceTotal, setInvoiceTotal] = useState(() => "");
  useEffect(() => {
    if (id) dispatch(getWalkinSalesInvoiceDetailsById(id));
  }, [dispatch]);

  const walkinSalesInvoiceDetailsById = useSelector((state) => state.walkinSalesInvoiceDetailsById);
  const {
    walkin_sales_invoice_by_id_loading,
    walkin_sales_invoice_by_id_data,
    walkin_sales_invoice_by_id_error } = walkinSalesInvoiceDetailsById;

  const { invoiceProducts, customerDetails } = walkin_sales_invoice_by_id_data;
  console.log("customerDetails : ", customerDetails, "invoiceProducts : ", invoiceProducts, "walkin_sales_invoice_by_id_data : ", walkin_sales_invoice_by_id_data);

  const columnDefs = [
    { field: "#", resizable: true, suppressSizeToFit: true, width: 75, minWidth: 75, maxWidth: 75, headerName: "#", },
    { field: "Name", resizable: true, suppressSizeToFit: true, width: 300, minWidth: 300, maxWidth: 300, headerName: "Name", },
    { field: "Quantity", resizable: true, suppressSizeToFit: true, width: 225, minWidth: 225, maxWidth: 225, headerName: "Quantity" },
    { field: "UnitPrice", resizable: true, suppressSizeToFit: true, width: 225, minWidth: 225, maxWidth: 225, headerName: "Unit Price" },
    { field: "DiscountPercent", resizable: true, suppressSizeToFit: true, width: 225, minWidth: 225, maxWidth: 225, headerName: "Discount [%]" },
    { field: "DiscountAmount", resizable: true, suppressSizeToFit: true, width: 200, minWidth: 200, maxWidth: 200, headerName: "Discount Amount" },
    { field: "DiscountStartsFrom", resizable: true, suppressSizeToFit: true, width: 200, minWidth: 200, maxWidth: 200, headerName: "Discount from[Kgs]" },
    { field: "ProductTax", resizable: true, suppressSizeToFit: true, width: 225, minWidth: 225, maxWidth: 225, headerName: "Product Tax [%]" },
    { field: "ProductTaxAmount", resizable: true, suppressSizeToFit: true, width: 225, minWidth: 225, maxWidth: 225, headerName: "Product Tax Amount [INR]" },
    { field: "ProductAmount", resizable: true, suppressSizeToFit: true, width: 225, minWidth: 225, maxWidth: 225, headerName: "Product Amount" },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
    }),
    []
  );

  let rowData = [];
  let gridApi = "";
  let renderContentUsingAGGrid = null;
  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  if (
    walkin_sales_invoice_by_id_data &&
    walkin_sales_invoice_by_id_data._id
  ) {
    console.log(
      "walkin_sales_invoice_by_id_data : ",
      walkin_sales_invoice_by_id_data
    );

    rowData = invoiceProducts.map((prd, index) => {
      console.log("Each Product  ::==>>  ", prd);
      return {
        "#": index + 1,
        "Name": prd.name,
        "Quantity": prd.quantity ? prd.quantity : 0.0,
        "UnitPrice": prd.unitPrice ? prd.unitPrice : 0.0,
        "DiscountPercent": prd.discountPercent ? prd.discountPercent : 0.0,
        "DiscountAmount": prd.discountAmount ? prd.discountAmount : 0.0,
        "DiscountStartsFrom": prd.discountStartsFrom ? prd.discountStartsFrom : 0.0,
        "ProductTax": prd.productTax ? prd.productTax : 0.0,
        "ProductTaxAmount": prd.productTaxAmount ? prd.productTaxAmount : 0.0,
        "ProductAmount": addDecimals(Number(prd.unitPrice) * Number(prd.quantity)),
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        // domLayout='autoHeight'
        ></AgGridReact>
      </div>
    );
  }

  return (
    <React.Fragment>
      {walkin_sales_invoice_by_id_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ margin: "1rem" }}>
          <Typography
            variant="h4"
            color="gray"
            style={{ margin: "0.5rem", textAlign: "center", textTransform: 'none' }}
          >
            POS Sales Invoice # {id}
            <CheckCircleRoundedIcon
              style={{ float: "center", color: "#26A541" }}
            />
          </Typography>
        </GridItem>
        {customerDetails && (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Customer Details
              </Typography>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Customer Name"
                      name="customerName"
                      value={
                        customerDetails.name ? customerDetails.name : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Customer Contact"
                      value={
                        customerDetails.contactNo
                          ? customerDetails.contactNo
                          : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Customer GST"
                      variant="outlined"
                      size="small"
                      value={
                        customerDetails.gst
                          ? customerDetails.gst
                          : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Bill Date"
                      variant="outlined"
                      size="small"
                      value={
                        walkin_sales_invoice_by_id_data.billDate
                          ? walkin_sales_invoice_by_id_data.billDate
                          : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Order Total"
                      variant="outlined"
                      size="small"
                      value={invoiceProducts && addDecimals(invoiceProducts.map((eachProduct) => eachProduct.itemCost).reduce((acc, amount) => acc + amount))}
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="UPI Amount Paid"
                      variant="outlined"
                      size="small"
                      value={
                        walkin_sales_invoice_by_id_data.paymentDetails.upiAmountPaid
                          ? walkin_sales_invoice_by_id_data.paymentDetails.upiAmountPaid
                          : 0.0
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Cash Amount Paid"
                      variant="outlined"
                      size="small"
                      value={
                        walkin_sales_invoice_by_id_data.paymentDetails.cashAmountPaid
                          ? addDecimals(walkin_sales_invoice_by_id_data.paymentDetails.cashAmountPaid)
                          : 0.0
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Due"
                      variant="outlined"
                      size="small"
                      value={
                        (invoiceProducts && addDecimals(invoiceProducts.map((eachProduct) => eachProduct.itemCost).reduce((acc, amount) => acc + amount) - (walkin_sales_invoice_by_id_data.paymentDetails.cashAmountPaid
                          ? Number(walkin_sales_invoice_by_id_data.paymentDetails.cashAmountPaid)
                          : 0.0 + walkin_sales_invoice_by_id_data.paymentDetails.upiAmountPaid
                            ? Number(walkin_sales_invoice_by_id_data.paymentDetails.upiAmountPaid)
                            : 0.0)))
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default WalkinSalesInvoiceDetailsScreen;
