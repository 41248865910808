import {
    STORE_PAYMENT_CREATE_BY_STORE_REQUEST,
    STORE_PAYMENT_CREATE_BY_STORE_SUCCESS,
    STORE_PAYMENT_CREATE_BY_STORE_RESET,
    STORE_PAYMENT_CREATE_BY_STORE_FAIL,
    STORE_PAYMENT_LIST_BY_STORE_REQUEST,
    STORE_PAYMENT_LIST_BY_STORE_SUCCESS,
    STORE_PAYMENT_LIST_BY_STORE_RESET,
    STORE_PAYMENT_LIST_BY_STORE_FAIL,
    STORE_PAYMENT_DETAILS_BY_ID_REQUEST,
    STORE_PAYMENT_DETAILS_BY_ID_SUCCESS,
    STORE_PAYMENT_DETAILS_BY_ID_FAIL,
    STORE_PAYMENT_DETAILS_BY_ID_RESET
  } from "../constants/storePaymentConstants";

export const paymentsListByStoreReducer = (state = {  }, action) => {
  switch (action.type) {
    case STORE_PAYMENT_LIST_BY_STORE_REQUEST:
      return { payments_by_store_loading: true, payments_by_store: [] };
    case STORE_PAYMENT_LIST_BY_STORE_SUCCESS:
      return {
        payments_by_store_loading: false,
        payments_by_store: action.payload,
      };
    case STORE_PAYMENT_LIST_BY_STORE_FAIL:
      return { payments_by_store_loading: false, payments_by_store_error: action.payload };
    default:
      return state;
  }
};

export const storePaymentByIdReducer = (
  state = { paymentById: {} },
  action
) => {
  switch (action.type) {
    case STORE_PAYMENT_DETAILS_BY_ID_REQUEST:
      return { payment_by_id_loading: true, payment_by_id:{}  };
    case STORE_PAYMENT_DETAILS_BY_ID_SUCCESS:
      return {
        payment_by_id_loading: false,
        payment_by_id: action.payload,
      };
    case STORE_PAYMENT_DETAILS_BY_ID_FAIL:
      return { payment_by_id_loading: false, payment_by_id_error: action.payload };
    default:
      return state;
  }
};

export const storePaymentCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_PAYMENT_CREATE_BY_STORE_REQUEST:
      return { create_store_payment_loading: true };
    case STORE_PAYMENT_CREATE_BY_STORE_SUCCESS:
      return { create_store_payment_loading: false, create_store_payment_success: true, create_store_payment : action.payload };
    case STORE_PAYMENT_CREATE_BY_STORE_FAIL:
      return { create_store_payment_loading: false, create_store_payment_error: action.payload };
    case STORE_PAYMENT_CREATE_BY_STORE_RESET:
      return {};
    default:
      return state;
  }
};



