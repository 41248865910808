import axios from "axios";
import {
  STOCK_TRANSER_INVOICE_CREATE_REQUEST,
  STOCK_TRANSER_INVOICE_CREATE_SUCCESS,
  STOCK_TRANSER_INVOICE_CREATE_FAIL,
  STOCK_TRANSER_INVOICE_LIST_REQUEST,
  STOCK_TRANSER_INVOICE_LIST_SUCCESS,
  STOCK_TRANSER_INVOICE_LIST_FAIL,
  STOCK_TRANSER_INVOICE_BY_ID_REQUEST,
  STOCK_TRANSER_INVOICE_BY_ID_FAIL,
  STOCK_TRANSER_INVOICE_BY_ID_SUCCESS,
  STOCK_TRANSER_INVOICE_DELETE_BY_ID_SUCCESS,
  STOCK_TRANSER_INVOICE_DELETE_BY_ID_REQUEST,
  STOCK_TRANSER_INVOICE_DELETE_BY_ID_FAIL,
  STOCK_TRANSER_INVOICE_LIST_BY_POS_REQUEST,
  STOCK_TRANSER_INVOICE_LIST_BY_POS_SUCCESS,
  STOCK_TRANSER_INVOICE_LIST_BY_POS_FAIL,
} from "../constants/stockTransferConstants";
import { groupAndSum } from "../util/Utility";

export const  createWarehouseStoreInvoice =
  (purchaseOrder, purchaseOrderProducts) => async (dispatch) => {
    console.log('createWarehouseStoreInvoice - START')
    const  { fromWarehouseStoreValue , toWarehouseStoreValue } = purchaseOrder
    console.log("purchaseOrder : ", purchaseOrder ? purchaseOrder :{})
    console.log("purchaseOrderProducts Length if any : ", purchaseOrderProducts ? purchaseOrderProducts.length : 0)
    console.log("fromWarehouseStoreValue : " , fromWarehouseStoreValue , "toWarehouseStoreValue : ", toWarehouseStoreValue);

    try {
      dispatch({ type: STOCK_TRANSER_INVOICE_CREATE_REQUEST });
      const { data } = await axios.post(`/api/stock-transfer-invoice`, {
        purchaseOrder,
        purchaseOrderProducts
      });
      console.log("Create Warehouse Store Invoice - Start ");
      dispatch({
        type: STOCK_TRANSER_INVOICE_CREATE_SUCCESS,
        payload: data,
      });
      console.log("Create Warehouse Store Invoice - End ");
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: STOCK_TRANSER_INVOICE_CREATE_FAIL,
        payload: message,
      });
    }
    console.log('createWarehouseStoreInvoice - END')
  };

export const listAllStockTransferInvoices = () => async (dispatch) => {
  console.log("Exc listAllStockTransferInvoices ...");
  try {
    dispatch({ type: STOCK_TRANSER_INVOICE_LIST_REQUEST });
    const { data } = await axios.get("/api/warehouse-store-invoice");
    dispatch({
      type: STOCK_TRANSER_INVOICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STOCK_TRANSER_INVOICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStockTransferInvoicesByPos = (pos) => async (dispatch) => {
  console.log("Exc listWarehouseStoreInvoices ...");
  try {
    dispatch({ type: STOCK_TRANSER_INVOICE_LIST_BY_POS_REQUEST });
    const { data } = await axios.get(`/api/stock-transfer-invoice/pos/${pos}`);
    dispatch({
      type: STOCK_TRANSER_INVOICE_LIST_BY_POS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STOCK_TRANSER_INVOICE_LIST_BY_POS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const warehouseStoreInvoiceById = (id) => async (dispatch) => {
  console.log('warehouseInvoiceById : id',id);
  try {
    dispatch({ type: STOCK_TRANSER_INVOICE_BY_ID_REQUEST });
    const { data } = await axios.get(`/api/stock-transfer-invoice/${id}`);

    dispatch({
      type: STOCK_TRANSER_INVOICE_BY_ID_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ");
    console.log(data);
  } catch (error) {
    dispatch({
      type: STOCK_TRANSER_INVOICE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteWarehouseStoreInvoice = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STOCK_TRANSER_INVOICE_DELETE_BY_ID_REQUEST,
    });

    await axios.delete(`/api/warehouse-store-invoice/${id}`);

    dispatch({
      type: STOCK_TRANSER_INVOICE_DELETE_BY_ID_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message) {
      dispatch({
        type: STOCK_TRANSER_INVOICE_DELETE_BY_ID_FAIL,
        payload: message,
      });
    }
  }
};

export const createWarehouseStoreInvoices =
  (warehouseInvoice, warehouseInvoiceProducts) => async (dispatch) => {
    console.log(
      "createwarehouseInvoice -start ",
      warehouseInvoice,
      warehouseInvoiceProducts
    );
    const resultOfGroupAndSum = groupAndSum(warehouseInvoiceProducts);
    console.log("resultOfGroupAndSum : ", resultOfGroupAndSum)
    const {
      vehicle,
      storeName,
      contactNo,
      billNo,
      billDate,
      invoiceProducts
    } = warehouseInvoice;
    try {
      dispatch({
        type: STOCK_TRANSER_INVOICE_CREATE_REQUEST,
      });

      console.log("warehouseInvoice : ", warehouseInvoice);
      dispatch({ type: STOCK_TRANSER_INVOICE_CREATE_REQUEST });
      const { data } = await axios.post(`/api/warehouse-store-invoice`, {
      vehicle,
      storeName,
      contactNo,
      billNo,
      billDate,
      invoiceProducts
      });
      console.log("createwarehouseInvoice - end ");
      dispatch({
        type: STOCK_TRANSER_INVOICE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: STOCK_TRANSER_INVOICE_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const updateWarehouseStoreInvoices = (id) => async (dispatch, getState) => {
  console.log("EXEC update warehouseInvoice : " + id);
};
