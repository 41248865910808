export const WALKIN_SALES_INVOICE_LIST_REQUEST = "WALKIN_SALES_INVOICE_LIST_REQUEST";
export const WALKIN_SALES_INVOICE_LIST_SUCCESS = "WALKIN_SALES_INVOICE_LIST_SUCCESS";
export const WALKIN_SALES_INVOICE_LIST_FAIL = "WALKIN_SALES_INVOICE_LIST_FAIL";
export const WALKIN_SALES_INVOICE_LIST_RESET = "WALKIN_SALES_INVOICE_LIST_RESET";

export const WALKIN_SALES_INVOICE_CREATE_REQUEST = "WALKIN_SALES_INVOICE_CREATE_REQUEST";
export const WALKIN_SALES_INVOICE_CREATE_SUCCESS = "WALKIN_SALES_INVOICE_CREATE_SUCCESS";
export const WALKIN_SALES_INVOICE_CREATE_FAIL = "WALKIN_SALES_INVOICE_CREATE_FAIL";
export const WALKIN_SALES_INVOICE_CREATE_RESET = "WALKIN_SALES_INVOICE_CREATE_RESET";

export const WALKIN_SALES_INVOICE_BY_POS_NAME_REQUEST =
  "WALKIN_SALES_INVOICE_BY_POS_NAME_REQUEST";
export const WALKIN_SALES_INVOICE_BY_POS_NAME_SUCCESS =
  "WALKIN_SALES_INVOICE_BY_POS_NAME_SUCCESS";
export const WALKIN_SALES_INVOICE_BY_POS_NAME_FAIL =
  "WALKIN_SALES_INVOICE_BY_POS_NAME_FAIL";
  export const WALKIN_SALES_INVOICE_BY_POS_NAME_RESET =
  "WALKIN_SALES_INVOICE_BY_POS_NAME_RESET";

export const WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST = "WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST";
export const WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS = "WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS";
export const WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL = "WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL";
export const WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET = "WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET";

export const WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST = "WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST";
export const WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS = "WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS";
export const WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_FAIL = "WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_FAIL";
export const WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_RESET = "WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_RESET";


export const WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST = "WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST";
export const WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS = "WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS";
export const WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_FAIL = "WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_FAIL";
export const WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_RESET = "WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_RESET";

export const WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST = "WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST";
export const WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS = "WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS";
export const WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL = "WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL";
export const WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_RESET = "WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_RESET";
