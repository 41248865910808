import {
  WAREHOUSE_STORE_INVOICE_LIST_REQUEST,
  WAREHOUSE_STORE_INVOICE_LIST_SUCCESS,
  WAREHOUSE_STORE_INVOICE_LIST_FAIL,
  
  WAREHOUSE_STORE_INVOICE_BY_ID_REQUEST,
  WAREHOUSE_STORE_INVOICE_BY_ID_SUCCESS,
  WAREHOUSE_STORE_INVOICE_BY_ID_FAIL,

  WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_REQUEST,
  WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_SUCCESS,
  WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_FAIL,

  WAREHOUSE_STORE_INVOICE_CREATE_RESET,
  WAREHOUSE_STORE_INVOICE_CREATE_FAIL,
  WAREHOUSE_STORE_INVOICE_CREATE_SUCCESS,
  WAREHOUSE_STORE_INVOICE_CREATE_REQUEST,

  WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_REQUEST,
  WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_SUCCESS,
  WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_FAIL,
  WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_RESET,


} from "../constants/warehouseConstants";

export const warehouseInvoicesListReducer = (state = { warehouseInvoices: [] }, action) => {
  switch (action.type) {
    case WAREHOUSE_STORE_INVOICE_LIST_REQUEST:
      return { loading: true, warehouseInvoices: [] };
    case WAREHOUSE_STORE_INVOICE_LIST_SUCCESS:
      return {
        loading: false,
        warehouseInvoices: action.payload,
      };
    case WAREHOUSE_STORE_INVOICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const warehouseInvoiceByIdReducer = (
  state = { wi_data: {} },
  action
) => {
  switch (action.type) {
    case WAREHOUSE_STORE_INVOICE_BY_ID_REQUEST:
      return { wi_loading: true, wi_data:{}  };
    case WAREHOUSE_STORE_INVOICE_BY_ID_SUCCESS:
      return {
        wi_loading: false,
        wi_data: action.payload,
      };
    case WAREHOUSE_STORE_INVOICE_BY_ID_FAIL:
      return { wi_loading: false, wi_error: action.payload };
    default:
      return state;
  }
};

export const warehouseInvoiceDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_REQUEST:
      return { loading: true };
    case WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_SUCCESS:
      return { loading: false, success: true };
    case WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const warehouseInvoiceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_STORE_INVOICE_CREATE_REQUEST:
      return { wi_create_loading: true };
    case WAREHOUSE_STORE_INVOICE_CREATE_SUCCESS:
      return { wi_create_loading: false, wi_create_success: true, wi_create_data: action.payload };
    case WAREHOUSE_STORE_INVOICE_CREATE_FAIL:
      return { wi_create_loading: false, wi_create_error: action.payload };
    case WAREHOUSE_STORE_INVOICE_CREATE_RESET:
      return { wi_create_loading: false, wi_create_success: false, wi_create_data: {}};
    default:
      return state;
  }
};

export const warehouseInvoiceUpdateByIdReducer = (state = { warehouseInvoice: {} }, action) => {
  switch (action.type) {
    case WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, warehouseInvoice: action.payload };
    case WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_RESET:
      return { warehouseInvoice: {} };
    default:
      return state;
  }
};

// export const warehouseInfoListReducer = (state = { warehouseInfos: [] }, action) => {
//   switch (action.type) {
//     case WAREHOUSE_STORE_INFO_LIST_REQUEST:
//       return { loading: true, warehouseInfos: [] };
//     case WAREHOUSE_STORE_INFO_LIST_SUCCESS:
//       return {
//         loading: false,
//         warehouseInfos: action.payload,
//       };
//     case WAREHOUSE_STORE_INFO_LIST_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

// export const warehouseInfoByIdReducer = (
//   state = { wi_data: {} },
//   action
// ) => {
//   switch (action.type) {
//     case WAREHOUSE_STORE_INFO_BY_ID_REQUEST:
//       return { wi_loading: true, wi_data:{}  };
//     case WAREHOUSE_STORE_INFO_BY_ID_SUCCESS:
//       return {
//         wi_loading: false,
//         wi_data: action.payload,
//       };
//     case WAREHOUSE_STORE_INFO_BY_ID_FAIL:
//       return { wi_loading: false, wi_error: action.payload };
//     default:
//       return state;
//   }
// };

// export const warehouseInfoDeleteByIdReducer = (state = {}, action) => {
//   switch (action.type) {
//     case WAREHOUSE_STORE_INFO_DELETE_BY_ID_REQUEST:
//       return { loading: true };
//     case WAREHOUSE_STORE_INFO_DELETE_BY_ID_SUCCESS:
//       return { loading: false, success: true };
//     case WAREHOUSE_STORE_INFO_DELETE_BY_ID_FAIL:
//       return { loading: false, error: action.payload };
//     default:
//       return state;
//   }
// };

// export const warehouseInfoUpdateByIdReducer = (state = { warehouseInfo: {} }, action) => {
//   switch (action.type) {
//     case WAREHOUSE_STORE_INFO_UPDATE_BY_ID_REQUEST:
//       return { loading: true };
//     case WAREHOUSE_STORE_INFO_UPDATE_BY_ID_SUCCESS:
//       return { loading: false, success: true, warehouseInfo: action.payload };
//     case WAREHOUSE_STORE_INFO_UPDATE_BY_ID_FAIL:
//       return { loading: false, error: action.payload };
//     case WAREHOUSE_STORE_INFO_UPDATE_BY_ID_RESET :
//       return { warehouseInfo: {} };
//     default:
//       return state;
//   }
// };

// export const warehouseInfoCreateReducer = (state = {}, action) => {
//   switch (action.type) {
//     case WAREHOUSE_STORE_INFO_CREATE_REQUEST:
//       return { wi_create_loading: true };
//     case WAREHOUSE_STORE_INFO_CREATE_SUCCESS:
//       return { wi_create_loading: false, wi_create_success: true, wi_create_data: action.payload };
//     case WAREHOUSE_STORE_INFO_CREATE_FAIL:
//       return { wi_create_loading: false, wi_create_error: action.payload };
//     case WAREHOUSE_STORE_INFO_CREATE_RESET:
//       return {};
//     default:
//       return state;
//   }
// };

