import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmpayType } from "../../actions/cartAction";
import {
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Button,
  Tooltip,
  TextField,
  Typography,
  Input,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import validate from "validate.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },

  control: {
    padding: theme.spacing(2),
  },
}));

const PaymentDetailsScreen = (props) => {
  console.log("props passed to PaymentDetailsScreen : ", props);
  const { payType, payMode, isUpi, isCash, isCheque } = props;
  console.log(
    "payType:",
    payType,
    "payMode:",
    payMode,
    isUpi,
    isCash,
    isCheque
  );
  // const cart = useSelector((state) => state.cart);
  const cart = [];
  const classes = useStyles();

  const schema = {
    upiAmount: {
      presence: { allowEmpty: true },
      length: {
        maximum: 9,
      },
    },
    cashAmount: {
      presence: { allowEmpty: true },
      length: {
        maximum: 9,
      },
    },
    chequeAmount: {
      presence: { allowEmpty: true },
      length: {
        maximum: 6,
      },
    },
    txnRefNo: {
      presence: { allowEmpty: true, message: "is required" },
      length: {
        maximum: 300,
      },
    },
    chequeNo: {
      presence: { allowEmpty: true, message: "is required" },
      length: {
        maximum: 300,
      },
    },
    bankName: {
      presence: { allowEmpty: true, message: "is required" },
      length: {
        maximum: 300,
      },
    },
    paidTo: {
      presence: { allowEmpty: true, message: "is required" },
      length: {
        maximum: 300,
      },
    },
  };
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    console.log("Updated formState : ", formState);
  }, []);

  const clearFormValueForSinglePayType = ()=> {
    console.log("clearFormValueForSinglePayType ");
    setFormState((prev)=>({ ...prev, values: {}}));
  }

  const handleChange = (event) => {
    event.persist();
    setFormState((prevState) => ({
      ...prevState,
      values: { ...prevState.values, [event.target.name]: event.target.value },
    }));
  };

  const renderUpiDetails =  //clearFormValueForSinglePayType && 
  (
    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <Typography
          variant="h5"
          style={{ textAlign: "left", fontSize: "1rem", margin:"1rem" }}
        >
          UPI Details
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Amount"
          required
          variant="outlined"
          size="small"
          name="upiAmount"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.upiAmount || ""}
          type="number"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Upi Ref"
          required
          variant="outlined"
          size="small"
          name="upiRef"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.upiRef || ""}
          type="text"
        />
      </GridItem>
    </Grid>
  );
  const renderCashDetails = //clearFormValueForSinglePayType && 
  (
    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <Typography
          variant="h5"
          style={{ textAlign: "left", fontSize: "1rem" ,margin:"1rem"}}
        >
          Cash Details
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Amount"
          required
          variant="outlined"
          size="small"
          name="cashAmount"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.cashAmount || ""}
          type="number"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Paid To"
          required
          variant="outlined"
          size="small"
          name="paidTo"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.paidTo || ""}
          type="text"
        />
      </GridItem>
    </Grid>
  );
  const renderChequeDetails = (
    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <Typography
          variant="h5"
          style={{ textAlign: "left", fontSize: "1rem" ,margin:"1rem"}}
        >
          Cheque Details
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Cheque No"
          required
          variant="outlined"
          size="small"
          name="chequeNo"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.chequeNo || ""}
          type="text"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Bank Name"
          required
          variant="outlined"
          size="small"
          name="bankName"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.bankName || ""}
          type="text"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Amount"
          required
          variant="outlined"
          size="small"
          name="chequeAmount"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.chequeAmount || ""}
          type="number"
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} style={{ marginBottom: "1rem" }}>
        <TextField
          label="Paid to"
          required
          variant="outlined"
          size="small"
          name="chequePaidTo"
          style={{ width: "15rem" }}
          onChange={handleChange}
          value={formState.values.chequePaidTo || ""}
          type="text"
        />
      </GridItem>
    </Grid>
  );
  const renderConfirmDetails = (
    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
      <Grid xs={12} sm={12} md={12} style={{ marginTop: "1rem" }}>
        <Button
          size="small"
          variant="contained"
          type="submit"
          color="secondary"
          style={{
            textTransform: "capitalize",
            width: "23vh",
            borderRadius: "5px",
          }}
          onClick={() => {
            console.log("Clicked Button", payType, payMode, formState.values);
            const selectedCbs = formState;
            props.paymentListener(
              payType,
              payMode,
              formState.values,
              selectedCbs
            );
          }}
        >
          Confirm Pay Details
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ margin: "1rem" }}>
            {payMode === "Multiple" && isUpi === true ? renderUpiDetails : ""}
          </Paper>
          <Paper style={{ margin: "1rem" }}>
            {payMode === "Single" && payType === "upi" ? renderUpiDetails : ""}
          </Paper>
          <Paper style={{ margin: "1rem" }}>
            {payMode === "Multiple" && isCash === true ? renderCashDetails : ""}
          </Paper>
          <Paper style={{ margin: "1rem" }}>
            {payMode === "Single" && payType === "cash"
              ? renderCashDetails
              : ""}
          </Paper>
          <Paper style={{ margin: "1rem" }}>
            {payMode === "Multiple" && isCheque === true
              ? renderChequeDetails
              : ""}
          </Paper>
          <Paper style={{ margin: "1rem" }}>
            {payMode === "Single" && payType === "cheque"
              ? renderChequeDetails
              : ""}
          </Paper>
        </GridItem>
        {(payMode === "Single" || payMode === "Multiple") &&
          (isUpi === true ||
            isCash === true ||
            isCheque === true ||
            payType === "upi" ||
            payType === "cash" ||
            payType === "cheque") &&
          renderConfirmDetails}
      </Grid>
    </React.Fragment>
  );
};

export default PaymentDetailsScreen;
