import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_ITEMS_COUNT,
  CART_EDIT_ITEM,
  CART_CLEAR_ITEMS,
  CART_INCREMENT_ITEM_QUANTITY,
  CART_DECREMENT_ITEM_QUANTITY
} from "../constants/cartConstants";

export const addToCart = (item, qtyUpdated) => async (
  dispatch,
  getState
) => {
  console.log(" About to Add this Item to Cart ::=>> ", item);
  const { name, orderTypeSelected, uom, quantity, unitPrice, discountPercent, discountStartsFrom, itemsDiscountPrice, cgst, cgstPrice, sgst, sgstPrice, igst, igstPrice, tcs, tcsPrice, productTax, productTaxAmount, itemCost, inKgs } = item;
  console.log("quantity, unitPrice, discountPercent, discountStartsFrom, cgst, cgstPrice, sgst, sgstPrice, igst, igstPrice, tcs, tcsPrice, itemCost : ", quantity, orderTypeSelected, uom, unitPrice, discountPercent, discountStartsFrom, cgst, cgstPrice, sgst, sgstPrice, igst, igstPrice, tcs, tcsPrice, productTax, productTaxAmount, itemCost);
  console.log("uom  : ", uom, " orderTypeSelected : ", orderTypeSelected);
  console.log("itemsDiscountPrice : ", itemsDiscountPrice, " inKgs : ", inKgs);
  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      name,
      uom,
      orderTypeSelected,
      unitPrice: unitPrice,
      quantity: quantity ? quantity : qtyUpdated,
      discountPercent,
      discountStartsFrom,
      cgst,
      cgstPrice,
      sgst,
      sgstPrice,
      igst,
      igstPrice,
      tcs,
      tcsPrice,
      productTax,
      productTaxAmount,
      itemsDiscountPrice,
      inKgs,
      itemCost
    },
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const removeFromCart = (name) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: name,
  })
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const resetCartItems = () => (dispatch, getState) => {
  dispatch({
    type: CART_CLEAR_ITEMS,
  })
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}

export const editCartItems = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_EDIT_ITEM,
    payload: id,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (address, city, postalCode) => (dispatch) => {
  console.log("EXEC saveShippingAddress..!")
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: { address, city, postalCode },
  });

  localStorage.setItem("shippingAddress", JSON.stringify({ address, city, postalCode }));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};

export const fetchUpdatedCartItemsCount = () => (dispatch, getState) => {
  dispatch({
    type: CART_ITEMS_COUNT,
    payload: getState().cart.cartItems.cartItemsCount,
  });
};

export const incrementQuantity = (item) => async (
  dispatch,
  getState
) => {
  console.log("incrementQuantity ..! Payload : ", item);
  dispatch({
    type: CART_INCREMENT_ITEM_QUANTITY,
    payload: {
      item
    },
  });
}

export const decrementQuantity = (item) => async (
  dispatch,
  getState
) => {
  console.log("decrementQuantity ..! Payload : ", item);
  dispatch({
    type: CART_DECREMENT_ITEM_QUANTITY,
    payload: {
      item
    },
  });
}
