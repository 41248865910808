import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography, Divider } from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { createVehicle } from "../../actions/vehicleAction";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { VEHICLE_CREATE_RESET } from "../../constants/vehicleConstants";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { InputLabel as MuiInputLabel, styled } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  vehicleNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
  chasisNumber: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  reportingBranch: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  make: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 30,
    },
  },
  model: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 30,
    },
  },
  // pollutionExpiryDate: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 30,
  //   },
  // },
  // fitnessExpiry: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 30,
  //   },
  // },
  // insuranceExpiry: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 30,
  //   },
  // },
  // roadTaxExpiry: {
  //   presence: { allowEmpty: false, message: "is required" },
  //   length: {
  //     maximum: 30,
  //   },
  // },
  lastServiceDate: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 30,
    },
  },
  kmDrivenAfterService: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 30,
    },
  }

};

const VehicleForm = (props) => {
  console.log("Exec VehicleForm");

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [insuranceExpiryDate, setInsuranceExpiryDate] = useState(() => "");
  const [pollutionExpiryDate, setPollutionExpiryDate] = useState(() => "");
  const [fitnessExpiryDate, setFitnessExpiryDate] = useState(() => "");
  const [roadTaxExpiryDate, setRoadTaxExpiryDate] = useState(() => "");


  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const vehicleCreated = useSelector((state) => state.vehicleCreated);
  const { loading, success_create, vehicle } = vehicleCreated;

  useEffect(() => {
    if (success_create) {
      dispatch({ type: VEHICLE_CREATE_RESET });
      navigate("/admin/vehicle/list");
    }
  }, [navigate, success_create]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(formState.values);
      dispatch(
        createVehicle(
          formState.values.vehicleNo,
          formState.values.reportingBranch,
          formState.values.chasisNumber,
          formState.values.make,
          formState.values.model,
          pollutionExpiryDate,
          fitnessExpiryDate,
          insuranceExpiryDate,
          roadTaxExpiryDate,
          formState.values.lastServiceDate,
          formState.values.kmDrivenAfterService
        )
      );
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody style={{ marginTop: "1rem" }}>
              <form
                name="password-reset-form"
                method="post"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                  
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Vehicle No "
                      variant="outlined"
                      size="small"
                      name="vehicleNo"
                      fullWidth
                      helperText={
                        hasError("vehicleNo")
                          ? formState.errors.vehicleNo[0]
                          : null
                      }
                      error={hasError("vehicleNo")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.vehicleNo || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Reporting Branch "
                      variant="outlined"
                      size="small"
                      name="reportingBranch"
                      fullWidth
                      helperText={
                        hasError("reportingBranch") ? formState.errors.reportingBranch[0] : null
                      }
                      error={hasError("reportingBranch")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.reportingBranch || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Chasis Number "
                      variant="outlined"
                      size="small"
                      name="chasisNumber"
                      fullWidth
                      helperText={
                        hasError("chasisNumber") ? formState.errors.chasisNumber[0] : null
                      }
                      error={hasError("chasisNumber")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.chasisNumber || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Make "
                      variant="outlined"
                      size="small"
                      name="make"
                      fullWidth
                      helperText={
                        hasError("make") ? formState.errors.make[0] : null
                      }
                      error={hasError("make")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.make || ""}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={4} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker value={pollutionExpiryDate} label="Pollution Check" style={{ width: "25vh", margin: "1rem" }} size="small"  
      // onChange={(newValue) => {
      //                   console.log(new Date(newValue).toISOString().split("T")[0])
      //                   setPollutionExpiryDate(new Date(newValue).toISOString().split("T")[0]);
      //                 }} 
                      />
    </LocalizationProvider>
    </Grid> */}

                   <Grid item xs={12} sm={4} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker value={pollutionExpiryDate} label="Pollution Check" style={{ width: "25vh", margin: "1rem" }} size="small" onChange={(newValue) => {
                        console.log(new Date(newValue).toISOString().split("T")[0])
                        console.log(new Date(newValue).toLocaleDateString())
                        setPollutionExpiryDate(new Date(newValue).toLocaleDateString());
                      }} renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid> 
                
                  <Grid item xs={12} sm={4} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker value={fitnessExpiryDate} label="Fitness Expiry" style={{ width: "25vh", margin: "1rem" }} size="small" onChange={(newValue) => {
                        setFitnessExpiryDate(new Date(newValue).toLocaleDateString());
                      }} renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                
                  <Grid item xs={12} sm={4} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker value={insuranceExpiryDate} label="Insurance Expiry" style={{ width: "25vh", margin: "1rem" }} size="small" onChange={(newValue) => {
                        setInsuranceExpiryDate(new Date(newValue).toLocaleDateString());
                      }} renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                
                  <Grid item xs={12} sm={4} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker value={roadTaxExpiryDate} label="Road Tax Expiry" style={{ width: "25vh", margin: "1rem" }} size="small" onChange={(newValue) => {
                        setRoadTaxExpiryDate(new Date(newValue).toLocaleDateString());
                      }} renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </LocalizationProvider>
                    </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Model "
                      variant="outlined"
                      size="small"
                      name="model"
                      fullWidth
                      helperText={
                        hasError("model") ? formState.errors.model[0] : null
                      }
                      error={hasError("model")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.model || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Last Service Date"
                      variant="outlined"
                      size="small"
                      name="lastServiceDate"
                      fullWidth
                      helperText={
                        hasError("lastServiceDate")
                          ? formState.errors.lastServiceDate[0]
                          : null
                      }
                      error={hasError("lastServiceDate")}
                      onChange={handleChange}
                      type="string"
                      value={formState.values.lastServiceDate || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Kms Driven After Service"
                      variant="outlined"
                      size="small"
                      name="kmDrivenAfterService"
                      fullWidth
                      helperText={
                        hasError("kmDrivenAfterService")
                          ? formState.errors.kmDrivenAfterService[0]
                          : null
                      }
                      error={hasError("kmDrivenAfterService")}
                      onChange={handleChange}
                      type="string"
                      value={formState.values.kmDrivenAfterService || ""}
                    />
                  </Grid>

                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default VehicleForm;
