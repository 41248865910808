import {
NEW_STOCK_NOTIFICATION_LIST_REQUEST,
NEW_STOCK_NOTIFICATION_LIST_SUCCESS,
NEW_STOCK_NOTIFICATION_LIST_FAIL,
NEW_STOCK_NOTIFICATION_LIST_RESET,
NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_REQUEST,
NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_SUCCESS,
NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_RESET,
NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_FAIL,
NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_REQUEST,
NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_SUCCESS,
NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_FAIL,
NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_RESET

} from "../constants/newStockNotificationConstants";

export const newStockNotificationListReducer = (
  state = { new_stock_list: [] },
  action
) => {
  switch (action.type) {
    case NEW_STOCK_NOTIFICATION_LIST_REQUEST:
      return { new_stock_list_loading: true, new_stock_list:[]  };
    case NEW_STOCK_NOTIFICATION_LIST_SUCCESS:
      return {
        new_stock_list_loading: false,
        new_stock_list: action.payload,
      };
    case NEW_STOCK_NOTIFICATION_LIST_FAIL:
      return { new_stock_list_loading: false, new_stock_list_error: action.payload };
    default:
      return state;
  }
};


export const newStockNotificationCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_REQUEST:
      return { new_stock_create_loading: true };
    case NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_SUCCESS:
      return { new_stock_create_loading: false, new_stock_create_success: true, new_stock: action.payload };
    case NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_FAIL:
      return { new_stock_create_loading: false, new_stock_create_error: action.payload };
    case NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_RESET:
      return { new_stock_create_loading: false, new_stock_create_success: false, new_stock: {} };
    default:
      return state;
  }
};

export const newStockNotificationUpdateConfirmStatusReducer = (state = { new_notification_stock_updated: {} }, action) => {
  switch (action.type) {
    case NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_REQUEST:
      return { new_notification_stock_update_loading: true };
    case NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_SUCCESS:
      return { new_notification_stock_update_loading: false, new_notification_stock_update_success: true, new_notification_stock_: action.payload };
    case NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_FAIL:
      return { new_notification_stock_update_loading: false, new_notification_stock_update_error: action.payload };
    case NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_RESET:
      return { new_notification_stock_updated: {} };
    default:
      return state;
  }
};

 /**
export const transitStockDetailsByIdReducer = (
  state = { transit_stock_by_id: {} },
  action
) => {
  switch (action.type) {
    case TRANSIT_STOCK_DETAILS_BY_ID_REQUEST:
      return { transit_stock_by_id_loading: true, transit_stock_by_id:{}  };
    case TRANSIT_STOCK_DETAILS_BY_ID_SUCCESS:
      return {
        transit_stock_by_id_loading: false,
        transit_stock_by_id: action.payload,
      };
    case TRANSIT_STOCK_DETAILS_BY_ID_FAIL:
      return { transit_stock_by_id_loading: false, 
        transit_stock_by_id_error: action.payload };
    case TRANSIT_STOCK_DETAILS_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const transitStockOutUpdateByVehicleNoReducer = (state = { transit_stock: {} }, action) => {
  switch (action.type) {
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST:
      return { transit_stock_out_update_loading: true };
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS:
      return { transit_stock_out_update_loading: false, success: true, transit_stock_out_update_success: action.payload };
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL:
      return { transit_stock_out_update_loading: false, transit_stock_out_update_error: action.payload };
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET:
      return { transit_stock: {} };
    default:
      return state;
  }
};

export const transitStockInUpdateByVehicleNoReducer = (state = { transit_stock: {} }, action) => {
  switch (action.type) {
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST:
      return { transit_stock_in_loading: true };
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS:
      return { transit_stock_in_loading: false, transit_stock_in_success: true, transit_stock_in_data: action.payload };
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL:
      return { transit_stock_in_loading: false, transit_stock_in_error: action.payload };
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET:
      return {};
    default:
      return state;
  }
};

export const transitStockListByVehicleNoSourceDestinationReducer = (
  state = { transit_stock_by_vehicle_no_source_dest: [] },
  action
) => {
  switch (action.type) {
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_REQUEST:
      return { transit_stock_by_vehicle_no_source_dest_loading: true, transit_stock_by_vehicle_no_source_dest:[]  };
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_SUCCESS:
      return {
        transit_stock_by_vehicle_no_source_dest_loading: false,
        transit_stock_by_vehicle_no_source_dest: action.payload,
      };
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_FAIL:
      return { transit_stock_by_vehicle_no_source_dest_loading: false, transit_stock_by_vehicle_no_source_dest_error: action.payload };
    default:
      return state;
  }
};
 */
