import axios from "axios";
import {
  WALKIN_SALES_INVOICE_LIST_REQUEST,
  WALKIN_SALES_INVOICE_LIST_SUCCESS,
  WALKIN_SALES_INVOICE_LIST_FAIL,
  WALKIN_SALES_INVOICE_CREATE_REQUEST,
  WALKIN_SALES_INVOICE_CREATE_SUCCESS,
  WALKIN_SALES_INVOICE_CREATE_RESET,
  WALKIN_SALES_INVOICE_CREATE_FAIL,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
  WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST,
  WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS,
  WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL,
  WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST,
  WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS,
  WALKIN_SALE_INVOICE_DETAILS_UPDATE_BY_INVOICE_ID_FAIL,
  WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST,
  WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS,
  WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_FAIL
} from "../constants/walkinSalesInvoiceConstants";

export const  createWalkinSaleInvoice =
    (order) => async (dispatch) => {
    console.log( "createWalkinSaleInvoice -start from Store | Warehouse Invoice Action ",order );
    const { customerDetails, invoiceDetails, itemsPrice, paymentDetails,  productsInCart, taxPrice, posName } = order
    console.log(
      "createVehicleSalesInvoice -start ",
      customerDetails, invoiceDetails, itemsPrice, paymentDetails,  productsInCart, taxPrice, posName
    );
    try {
      dispatch({ type: WALKIN_SALES_INVOICE_CREATE_REQUEST });
      const { data } = await axios.post(`/api/walkin-sales-invoice/${posName}`, {
        invoiceDetails,
        customerDetails,
        productsInCart,
        paymentDetails,
        taxPrice,
        itemsPrice
      });
      dispatch({
        type: WALKIN_SALES_INVOICE_CREATE_SUCCESS,
        payload: data,
      });
      // dispatch({ type: WALKIN_SALES_INVOICE_CREATE_RESET });
      console.log("create INVOICE - end ");
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WALKIN_SALES_INVOICE_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const listWalkinSaleInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: WALKIN_SALES_INVOICE_LIST_REQUEST });
    const { data } = await axios.get("/api/walkin-sales-invoice");
    dispatch({
      type: WALKIN_SALES_INVOICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALKIN_SALES_INVOICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getWalkinSalesInvoiceDetailsById = (invoiceId) => async (dispatch) => {
  console.log('getWalkinSalesInvoiceDetailsById : invoiceId',invoiceId);
  try {
    dispatch({ type: WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST });
    const { data } = await axios.get(`/api/walkin-sales-invoice/details/${invoiceId}`);

    dispatch({
      type: WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateWalkinSalesInvoicePaymentDetailsById = (walkinInvPayDetails) => async (dispatch) => {
  const {id, paymntDetails} = walkinInvPayDetails
  console.log("Inside updateOrderPaymentDetailsById ...",id,  paymntDetails);
  try {
    dispatch({ type: WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST });
    const { data } = await axios.put(`/api/walkin-sales-invoice/${id}/payment`, {
      paymntDetails
    });
    dispatch({
      type: WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listWalkinSaleInvoicesByPosName = (pos) => async (dispatch) => {
  try {
    dispatch({ type: WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST });
    const { data } = await axios.get(`/api/walkin-sales-invoice/${pos}`);

    dispatch({
      type: WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ");
    console.log(data);
  } catch (error) {
    dispatch({
      type: WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


