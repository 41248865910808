import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField } from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { createStockByProductId } from "../../actions/stockAction";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import {
  CREATE_STOCK_BY_PRODUCT_ID_REQUEST,
  CREATE_STOCK_BY_PRODUCT_ID_FAIL,
  CREATE_STOCK_BY_PRODUCT_ID_RESET,
  CREATE_STOCK_BY_PRODUCT_ID_SUCCESS,
} from "../../constants/stockConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  productName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  uom: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  countInStock: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 9,
    },
  },
  product: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
};

const StockForm = (props) => {
  console.log("Exec StockForm");

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const stockCreated = useSelector((state) => state.stockCreated);
  const { loading, success_create, stock } = stockCreated;

  useEffect(() => {
    if (success_create) {
      dispatch({ type: CREATE_STOCK_BY_PRODUCT_ID_RESET });
      navigate("/admin/stock");
    }
  }, [navigate, success_create]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(formState.values);
      dispatch(
        createStockByProductId(
          formState.values.product,
          formState.values.productName,
          formState.values.uom,
          formState.values.countInStock
        )
      );
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}> New Stock </h4>
            </CardHeader>
            <CardBody>
              <form
                name="password-reset-form"
                method="post"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      placeholder="Product Name"
                      label="Product Name *"
                      variant="outlined"
                      size="small"
                      name="productName"
                      fullWidth
                      helperText={
                        hasError("productName")
                          ? formState.errors.productName[0]
                          : null
                      }
                      error={hasError("productName")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.productName || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      placeholder="UOM"
                      label="UOM *"
                      variant="outlined"
                      size="small"
                      name="uom"
                      fullWidth
                      helperText={
                        hasError("uom") ? formState.errors.uom[0] : null
                      }
                      error={hasError("uom")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.uom || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      placeholder="Count In Stock"
                      label="Count In Stock *"
                      variant="outlined"
                      size="small"
                      name="countInStock"
                      fullWidth
                      helperText={
                        hasError("countInStock")
                          ? formState.errors.countInStock[0]
                          : null
                      }
                      error={hasError("countInStock")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.countInStock || ""}
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default StockForm;
