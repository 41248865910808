import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    background: theme.palette.common.black,
    color: theme.palette.common.black,
    width: "100%",
    zIndex: 1302,
    position: "relative",
  },
  mainContainer: {
    position: "absolute",
    backgroundColor: "#26A541",
  },
  link: {
    color: "darkgray",
    fontFamily: "Arial",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none",
  },
  gridItem: {
    margin: "3em",
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer className={classes.footer} style={{ position: "relative", bottom: '0px' }} >
      <Grid container justify="center" style={{ backgroundColor: "white", justifyContent: "center" }}>
        <div>
          <h3 style={{
            textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize: "0.75rem", fontWeight: "600", letterSpacing: "1px"
          }}>
            All rights reserved by novellogiks.com Copyright &copy; 2024 , Ver : 2.0.0 , 10-10-2024.
          </h3>
        </div>
      </Grid>
    </footer>
  );
}
