import React, { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardBody from "../../Card/CardBody";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import {
  Tooltip,
  Typography,
  Divider,
  Grid,
  Button,
  TextField,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import {
  listAllWarehouses,
  updateWarehouseInfos,
} from "../../../actions/warehouseInfoAction";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Message from "../../Message.js";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const WarehouseInfoListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "WarehouseName",
      headerName: "Warehouse Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "PhoneNo",
      headerName: "Phone No",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Address",
      headerName: "Address ",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
    },
    {
      field: "ManagerName",
      headerName: "Manager Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: "PostalCode",
      headerName: "Postal Code",
      resizable: true,
      suppressSizeToFit: true,
      width: 180,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      field: "ManagerEmail",
      headerName: "Manager Email",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Edit Warehouse" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Delete" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("Deleting ", params.value)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];

  const export_label = "Export > CSV";

  const [open, setOpen] = useState(() => false);
  const [filteredWarehouse, setFilteredWarehouse] = useState(() => {
    return "";
  });

  useEffect(() => {
    dispatch(listAllWarehouses());
  }, [dispatch]);

  const warehouseInfoList = useSelector((state) => state.warehouseInfoList);
  const { loading_wh_infos, warehouseInfos, error } = warehouseInfoList;
  if (warehouseInfos) console.log("warehouseInfos : ", warehouseInfos);

  const createWarehouseHandler = (category) => {
    navigate("/admin/warehouse/new");
  };
  const warehouseNameChangeHandler = (nm) => {
    setFilteredWarehouse({ ...filteredWarehouse, warehousesName: nm });
  };

  const phoneNoChangeHandler = (ph) => {
    setFilteredWarehouse({ ...filteredWarehouse, phoneNo: ph });
  };

  const addressChangeHandler = (addres) => {
    setFilteredWarehouse({ ...filteredWarehouse, address: addres });
  };

  const managerNameChangeHandler = (managerName) => {
    setFilteredWarehouse({ ...filteredWarehouse, managerName: managerName });
  };
  const postalCodeChangeHandler = (postalCode) => {
    setFilteredWarehouse({ ...filteredWarehouse, postalCode: postalCode });
  };

  const managerEmailChangeHandler = (managerEmail) => {
    setFilteredWarehouse({
      ...filteredWarehouse,
      managerEmail: managerEmail,
    });
  };

  const handleEdit = (params) => {
    const {data} = params;
    setOpen(true);
    console.log("data : " , data); 
    setFilteredWarehouse({
      _id: params.data.Edit,
      warehouseName: params.data.WarehouseName,
      address: params.data.Address,
      phoneNo: params.data.PhoneNo,
      managerName: params.data.ManagerName,
      managerEmail: params.data.ManagerEmail,
      postalCode: params.data.PostalCode
    });
  };
  const showWarehouseInfoDetails = (id) => {
    console.log("Clicked showWarehouseInfoDetails ", id);
    navigate(`/admin/warehouse/${id}`);
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    console.log(" ********* filteredWarehouse *********  : ",filteredWarehouse);
    dispatch(
      updateWarehouseInfos(
        filteredWarehouse.warehouseName,
        filteredWarehouse.address,
        filteredWarehouse.phoneNo,
        filteredWarehouse.managerName,
        filteredWarehouse.managerEmail,
        filteredWarehouse.postalCode,
      )
    );
    setOpen(false);
    setFilteredWarehouse({});
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };
  if (warehouseInfos && warehouseInfos.length > 0) {
    rowData = warehouseInfos.map((eachWarehouseInfo, index) => {
      return {
        "#": index + 1,
        WarehouseName: eachWarehouseInfo.warehouseName,
        Address: eachWarehouseInfo.address,
        PhoneNo: eachWarehouseInfo.phoneNo,
        ManagerName: eachWarehouseInfo.managerName,
        PostalCode: eachWarehouseInfo.postalCode,
        ManagerEmail: eachWarehouseInfo.managerEmail,
        Edit: eachWarehouseInfo._id,
        Delete: eachWarehouseInfo._id
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
      </div>
    );
  }

  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading_wh_infos && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
          <Tooltip title="Create New Warehouse" arrow>
            <AddCircleIcon
              fontSize="medium"
              style={{ float: "left" }}
              // onClick={()=>navigate(`/admin/wh-info/new`)}
              onClick={createWarehouseHandler}
            />
          </Tooltip>
          
        </GridItem>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
              <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em" }}
                    />
                  </Tooltip>
                  Warehouses Information
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "1rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader>
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    ></Typography>
                    <h2 align="center"> Edit Warehouses</h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={submitHandler}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        margin="auto"
                      >
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Warehouse Name"
                            placeholder="Warehouse Name"
                            variant="outlined"
                            name="warehouseName"
                            onChange={(e) =>
                              warehouseNameChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredWarehouse &&
                              filteredWarehouse.warehouseName
                                ? filteredWarehouse.warehouseName
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Phone No"
                            placeholder="Phone No"
                            variant="outlined"
                            name="PhoneNo"
                            onChange={(e) => phoneNoChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredWarehouse && filteredWarehouse.phoneNo
                                ? filteredWarehouse.phoneNo
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Address"
                            placeholder="Address"
                            variant="outlined"
                            name="Address"
                            id="address"
                            onChange={(e) =>
                              addressChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredWarehouse && filteredWarehouse.address
                                ? filteredWarehouse.address
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Manager Name"
                            placeholder="Manager Name"
                            variant="outlined"
                            name="managerName"
                            id="managerName"
                            onChange={(e) =>
                              managerNameChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredWarehouse &&
                              filteredWarehouse.managerName
                                ? filteredWarehouse.managerName
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Postal Code"
                            placeholder="Postal Code"
                            variant="outlined"
                            name="postalCode"
                            id="postalCode"
                            onChange={(e) =>
                              postalCodeChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredWarehouse &&
                              filteredWarehouse.postalCode
                                ? filteredWarehouse.postalCode
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Manager Email"
                            placeholder="Manager Email"
                            variant="outlined"
                            name="managerEmail"
                            id="managerEmail"
                            onChange={(e) =>
                              managerEmailChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredWarehouse &&
                              filteredWarehouse.managerEmail
                                ? filteredWarehouse.managerEmail
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} align="center">
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{
                              textTransform: "capitalize",
                              width: "20vh",
                              borderRadius: "5px",
                            }}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default WarehouseInfoListScreen;
