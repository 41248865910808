import axios from "axios";
import {
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_REQUEST,
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SUCCESS,
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_FAIL,
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_RESET,

  TRANSIT_STOCK_DETAILS_BY_ID_REQUEST,
  TRANSIT_STOCK_DETAILS_BY_ID_SUCCESS,
  TRANSIT_STOCK_DETAILS_BY_ID_FAIL,
  TRANSIT_STOCK_DETAILS_BY_ID_RESET,
  TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST,
  TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET,
  TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS,
  TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL,
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_REQUEST,
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_SUCCESS,
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_FAIL,
  TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_RESET,
  TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
  TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST,
  TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
  TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
  TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_REQUEST,
  TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_SUCCESS,
  TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_FAIL,
  TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_RESET
} from "../constants/transitStockConstants";

export const listStockByVehicleNo = (vehicleNo) => async (dispatch) => {
  try {
    console.log('vehicleNo : ', vehicleNo);
    dispatch({ type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_REQUEST });
    const { data } = await axios.get(`/api/transit-stock/${vehicleNo}`);
    dispatch({
      type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SUCCESS,
      payload: data,
    });
    dispatch({ type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_RESET });
  } catch (error) {
    dispatch({
      type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStockByVehicleNoSourceDestination = (vehicleNo, source, destination) => async (dispatch) => {
  try {
    console.log('vehicleNo : ', vehicleNo, ' source : ', source, ' destination : ', destination);
    dispatch({ type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_REQUEST });
    const { data } = await axios.get(`/api/transit-stock/${vehicleNo}/${source}/${destination}`);
    dispatch({
      type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_SUCCESS,
      payload: data,
    });
    dispatch({ type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_RESET });
  } catch (error) {
    dispatch({
      type: TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStockById = (id) => async (dispatch) => {
  try {
    console.log('Transit Stock Entry Id  : ', id);
    dispatch({ type: TRANSIT_STOCK_DETAILS_BY_ID_REQUEST });
    const { data } = await axios.get(`/api/transit-stock/entry/${id}`);
    dispatch({
      type: TRANSIT_STOCK_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
    dispatch({ type: TRANSIT_STOCK_DETAILS_BY_ID_RESET });
  } catch (error) {
    dispatch({
      type: TRANSIT_STOCK_DETAILS_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createTransitStockByVehicleNoAndProductName = (invoiceForm, invoiceProducts, fromWhStoreValue, toWhStoreVehicleValue) => async (dispatch) => {
  console.log(
    "createTransitStockByVehicleNoAndProductName -start ",
    invoiceForm,
    invoiceProducts,
    fromWhStoreValue,
    toWhStoreVehicleValue
  );

  /** 
   * LSK : USE THIS WHILE CALCIULATING THE QUANTITY OF THE EACH PRODUCT WHULE INSERTING INTO THE WH-STOCK
   * const resultOfGroupAndSum = groupAndSum(purchaseOrderProducts);
     console.log("resultOfGroupAndSum : ", resultOfGroupAndSum)
  */

  try {
    dispatch({
      type: TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST,
    });
    console.log('Vehicle No : ', toWhStoreVehicleValue);
    const { data } = await axios.post(`/api/transit-stock/${invoiceForm.vehicleNo}/in`, {
      invoiceForm,
      invoiceProducts,
      fromWhStoreValue,
      toWhStoreVehicleValue
    });
    console.log("createStockByVehicleNoAndProductName - end ");

    dispatch({
      type: TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS,
      payload: data,
    });

    dispatch({
      type: TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL,
      payload: message,
    });
  }
};

export const deleteStockByVehicleNo = (vehicleNo) => async (dispatch) => {
  try {
    console.log('vehicleNo : ', vehicleNo);
    dispatch({ type: DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_REQUEST });
    const { data } = await axios.delete(`/api/vehicle-stock/${vehicleNo}/delete`);
    dispatch({
      type: DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_SUCCESS,
      payload: data,
    });
    dispatch({ type: DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_RESET });
  } catch (error) {
    dispatch({
      type: DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateTransitStockStatusToAwaitingConfirmation =
  (
    vehicleNo,
    srcWhStoreName,
    destWhStoreName,
    product
  ) =>
    async (dispatch, getState) => {
      try {
        console.log("Exec updateTransitStockStatusToAwaitingConfirmation Action .!");
        console.log(
          vehicleNo,
          srcWhStoreName,
          destWhStoreName,
          product
        );
        dispatch({
          type: TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_REQUEST
        });

        const { data } = await axios.put(`/api/transit-stock/update/status/awaiting-confirm`, {
          vehicleNo,
          srcWhStoreName,
          destWhStoreName,
          product
        });

        dispatch({
          type: TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Not authorized, token failed") {
        }
        dispatch({
          type: TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_FAIL,
          payload: message,
        });
      }
    };

export const updateTransitStockConfirmStatusByVehicleNoAndProductName =
  (
    vehicleNo,
    productName,
    whStoreName
  ) =>
    async (dispatch, getState) => {
      try {
        console.log("Exec updateTransitStockConfirmStatusByVehicleNoAndProductName Action .!");
        console.log(
          vehicleNo,
          productName,
          whStoreName
        );
        dispatch({
          type: TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST
        });

        const { data } = await axios.put(`/api/vehicle/update-assigned-or-avail/${vehicleNo}`, {
          vehicleNo,
          availabilityStatus,
          whStoreName
        });

        dispatch({
          type: TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Not authorized, token failed") {
        }
        dispatch({
          type: TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
          payload: message,
        });
      }
    };