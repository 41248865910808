export const WAREHOUSE_INFO_LIST_REQUEST = "WAREHOUSE_INFO_LIST_REQUEST";
export const WAREHOUSE_INFO_LIST_SUCCESS = "WAREHOUSE_INFO_LIST_SUCCESS";
export const WAREHOUSE_INFO_LIST_FAIL = "WAREHOUSE_INFO_LIST_FAIL";

export const WAREHOUSE_INFO_BY_ID_REQUEST = "WAREHOUSE_INFO_BY_ID_REQUEST";
export const WAREHOUSE_INFO_BY_ID_SUCCESS = "WAREHOUSE_INFO_BY_ID_SUCCESS";
export const WAREHOUSE_INFO_BY_ID_FAIL = "WAREHOUSE_INFO_BY_ID_FAIL";

export const WAREHOUSE_INFO_BY_NAME_REQUEST = "WAREHOUSE_INFO_BY_NAME_REQUEST";
export const WAREHOUSE_INFO_BY_NAME_SUCCESS = "WAREHOUSE_INFO_BY_NAME_SUCCESS";
export const WAREHOUSE_INFO_BY_NAME_FAIL = "WAREHOUSE_INFO_BY_NAME_FAIL";

export const WAREHOUSE_INFO_DELETE_REQUEST = "WAREHOUSE_INFO_DELETE_REQUEST";
export const WAREHOUSE_INFO_DELETE_SUCCESS = "WAREHOUSE_INFO_DELETE_SUCCESS";
export const WAREHOUSE_INFO_DELETE_FAIL = "WAREHOUSE_INFO_DELETE_FAIL";

export const WAREHOUSE_INFO_CREATE_REQUEST = "WAREHOUSE_INFO_CREATE_REQUEST";
export const WAREHOUSE_INFO_CREATE_SUCCESS = "WAREHOUSE_INFO_CREATE_SUCCESS";
export const WAREHOUSE_INFO_CREATE_FAIL = "WAREHOUSE_INFO_CREATE_FAIL";
export const WAREHOUSE_INFO_CREATE_RESET = "WAREHOUSE_INFO_CREATE_RESET";

export const WAREHOUSE_INFO_UPDATE_REQUEST = "WAREHOUSE_INFO_UPDATE_REQUEST";
export const WAREHOUSE_INFO_UPDATE_SUCCESS = "WAREHOUSE_INFO_UPDATE_SUCCESS";
export const WAREHOUSE_INFO_UPDATE_FAIL = "WAREHOUSE_INFO_UPDATE_FAIL";
export const WAREHOUSE_INFO_UPDATE_RESET = "WAREHOUSE_INFO_UPDATE_RESET";

