import axios from "axios";
import {
  STORE_PURCHASE_ORDER_CREATE_REQUEST,
  STORE_PURCHASE_ORDER_CREATE_SUCCESS,
  STORE_PURCHASE_ORDER_CREATE_FAIL,
  STORE_PURCHASE_ORDER_LIST_REQUEST,
  STORE_PURCHASE_ORDER_LIST_SUCCESS,
  STORE_PURCHASE_ORDER_LIST_FAIL,
  STORE_PURCHASE_ORDER_BY_ID_REQUEST,
  STORE_PURCHASE_ORDER_BY_ID_FAIL,
  STORE_PURCHASE_ORDER_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST,
  STORE_PURCHASE_ORDER_DELETE_BY_ID_FAIL,
  STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST,
  STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL,
  STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS,
  STORE_PURCHASE_ORDER_UPDATE_BY_ID_REQUEST,
  STORE_PURCHASE_ORDER_UPDATE_BY_ID_FAIL,
  STORE_PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_FAIL,
  STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_REQUEST
} from "../constants/storePurchaseOrderConstants";
import {
  STORE_STOCK_BY_STORE_NAME_REQUEST,
  STORE_STOCK_BY_STORE_NAME_RESET,
  STORE_STOCK_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_BY_STORE_NAME_FAIL,
} from "../constants/storeStockConstants";
import { groupAndSum } from "../util/Utility";

export const listStorePurchaseOrders = () => async (dispatch) => {
  try {
    dispatch({ type: STORE_PURCHASE_ORDER_LIST_REQUEST });
    const { data } = await axios.get("/api/store-po");
    dispatch({
      type: STORE_PURCHASE_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STORE_PURCHASE_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStorePurchaseOrderById = (id) => async (dispatch) => {
  try {
    dispatch({ type: STORE_PURCHASE_ORDER_BY_ID_REQUEST });
    const { data } = await axios.get(`/api/store-po/${id}`);

    dispatch({
      type: STORE_PURCHASE_ORDER_BY_ID_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ",data?data.length :0);
  } catch (error) {
    dispatch({
      type: STORE_PURCHASE_ORDER_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStorePurchaseOrdersByPosName = (posName) => async (dispatch) => {
  try {
    dispatch({ type: STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST });
    const { data } = await axios.get(`/api/store-po/pos/${posName}`);

    dispatch({
      type: STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ",data?data.length :0);
  } catch (error) {
    dispatch({
      type: STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteStorePurchaseOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST,
    });

    await axios.delete(`/api/store-po/${id}`);

    dispatch({
      type: STORE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message) {
      dispatch({
        type: STORE_PURCHASE_ORDER_DELETE_BY_ID_FAIL,
        payload: message,
      });
    }
  }
};

export const  createStorePurchaseOrder =
  (purchaseOrder, purchaseOrderProducts, stName) => async (dispatch) => {
    console.log(
      "createStorePurchaseOrder -start . purchaseOrder :: ",
      purchaseOrder, "purchaseOrderProducts :: ", 
      purchaseOrderProducts,"stName :: ",
      stName
    );
   
    try {
      dispatch({ type: STORE_PURCHASE_ORDER_CREATE_REQUEST });
      const { data } = await axios.post(`/api/store-po`, {purchaseOrder, purchaseOrderProducts, stName});
      console.log("createPurchaseOrder at Store  - start ",stName);
      dispatch({
        type: STORE_PURCHASE_ORDER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: STORE_PURCHASE_ORDER_CREATE_FAIL,
        payload: message,
      });
    }
    console.log('Outside of try catch [STORE_PURCHASE_ORDER_CREATE_REQUEST]...checking if this gets exec');
    console.log('Now Dispatching of try catch [STORE_STOCK_CREATE_REQUEST]');
  };

export const updateStorePurchaseOrder = (id) => async (dispatch, getState) => {
  console.log("EXEC update purchaseOrder : " + id);
};

export const updateStorePurchaseOrderPaymentById = (obj) => async (dispatch, getState) => {
  const { id, cashAmount, upiAmount, upiRef } = obj
  console.log("EXEC update updateStorePurchaseOrderPaymentById : " , id, cashAmount, upiAmount, upiRef);
  try {
    dispatch({
      type: STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_REQUEST,
    });

    const { data } = await axios.put(`/api/store-po/payment/${id}`, {
      cashAmount, upiAmount, upiRef
    });

    dispatch({
      type: STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_FAIL,
      payload: message,
    });
  }
};
