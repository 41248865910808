import React, { useState, useEffect } from "react";
import {
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Button,
    InputLabel,
    Select,
    MenuItem,
    Icon,
    Typography,
    TextField,
} from "@mui/material";
import rupeeSvgIcon from "../../../../assets/images/currency-inr.svg";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../../Grid/GridItem";
import Snackbar from '@mui/material/Snackbar';
import GridContainer from "../../../Grid/GridContainer";
import { addDecimals } from "../../../../util/Utility.js";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../../assets/images/green_tick.png";
import CustomBackdropSpinner from "../../../CustomBackdropSpinner.js";
import { getWalkinSalesInvoiceDetailsById, updateWalkinSalesInvoicePaymentDetailsById } from "../../../../actions/walkinSaleInvoiceAction.js";
import { WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_RESET } from "../../../../constants/walkinSalesInvoiceConstants.js";
import { createWalkinSaleReceipt } from "../../../../actions/walkinSaleReceiptAction.js";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },

    control: {
        padding: theme.spacing(2),
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleGreen: {
        color: "#26A541",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "Roboto",
        marginBottom: "3px",
        textDecoration: "none",
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        textAlign: "left",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
}));

const WalkinSalesInvoiceReceivePaymentScreen = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    console.log('id : ', id)
    const vertical = 'top';
    const horizontal = 'center';
    const [paymentDetails, setPaymentDetails] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [isCash, setIsCash] = useState(() => false);
    const [isUpi, setIsUpi] = useState(() => false);
    const [isLazyPay, setIsLazyPay] = useState(() => false);
    const [isCashRadioItem, setIsCashRadioItem] = useState(() => false);
    const [isUpiRadioItem, setIsUpiRadioItem] = useState(() => false);
    const [isLazyPayRadioItem, setIsLazyPayRadioItem] = useState(() => false);
    const [paymentMode, setPaymentMode] = useState(() => "Single");
    const [paymentType, setPaymentType] = useState(() => []);
    const [cashAmount, setCashAmount] = useState(() => 0.0);
    const [upiAmount, setUpiAmount] = useState(() => 0.0);
    const [lazyPayAmount, setLazyPayAmount] = useState(() => 0.0);
    const [amountPaid, setAmountPaid] = useState(() => 0.0);
    const [paidTo, setPaidTo] = useState(() => "");
    const [upiReference, setUpiReference] = useState(() => "");
    const [errorObject, setErrorObject] = useState(() => null);
    const [customError, setCustomError] = useState(() => []);
    let renderCashDetails = "";
    let renderUpiDetails = "";
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const walkinSalesInvoiceDetailsById = useSelector((state) => state.walkinSalesInvoiceDetailsById);
    const { walkin_sales_invoice_by_id_loading, walkin_sales_invoice_by_id_data, walkin_sales_invoice_by_id_error } = walkinSalesInvoiceDetailsById;

    const walkinSalesInvoicePaymentDetailsUpdateById = useSelector((state) => state.walkinSalesInvoicePaymentDetailsUpdateById);
    const { walkin_sales_invoice_receipt_update_loading, walkin_sales_invoice_receipt_update, walkin_sales_invoice_receipt_update_success, walkin_sales_invoice_receipt_update_error } = walkinSalesInvoicePaymentDetailsUpdateById;

    useEffect(() => {
        if (id && id.length > 0) {
            console.log('Dispatching to get the Walkin Sale Order Details by ID : ', id)
            dispatch(getWalkinSalesInvoiceDetailsById(id));
        } else {
            console.log('NONE OF THE ABOVE HAVE EXEC', id)
        }
    }, [dispatch, id]);

    const populatePaymentDetails = () => {
        console.log('Exec populatePaymentDetails ')
        const payDetails = walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.paymentDetails ? walkin_sales_invoice_by_id_data.paymentDetails : null
        const cashAmntPaid = payDetails && payDetails.cashAmountPaid ? addDecimals(Number(payDetails.cashAmountPaid).toFixed(2)) : 0
        const upiAmntPaid = payDetails && payDetails.upiAmountPaid ? addDecimals(Number(payDetails.upiAmountPaid).toFixed(2)) : 0
        const totalAmountPaid = cashAmntPaid + upiAmntPaid
        const customerName = walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.customerDetails && walkin_sales_invoice_by_id_data.customerDetails.name ? walkin_sales_invoice_by_id_data.customerDetails.name : ''
        const orderTotal = payDetails && payDetails.orderTotal ? payDetails.orderTotal : 0
        setPaymentDetails((prev) => ({
            billNo: walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.billNo ? walkin_sales_invoice_by_id_data.billNo : "",
            billDate: walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.billDate ? walkin_sales_invoice_by_id_data.billDate : "",
            customer: customerName,
            totalAmount: orderTotal ? orderTotal : 0,
            amountPaid: totalAmountPaid,
            balanceAmount: orderTotal - totalAmountPaid
        }))
    }

    useEffect(() => {
        if (walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data._id && walkin_sales_invoice_by_id_data._id.length > 0) {
            console.log("useEffect Getting Called for populatePaymentDetails as walkin_sales_invoice_by_id_data is not Empty");
            console.log("walkin_sales_invoice_by_id_data :==> ", walkin_sales_invoice_by_id_data ? walkin_sales_invoice_by_id_data._id : "");
            populatePaymentDetails()
        }
    }, [walkin_sales_invoice_by_id_data]);

    {/** NEED TO USE APPROPRIATE ID_RESET HERE*/ }
    const handleClose = () => {
        setOpen(false);
        dispatch({ type: WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_RESET });
        navigate("/admin/walkin-sales-invoice/list");
    };

    {/** PAYMENT STATUS CHECK AND OPEN DIALOG MESSAGE ON PAYMENT*/ }
    useEffect(() => {
        console.log("useEffect Getting Called on PAYMENT UPDATE IS SUCCESS");
        if (walkin_sales_invoice_receipt_update_success && walkin_sales_invoice_receipt_update) {
            console.log(" ************* Dispatching to Create Walkin Sales Receipt :  MAKING ENTRY IN Walkin Sales Receipt ************* ");
            dispatch(createWalkinSaleReceipt({id,walkin_sales_invoice_receipt_update}))
            setOpen(() => true);
        }
    }, [walkin_sales_invoice_receipt_update_success]);

    const updatePaymentHandler = () => {
        console.log(" *******  Clicked  updatePaymentHandler Payments Receivables *******  ");
        const payDetails = walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.paymentDetails ? walkin_sales_invoice_by_id_data.paymentDetails : null
        const customerName = walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.customerDetails && walkin_sales_invoice_by_id_data.customerDetails.name ? walkin_sales_invoice_by_id_data.customerDetails.name : ''
        const orderTotal = payDetails && payDetails.orderTotal ? payDetails.orderTotal : 0
        const cashAmntPaidNow = cashAmount ? Number(cashAmount).toFixed(2) : 0
        const upiAmntPaidNow = upiAmount ? Number(upiAmount).toFixed(2) : 0
        const cashAmntPaidEarlier = payDetails && payDetails.cashAmountPaid ? Number(payDetails.cashAmountPaid).toFixed(2) : 0
        const upiAmntPaidEarlier = payDetails && payDetails.upiAmountPaid ? Number(payDetails.upiAmountPaid).toFixed(2) : 0
        const totalCashPaid = Number(cashAmntPaidNow) + Number(cashAmntPaidEarlier)
        const totalUpiPaid = Number(upiAmntPaidNow) + Number(upiAmntPaidEarlier)

        const totalAmountPaid = Number(totalCashPaid) + Number(totalUpiPaid)
        setPaymentDetails((prev) => ({
            billNo: walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.billNo ? walkin_sales_invoice_by_id_data.billNo : "",
            billDate: walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.billDate ? walkin_sales_invoice_by_id_data.billDate : "",
            customer: customerName,
            totalAmount: orderTotal ? orderTotal : 0,
            amountPaid: totalAmountPaid ? totalAmountPaid : 0.0,
            cashPaid: totalCashPaid ? totalCashPaid : 0.0,
            upiPaid: totalUpiPaid ? totalUpiPaid : 0.0,
            balanceAmount: orderTotal - totalAmountPaid
        }))
        const paymntDetails = {
            // billNo : order && order._id ? order._id : "",
            billNo: walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.billNo ? walkin_sales_invoice_by_id_data.billNo : "",
            billDate: walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.billDate ? walkin_sales_invoice_by_id_data.billDate : "",
            customer: customerName,
            totalAmount: orderTotal ? orderTotal : 0,
            amountPaid: totalAmountPaid ? totalAmountPaid : 0.0,
            cashPaid: totalCashPaid ? totalCashPaid : 0.0,
            upiPaid: totalUpiPaid ? totalUpiPaid : 0.0,
            balanceAmount: orderTotal - totalAmountPaid
        }
        console.log(" ******* NOW DISPATCHING TO updateWalkinSalesInvoicePaymentDetailsById ******* ");
        dispatch(updateWalkinSalesInvoicePaymentDetailsById({
            id, paymntDetails
        }))
    }

    const handlePaymentModeChange = (e) => {
        console.log("Exec handlePaymentModeChange ", e);
        console.log(
            "e.target.name :==> ",
            e.target.name,
            " , e.target.value :==> ",
            e.target.value
        );
        clearFormValueForPaymentModeChange();
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
        setPaymentMode((prev) => e.target.value)
    };

    const handleChangeCheckbox = (event) => {
        console.log("event : ", event.target.name, event.target.checked);
        switch (event.target.name) {
            case "isCash":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => true)
                    setPaymentType(() => [...paymentType, "cash"])

                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.UN checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => false)
                    setAmountPaid((prev) => prev - (lazyPayAmount + upiAmount));
                    console.log(" ***** Deducting cashAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setCashAmount(() => 0.0)
                    setPaidTo(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i === "cash")],
                    );
                }
                break;
            case "isUpi":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => true)
                    setPaymentType(() => [...paymentType, "upi"],
                    );
                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target. UN checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => false)
                    setAmountPaid((prev) => prev - (cashAmount + lazyPayAmount));
                    console.log(" ***** Deducting upiAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setUpiAmount(() => 0.0)
                    setUpiReference(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i !== "upi")],
                    );
                }
                break;
            case "isLazyPay":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsLazyPay(() => true)
                    setPaymentType(() => [...paymentType, "lazyPay"])

                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.UN checked ***** ", event.target.name, event.target.checked)
                    setIsLazyPay(() => false)
                    setAmountPaid((prev) => prev - (cashAmount + upiAmount));
                    console.log(" ***** Deducting cashAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setCashAmount(() => 0.0)
                    setPaidTo(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i === "lazyPay")],
                    );
                }
                break;
            default:
                break;
        }
    };

    const handleChangeRadioOptions = (event) => {
        event.persist();
        clearFormValueForSinglePaymentType();
        console.log(
            "handleChange Radio event : ",
            event.target.name,
            event.target.value
        );
        if (event.target.value === "cash") {
            setIsCashRadioItem(() => true)
            setIsUpiRadioItem(() => false)
            setIsLazyPayRadioItem(() => false)
            setPaymentType(["cash"])
            console.log("Have Set Cash Radio Item..");
        }
        else if (event.target.value === "upi") {
            setIsCashRadioItem(() => false)
            setIsUpiRadioItem(() => true)
            setIsLazyPayRadioItem(() => false)
            setPaymentType(["upi"])
            console.log("Have Set upi  Radio Item..");
        }
        else if (event.target.value === "lazyPay") {
            setIsCashRadioItem(() => false)
            setIsUpiRadioItem(() => false)
            setIsLazyPayRadioItem(() => true)
            setPaymentType(["lazyPay"])
            console.log("Have Set lazyPay  Radio Item..");
        }
    };

    const handleChangeUpiReference = (event) => {
        event.persist();
        const upiReferenceValue = event.target.value;
        setUpiReference(() => upiReferenceValue);
    };

    const clearFormValueForPaymentModeChange = () => {
        console.log("clearFormValueForPaymentModeChange ");
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
    };

    const clearFormValueForSinglePaymentType = () => {
        console.log("clearFormValueForSinglePaymentType ");
        setIsCashRadioItem((prev) => false)
        setIsUpiRadioItem((prev) => false)
        setIsLazyPayRadioItem((prev) => false)
        setCashAmount(() => 0.0)
        setUpiAmount(() => 0.0)
        setLazyPayAmount(() => 0.0)
        setUpiReference(() => "")
        setPaidTo(() => "")
        setAmountPaid(() => 0.0)
    };

    const clearFormValueForMultiplePaymentType = () => {
        console.log("clearFormValueForMultiplePaymentType ");
        setIsCash((prev) => false)
        setIsUpi((prev) => false)
        setIsLazyPay((prev) => false)
        setCashAmount(() => 0.0)
        setUpiAmount(() => 0.0)
        setLazyPayAmount(() => 0.0)
        setUpiReference(() => "")
        setPaidTo(() => "")
        setAmountPaid(() => 0.0)
    };

    const renderDiscTaxTotalAmountContentUsingCards = (
        <Card style={{ backgroundColor: "#F2F2F2" }}>

            <GridContainer spacing={2} alignItems="center" justify="center">
                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Bill No :
                        {/* {walkin_sales_invoice_by_id_data && walkin_sales_invoice_by_id_data.billNo ? walkin_sales_invoice_by_id_data.billNo : ""} */}
                        {paymentDetails && paymentDetails.billNo ? paymentDetails.billNo : "not yet"}
                    </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'right' }}
                        color="black"
                    >
                        Bill Date :
                        {paymentDetails && paymentDetails.billDate ? paymentDetails.billDate : ""}
                    </Typography>
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Customer  : {paymentDetails && paymentDetails.customer ? paymentDetails.customer.toUpperCase() : ''}
                    </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'right', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Total Amount : <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>
                        {paymentDetails && paymentDetails.totalAmount ? addDecimals(Number(paymentDetails.totalAmount).toFixed(2)) : 0.0}
                    </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "1rem", textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}
                        color="green"
                    >
                        Amount paid :
                        <img
                            alt="curency inr"
                            src={rupeeSvgIcon}
                            style={{ height: "1rem", verticalAlign: "center", paddingTop: "0.10rem" }}
                            className={classes.imageIcon}
                        />
                        {paymentDetails && paymentDetails.amountPaid ? addDecimals(Number(paymentDetails.amountPaid).toFixed(2)) : 0.0}
                    </Typography>
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "1rem", textAlign: 'right', justifyContent: 'center', alignItems: 'center' }}
                        color="red"
                    >
                        Balance Amount : <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "red", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>

                        {paymentDetails && paymentDetails.balanceAmount ? addDecimals(Number(paymentDetails.balanceAmount).toFixed(2)) : 0.0}
                    </Typography>
                </GridItem>
            </GridContainer>
        </Card>
    );

    renderUpiDetails =
        (
            <Grid container spacing={2}>
                {checked && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
                    <Alert onClose={handleCloseSnackBar} severity="warning" sx={{ width: '100%', textTransform: "none" }}>
                        Please make the payment and enter the Valid Transaction id in the UPI Ref field
                    </Alert>
                </Snackbar>)}
                <GridItem item xs={12} sm={12} md={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <TextField
                        label="UPI Amount"
                        required
                        variant="outlined"
                        size="small"
                        name="upiAmount"
                        style={{
                            width: "50%",
                        }}
                        onChange={(e) => {
                            const upiValue = e.target.value;
                            console.log("UPI Amount : ", upiValue)
                            validateInputLessThanOrderTotal(Number(upiValue));
                            setUpiAmount((prev) => Number(upiValue));
                            setAmountPaid((prev) => {
                                if (isCash) {
                                    return Number(cashAmount) + (upiValue ? Number(upiValue) : 0.0)
                                } else {
                                    return upiValue ? Number(upiValue) : 0.0
                                }
                            })
                            calculateBalanceAmountIfVer(Number(upiValue), 0);
                        }}
                        value={upiAmount ? Number(upiAmount) : ""}
                        type="number"
                    />
                    {errorObject && errorObject.errorMessage && (<Typography
                        variant="h5"
                        style={{ textAlign: "center", fontSize: "1rem", margin: "1rem", color: "red" }}
                    >
                        {errorObject.errorMessage}
                    </Typography>)}
                </GridItem>
                <GridItem item xs={12} sm={12} md={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <TextField
                        label="Upi Ref"
                        required
                        variant="outlined"
                        size="small"
                        name="upiReference"
                        xs={{ width: "8rem" }}
                        style={{
                            width: "50%", marginTop: "0.5rem"
                        }}
                        onChange={handleChangeUpiReference}
                        value={upiReference ? upiReference : ""}
                        type="text"
                    />
                </GridItem>
            </Grid>
        );

    const validateInputLessThanOrderTotal = (val) => {
        // const totalAmnt = paymentDetails && paymentDetails.totalAmount ? addDecimals(Number(paymentDetails.totalAmount).toFixed(2)) : 0.0
        console.log("amountPaid : ", amountPaid, upiAmount, cashAmount);
        const balanceAmnt = paymentDetails && paymentDetails.balanceAmount ? Number(paymentDetails.balanceAmount).toFixed(2) : 0.0
        console.log('Balance Amount : ', balanceAmnt)
        console.log('Entered Amount : ', val)
        if (val && balanceAmnt && Number(val) > balanceAmnt) {
            setErrorObject({ errorMessage: `Amount can't exceed  :  ${balanceAmnt}` });
        }
        else
            setErrorObject(null);
        return;
    }

    renderCashDetails =
        (
            <Grid container spacing={2}>
                <GridItem item xs={12} sm={12} md={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <TextField
                        label="Cash Amount"
                        required
                        variant="outlined"
                        size="small"
                        name="cashAmount"
                        style={{
                            width: "50%"
                        }}
                        onChange={(e) => {
                            const cashValue = e.target.value;
                            console.log("CASH Amount : ", cashValue)
                            validateInputLessThanOrderTotal(cashValue);
                            setCashAmount((prev) => Number(cashValue));
                            setAmountPaid((prev) => {
                                if (isUpi) {
                                    return Number(upiAmount) + (cashValue ? Number(cashValue) : 0.0)
                                } else
                                    return cashValue ? Number(cashValue) : 0.0

                            })
                            calculateBalanceAmountIfVer(0, Number(cashValue));
                        }}
                        value={cashAmount ? Number(cashAmount) : null}
                        type="number"
                    />
                    {errorObject && errorObject.errorMessage && (<Typography
                        variant="h5"
                        style={{ textAlign: "center", fontSize: "1rem", margin: "1rem", color: "red" }}
                    >
                        {errorObject.errorMessage}
                    </Typography>)}
                </GridItem>
            </Grid>
        );

    const renderMultiplePaymentModesContent = (
        <React.Fragment>
            <FormControl
                sx={{ marginRight: "3rem" }}
                component="fieldset"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    height: "3vh"
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCash}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isCash}
                            name="isCash"
                        />
                    }
                    label="Cash"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isUpi}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isUpi}
                            name="isUpi"
                        />
                    }
                    label="UPI"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isLazyPay}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isLazyPay}
                            name="isLazyPay"
                        />
                    }
                    label="Lazy Pay"
                />
            </FormControl>
        </React.Fragment>
    );

    const renderSinglePaymentModeContent = (
        <React.Fragment>
            <FormControl
                component="fieldset"
                sx={{ marginRight: "3rem" }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    margin: "1rem"
                }}
            >
                <RadioGroup
                    row={true}
                    aria-label="paymentType"
                    name="paymentType"
                    value={paymentType}
                    style={{ marginLeft: "0.5rem", textAlign: "center", alignItems: "center", justifyContent: "center" }}
                    onChange={(e) => handleChangeRadioOptions(e)}
                >
                    <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                    <FormControlLabel value="upi" control={<Radio />} label="Upi" />
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );

    const calculateBalanceAmountIfVer = (upi, cash) => {
        let totalOfDifferentModes = 0.0;
        console.log("Exec calculateBalanceAmountIfVer : UPI Amount : ", upi, " , cashAmount : ", cash);
        console.log(' ****** paymentMode ******  : ', paymentMode)
        if (paymentMode === "Single") {
            if (isUpiRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (upi ? Number(upi) : 0.0);
                console.log("totalOfDifferentModes When isUpi is Selected :=> ", totalOfDifferentModes);
            } else if (isCashRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (cash ? Number(cash) : 0.0);
                console.log("totalOfDifferentModes  When isCash is Selected:=> ", totalOfDifferentModes);
            }
        }

        if (paymentMode === "Multiple") {
            if (paymentType.includes("cash")) {
                console.log("totalOfDifferentModes When Multiple  && isCash is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (cash
                        ? Number(cash)
                        : 0.0)
            }
            else if (paymentType.includes("upi")) {
                console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (upi
                        ? Number(upi)
                        : 0.0)
            }
            console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
        }
        // setBalanceAmountTobePaid((prev) => balanceAmount);
    };
    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackBar(false);
    };

    return (
        <React.Fragment>
            {walkin_sales_invoice_receipt_update_loading && <CustomBackdropSpinner />}
            {walkin_sales_invoice_by_id_loading && <CustomBackdropSpinner />}
            {customError && customError.errorMessage && customError.errorMessage !== "" && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    Please Ensure Cash Amount doesn't exceed 2 Laks.!
                </Alert>
            </Snackbar>)}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <GridContainer spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} >
                            <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                                    <img
                                        alt="Order Placed"
                                        src={greeTickImage}
                                        style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                                    />
                                </div>
                                <Typography
                                    variant="h5"
                                    color="black"
                                    style={{
                                        margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                                        fontWeight: "600",
                                        fontSize: "1rem",
                                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                    }}
                                >
                                    Updated Payment Details Sucessfully
                                </Typography>
                            </Paper>
                        </Grid>
                    </GridContainer>
                </DialogContent>
            </BootstrapDialog>
            <GridContainer spacing={2} alignItems="center" justify="center">
                <Grid item xs={12} sm={12} md={12}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "1.55rem", textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Walkin Sales Order Receive Payment
                    </Typography>
                </Grid>
            </GridContainer>

            <GridContainer spacing={2} >
                <Grid item xs={12} sm={12} md={12}>
                    <Paper style={{ margin: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                        {renderDiscTaxTotalAmountContentUsingCards}
                    </Paper>
                </Grid>
            </GridContainer>
            {paymentDetails && paymentDetails.balanceAmount && paymentDetails.balanceAmount > 0 ? (<Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card >
                        <CardBody>
                            <GridContainer spacing={2}>
                                <GridItem item xs={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center", width: '100%' }}>
                                    <InputLabel id="demo-simple-select-label">
                                        Payment Mode
                                    </InputLabel>
                                    <Select
                                        label="Payment Mode"
                                        placeholder="Payment Mode"
                                        variant="outlined"
                                        required
                                        size="small"
                                        name="paymentMode"
                                        onChange={handlePaymentModeChange}
                                        value={paymentMode}
                                        style={{ width: '50%' }}
                                    >
                                        <MenuItem key="Single" value="Single">Single</MenuItem>
                                        <MenuItem key="Multiple" value="Multiple">Multiple</MenuItem>
                                    </Select>
                                </GridItem>
                                <GridItem item xs={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                    {paymentMode === "Single" &&
                                        renderSinglePaymentModeContent}
                                    {paymentMode === "Multiple" &&
                                        renderMultiplePaymentModesContent}
                                </GridItem>
                                {isCash || isCashRadioItem ? (
                                    <GridItem xs={12} sm={12} md={12} >
                                        {renderCashDetails}
                                    </GridItem>
                                ) : null}
                                {isUpi || isUpiRadioItem ? (
                                    <GridItem xs={12} sm={12} md={12} >
                                        {renderUpiDetails}
                                    </GridItem>
                                ) : null}

                                {/*
                            PLACE ORDER BUTTON : START
                            */}
                                <GridItem xs={12} sm={12} md={12} style={{
                                    textAlign: "center",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        color="secondary"
                                        onClick={updatePaymentHandler}
                                        style={{
                                            textTransform: "capitalize",
                                            borderRadius: "5px",
                                            width: "25%",
                                            marginTop: "0.5rem"
                                        }}
                                        disabled={((isUpi === true || isUpiRadioItem === true) && upiReference && upiReference.length > 0) ?
                                            false : ((isCash === true || isCashRadioItem === true)) ?
                                                false : (isLazyPay === true || isLazyPayRadioItem === true) ? false : (errorObject && errorObject.errorMessage && errorObject.errorMessage.length === 0 ? false : true)}
                                    >
                                        Update Payment
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>) : null}
        </React.Fragment>
    );
};

export default WalkinSalesInvoiceReceivePaymentScreen;
