import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Tooltip,Typography } from "@mui/material";
import CategoryForm from "./CategoryForm";
import Section from "../organisms/Section/Section";
import HomeIcon from "@mui/icons-material/Home";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    height: "50vh",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const CategoryCreate = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ margin: "0.5rem" }}>
            <Tooltip title="Back" arrow>
              <ArrowBackIcon
                fontSize="medium"
                onClick={() => window.history.back()}
                style={{ float: "left" }}
              />
            
            </Tooltip>
          </div>
        </Grid>
        <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <Typography variant="h5" style={{ fontWeight: 500, textAlign:"center"}}>
                 <b>New Category</b>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <Grid item xs={12} sm={12} md={12} marginTop="-3rem">
          <Section className={classes.section}>
            <div className={classes.formContainer}>
              <CategoryForm location={location} history={history} />
            </div>
          </Section>
        </Grid>
      </Grid>
    </div>
  );
};

export default CategoryCreate;
