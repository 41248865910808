import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/userAction";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { useNavigate } from "react-router-dom";
import { USER_REGISTER_RESET } from "../../constants/userConstants";
import { listAllStores } from "../../actions/storeAction";
import { role_types } from "../../data/index.js";
import { listAllWarehouses } from "../../actions/warehouseInfoAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  userName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 9,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: {
      message: "doesn't look like a valid email",
    },
    length: {
      maximum: 300,
    },
  },
  contactNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 10,
      maximum: 10,
    },
  },
};

const SignUpForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;
  const warehouseInfoList = useSelector((state) => state.warehouseInfoList);
  const { warehouseInfos } = warehouseInfoList;

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [role, setRole] = useState(() => {
    return "";
  });

  const [selectedStore, setSelectedStore] = useState(() => "");
  const [selectedWarehouse, setSelectedWarehouse] = useState(() => "");

  if (userInfo) {
    console.log(
      "Created NEW User and Invoking the CallBackHandker from SignupForm...! Lets see...",
      userInfo
    );
    dispatch({ type: USER_REGISTER_RESET });
    navigate("/signin");
  }
  useEffect(() => {
    console.log("useEffect Getting StoreList");
    dispatch(listAllStores());
    dispatch(listAllWarehouses());
  }, [dispatch]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  let renderStoreOptions = "";
  if (stores && stores.length > 0) {
    renderStoreOptions = stores.map((eachStore, idx) => {
      return (
        <MenuItem key={eachStore._id} value={eachStore.storeName}>
          {eachStore.storeName}
        </MenuItem>
      );
    });
  }

  let renderWarehouseOptions = "";
  if (warehouseInfos && warehouseInfos.length > 0) {
    renderWarehouseOptions = warehouseInfos.map((eachWarehouse, idx) => {
      return (
        <MenuItem key={eachWarehouse._id} value={eachWarehouse.warehouseName}>
          {eachWarehouse.warehouseName}
        </MenuItem>
      );
    });
  }

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "role_types":
        return role_types.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const handleChangeStore = (e) => {
    console.log("Store Changed  " + e.target.value);
    setSelectedStore(() => e.target.value);
  };

  const handleChangeWarehouse = (e) => {
    console.log("Warehouse Changed  " + e.target.value);
    setSelectedWarehouse(() => e.target.value);
  };

  const handleRoleChange = (event) => {
    console.log("Role Selected  : ", event.target.value);
    setRole(event.target.value);
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "select"
            ? event.target.selected
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", formState.values);
    event.preventDefault();
    console.log("formState.isValid : ", formState.isValid);
    console.log(formState.isValid);
    if (formState.isValid)
      dispatch(
        register(
          formState.values.name,
          formState.values.userName,
          formState.values.password,
          formState.values.email,
          formState.values.contactNo,
          role,
          selectedStore,
          selectedWarehouse,
        )
      );

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      <form method="post" onSubmit={handleSubmit}>
        <Card>
          <CardBody>
            <Grid container spacing={2} justifyContent="center" marginTop="auto">
              <Grid item xs={12} align="center">
                <TextField
                  placeholder="Name"
                  label="Name*"
                  variant="outlined"
                  style={{ width: "32vh" }}
                  size="small"
                  name="name"
                  helperText={
                    hasError("name") ? formState.errors.name[0] : null
                  }
                  error={hasError("name")}
                  onChange={handleChange}
                  type="text"
                  value={formState.values.name || ""}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  placeholder="User Name"
                  label="User Name*"
                  variant="outlined"
                  size="small"
                  name="userName"
                  style={{ width: "32vh" }}
                  helperText={
                    hasError("userName") ? formState.errors.userName[0] : null
                  }
                  error={hasError("userName")}
                  onChange={handleChange}
                  type="text"
                  value={formState.values.userName || ""}
                />
              </Grid>

              <Grid item xs={12} align="center">
                <TextField
                  placeholder="E-mail"
                  label="E-mail *"
                  variant="outlined"
                  size="small"
                  name="email"
                  style={{ width: "32vh" }}
                  helperText={
                    hasError("email") ? formState.errors.email[0] : null
                  }
                  error={hasError("email")}
                  onChange={handleChange}
                  type="email"
                  value={formState.values.email || ""}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <TextField
                  placeholder="Password"
                  label="Password *"
                  variant="outlined"
                  size="small"
                  name="password"
                  style={{ width: "32vh" }}
                  helperText={
                    hasError("password") ? formState.errors.password[0] : null
                  }
                  error={hasError("password")}
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ""}
                />
              </Grid>

              <Grid item xs={12} align="center">
                <TextField
                  placeholder=""
                  label="Contact No*"
                  variant="outlined"
                  size="small"
                  name="contactNo"
                  style={{ width: "32vh" }}
                  helperText={
                    hasError("contactNo") ? formState.errors.contactNo[0] : null
                  }
                  error={hasError("contactNo")}
                  onChange={handleChange}
                  type="number"
                  value={formState.values.contactNo || ""}
                />
              </Grid>
              <Grid item xs={12} align="center">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    label="Role Name"
                    variant="outlined"
                    required
                    size="small"
                    name="role"
                    onChange={handleRoleChange}
                    value={role}
                    style={{ width: "32vh" }}
                  >
                    {renderDynamicOptions("role_types")}
                  </Select>
                </FormControl>
              </Grid>
              {role !== "" && role === "STORE_INCHARGE_ROLE" && (
                <Grid item xs={12} align="center">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Store Name
                    </InputLabel>

                    <Select
                      placeholder="Store Name"
                      label="Store Name "
                      variant="outlined"
                      size="small"
                      name="role"
                      onChange={handleChangeStore}
                      value={selectedStore}
                      style={{ width: "32vh" }}
                    >
                      {renderStoreOptions}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              {role !== "" && role === "WAREHOUSE_INCHARGE_ROLE" && (
                <Grid item xs={12} align="center">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                    Warehouse Name
                    </InputLabel>

                    <Select
                      placeholder="Warehouse Name"
                      label="Warehouse Name "
                      variant="outlined"
                      size="small"
                      name="role"
                      onChange={handleChangeWarehouse}
                      value={selectedWarehouse}
                      style={{ width: "32vh" }}
                    >
                      {renderWarehouseOptions}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} align="center">
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  style={{
                    textTransform: "capitalize",
                    width: "25vh",
                    borderRadius: "5px",
                  }}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          </CardBody>
        </Card>
      </form>
    </div>
  );
};

export default SignUpForm;
