import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VersionUpdateCreateForm from "./VersionUpdatesCreateForm";
import Section from "../organisms/Section/Section";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import GridContainer from "../Grid/GridContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const VersionUpdateCreate = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;

  return (
    <div>
      <GridContainer spacing={2} alignItems="center" justifyContent="center">
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <Card>
            <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em", textAlign: "center" }}
                />
              </Tooltip>
              <h2 align="center"> New Software Version </h2>
            </CardHeader>
            <CardBody>
              <VersionUpdateCreateForm />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default VersionUpdateCreate;
