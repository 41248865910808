// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Add this to your CSS file */
.ag-header-cell-label {
    justify-content: center !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/Reports/aggridStyles.css"],"names":[],"mappings":"AAAA,8BAA8B;AAC9B;IACI,kCAAkC;EACpC","sourcesContent":["/* Add this to your CSS file */\n.ag-header-cell-label {\n    justify-content: center !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
