export const STOCK_TRANSER_INVOICE_LIST_REQUEST = "STOCK_TRANSER_INVOICE_LIST_REQUEST";
export const STOCK_TRANSER_INVOICE_LIST_SUCCESS = "STOCK_TRANSER_INVOICE_LIST_SUCCESS";
export const STOCK_TRANSER_INVOICE_LIST_FAIL = "STOCK_TRANSER_INVOICE_LIST_FAIL";
export const STOCK_TRANSER_INVOICE_LIST_RESET = "STOCK_TRANSER_INVOICE_LIST_RESET";

export const STOCK_TRANSER_INVOICE_LIST_BY_POS_REQUEST = "STOCK_TRANSER_INVOICE_LIST_BY_POS_REQUEST";
export const STOCK_TRANSER_INVOICE_LIST_BY_POS_SUCCESS = "STOCK_TRANSER_INVOICE_LIST_BY_POS_SUCCESS";
export const STOCK_TRANSER_INVOICE_LIST_BY_POS_FAIL = "STOCK_TRANSER_INVOICE_LIST_BY_POS_FAIL";
export const STOCK_TRANSER_INVOICE_LIST_BY_POS_RESET = "STOCK_TRANSER_INVOICE_LIST_BY_POS_RESET";

export const STOCK_TRANSER_INVOICE_BY_ID_REQUEST =
  "STOCK_TRANSER_INVOICE_BY_ID_REQUEST";
export const STOCK_TRANSER_INVOICE_BY_ID_SUCCESS =
  "STOCK_TRANSER_INVOICE_BY_ID_SUCCESS";
export const STOCK_TRANSER_INVOICE_BY_ID_FAIL =
  "STOCK_TRANSER_INVOICE_BY_ID_FAIL";

export const STOCK_TRANSER_INVOICE_DELETE_BY_ID_REQUEST = "STOCK_TRANSER_INVOICE_DELETE_BY_ID_REQUEST";
export const STOCK_TRANSER_INVOICE_DELETE_BY_ID_SUCCESS = "STOCK_TRANSER_INVOICE_DELETE_BY_ID_SUCCESS";
export const STOCK_TRANSER_INVOICE_DELETE_BY_ID_FAIL = "STOCK_TRANSER_INVOICE_DELETE_BY_ID_FAIL";

export const STOCK_TRANSER_INVOICE_CREATE_REQUEST = "STOCK_TRANSER_INVOICE_CREATE_REQUEST";
export const STOCK_TRANSER_INVOICE_CREATE_SUCCESS = "STOCK_TRANSER_INVOICE_CREATE_SUCCESS";
export const STOCK_TRANSER_INVOICE_CREATE_FAIL = "STOCK_TRANSER_INVOICE_CREATE_FAIL";
export const STOCK_TRANSER_INVOICE_CREATE_RESET = "STOCK_TRANSER_INVOICE_CREATE_RESET";

export const STOCK_TRANSER_INVOICE_UPDATE_BY_ID_REQUEST = "STOCK_TRANSER_INVOICE_UPDATE_BY_ID_REQUEST";
export const STOCK_TRANSER_INVOICE_UPDATE_BY_ID_SUCCESS = "STOCK_TRANSER_INVOICE_UPDATE_BY_ID_SUCCESS";
export const STOCK_TRANSER_INVOICE_UPDATE_BY_ID_FAIL = "STOCK_TRANSER_INVOICE_UPDATE_BY_ID_FAIL";
export const STOCK_TRANSER_INVOICE_UPDATE_BY_ID_RESET = "STOCK_TRANSER_INVOICE_UPDATE_BY_ID_RESET";
