import React from "react";
import { makeStyles } from "@mui/styles";
import GridItem from "../Grid/GridItem.js";
import Section from "../organisms/Section/Section";
import { Grid, Tooltip, Typography } from "@mui/material";
import ProductCreateForm from "./ProductCreateForm.js";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";

const ProductCreate = ({ match }) => {
  const useStyles = makeStyles((theme) => ({
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
      maxWidth: 100,
      margin: `0 auto`,
    },
    section: {
      padding: "5px 5px 2px 2px",


    },
  }));
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <div>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
            <Tooltip title="Back" arrow>
            <ArrowBackIcon
              fontSize="medium"
              onClick={() => window.history.back()}
              style={{ float: "left" }}
            />
          
          </Tooltip> 
              <Typography variant="h5" style={{ fontWeight: 500, textAlign:"center" }}>
             
                New Product Entry
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <ProductCreateForm />
    </div>
  );
};

export default ProductCreate;
