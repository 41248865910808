import {
  VEHICLE_SALE_INVOICE_LIST_REQUEST,
  VEHICLE_SALE_INVOICE_LIST_SUCCESS,
  VEHICLE_SALE_INVOICE_LIST_FAIL,
  VEHICLE_SALE_INVOICE_LIST_RESET,

  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_REQUEST,
  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_SUCCESS,
  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_FAIL,
  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET,
  
  VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_REQUEST,
  VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_SUCCESS,
  VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_FAIL,

  VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_REQUEST,
  VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_SUCCESS,
  VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_FAIL,

  VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_REQUEST,
  VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_SUCCESS,
  VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_FAIL,
  VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_RESET,

  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST,
  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET,

  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_REQUEST,
  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_SUCCESS,
  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_FAIL,
  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_RESET,
  VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_REQUEST,
  VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_FAIL,
  VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_SUCCESS,
} from "../constants/vehicleSalesInvoiceConstants";

export const vehicleSalesInvoiceListReducer = (state = { vehicle_sale_invoice_data: [] }, action) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_LIST_REQUEST:
      return { vehicle_sale_invoice_list_loading: true, vehicle_sale_invoice_list_data: [] };
    case VEHICLE_SALE_INVOICE_LIST_SUCCESS:
      return {
        vehicle_sale_invoice_list_loading: false,
        vehicle_sale_invoice_list_success: true,
        vehicle_sale_invoice_list_data: action.payload,
      };
    case VEHICLE_SALE_INVOICE_LIST_FAIL:
      return { vehicle_sale_invoice_list_loading: false, vehicle_sale_invoice_list_error: action.payload };
    default:
      return state;
  }
};

export const vehicleSalesInvoiceByVehicleNoReducer = (
  state = { vehicle_sale_invoice_by_vehicle_no_data: {} },
  action
) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_REQUEST:
      return { vehicle_sale_invoice_by_vehicle_no_loading: true, vehicle_sale_invoice_by_vehicle_no_data:{}  };
    case VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_SUCCESS:
      return {
        vehicle_sale_invoice_by_vehicle_no_loading: false,
        vehicle_sale_invoice_by_vehicle_no_success: true,
        vehicle_sale_invoice_by_vehicle_no_data: action.payload,
      };
    case VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_FAIL:
      return { vehicle_sale_invoice_by_vehicle_no_loading: false, vehicle_sale_invoice_by_vehicle_no_error: action.payload };
    default:
      return state;
  }
};

export const vehicleSalesInvoiceByInchargeNameReducer = (
  state = { vehicle_sales_invoice_by_vehicle_incharge_data: {} },
  action
) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_REQUEST:
      return { vehicle_sale_invoice_by_incharge_loading: true, vehicle_sale_invoice_by_incharge_data:{}  };
    case VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_SUCCESS:
      return {
        vehicle_sale_invoice_by_incharge_loading: false,
        vehicle_sale_invoice_by_incharge_success: true,
        vehicle_sale_invoice_by_incharge_data: action.payload,
      };
    case VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_FAIL:
      return { vehicle_sale_invoice_by_incharge_loading: false, vehicle_sale_invoice_by_incharge_error: action.payload };
    default:
      return state;
  }
};

export const vehicleSalesInvoiceByInvoiceNoReducer = (
  state = { vehicle_sale_invoice_by_invoice_no_data: {} },
  action
) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST:
      return { vehicle_sale_invoice_by_invoice_no_loading: true, vehicle_sale_invoice_by_invoice_no_data:{}  };
    case VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS:
      return {
        vehicle_sale_invoice_by_invoice_no_loading: false,
        vehicle_sale_invoice_by_invoice_no_success: true,
        vehicle_sale_invoice_by_invoice_no_data: action.payload,
      };
    case VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL:
      return { vehicle_sale_invoice_by_invoice_no_loading: false, vehicle_sale_invoice_by_invoice_no_error: action.payload };
    case VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET:
      return { vehicle_sale_invoice_by_invoice_no_data: {} };
    default:
      return state;
  }
};

export const vehicleSalesInvoiceDeleteByVehicleNoReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_REQUEST:
      return { loading: true };
    case VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_SUCCESS:
      return { loading: false, success: true };
    case VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const vehicleSalesInvoiceCreateByVehicleNoReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_REQUEST:
      return { vehicle_shop_invoice_create_loading: true };
    case VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_SUCCESS:
      return { vehicle_shop_invoice_create_loading: false, vehicle_shop_invoice_create_success: true, vehicle_shop_invoice_create_data: action.payload };
    case VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_FAIL:
      return { vehicle_shop_invoice_create_loading: false, vehicle_shop_invoice_create_error: action.payload };
    case VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const vehicleSalesInvoiceUpdateByVehicleNoReducer = (state = { vehicleInvoice: {} }, action) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_REQUEST:
      return { loading: true };
    case VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_SUCCESS:
      return { loading: false, success: true, vehicleInvoice: action.payload };
    case VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_RESET:
      return { vehicleInvoice: {} };
    default:
      return state;
  }
};

export const vehicleSalesInvoiceUpdateByInvoiceNoReducer = (state = { }, action) => {
  switch (action.type) {
    case VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_REQUEST:
      return { vehicle_sales_invoice_update_by_id_loading: true };
    case VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_SUCCESS:
      return { vehicle_sales_invoice_update_by_id_loading: false, vehicle_sales_invoice_update_by_id_success: true, vehicle_sales_invoice_update_by_id_data: action.payload };
    case VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_FAIL:
      return { vehicle_sales_invoice_update_by_id_loading: false, vehicle_sales_invoice_update_by_id_error: action.payload };
    case VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_RESET:
      return { vehicleInvoice: {} };
    default:
      return state;
  }
};
