export const ORDER_RECEIPT_CREATE_BY_STORE_REQUEST = "ORDER_RECEIPT_CREATE_BY_STORE_REQUEST";
export const ORDER_RECEIPT_CREATE_BY_STORE_SUCCESS = "ORDER_RECEIPT_CREATE_BY_STORE_SUCCESS";
export const ORDER_RECEIPT_CREATE_BY_STORE_FAIL = "ORDER_RECEIPT_CREATE_BY_STORE_FAIL";
export const ORDER_RECEIPT_CREATE_BY_STORE_RESET = "ORDER_RECEIPT_CREATE_BY_STORE_RESET";

export const ORDER_RECEIPT_LIST_BY_STORE_REQUEST = "ORDER_RECEIPT_LIST_BY_STORE_REQUEST";
export const ORDER_RECEIPT_LIST_BY_STORE_SUCCESS = "ORDER_RECEIPT_LIST_BY_STORE_SUCCESS";
export const ORDER_RECEIPT_LIST_BY_STORE_FAIL = "ORDER_RECEIPT_LIST_BY_STORE_FAIL";
export const ORDER_RECEIPT_LIST_BY_STORE_RESET = "ORDER_RECEIPT_LIST_BY_STORE_RESET";

export const ORDER_RECEIPT_DETAILS_BY_ID_REQUEST = "ORDER_RECEIPT_DETAILS_BY_ID_REQUEST";
export const ORDER_RECEIPT_DETAILS_BY_ID_SUCCESS = "ORDER_RECEIPT_DETAILS_BY_ID_SUCCESS";
export const ORDER_RECEIPT_DETAILS_BY_ID_FAIL = "ORDER_RECEIPT_DETAILS_BY_ID_FAIL";
export const ORDER_RECEIPT_DETAILS_BY_ID_RESET = "ORDER_RECEIPT_DETAILS_BY_ID_RESET";







