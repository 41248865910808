import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
export const exportToExcel = async (groupedJsonData) => {

    console.log('Exec... export To Excel ', groupedJsonData ? groupedJsonData.length : 0 )
    if(groupedJsonData){
        console.log('CONTENT PASSED TO EXPORT EXCEL IS BELOW : ' )
        groupedJsonData.map((e,idx)=>console.log('[',idx,']',e ));
    }
    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Product Wise Report");

    // Define column headers and their styles
    const columns = [
      { header: "Invoice No", key: "InvoiceId", width: 25 },
      { header: "Bill Date", key: "Bill Data", width: 15 },
    //   { header: "Items Price", key: "Items Price", width: 15 },
      { header: "Point Of Sale", key: "Point Of Sale", width: 25 },
      { header: "Customer", key: "Customer Name", width: 25 },
      { header: "Contact No", key: "Contact No", width: 25 },
      { header: "Product", key: "Product", width: 25 },
      { header: "Quantity", key: "Quantity", width: 25 },
      { header: "Items Price", key: "Items Price", width: 25 },
    ];

    worksheet.columns = columns;

    // Style the header row
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { name: "Arial", family: 4, size: 13, bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fffff9f3" },
        bgColor: { argb: "fffff9f3" }
      };
      cell.font = {
        color: { argb: "ff626678" },
        bold: true,
      };
    });

    // Add data to worksheet
    groupedJsonData.forEach((item) => {
        // console.log('item passed to each row : ',item)
      worksheet.addRow(item);
      console.log('Done adding item to each row : ',item)
    });
    console.log(' ######## Done adding items to each row ########  !! ')

    // Style the data rows
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      row.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        if (['Quantity', 'Items Cost'].includes(cell._column._key)) {
          cell.alignment = { vertical: 'middle', horizontal: 'right' };
        }
      });
    }
  });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();
    // Save to file
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "product-wise-report.xlsx");
  };

  export const exportVehicleSalesProductWiseToExcel = async (groupedJsonData) => {

    console.log('Exec... exportVehicleSalesProductWiseToExcel To Excel ', groupedJsonData ? groupedJsonData.length : 0 )
    if(groupedJsonData){
        console.log('CONTENT PASSED TO EXPORT EXCEL IS BELOW : ' )
        groupedJsonData.map((e,idx)=>console.log('[',idx,']',e ));
    }
    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Product Wise Report");

    // Define column headers and their styles
    const columns = [
      { header: "Invoice No", key: "InvoiceId", width: 25 },
      { header: "Bill Date", key: "Bill Data", width: 15 },
    //   { header: "Items Price", key: "Items Price", width: 15 },
      { header: "Vehicle No", key: "Vehicle No", width: 25 },
      { header: "Customer", key: "Customer Name", width: 25 },
      { header: "Contact No", key: "Contact No", width: 25 },
      { header: "Product", key: "Product", width: 25 },
      { header: "Quantity", key: "Quantity", width: 25 },
      { header: "Items Price", key: "Items Price", width: 25 },
    ];

    worksheet.columns = columns;

    // Style the header row
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { name: "Arial", family: 4, size: 13, bold: true };
      cell.alignment = { vertical: "middle", horizontal: "center" };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "fffff9f3" },
        bgColor: { argb: "fffff9f3" }
      };
      cell.font = {
        color: { argb: "ff626678" },
        bold: true,
      };
    });

    // Add data to worksheet
    groupedJsonData.forEach((item) => {
        // console.log('item passed to each row : ',item)
      worksheet.addRow(item);
      console.log('Done adding item to each row : ',item)
    });
    console.log(' ######## Done adding items to each row ########  !! ')

    // Style the data rows
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      row.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        if (['Quantity', 'Items Cost'].includes(cell._column._key)) {
          cell.alignment = { vertical: 'middle', horizontal: 'right' };
        }
      });
    }
  });

    // Generate buffer
    const buffer = await workbook.xlsx.writeBuffer();
    // Save to file
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "product-wise-report.xlsx");
  };