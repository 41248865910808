export const WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_REQUEST = "WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_REQUEST";
export const WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_SUCCESS = "WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_SUCCESS";
export const WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_FAIL = "WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_FAIL";
export const WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_RESET = "WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_RESET";

export const WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_REQUEST =
  "WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_REQUEST";
export const WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_SUCCESS =
  "WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_SUCCESS";
export const WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_FAIL =
  "WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_FAIL";

export const WAREHOUSE_STOCK_DELETE_BY_ID_REQUEST = "WAREHOUSE_STOCK_DELETE_BY_ID_REQUEST";
export const WAREHOUSE_STOCK_DELETE_BY_ID_SUCCESS = "WAREHOUSE_STOCK_DELETE_BY_ID_SUCCESS";
export const WAREHOUSE_STOCK_DELETE_BY_ID_FAIL = "WAREHOUSE_STOCK_DELETE_BY_ID_FAIL";

export const WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_REQUEST = "WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_REQUEST";
export const WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_SUCCESS = "WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_SUCCESS";
export const WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_FAIL = "WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_FAIL";
export const WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_RESET = "WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_RESET";

export const WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_REQUEST = "WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_REQUEST";
export const WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_SUCCESS = "WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_SUCCESS";
export const WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_FAIL = "WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_FAIL";
export const WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_RESET = "WAREHOUSE_STOCK_OUT_CREATE_BY_WAREHOUSE_NAME_RESET";

export const WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_REQUEST = "WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_REQUEST";
export const WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_SUCCESS = "WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_SUCCESS";
export const WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_FAIL = "WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_FAIL";
export const WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET = "WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET";

export const WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_REQUEST = "WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_REQUEST";
export const WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_SUCCESS = "WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_SUCCESS";
export const WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_FAIL = "WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_FAIL";
export const WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_RESET = "WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_RESET";

export const WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_REQUEST = "WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_REQUEST";
export const WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_SUCCESS = "WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_SUCCESS";
export const WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_FAIL = "WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_FAIL";
export const WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_RESET = "WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_RESET";

