import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardBody from "../Card/CardBody";
import {
  Typography,
  Grid,
  Button,
  Select,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Tooltip,
  Divider,
} from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {
  deleteProduct,
  updateProduct,
} from "../../actions/productAction";
import { listAllVehicles } from "../../actions/vehicleAction";

import { listStockByVehicleNo } from "../../actions/transitStockAction";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const TransitStockListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const columnDefs = [
    { field: "#", resizable: true, suppressSizeToFit: true, width: 75, minWidth: 75, maxWidth: 75 },
    {
      field: "VehicleNo", headerName: 'Vehicle #', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "SourceName", headerName: 'Source Name', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "DestinationName", headerName: 'Destination Name', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "VehicleInchargeName", headerName: 'Vehicle Incharge Name', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "DriverName", headerName: 'Driver Name', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "DriverContact", headerName: 'Driver Contact', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "StockInTime", headerName: 'Stock In Time', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "StockOutTime", headerName: 'Stock Out Time', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "Id",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer : (params) => (

        <span>
          {console.log("params ", params, params.data)}
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showTransitStockEntryDetails(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];

  const [vehicleSelected, setVehicleSelected] = useState(() => "");
  const [open, setOpen] = useState(() => false);
  const [confirmOpen, setConfirmOpen] = useState(() => false);
  const [filteredProduct, setFilteredProduct] = useState(() => { });
  const [action, setAction] = useState(() => { });
  let noDataFoundContent = null;
  const [idSelected, setIdSelected] = useState(() => {
    return "";
  });

  const dispatch = useDispatch();

  const vehicleList = useSelector((state) => state.vehicleList);
  const transitStockListByVehicleNo = useSelector((state) => state.transitStockListByVehicleNo);
  const { loading, error, vehicles } = vehicleList;
  const { transit_stock_by_vehicle_no_loading, transit_stock_by_vehicle_no_error, transit_stock_by_vehicle_no } = transitStockListByVehicleNo

  useEffect(() => {
    console.log("Dispatching to List All Vehicles ");
    dispatch(listAllVehicles());
  }, [dispatch, navigate]);

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const groupByProperty = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  useEffect(() => {
    if (vehicleSelected && vehicleSelected !== "")
      dispatch(listStockByVehicleNo(vehicleSelected));
  }, [dispatch, vehicleSelected]);

  const showTransitStockEntryDetails = (params) => {
    console.log("showTransitStockEntryDetails : ", params);
    console.log("Bulk params", params.data.Id, params.data);
    // const id = params.data.Id;
    const veh = params.data.VehicleNo;
    const src = params.data.SourceName;
    const dest = params.data.DestinationName;

    console.log(` ###### Now showing Transit Stock Entry Details ######  ${veh}/${src}/${dest}`);
    navigate(`/admin/transit-stock/details/${veh}/${src}/${dest}`);
  };


  const handleChangeVehicle = (e) => {
    console.log("Vehicle Changed  " + e.target.value);
    setVehicleSelected(() => e.target.value);
  };

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found for Vehicle # {vehicleSelected}</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  let renderVehiclesOptions = "";
  if (vehicles && vehicles.length > 0) {
    renderVehiclesOptions = vehicles.map((eachVehicle, idx) => {
      return (
        <MenuItem key={idx} value={eachVehicle.vehicleNo} style={{ color: "Gray" }}>
          {eachVehicle.vehicleNo}
        </MenuItem>
      );
    });
  }

  if (transit_stock_by_vehicle_no && transit_stock_by_vehicle_no.length > 0) {
    const groupedByDestinationName = groupByProperty(transit_stock_by_vehicle_no, 'destinationName');
    console.log("groupedByDestinationName : ", groupedByDestinationName);
    const keys = Object.keys(groupedByDestinationName);
    const entries = Object.entries(groupedByDestinationName);
    console.log(" Keys : ", keys, " entries : ", entries);
    const transformedMap = new Map();
    entries.map(([key, value], index) => {
      console.log(" Key : ", key, " Value : ", value);
      for (const eachItem of value) {
        const eachPrd = {
          countInStock: eachItem.countInStock,
          productName: eachItem.productName,
          status: eachItem.status,
          stockInTime: eachItem.stockInTime,
        }
        if (transformedMap.has(key)) {
          console.log("Value : ", transformedMap.get(key).values().next())
        } else {
          let prdsMap = new Map();
          transformedMap.set(key, prdsMap.set('products',[eachPrd]))
        }
      }
    }
    )
    console.log("transformedMap : ", transformedMap);
    transformedMap.forEach(([key, value], index) => console.log("TXF Key : ",transformedMap.get(key), "Value : ",value))

    const json = JSON.stringify(Object.fromEntries(transformedMap));
    const parsedJson = JSON.parse(json);
    console.log("parsedJson : " , parsedJson['KOKAPET_ST'])
  }
  if (transit_stock_by_vehicle_no && transit_stock_by_vehicle_no.length > 0) {
    const entries = Object.entries(transit_stock_by_vehicle_no);
    rowData = entries.map(([key, value], index) => {
      return {
        "#": index + 1,
        "VehicleNo": value.vehicleNo,
        "SourceName": value.sourceName,
        "DestinationName": value.destinationName,
        "VehicleInchargeName": value.vehicleInchargeName,
        "DriverName": value.driverName,
        "DriverContact": value.driverContact,
        "StockInTime": value.stockInTime,
        "StockOutTime": value.stockOutTime,
        Id: value._id,
      };
    }
    );

    if (rowData && rowData.length > 0) {
      renderContentUsingAGGrid = (
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
        </div>
      );
    }
  }

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const submitHandler = () => {
    console.log(" ####### EXEC submitHandler ####### : ProductList", action, filteredProduct);
    if (action === "edit") {
      console.log("filteredProduct :==> ", filteredProduct);
      dispatch(updateProduct(filteredProduct));
      setOpen(false);
      setFilteredProduct({});
    } else if (action === "delete") {
      console.log(filteredProduct);
      dispatch(deleteProduct(filteredProduct._id));
      setOpen(false);
    }
  };

  return (
    <React.Fragment>
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
              <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >Transit Stock Data by Vehicle No
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
          <FormControl
            style={{
              textTransform: "capitalize",
              margin: "1rem",
              float: "left",
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Select Vehicle
            </InputLabel>
            <Select
              label="Select Vehicle"
              placeholder="Select Vehicle"
              variant="outlined"
              required
              size="small"
              name="Select Vehicle"
              value={vehicleSelected}
              onChange={handleChangeVehicle}
              style={{ width: "15rem" }}
            >
              {renderVehiclesOptions}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : transit_stock_by_vehicle_no_loading !== true && vehicleSelected !== null && noDataFoundContent}
        </GridItem>
      </GridContainer>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader >
                  <Typography variant="h5" style={{ fontWeight: 500 }}>
                    Are you Sure,All the Products are delivered ?
                  </Typography>
                  <Divider />
                </CardHeader>
                <CardBody>
                  <form onSubmit={submitHandler}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          color="secondary"
                          justify="center"
                          style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                        >
                          Yes
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default TransitStockListScreen;
