import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { getInvoiceDetailsByInvoiceId, getSalesInvoiceDetailsByInvoiceId } from "../../../../actions/vehicleSalesInvoiceAction.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import CustomBackdropSpinner from "../../../CustomBackdropSpinner.js";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { addDecimals } from "../../../../util/Utility.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const VehicleSalesInvoiceDetailsScreen = () => {
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();
  console.log('id', id)
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) dispatch(getSalesInvoiceDetailsByInvoiceId(id));
  }, [dispatch, id]);

  const vehicleSalesInvoiceByInvoiceNo = useSelector((state) => state.vehicleSalesInvoiceByInvoiceNo);
  console.log('vehicleSalesInvoiceByInvoiceNo : ', vehicleSalesInvoiceByInvoiceNo)
  const {
    vehicle_sale_invoice_by_invoice_no_loading,
    vehicle_sale_invoice_by_invoice_no_data,
    vehicle_sale_invoice_by_invoice_no_error } = vehicleSalesInvoiceByInvoiceNo;

  const { customerDetails, invoiceProducts, vehicleNo, driverName, driverContact, itemsPrice, taxPrice } =
    vehicle_sale_invoice_by_invoice_no_data;
  console.log("customerDetails : ", customerDetails);
  console.log("invoiceProducts : ", invoiceProducts);
  console.log("vehicle_sale_invoice_by_invoice_no_data : ", vehicle_sale_invoice_by_invoice_no_data);

  const columnDefs = [
    { field: "#", resizable: true, suppressSizeToFit: true, width: 75, minWidth: 75, maxWidth: 75, headerName: "#", },
    { field: "Name", resizable: true, suppressSizeToFit: true, width: 300, minWidth: 300, maxWidth: 300, headerName: "Name", },
    { field: "Quantity", resizable: true, suppressSizeToFit: true, width: 150, minWidth: 150, maxWidth: 150, headerName: "Quantity" },
    { field: "UnitPrice", resizable: true, suppressSizeToFit: true, width: 150, minWidth: 150, maxWidth: 150, headerName: "Selling Price" },
    { field: "DiscountPercent", resizable: true, suppressSizeToFit: true, width: 150, minWidth: 150, maxWidth: 150, headerName: "Discount [%]" },
    { field: "DiscountStartsFrom", resizable: true, suppressSizeToFit: true, width: 200, minWidth: 200, maxWidth: 200, headerName: "Discount from[Kgs]" },
    { field: "ProductTax", resizable: true, suppressSizeToFit: true, width: 150, minWidth: 150, maxWidth: 150, headerName: "Total Tax [%]" },
    { field: "ProductTaxAmount", resizable: true, suppressSizeToFit: true, width: 150, minWidth: 150, maxWidth: 150, headerName: "Total Tax Amount" },
    { field: "ItemCost", resizable: true, suppressSizeToFit: true, width: 150, minWidth: 150, maxWidth: 150, headerName: "Items Total Cost" },];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
    }),
    []
  );
  let rowData = [];
  let renderContentUsingAGGrid = null;

  if (
    vehicle_sale_invoice_by_invoice_no_data &&
    vehicle_sale_invoice_by_invoice_no_data._id
  ) {
    console.log(
      "vehicle_sale_invoice_by_invoice_no_data: ",
      vehicle_sale_invoice_by_invoice_no_data
    );
    rowData = vehicle_sale_invoice_by_invoice_no_data.invoiceProducts.map((prd, index) => {
      return {
        "#": index + 1,
        "Name": prd.name,
        "Quantity": prd.quantity,
        "UnitPrice": prd.unitPrice,
        "DiscountPercent": prd.discountPercent,
        "DiscountStartsFrom": prd.discountStartsFrom,
       "ProductTax": prd.productTax ? prd.productTax : 0.0,
        "ProductTaxAmount": prd.productTaxAmount ? prd.productTaxAmount : 0.0,
        "ItemCost": prd.itemCost && addDecimals(prd.itemCost),
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    );
  }

  return (
    <React.Fragment>
      {vehicle_sale_invoice_by_invoice_no_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
          <Tooltip title="Back" arrow>
            <ArrowBackIcon
              fontSize="medium"
              onClick={() => window.history.back()}
              style={{ float: "left" }}
            />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "0.5rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>
        <Typography
          variant="h4"
          color="gray"
          style={{ margin: "0.5rem", textAlign: "center", textTransform: 'none' }}
        >
          Vehicle Sales Invoice # {id}
          <CheckCircleRoundedIcon
            style={{ float: "center", color: "#26A541" }}
          />
        </Typography>
        {customerDetails && (
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Customer Details
              </Typography>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Shop Name"
                      name="shopName"
                      value={
                        customerDetails.shopName ? customerDetails.shopName : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      label="Shop Address"
                      value={
                        customerDetails.shopAddress
                          ? customerDetails.shopAddress
                          : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Contact"
                      variant="outlined"
                      size="small"
                      value={
                        customerDetails.shopOwnerContact
                          ? customerDetails.shopOwnerContact
                          : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Bill Date"
                      variant="outlined"
                      size="small"
                      value={
                        vehicle_sale_invoice_by_invoice_no_data.billDate
                          ? vehicle_sale_invoice_by_invoice_no_data.billDate
                          : ""
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Order Total"
                      variant="outlined"
                      size="small"
                      value={invoiceProducts && addDecimals(invoiceProducts.map((eachProduct) => eachProduct.itemCost).reduce((acc, amount) => acc + amount))}
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="UPI Amount Paid"
                      variant="outlined"
                      size="small"
                      value={
                        vehicle_sale_invoice_by_invoice_no_data.paymentDetails.upiAmountPaid
                          ? vehicle_sale_invoice_by_invoice_no_data.paymentDetails.upiAmountPaid
                          : 0.0
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Cash Amount Paid"
                      variant="outlined"
                      size="small"
                      value={
                        vehicle_sale_invoice_by_invoice_no_data.paymentDetails.cashAmountPaid
                          ? addDecimals(vehicle_sale_invoice_by_invoice_no_data.paymentDetails.cashAmountPaid)
                          : 0.0
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Due"
                      variant="outlined"
                      size="small"
                      value={
                        (vehicle_sale_invoice_by_invoice_no_data && vehicle_sale_invoice_by_invoice_no_data.paymentDetails && vehicle_sale_invoice_by_invoice_no_data.paymentDetails.balanceAmount ? vehicle_sale_invoice_by_invoice_no_data.paymentDetails.balanceAmount :0.0)
                      }
                      style={{ width: "20vh" }}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        )}
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default VehicleSalesInvoiceDetailsScreen;
