import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography, Divider  } from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { createWarehouseInfos } from "../../../actions/warehouseInfoAction";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer.js";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import CardBody from "../../Card/CardBody.js";
import { WAREHOUSE_INFO_CREATE_RESET } from "../../../constants/warehouseInfoConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  warehouseName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  phoneNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
  address: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  managerName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  managerEmail: {
    presence: { allowEmpty: false, message: "is required" },
    email: {
      message: "doesn't look like a valid email"
    },
    length: {
      maximum: 300,
    },
  },
  postalCode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 6,
    },
  },
};

const WarehouseForm = (props) => {
  console.log("Exec WarehouseForm");

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const warehouseInfoCreated = useSelector((state) => state.warehouseInfoCreated);
  const { wh_info_create_success } = warehouseInfoCreated;

  useEffect(() => {
    if (wh_info_create_success) {
      navigate("/admin/wh-info/list");
    }
  }, [navigate, wh_info_create_success]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(formState.values);
      dispatch(
        createWarehouseInfos(
          formState.values.warehouseName,
          formState.values.phoneNo,
          formState.values.address,
          formState.values.managerName,
          formState.values.managerEmail,
          formState.values.postalCode
        )
      );
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <GridContainer spacing={1} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form
                name="password-reset-form"
                method="post"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Warehouse Name"
                      label="Warehouse Name *"
                      variant="outlined"
                      size="small"
                      name="warehouseName"
                      fullWidth
                      helperText={
                        hasError("warehouseName")
                          ? formState.errors.warehouseName[0]
                          : null
                      }
                      error={hasError("warehouseName")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.warehouseName || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Phone No"
                      label="Phone No *"
                      variant="outlined"
                      size="small"
                      name="phoneNo"
                      fullWidth
                      helperText={
                        hasError("phoneNo") ? formState.errors.phoneNo[0] : null
                      }
                      error={hasError("phoneNo")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.phoneNo || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Address"
                      label="Address *"
                      variant="outlined"
                      size="small"
                      name="address"
                      fullWidth
                      helperText={
                        hasError("address") ? formState.errors.address[0] : null
                      }
                      error={hasError("address")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.address || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Manager Name"
                      label="Manager Name *"
                      variant="outlined"
                      size="small"
                      name="managerName"
                      fullWidth
                      helperText={
                        hasError("managerName")
                          ? formState.errors.managerName[0]
                          : null
                      }
                      error={hasError("managerName")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.managerName || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Manager Email"
                      label="Manager Email *"
                      variant="outlined"
                      size="small"
                      name="managerEmail"
                      fullWidth
                      helperText={
                        hasError("managerEmail")
                          ? formState.errors.managerEmail[0]
                          : null
                      }
                      error={hasError("managerEmail")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.managerEmail || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Postal Code"
                      label="Postal Code *"
                      variant="outlined"
                      size="small"
                      name="postalCode"
                      fullWidth
                      helperText={
                        hasError("postalCode")
                          ? formState.errors.postalCode[0]
                          : null
                      }
                      error={hasError("postalCode")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.postalCode || ""}
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default WarehouseForm;
