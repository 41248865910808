import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Button, Typography, Grid, TextField } from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { createCategory } from "../../actions/categoryAction";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { CATEGORY_CREATE_RESET } from "../../constants/categoryConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  description: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
};

const CategoryForm = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const categoryCreate = useSelector((state) => state.categoryCreate);
  const { loading, success_create, category } = categoryCreate;

  useEffect(() => {
    console.log("useEffect Getting Called CategoryListScreen");
    if (success_create) {
      dispatch({ type: CATEGORY_CREATE_RESET });
      navigate("/admin/categories");
    }
  }, [dispatch, navigate, success_create]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(formState.values);
      dispatch(
        createCategory(formState.values.name, formState.values.description)
      );
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
const customGrayColor = "#E9EBEE";
  return (
    <div className={classes.root}>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={{customGrayColor}}>
            </CardHeader>
            <CardBody>
              <form
                name="password-reset-form"
                method="post"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                <Grid item xs={12} align="center">
                    <TextField
                      placeholder="Name"
                      label="Name*"
                      variant="outlined"
                      size="small"
                      name="name"
                      helperText={
                        hasError("name") ? formState.errors.name[0] : null
                      }
                      error={hasError("name")}
                      onChange={handleChange}
                      value={formState.values.name || ""}
                      type="text"
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <TextField
                      placeholder="Description"
                      label="Description *"
                      variant="outlined"
                      size="small"
                      name="description"
                      helperText={
                        hasError("description")
                          ? formState.errors.description[0]
                          : null
                      }
                      error={hasError("description")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.description || ""}
                    />
                  </Grid>
                  
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      // fullWidth
                      sx={{
                        width: {
                          xs :"12rem",
                          sm :"12rem",
                          md :"12rem",
                          lg :"12rem",
                        }
                      }}
                      style={{ textTransform: "capitalize", borderRadius: "5px" }}
                    >
                      Create 
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"




              
                      color="textSecondary"
                      align="center"
                    ></Typography>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CategoryForm;
