import axios from "axios";
import {
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAIL,
  STORE_DELETE_SUCCESS,
  STORE_DELETE_REQUEST,
  STORE_DELETE_FAIL,
  STORE_CREATE_REQUEST,
  STORE_CREATE_SUCCESS,
  STORE_CREATE_FAIL,
  STORE_UPDATE_REQUEST,
  STORE_UPDATE_SUCCESS,
  STORE_UPDATE_FAIL,
  STORE_BY_ID_REQUEST,
  STORE_BY_ID_SUCCESS,
  STORE_BY_ID_FAIL,
} from "../constants/storeConstants";

export const listAllStores = () => async (dispatch) => {
  try {
    dispatch({ type: STORE_LIST_REQUEST });

    const { data } = await axios.get(`/api/store-info`);

    dispatch({
      type: STORE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STORE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStoreById = (id) => async (dispatch) => {
  try {
    dispatch({ type: STORE_BY_ID_REQUEST });

    const { data } = await axios.get(`/api/store-info/${id}`);

    dispatch({
      type: STORE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STORE_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteStore = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_DELETE_REQUEST,
    });

    await axios.delete(`/api/store-info/${id}`);

    dispatch({
      type: STORE_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: STORE_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createStore =
  (storeName, phoneNo, address, managerName, managerEmail,latitude, longitude, postalCode) =>
  async (dispatch, getState) => {
    try {
      console.log(
        "storeName : " +
          storeName +
          ", phoneNo : " +
          phoneNo +
          ", address: " +
          address,
          "latitude : ",latitude, "longitude : ", longitude
      );
      dispatch({
        type: STORE_CREATE_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `/api/store-info`,
        { storeName, phoneNo, address, managerName, managerEmail, latitude, longitude, postalCode },
        config
      );

      dispatch({
        type: STORE_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: STORE_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const updateStore =
  (id, storeName, address, phoneNo, managerName, managerEmail, postalCode) =>
  async (dispatch, getState) => {
    try {
      console.log("Exec updateStore from Action");
      console.log(
        id,
        storeName,
        phoneNo,
        address,
        managerName,
        managerEmail,
        latitude,
        longitude,
        postalCode
      );
      dispatch({
        type: STORE_UPDATE_REQUEST,
      });

      const { data } = await axios.put(`/api/store-info`, {
        storeName,
        address,
        phoneNo,
        managerName,
        managerEmail,
        latitude,
        longitude,
        postalCode,
      });

      dispatch({
        type: STORE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
      }
      dispatch({
        type: STORE_UPDATE_FAIL,
        payload: message,
      });
    }
  };
