import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {
  Divider,
  Autocomplete,
} from "@mui/material";
import { createWarehouseStoreInvoice } from "../../actions/stockTransferInvoiceAction";
import { createTransitStockByVehicleNoAndProductName } from "../../actions/transitStockAction";
import { updateStockInByWarehouseProduct, updateStockOutByWarehouseProduct } from "../../actions/warehouseStockAction";
import { updateStockInByStoreProduct, updateStockOutByStoreProduct } from "../../actions/storeStockAction";
import { } from "../../actions/warehouseStockAction";
import { listStockByStoreName } from "../../actions/storeStockAction";
import { listStockByWarehouseName } from "../../actions/warehouseStockAction";
import { listAllWarehouses } from "../../actions/warehouseInfoAction";
import { listAllStores } from "../../actions/storeAction";
import { listAvailableVehicles, updateVehicleAvailabilityAndAssignedStatus } from "../../actions/vehicleAction";
import { listUsers } from "../../actions/userAction";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper
} from "@mui/material";
import SignpostIcon from "@mui/icons-material/Signpost";
import { WAREHOUSE_INVOICE_CREATE_RESET } from "../../constants/warehouseInvoiceConstants";
import { WAREHOUSE_STORE_INVOICE_CREATE_RESET } from "../../constants/warehouseConstants";

import {
  service_states,
  payment_modes,
  unit_of_meassures,
  warehouses,
  warehouse_store,
  warehouse_store_vehicle,
  stock_transfer_invoice_status,
  vehicle_shifts,
} from "../../data/index";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../assets/images/green_tick.png";

import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET } from "../../constants/vehicleStockConstants";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const StockTransfer = ({ match }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vertical = 'top';
  const horizontal = 'center';
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
      '& .MuiDialogContent-root': {
          padding: theme.spacing(2),
      },
      '& .MuiDialogActions-root': {
          padding: theme.spacing(1),
      },
  }));
  const [open, setOpen] = React.useState(false);
  const [maxQuantityAllowed, setMaxQuantityAllowed] = React.useState("");
  const [maxQuantityAllowedErrorMessage, setMaxQuantityAllowedErrorMessage] = React.useState("");

  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const handleClose = () => {
    setOpen(false);
    if (some_success) {
        dispatch({ type: some_RESET });
        navigate("/admin/stock-transfer/list");
    }
};

  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName } = userInfo;

  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const columnDefs = [
    {
      field: "SNo",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 550,
      minWidth: 550,
      maxWidth: 550
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      editable: true
    },
    {
      field: "UnitPrice",
      headerName: "UnitPrice",
      resizable: true,
      suppressSizeToFit: true, width: 180,
      minWidth: 180,
      maxWidth: 180
    },
    {
      field: "Amount",
      headerName: "Amount",
      resizable: true,
      suppressSizeToFit: true, width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "Delete", resizable: true, suppressSizeToFit: true, width: 125, minWidth: 125, maxWidth: 125,
      cellRenderer: (params) => (
        <span><Tooltip title="Delete" arrow>
          <DeleteRoundedIcon
            size="small"
            variant="contained"
            // type="submit"
            color="Gray"
            justify="center"
            onClick={() => {
              console.log("Deleting ", params);
              console.log("productsTobeAddedInInvoice : ", productsTobeAddedInInvoice);
              console.log(" Item to be Deleted at Index : ", Number(params.data.SNo) - 1);
              console.log(" Removed : ", productsTobeAddedInInvoice.splice(Number(params.data.SNo) - 1, 1));
              setProductsTobeAddedInInvoice(() => [...productsTobeAddedInInvoice]);
              console.log("After Removal , Length of productsTobeAddedInInvoice : ", productsTobeAddedInInvoice.length);
            }
            }
            style={{ marginRight: "1rem", width: "2rem" }}
          ></DeleteRoundedIcon>
        </Tooltip></span>
      ), sortable: false,
      filter: false,
      floatingFilter: false
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );


  const [productsTobeAddedInInvoice, setProductsTobeAddedInInvoice] = useState(
    () => []
  );
  const [billDate, setBillDate] = useState(new Date());

  const [productFormInputs, setProductFormInputs] = useState({
    name: "",
    quantity: "",
    metrics: "",
    actualWeight: 0,
    pricePerUnit: 0,
    unitPrice: 0,
    tax: "",
    total: 0.0,
    subCategory: "",
    unit: 0,
    massUnits: 0,
  });

  const [invoiceFormInputs, setInvoiceFormInputs] = useState({
    description: "",
    total: 0.0,
    vehicleNo: "",
    vehicleInchargeName: "",
    vehicleInchargeContact: "",
    driverContact: "",
    driverName: "",
    fromName: "",
    fromWarehouseStoreValue: "",
    toName: "",
    toWarehouseStoreValue: "",
    stockTransferInvoiceStatus: "",
    billDate: "",
    shift: "",
  });

  const [autoCompleteValue, setAutoCompleteValue] = useState(() => "");
  useState(() => "");

  const [fromWarehouseStoreValue, setFromWarehouseStoreValue] =
    useState(() => "");

  const [
    toWarehouseStoreValue,
    setToWarehouseStoreValue,
  ] = useState(() => "");

  const [warehouseNamesFilter, setWarehouseNamesFilter] = useState(() => []);
  const [storeNamesFilter, setStoreNamesFilter] = useState(() => []);
  const [targetNamesFilter, setTargetNamesFilter] = useState(() => []);

  const [autoCompleteVehicleValue, setAutoCompleteVehicleValue] = useState(
    () => ""
  );
  const warehouseStockList = useSelector((state) => state.warehouseStockListByWarehouseName);
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const { wh_stock_data, wh_stock_error } = warehouseStockList;
  const storeStockList = useSelector((state) => state.storeStockListByStoreName);
  const { store_stock_data } = storeStockList;
  const warehouseInfoList = useSelector((state) => state.warehouseInfoList);
  const { warehouseInfos } = warehouseInfoList;
  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;
  // const vehicleList = useSelector((state) => state.vehicleList);
  const availableVehicleList = useSelector((state) => state.availableVehicleList);
  const { vehicles_available } = availableVehicleList;
  const warehouseInvoiceCreate = useSelector((state) => state.warehouseInvoiceCreate);
  const warehouseStockUpdated = useSelector((state) => state.warehouseStockUpdated);
  const storeStockUpdated = useSelector((state) => state.storeStockUpdated);
  const { store_stock_update_success } = storeStockUpdated;
  const { success, wh_stock_update_success } = warehouseStockUpdated;
  const vehicleStockInUpdated = useSelector((state) => state.vehicleStockInUpdated);
  const { vehicle_stock_in_update_loading, vehicle_stock_in_update_success, vehicle_stock_in_update_data, vehicle_stock_in_update_error } = vehicleStockInUpdated;
  let rowData = [];
  const {
    wi_create_loading,
    wi_create_success,
    wi_create_data,
    wi_create_error,
  } = warehouseInvoiceCreate;

  const populateTargetNamesFilters = () => {
    console.log('Exec populateTargetNamesFilters..! ')
    let tempTargetNames = []
    if (fromWarehouseStoreValue.includes("WAREHOUSE") && invoiceFormInputs.toName === "Warehouse") {
      tempTargetNames = customWarehouses.filter(wh => wh.warehouseName !== fromWarehouseStoreValue).map((eachFy, idx) => <MenuItem key={idx} value={eachFy.warehouseName}>{eachFy.warehouseName}</MenuItem>)
    } else if (fromWarehouseStoreValue.includes("WAREHOUSE") && invoiceFormInputs.toName === "Store") {
      tempTargetNames = customStores.map((eachFy, idx) => <MenuItem key={idx} value={eachFy.storeName}>{eachFy.storeName}</MenuItem>)
    }
    if (fromWarehouseStoreValue.includes("STORE") && invoiceFormInputs.toName === "Store") {
      tempTargetNames = customStores.filter(wh => wh.storeName !== fromWarehouseStoreValue).map((eachFy, idx) => <MenuItem key={idx} value={eachFy.storeName}>{eachFy.storeName}</MenuItem>)
    } else if (fromWarehouseStoreValue.includes("STORE") && invoiceFormInputs.toName === "Warehouse") {
      tempTargetNames = customWarehouses.map((eachFy, idx) => <MenuItem key={idx} value={eachFy.warehouseName}>{eachFy.warehouseName}</MenuItem>)
    }
    console.log('tempTargetNames : ', tempTargetNames ? tempTargetNames.length : 0)
    setTargetNamesFilter(() => tempTargetNames);
  }
  let customProducts = [];
  let customWarehouses = [];
  let customStores = [];
  let customVehicles = [];
  let vehicleIncharges = [];
  let drivers = [];

  if (fromWarehouseStoreValue.includes("WAREHOUSE") && wh_stock_data && wh_stock_data.length > 0) {
    customProducts = wh_stock_data;
    console.log('wh_stock_data : ', wh_stock_data);
  }
  if (fromWarehouseStoreValue.includes("STORE") && store_stock_data && store_stock_data.length > 0) {
    customProducts = store_stock_data;
    console.log('store_stock_data : ', store_stock_data.length);
  }
  if (warehouseInfos && warehouseInfos.length > 0)
    customWarehouses = warehouseInfos;
  if (vehicles_available && vehicles_available.length > 0) {
    customVehicles = vehicles_available;
    console.log("vehicles_available : ", vehicles_available.length );
  }
  if (stores && stores.length > 0)
    customStores = stores;

  if (users && users.length > 0) {
    vehicleIncharges = users.filter(each => each.role === "VEHICLE_SALES_INCHARGE_ROLE");
    drivers = users.filter(each => each.role === "DRIVER_ROLE");
    console.log("vehicleIncharges  : ", vehicleIncharges?vehicleIncharges.length:0, "drivers  :", drivers?drivers.length:0);
  }

  useEffect(() => {
    if (invoiceFormInputs.toName && invoiceFormInputs.toName === "Store" || invoiceFormInputs.toName === "Warehouse") {
      setTargetNamesFilter(() => []);
      populateTargetNamesFilters()
    }
  }, [invoiceFormInputs.toName]);

  const populateFromWarehouseStoreValue = (whSt) => {
    console.log(" Setting FromWarehouseStoreValueValue from Store or Warehouse : ", storeName);
    setFromWarehouseStoreValue(() => whSt);
  }

  useEffect(() => {
    dispatch(listAllWarehouses());
    dispatch(listAllStores());
    dispatch(listUsers());
  }, [dispatch]);

  useEffect(() => {
    if (storeName && storeName.length > 0) {
      console.log(" As From Name is Store puling All Products from Store : ", storeName);
      populateFromWarehouseStoreValue(storeName);
      dispatch(listAvailableVehicles());
      dispatch(listStockByStoreName(storeName));
    }
    else if (warehouseName && warehouseName.length > 0) {
      console.log(" As From Name is Warehouse puling All Products from WH : ", warehouseName);
      populateFromWarehouseStoreValue(warehouseName);
      dispatch(listAvailableVehicles());
      dispatch(listStockByWarehouseName(warehouseName));
    }

  }, [dispatch, storeName, warehouseName]);


  useEffect(() => {
    if (wi_create_success) {
      console.log(
        "wi_create_data Invoice ID :==> ",
        wi_create_success,
      );
      dispatch({ type: WAREHOUSE_STORE_INVOICE_CREATE_RESET });
      navigate("/admin/stock-transfer/list");
    }
  }, [
    dispatch,
    navigate,
    wi_create_success,
  ]);

  useEffect(() => {
    if (vehicle_stock_in_update_success) {
      console.log(
        "vehicle_stock_in_update_success  :==> ",
        vehicle_stock_in_update_success
      );
      dispatch({ type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET });
      navigate("/admin/stock-transfer/list");
    }
  }, [
    dispatch,
    navigate,
    vehicle_stock_in_update_success
    ,
  ]);

  const validateProductBeforeAdd = () => {
    console.log("validateProductBeforeAdd ...! ");
    if (productFormInputs.name !== null && productFormInputs.quantity)
      return true;
    else return false;
  }

  const handleProductChange = (event) => {
    setProductFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    // if (Number(event.target.value) > Number(maxQuantityAllowed))
    //   setMaxQuantityAllowedErrorMessage("Max Allowed Quantity is : " + maxQuantityAllowed)
  };

  const handleQuantityChange = (event) => {
    setMaxQuantityAllowedErrorMessage(() => "");
    const currentQuantity = event.target.value ? Number(event.target.value) : 0
    console.log("handleQuantityChange . Current Value is : ", currentQuantity)
    setMaxQuantityAllowedErrorMessage(() => "");
    setProductFormInputs((prevState) => ({
      ...prevState,
      quantity: currentQuantity,
    }));

    if (Number(currentQuantity) > Number(maxQuantityAllowed))
      setMaxQuantityAllowedErrorMessage("Max Allowed Quantity is : " + maxQuantityAllowed)
  };

  const handleInvoiceChange = (event) => {
    setInvoiceFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleStockTransfer = () => {
    console.log("handleStockTransfer ! ");
    const tempFromWarehouseStoreValue = fromWarehouseStoreValue.includes("WAREHOUSE")
    ? warehouseName : fromWarehouseStoreValue.includes("STORE") ? storeName : null
    const tempToWarehouseStoreValue = toWarehouseStoreValue ? toWarehouseStoreValue : ""
    console.log("fromWarehouseStoreValue : ", tempFromWarehouseStoreValue)
    console.log("toWarehouseStoreValue : ", tempToWarehouseStoreValue)

    setInvoiceFormInputs((prevState) => ({
      ...prevState,
      fromWarehouseStoreValue: tempFromWarehouseStoreValue,
      toWarehouseStoreValue: tempToWarehouseStoreValue
    }));

    handleCreateInvoice();
  };

  const handleCreateInvoice = () => {
    console.log('###### Exe handleCreateInvoice ######  ')
    console.log('invoiceFormInputs : ', invoiceFormInputs)
    console.log('productsTobeAddedInInvoice Length :==> ', productsTobeAddedInInvoice ? productsTobeAddedInInvoice.length : 0)
    {
      /** 
        NEW LOGIC FOR STOCK IN TO WH... IS HANDLED HERE.!!!
        STEP 0 : DISPLAY THE VEHICLES && DRIVERS  WHICH ARE AVAILABLE
        STEP 1 : UPDATE THE STOCK MINUS FROM SOURCE
        STEP 2 : UPDATE THE STOCK PLUS INTO DESTINATION
        STEP 3 : UPDATE THE VEHICLE AVAILABILITY
      */
    }

    /** 
      LOGIC FOR STOCK OUT OF WH... IS HANDLED HERE.!!!
    */
    if (fromWarehouseStoreValue.includes("WAREHOUSE")) {
      console.log(" ******* NOW DISPATCHING TO updateStockOutByWarehouseProduct ******* ");
      console.log(" ******* Need to update Stock By WarehouseName And ProductName from Source Warehouse ******* ");
      dispatch(
        updateStockOutByWarehouseProduct(
          invoiceFormInputs,
          productsTobeAddedInInvoice,
        )
      );
    }

    /** 
     LOGIC FOR STOCK OUT OF STORE... IS HANDLED HERE.!!!
   */
    if (fromWarehouseStoreValue.includes("STORE")) {
      console.log(" ******* NOW DISPATCHING TO updateStockOutByStoreProduct ******* ");
      console.log(" ******* Need to update Stock By Store Name And ProductName from Source Store ******* ");
      dispatch(
        updateStockOutByStoreProduct(
          invoiceFormInputs,
          productsTobeAddedInInvoice,
        )
      );
    }

    /** 
      LOGIC FOR STOCK IN TO WH... IS HANDLED HERE.!!!
    */
    if (toWarehouseStoreValue.includes("WAREHOUSE")) {
      console.log(" ******* NOW DISPATCHING TO updateStockInByWarehouseProduct ******* ");
      console.log(" ******* Need to update Stock By WarehouseName And ProductName from Source Warehouse ******* ");
      dispatch(
        updateStockInByWarehouseProduct(
          invoiceFormInputs,
          productsTobeAddedInInvoice)
      );
    }

    /** 
    LOGIC FOR STOCK IN TO STORE... IS HANDLED HERE.!!!
  */
    if (toWarehouseStoreValue.includes("STORE")) {
      console.log(" ******* NOW DISPATCHING TO updateStockInByStoreProduct ******* ");
      console.log(" ******* Need to update Stock By WarehouseName And ProductName TO Store ******* ");
      dispatch(
        updateStockInByStoreProduct(
          invoiceFormInputs,
          productsTobeAddedInInvoice,
        )
      );
    }
    {/** LOGIC TO GENERATE THE INVOICE WHEN TARGET IS WH OR STORE
   */}
    if (toWarehouseStoreValue && toWarehouseStoreValue.length > 0) {
      console.log(" ******* NOW creating Invoice for Warehouse or Store  *******  toWarehouseStoreValue : ", toWarehouseStoreValue);
      dispatch(
        createWarehouseStoreInvoice(
          invoiceFormInputs, productsTobeAddedInInvoice
        )
      );
      console.log("handleCreateInvoice - STOP");
    } else {
      console.log(" ******* NOT creating Invoice for Warehouse or Store  *******  toWarehouseStoreValue : ", toWarehouseStoreValue);
    }
  };

  const handleClearInvoiceForm = () => {
    setInvoiceFormInputs((prevState) => {
      return {
        driverContact: 0,
        description: "",
        roundOff: 0.0,
        total: 0.0,
        stateOfSupply: "",
        paymentType: "",
        storeName: "",
        warehouseName: "",
      };
    });
  };

  const handleClearProductForm = () => {
    setProductFormInputs(() => {
      return {
        name: "",
        quantity: "",
        pricePerUnit: "",
        tax: "",
        taxAmount: "",
        subCategory: "",
        unit: 0,
        total: 0.0,
      };
    });
  };

  const handleAddProduct = () => {
    console.log(
      "toWarehouseStoreValue : ",
      toWarehouseStoreValue,
      "autoCompleteValue : ",
      autoCompleteValue
    );

    setProductsTobeAddedInInvoice([
      ...productsTobeAddedInInvoice,
      {
        name: autoCompleteValue.productName,
        quantity: productFormInputs.quantity,
        unitPrice: productFormInputs.unitPrice,
        tax: productFormInputs.tax,
        massUnits: productFormInputs.massUnits,
        actualWeight: productFormInputs.actualWeight,
        isTaxable: true,
        total:
          productFormInputs.quantity * productFormInputs.unitPrice +
          (productFormInputs.tax / 100) *
          (productFormInputs.quantity * productFormInputs.unitPrice),
        subCategory: "",
      },
    ]);
    handleClearProductForm();
  };

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "payment_modes":
        return payment_modes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "warehouses":
        return warehouses.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "service_states":
        return service_states.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "unit_of_meassures":
        return unit_of_meassures.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "stores":
        return stores.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "warehouse_store":
        return warehouse_store.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "warehouse_store_vehicle":
        return warehouse_store_vehicle.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "stock_transfer_invoice_status":
        return stock_transfer_invoice_status.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "vehicle_shifts":
        return vehicle_shifts.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "metrics":
        return unit_of_meassures.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const handleDisabled = () => {
    console.log(
      "toWarehouseStoreValue === Vehicle : ",
      toWarehouseStoreValue
    );
    return toWarehouseStoreValue === "Vehicle" ? true : false;
  };

  const noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found under selected  Source {fromWarehouseStoreValue.includes("Warehouse") ? fromWarehouseStoreValue : fromWarehouseStoreValue}</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  const showMaximumQuantityAllowedMessage = (prd) => {
    console.log("Getting the Max Quantity Allowed for Product : ", prd);
    let selectedProductsQuantity = null;
    let maxQuantityAllowed = 0;
    if (fromWarehouseStoreValue.includes("WAREHOUSE") && prd && prd !== null) {
      if (wh_stock_data && wh_stock_data.length > 0) {
        selectedProductsQuantity = wh_stock_data.filter(e => e.productName === prd.productName);
        if (selectedProductsQuantity && selectedProductsQuantity.length > 0) {
          console.log("selectedProductsQuantity : ", selectedProductsQuantity[0]);
          maxQuantityAllowed = selectedProductsQuantity[0].countInStock;
          console.log("Max Quantity that can be Selected from WH : ", maxQuantityAllowed);
        }
      } else {
        console.log("Failed Getting the Max Quantity Allowed from WH ", invoiceFormInputs.fromName, "for Product : ", prd, " As wh_stock_data is null");
      }
    }
    if (fromWarehouseStoreValue.includes("STORE") && prd && prd !== null) {
      if (store_stock_data && store_stock_data.length > 0) {
        selectedProductsQuantity = store_stock_data.filter(e => e.productName === prd.productName);
        if (selectedProductsQuantity && selectedProductsQuantity.length > 0) {
          console.log("selectedProductsQuantity : ", selectedProductsQuantity[0]);
          maxQuantityAllowed = selectedProductsQuantity[0].countInStock;
          console.log("Max Quantity that can be Selected from Store : ", maxQuantityAllowed);
        }
      } else {
        console.log("Failed Getting the Max Quantity Allowed  from STore ", fromWarehouseStoreValue, "for Product : ", prd, " As store_stock_data is null");
      }
    }

    setMaxQuantityAllowed(() => maxQuantityAllowed);
  };

  if (productsTobeAddedInInvoice && productsTobeAddedInInvoice.length > 0) {
    rowData = productsTobeAddedInInvoice.map((eachProduct, index) => {
      return {
        "SNo": index + 1,
        ProductName: eachProduct.name,
        Quantity: eachProduct.quantity,
        UnitPrice: eachProduct.unitPrice,
        Amount: eachProduct.quantity * eachProduct.unitPrice +
          (eachProduct.tax / 100) *
          (eachProduct.quantity * eachProduct.unitPrice),
        Delete: eachProduct._id,
      };
    });
    if (rowData) {
      renderContentUsingAGGrid = (
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          // domLayout='autoHeight'
          ></AgGridReact>
        </div>
      );
    }
  }

  return (
    <div className={classes.root}>
      {vehicle_stock_in_update_loading && <CustomBackdropSpinner />}
      <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <GridContainer spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} >
                            <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                                    <img
                                        alt="Order Placed"
                                        src={greeTickImage}
                                        style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                                    />
                                </div>
                                <Typography
                                    variant="h5"
                                    color="black"
                                    style={{
                                        margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                                        fontWeight: "600",
                                        fontSize: "1rem",
                                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                    }}
                                >
                                    Updated Payment Details
                                </Typography>
                            </Paper>
                        </Grid>
                    </GridContainer>
                </DialogContent>
            </BootstrapDialog>
      <form name="password-reset-form" method="post">
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem", textAlign: "center" }}
                  color="black"
                >
                  Internal Stock Transfer  [ Warehouse
                  <SignpostIcon
                    style={{
                      color: "Gray",
                    }}
                  />
                  Store ]</Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Stock Transfer Details
              </Typography>
              <CardBody>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={6} lg={4} style={{ textAlign: "center" }}>
                    <TextField
                      label="From Name"
                      placeholder="From Name"
                      variant="outlined"
                      size="small"
                      required
                      name="fromName"
                      disabled
                      type="text"
                      fullWidth
                      style={{ textAlign: "center" }}
                      value={fromWarehouseStoreValue}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6} md={6} lg={4} style={{ textAlign: "center" }}>
                    <Select
                      label="To Name"
                      placeholder="To Name"
                      variant="outlined"
                      required
                      size="small"
                      name="toName"
                      fullWidth
                      onChange={handleInvoiceChange}
                      value={invoiceFormInputs.toName}
                    >
                      {renderDynamicOptions("warehouse_store")}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={4} style={{ textAlign: "center" }}>
                    <Select
                      label="Select To"
                      placeholder="Select To"
                      variant="outlined"
                      required
                      size="small"
                      fullWidth
                      onChange={(event, value) => {
                        console.log("Currently Selected Store | Warehouse Name :", event.target.value, value);
                        setToWarehouseStoreValue(() => event.target.value)
                        setInvoiceFormInputs((prevState) => ({
                          ...prevState,
                          toWarehouseStoreValue: event.target.value,
                          fromWarehouseStoreValue: fromWarehouseStoreValue,
                        }));
                      }}
                      value={toWarehouseStoreValue ? toWarehouseStoreValue : ""}
                    >
                      {invoiceFormInputs.toName && invoiceFormInputs.toName.length > 0 ? targetNamesFilter : []}
                    </Select>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/** VEHICLE DETAILS PANEL */}
        {toWarehouseStoreValue && (<GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem" }}
                  color="black"
                >
                  Vehicle Details
                </Typography>
              </div>
              <CardBody>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4} >
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        if (value) {
                          setAutoCompleteVehicleValue(value);
                          setInvoiceFormInputs((prevState) => ({
                            ...prevState,
                            vehicleNo: value && value.vehicleNo?value.vehicleNo : "",
                          }));
                        }
                      }}
                      id="combo-box-products"
                      options={
                        customVehicles
                      }
                      size="small"
                      fullWidth
                      getOptionLabel={(option) =>
                        `${option.vehicleNo}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select Vehicle No"
                          size="small"
                          value={invoiceFormInputs.vehicleNo}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4} >
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        setInvoiceFormInputs((prevState) => ({
                          ...prevState,
                          driverName: value && value.name ? value.name : "",
                          driverContact: value && value.contactNo ? value.contactNo : "",
                        }));
                      }}
                      id="combo-box-products"
                      options={
                        drivers
                      }
                      size="small"
                      fullWidth
                      getOptionLabel={(option) =>
                        `${option.name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Driver Name"
                          placeholder="Select Driver Name"
                          size="small"
                          value={invoiceFormInputs.driverName}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        )}

        {customProducts && customProducts.length === 0 && wh_stock_error && noDataFoundContent}
        {/** VEHICLE DETAILS END */}
        {customProducts && customProducts.length > 0 && (<GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Product Details
                <Divider></Divider>
              </Typography>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log('value : ', value);
                        showMaximumQuantityAllowedMessage(value)
                        setAutoCompleteValue(value);
                      }}
                      id="combo-box-products"
                      options={customProducts}
                      size="small"
                      fullWidth
                      getOptionLabel={(option) => `${option.productName}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Product"
                          size="small"
                          value={productFormInputs.name}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                    <Select
                      label="Mass Units"
                      placeholder="Mass Units"
                      variant="outlined"
                      required
                      size="small"
                      name="massUnits"
                      onChange={handleProductChange}
                      value={productFormInputs.massUnits}
                      fullWidth
                    >
                      {renderDynamicOptions("metrics")}
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                    <TextField
                      label="Unit Price"
                      variant="outlined"
                      size="small"
                      name="unitPrice"
                      onChange={handleProductChange}
                      type="number"
                      fullWidth
                      value={productFormInputs.unitPrice || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                    <TextField
                      label="Actual Weight"
                      variant="outlined"
                      size="small"
                      name="actualWeight"
                      onChange={handleProductChange}
                      type="number"
                      fullWidth
                      value={productFormInputs.actualWeight || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                    <TextField
                      label="Quantity"
                      variant="outlined"
                      size="small"
                      name="quantity"
                      onChange={handleProductChange}
                      type="number"
                      fullWidth
                      value={productFormInputs.quantity || null}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                    <TextField
                      label="Max Quantity allowed "
                      variant="outlined"
                      size="small"
                      disabled={true}
                      type="text"
                      fullWidth
                      value={maxQuantityAllowed || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <Typography
                      variant="body2"
                      style={{ margin: "0.5rem", fontWeight: "bold", verticalAlign: "middle", justifyContent: "center", alignItems: "center" }}
                      color="red"
                    >{maxQuantityAllowedErrorMessage}</Typography>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      justify="center"
                      disabled={(maxQuantityAllowedErrorMessage !== "" ? false : true) && !validateProductBeforeAdd()}
                      onClick={handleAddProduct}
                      style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                    >
                      Add Product
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        )}
        <GridContainer spacing={2} alignItems="center" justify="center">
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridContainer>
        {productsTobeAddedInInvoice && productsTobeAddedInInvoice.length > 0 && (<GridContainer spacing={2} >
          <Grid item xs={12} sm={12} md={12} style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
              disabled={
                !(
                  productsTobeAddedInInvoice &&
                  productsTobeAddedInInvoice.length > 0 &&
                  maxQuantityAllowedErrorMessage === ""
                )
              }
              justify="center"
              onClick={handleStockTransfer}
            >
              Transfer Stock
            </Button>
          </Grid>
        </GridContainer>
        )}
      </form>
    </div>
  );
};

export default StockTransfer;
