import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Container, ThemeProvider } from "@mui/material";
import theme from "./components/Theme";
import CustomHeader from "./components/CustomHeader";
import AdminUsersListPage from "./components/Users/AdminUsersListPage";
import Signin from "./components/Users/Signin";
import Signup from "./components/Users/Signup";
import Dashboard from "./components/Dashboard";
import CategoryListScreen from "./components/Category/CategoryListScreen";
import CategoryCreate from "./components/Category/CategoryCreate";
import SubCategoryListScreen from "./components/SubCategory/SubCategoryListScreen";
import SubCategoryCreate from "./components/SubCategory/SubCategoryCreate";
import ProductListScreen from "./components/Product/ProductListScreen";
import ProductCreate from "./components/Product/ProductCreate";
import StockListScreen from "./components/Stock/StockListScreen";
import StoreListScreen from "./components/Store/StoreListScreen";
import StoreCreate from "./components/Store/StoreCreate";
import StockCreate from "./components/Stock/StockCreate";
import BulkListScreen from "./components/Bulk/BulkListScreen";
import BulkItemCreate from "./components/Bulk/BulkItemCreate";
import DomesticItemCreate from "./components/Domestic/DomesticItemCreate";
import DomesticListScreen from "./components/Domestic/DomesticListScreen";
import OrderListScreen from "./components/Orders/OrderListScreen";
import StoreOrderListScreen from "./components/Orders/StoreOrderListScreen";
import OrderTracker from "./components/Orders/OrderTracker";
import OrderDetailsScreen from "./components/Orders/OrderDetailsScreen";
import ReportsScreen from "./components/Reports/ReportsScreen";
import WalkinSalesReport from "./components/Reports/WalkinSalesReport";
import WalkinVyaparSaleReport from "./components/Reports/WalkinVyaparSaleReport";
import ItemSalesVyaparReport from "./components/Reports/ItemSalesVyaparReport";
import ReportsDashboardScreen from "./components/Reports/ReportsDashboardScreen";
import PurchaseOrdersListScreen from "./components/Warehouse/PurchaseOrder/PurchaseOrdersListScreen";
import PurchaseOrderCreateScreen from "./components/Warehouse/PurchaseOrder/PurchaseOrderCreateScreen";
import Footer from "./components/Footer";
import PurchaseOrderProductDetails from "./components/Warehouse/PurchaseOrder/PurchaseOrderProductDetails";
import StockTransferListScreen from "./components/Warehouse/Invoice/StockTransferListScreen";
import StockTransferDetailsScreen from "./components/Warehouse/Invoice/StockTransferDetailsScreen";
import VehicleCreateScreen from "./components/Vehicle/VehicleCreateScreen";
import VehicleListScreen from "./components/Vehicle/VehicleListScreen";
import VehicleDetailsScreen from "./components/Vehicle/VehicleDetailsScreen";
import AdminUsersEditPage from "./components/Users/AdminUsersEditPage";
import WarehouseInfoListScreen from "./components/Warehouse/Info/WarehouseInfoListScreen";
import WarehouseCreate from "./components/Warehouse/Info/WarehouseCreate";
import StockTransfer from "./components/StockTransfer/StockTransfer";
import VehicleSalesInvoiceListScreen from "./components/Vehicle/Sales/Invoice/VehicleSalesInvoiceListScreen";
import VehicleSalesInvoiceCreateScreen from "./components/Vehicle/Sales/Invoice/VehicleSalesInvoiceCreateScreen";
import VehicleSalesInvoiceDetailsScreen from "./components/Vehicle/Sales/Invoice/VehicleSalesInvoiceDetailsScreen";
import WalkinSalesInvoicesListScreen from "./components/Walkin/Sales/Invoice/WalkinSalesInvoicesListScreen";
import WalkinSalesInvoiceCreateScreen from "./components/Walkin/Sales/Invoice/WalkinSalesInvoiceCreateScreen";
import WalkinSalesInvoiceDetailsScreen from "./components/Walkin/Sales/Invoice/WalkinSalesInvoiceDetailsScreen";
import CartScreen from "./components/CartScreen";
import PlaceOrderScreen from "./components/PlaceOrderScreen";
import PaymentDetailsScreen from "./components/Payment/PaymentDetailsScreen";
import ResetPassword from "./components/Users/ResetPassword";
import StoreEdit from "./components/Store/StoreEdit";
import VehicleStatusListScreen from "./components/Vehicle/VehicleStatusListScreen";
import VehicleSalesStockToVehicleCreateScreen from "./components/Warehouse/Invoice/VehicleSalesStockToVehicleCreateScreen";
import TransitStockListScreen from "./components/Vehicle/TransitStockListScreen";
import NewStockNotificationDetailsScreen from "./components/Vehicle/NewStockNotificationDetailsScreen";
import NewStockNotificationsListScreen from "./components/Vehicle/NewStockNotificationsListScreen";
import TransitStockEntryDetailsScreen from "./components/Vehicle/TransitStockEntryDetailsScreen";
import OrderTransferScreen from "./components/Orders/OrderTransferScreen";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentsReceiptsListScreen from "./components/Payment/PaymentsReceiptsListScreen";
import CustomerSignup from "./components/Customer/CustomerSignup";
import CustomerEditPage from "./components/Customer/CustomerEditPage";
import CustomerListPage from "./components/Customer/CustomerListPage";
import VersionUpdatesListScreen from "./components/VersionUpdates/VersionUpdatesListPage";
import VersionUpdatesCreateScreen from "./components/VersionUpdates/VersionUpdatesCreateScreen";
import SupplierListPage from "./components/Supplier/SupplierListPage";
import SupplierSignup from "./components/Supplier/SupplierSignup";
import PreProductionPOListScreen from "./components/CTC/ProductionUnit/PreProductionPOListScreen";
import CtcTrackingScreen from "./components/CTC/CtcTrackingScreen";
import VehicleSalesPayment from "./components/Vehicle/Sales/Invoice/VehicleSalesPayment";
import PayNowScreen from "./components/Vehicle/Sales/Invoice/PayNowScreen";
import PurchaseOrderPaymentScreen from "./components/Warehouse/PurchaseOrder/PurchaseOrderPaymentScreen";
import PaymentsReceiptsPayInScreen from "./components/Payment/PaymentsReceiptsPayInScreen";
import OnlineOrdersListScreen from "./components/Orders/OnlineOrdersListScreen";
import OnlineOrderReceivePaymentScreen from "./components/Orders/OnlineOrderReceivePaymentScreen";
import WalkinSalesInvoiceReceivePaymentScreen from "./components/Walkin/Sales/Invoice/WalkinSalesInvoiceReceivePaymentScreen";
import ColdStorageListPage from "./components/CTC/ColdStorage/ColdStorageListPage";
import ColdStorageRegister from "./components/CTC/ColdStorage/ColdStorageRegister";
import FactoryListPage from "./components/CTC/Factory/FactoryListPage";
import FactoryRegister from "./components/CTC/Factory/FactoryRegister";
import VarietyRegister from "./components/CTC/Variety/VarietyRegister";
import VarietyListPage from "./components/CTC/Variety/VarietyListPage";
import BondsListPage from "./components/CTC/Bonds/BondsListPage";
import BondCreate from "./components/CTC/Bonds/BondCreate";
import ReceiptsListPage from "./components/CTC/Receipts/ReceiptsListPage";
import ReceiptCreate from "./components/CTC/Receipts/ReceiptCreate";
import CtcPurchaseOrderCreate from "./components/CTC/PurchaseOrder/CtcPurchaseOrderCreate";
import CtcPurchaseOrderListPage from "./components/CTC/PurchaseOrder/CtcPurchaseOrderListPage";
import CtcProductionCreate from "./components/CTC/Production/CtcProductionCreate";
import CtcProductionListPage from "./components/CTC/Production/CtcProductionListPage";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CustomHeader />
        <main className="py-0" style={{
          marginTop: "5rem", minHeight: "100vh",
          overflow: "hidden", display: "block", position: "relative", paddingBottom: "100px"
        }}>
          <Container style={{minWidth:'100%'}}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/signin" element={<Signin />} />
              <Route path="/admin/customer/signup" element={<CustomerSignup />} />
              <Route path="/admin/customers" element={<CustomerListPage />} />
              <Route path="/admin/suppliers" element={<SupplierListPage />} />
              <Route path="/admin/supplier/signup" element={<SupplierSignup />} />
              <Route path="/admin/edit-customer/:id" element={<CustomerEditPage />} />
              <Route path="/admin/users/create" element={<Signup />} />
              <Route path="/admin/users/reset/password" element={<ResetPassword />} />
              <Route path="/admin/users" element={<AdminUsersListPage />} />
              <Route path="/admin/edit-user/:userId" element={<AdminUsersEditPage />} />
              {/* <Route path="/admin/edit-user/:userId" element={<AdminUsersEditPage />} /> */}
              <Route
                path="/admin/categories"
                element={<CategoryListScreen />}
              />
              <Route
                path="/admin/subcategories"
                element={<SubCategoryListScreen />}
              />
              <Route path="/admin/category/new" element={<CategoryCreate />} />
              <Route
                path="/admin/subcategory/new"
                element={<SubCategoryCreate />}
              />
              <Route path="/admin/products" element={<ProductListScreen />} />
              <Route path="/admin/product/new" element={<ProductCreate />} />
              <Route path="/admin/stock" element={<StockListScreen />} />
              <Route path="/admin/store" element={<StoreListScreen />} />
              <Route path="/admin/store/new" element={<StoreCreate />} />
              <Route path="/admin/store/edit/:id" element={<StoreEdit />} />
              <Route path="/admin/warehouse/new" element={<WarehouseCreate />} />
              <Route path="/admin/stock/new" element={<StockCreate />} />
              <Route
                path="/admin/product/bulk/:id"
                element={<BulkListScreen />}
              />
              <Route
                path="/admin/bulk/new/:productId"
                element={<BulkItemCreate />}
              />
              <Route
                path="/admin/product/domestic/:id"
                element={<DomesticListScreen />}
              />
              <Route
                path="/admin/domestic/new/:productId"
                element={<DomesticItemCreate />}
              />
              {/* <Route path="/admin/orders" element={<OrderListScreen />} /> */}
              <Route
                path="/admin/store-orders"
                element={<StoreOrderListScreen />}
              />
              <Route
                path="/admin/order-details/:id"
                element={<OrderDetailsScreen />}
              />
              <Route
                path="/admin/order/pay-details/:id"
                element={<PaymentDetailsScreen />}
              />
              <Route path="/admin/order/track/:id" element={<OrderTracker />} />
              <Route path="/admin/order-transfer" element={<OrderTransferScreen />} />
              <Route path="/admin/analytics" element={<ReportsScreen />} />
              <Route path="/admin/analytics/walkin/sales" element={<WalkinSalesReport />} />
              <Route path="/admin/analytics/walkin/sales/vyapar" element={<WalkinVyaparSaleReport />} />
              <Route path="/admin/analytics/walkin/item-sales/vyapar" element={<ItemSalesVyaparReport />} />
              <Route path="/admin/purchase-orders" element={<PurchaseOrdersListScreen />} />
              <Route path="/admin/purchase-orders/new" element={<PurchaseOrderCreateScreen />} />
              <Route path="/admin/purchase-order/:id/:warehouseOrStore" element={<PurchaseOrderProductDetails />} />
              <Route path="/admin/wh-info/list" element={<WarehouseInfoListScreen />} />
              <Route path="/admin/wh-info/new" element={<WarehouseCreate />} />
              <Route path="/admin/stock-transfer/list" element={<StockTransferListScreen />} />
              <Route path="/admin/stock-transfer/new" element={<StockTransfer />} />
              <Route path="/admin/stock-transfer/:id" element={<StockTransferDetailsScreen />} />
              <Route path="/admin/vehicle/list" element={<VehicleListScreen />} />
              <Route path="/admin/vehicle/new" element={<VehicleCreateScreen />} />
              <Route path="/admin/vehicle/:id" element={<VehicleDetailsScreen />} />
              <Route path="/admin/wh-store-veh-stock/list" element={<StockListScreen />} />
              <Route path="/admin/vehicle-sales-invoice/list" element={<VehicleSalesInvoiceListScreen />} />
              <Route path="/admin/vehicle-sales-invoice/payment/:id" element={<PayNowScreen />} />
              <Route path="/admin/vehicle-sales-invoice/new" element={<VehicleSalesInvoiceCreateScreen />} />
              <Route path="/admin/vehicle-sales-invoice/:id" element={<VehicleSalesInvoiceDetailsScreen />} />
              <Route path="/admin/online-orders" element={<OnlineOrdersListScreen />} />
              <Route path="/admin/online-order/payment/:id" element={<OnlineOrderReceivePaymentScreen />} />
              <Route path="/admin/walkin-sales-invoice/list" element={<WalkinSalesInvoicesListScreen />} />
              <Route path="/admin/purchase-orders/payment/:id" element={<PurchaseOrderPaymentScreen />} />
              <Route path="/admin/walkin-sales-invoice/new" element={<WalkinSalesInvoiceCreateScreen />} />
              <Route path="/admin/walkin-sales-invoice/:id" element={<WalkinSalesInvoiceDetailsScreen />} />
              <Route path="/admin/walkin-sales-invoice/payment/:id" element={<WalkinSalesInvoiceReceivePaymentScreen />} />
              <Route path="/admin/vehicle-sales-stock/new" element={<VehicleSalesStockToVehicleCreateScreen />} />
              <Route path="/shoppingcart" element={<CartScreen />} />
              <Route
                path="/cart/:productId/:quantitySelected/:uom/:orderTypeSelected/:calculatedUnitPrice"
                element={<CartScreen />}
              />  
              <Route path="/admin/transit-stock/list" element={<TransitStockListScreen />} />
              <Route path="/admin/transit-stock/details/:veh/:src/:dest" element={<TransitStockEntryDetailsScreen />} />
              <Route path="/placeorder" element={<PlaceOrderScreen />} />
              <Route path="/admin/vehicle-status/list" element={<VehicleStatusListScreen />} />
              <Route path="/admin/new-stock-notifications-details/:id" element={<NewStockNotificationDetailsScreen />} />
              <Route path="/admin/new-stock-notifications-list" element={<NewStockNotificationsListScreen />} />
              <Route path="/admin/payments-receipts" element={<PaymentsReceiptsListScreen />} />
              <Route path="/admin/payments-receipts/payin/:id" element={<PaymentsReceiptsPayInScreen />} />
              <Route path="/admin/version-updates" element={<VersionUpdatesListScreen />} />
              <Route path="/admin/version-updates/create" element={<VersionUpdatesCreateScreen />} />
              <Route path="/ctc/pre-production-po" element={<PreProductionPOListScreen />} />
               <Route path="/ctc/tracking" element={<CtcTrackingScreen />} />
               <Route path="/ctc/cold-storage" element={<ColdStorageListPage />} />
               <Route path="/ctc/cold-storage/new" element={<ColdStorageRegister />} />
               <Route path="/ctc/factory" element={<FactoryListPage />} />
               <Route path="/ctc/factory/new" element={<FactoryRegister />} />
               <Route path="/ctc/variety" element={<VarietyListPage />} />
               <Route path="/ctc/variety/new" element={<VarietyRegister />} />
               <Route path="/ctc/bonds" element={<BondsListPage />} />
               <Route path="/ctc/bonds/new" element={<BondCreate />} />
               <Route path="/ctc/receipts" element={<ReceiptsListPage />} />
               <Route path="/ctc/receipts/new" element={<ReceiptCreate />} />
               <Route path="/ctc/purchase-orders" element={<CtcPurchaseOrderListPage />} />
               <Route path="/ctc/purchase-order/new" element={<CtcPurchaseOrderCreate />} />
               <Route path="/ctc/production" element={<CtcProductionListPage />} />
               <Route path="/ctc/production/new" element={<CtcProductionCreate />} />
            </Routes>
          </Container>
        </main>
        <Footer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
