import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardBody from "../Card/CardBody.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  listAllVehicles,
  vehicleDetailsById,
  createVehicle,
  updateVehicleById,
  deleteVehicle
} from "../../actions/vehicleAction.js";
import { Typography, Grid, Button, TextField, Tooltip, Divider } from "@mui/material";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import Message from "../Message.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useNavigate } from "react-router-dom";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { number } from "prop-types";

const styles = {
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      fontWeight: "700",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardVehicleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  trStyle: {
    borderBottom: "3px",
    borderColor: "gray",
  },
};

const VehicleListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const gridRef = useRef();

  const calculateDateDifference = (expiryDate) => {
    let diffDays ; 
    if(expiryDate){
      const curDate = new Date(new Date().toLocaleDateString());
      const expDate = new Date(new Date(expiryDate).toLocaleDateString());
      console.log("curDate : ", curDate, "expDate : ", expDate)
      diffDays = parseInt((expDate - curDate) / (1000 * 60 * 60 * 24), 10);
      console.log("diffDays : ", diffDays)
    }else{
      console.log('expiryDate is NOT VALID...! ' )
      diffDays = -100
    }
    return diffDays
  }

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      filter: false,
      floatingFilter: false,
      width: 75,
      minWidth: 75,
      maxWidth: 75
    },
    {
      field: "VehicleNo",
      headerName: "Vehicle No",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125
    },
    {
      field: "DriverName",
      headerName: "Driver Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "DriverContact",
      headerName: "Driver Contact",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200
    }, {
      field: "PollutionCheck",
      headerName: "Pollution Check",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      cellStyle: params => {
        console.log("PollutionCheck", params.data.PollutionCheck)
        const dueDate = calculateDateDifference(params.data.PollutionCheck)
        if (typeof dueDate == "number") {
          console.log(" dueDate is Number .")
          if (dueDate > 30) {
            return { color: 'white', backgroundColor: 'green', textAlign: "center" };
          }
          else if (dueDate >= 15 && dueDate <=30) {
            return { color: 'white', backgroundColor: 'yellow', textAlign: "center" };
          }
          else if (dueDate < 15) {
            return { color: 'white', backgroundColor: 'red', textAlign: "center" };
          }
          else{
              return { color: 'white', backgroundColor: 'red', textAlign: "center" };
          }
        } else {
          return { color: 'white', backgroundColor: 'gray', textAlign: "center" };
        }
        // return null;
      },
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "FitnessExpiry",
      headerName: "Fitness Expiry",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      cellStyle: params => {
        console.log("FitnessExpiry", params.data.PollutionCheck)
        const dueDate = calculateDateDifference(params.data.FitnessExpiry)
        if (typeof dueDate == "number") {
          console.log(" dueDate is Number .")
          if (dueDate > 30) {
            return { color: 'white', backgroundColor: 'green', textAlign: "center" };
          }
          else if (dueDate > 15 && dueDate < 30) {
            return { color: 'white', backgroundColor: 'yellow', textAlign: "center" };
          }
          else if (dueDate < 15) {
            return { color: 'white', backgroundColor: 'red', textAlign: "center" };
          }
          return null;
        } else {
          console.log(" dueDate is Other .")
        }
        return null;
      },
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "InsuranceExpiry",
      headerName: "Insurance Expiry",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      cellStyle: params => {
        console.log("FitnessExpiry", params.data.InsuranceExpiry)
        const dueDate = calculateDateDifference(params.data.InsuranceExpiry)
        if (typeof dueDate == "number") {
          console.log(" dueDate is Number .")
          if (dueDate > 30) {
            return { color: 'white', backgroundColor: 'green', textAlign: "center" };
          }
          else if (dueDate > 15 && dueDate < 30) {
            return { color: 'white', backgroundColor: 'yellow', textAlign: "center" };
          }
          else if (dueDate < 15) {
            return { color: 'white', backgroundColor: 'red', textAlign: "center" };
          }
          return null;
        } else {
          console.log(" dueDate is Other .")
        }
        return null;
      },
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "RoadTaxExpiry",
      headerName: "Road Tax Expiry",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      cellStyle: params => {
        console.log("FitnessExpiry", params.data.RoadTaxExpiry)
        const dueDate = calculateDateDifference(params.data.RoadTaxExpiry)
        if (typeof dueDate == "number") {
          console.log(" dueDate is Number .")
          if (dueDate > 30) {
            return { color: 'white', backgroundColor: 'green', textAlign: "center" };
          }
          else if (dueDate > 15 && dueDate < 30) {
            return { color: 'white', backgroundColor: 'yellow', textAlign: "center" };
          }
          else if (dueDate < 15) {
            return { color: 'white', backgroundColor: 'red', textAlign: "center" };
          }
          return null;
        } else {
          console.log(" dueDate is Other .")
        }
        return null;
      },
      minWidth: 200,
      maxWidth: 200
    },

    {
      field: "VehicleServiceDate",
      headerName: "Vehicle Service Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "VehicleKmDriven",
      headerName: "Vehicle Km Driven",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "Edit",
      headerName: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit Vehicle" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Delete Warehouse" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("Deleting ", params.value)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const export_label = "Export > CSV";
  const [open, setOpen] = useState(() => false);
  const [filteredVehicle, setFilteredVehicle] = useState(() => {
    return "";
  });

  const dispatch = useDispatch();
  const vehicleList = useSelector((state) => state.vehicleList);
  const { loading, error, vehicles } = vehicleList;

  useEffect(() => {
    console.log("useEffect Getting Called VehicleListScreen For First Time");
    dispatch(listAllVehicles());
  }, [dispatch, navigate]);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const createVehicleHandler = (Vehicle) => {
    navigate("/admin/vehicle/new");
  };

  const vehicleNoChangeHandler = (nm) => {
    setFilteredVehicle({ ...filteredVehicle, vehicleNo: nm });
    console.log(filteredVehicle);
  };

  const driverNameChangeHandler = (nm) => {
    setFilteredVehicle({ ...filteredVehicle, driverName: nm });
    console.log(filteredVehicle);
  };

  const phoneChangeHandler = (ph) => {
    setFilteredVehicle({ ...filteredVehicle, driverContact: ph });
    console.log(filteredVehicle);
  };
  const insuranceExpiryChangeHandler = (ph) => {
    setFilteredVehicle({ ...filteredVehicle, insuranceExpiry: ph });
    console.log(filteredVehicle);
  };
  const pollutionCheckChangeHandler = (ph) => {
    setFilteredVehicle({ ...filteredVehicle, pollutionCheck: ph });
    console.log(filteredVehicle);
  };
  const fitnessExpiryChangeHandler = (fe) => {
    setFilteredVehicle({ ...filteredVehicle, fitnessExpiry: fe });
    console.log(filteredVehicle);
  };
  const roadTaxExpiryChangeHandler = (rt) => {
    setFilteredVehicle({ ...filteredVehicle, roadTaxExpiry: rt });
    console.log(filteredVehicle);
  };
  const vehicleServiceDateChangeHandler = (ph) => {
    setFilteredVehicle({ ...filteredVehicle, vehicleServiceDate: ph });
    console.log(filteredVehicle);
  };
  const vehicleKmDrivenChangeHandler = (ph) => {
    setFilteredVehicle({ ...filteredVehicle, vehicleKmDriven: ph });
    console.log(filteredVehicle);
  };

  const handleEdit = (params) => {
    setOpen(true);
    console.log("ID SELECTED : ", params);
    setFilteredVehicle({
      _id: params.data.Id,
      vehicleNo: params.data.VehicleNo,
      driverName: params.data.DriverName,
      driverContact: params.data.DriverContact,
      pollutionCheck: params.data.PollutionCheck,
      insuranceExpiry: params.data.InsuranceExpiry,
      fitnessExpiry: params.data.FitnessExpiry,
      roadTaxExpiry: params.data.RoadTaxExpiry,
      vehicleServiceDate: params.data.VehicleServiceDate,
      vehicleKmDriven: params.data.VehicleKmDriven,
    });
  };

  const handleDelete = (Vehicle) => {
    console.log("handleDelete Exec..." + Vehicle._id);
    console.log("ID SELECTED : " + Vehicle._id);
    dispatch(deleteVehicle(Vehicle._id));
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    console.log(filteredVehicle);
    dispatch(updateVehicleById(filteredVehicle));
    setOpen(false);
    setFilteredVehicle({});
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  if (vehicles && vehicles.length > 0) {
    rowData = vehicles.map((eachVehicle, index) => {
      console.log("eachVehicle  : ", eachVehicle);
      return {
        "#": index + 1,
        VehicleNo: eachVehicle.vehicleNo,
        DriverName: eachVehicle.driverName,
        "DriverContact": eachVehicle.driverContact,
        InsuranceExpiry: eachVehicle.insuranceExpiry,
        PollutionCheck: eachVehicle.pollutionExpiry,
        FitnessExpiry: eachVehicle.fitnessExpiry,
        RoadTaxExpiry: eachVehicle.roadTaxExpiry,
        VehicleServiceDate: eachVehicle.vehicleServiceDate,
        VehicleKmDriven: eachVehicle.vehicleKmDriven,
        "Id": eachVehicle._id,
        "Delete": eachVehicle._id,
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    );
  }
  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
          <Tooltip title="Create Vehicle" arrow>
            <AddCircleIcon
              fontSize="medium"
              style={{ float: "left" }}
              onClick={createVehicleHandler}
            />
          </Tooltip>

        </GridItem>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{ fontWeight: 500 }}>
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em" }}
                    />
                  </Tooltip>
                  Vehicles List
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "0.5rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : "EMPTY AG GRID"}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >

                    </Typography>
                    <h2 align="center"> Edit Vehicle</h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={submitHandler}>
                      <Grid container spacing={2} justifyContent="center" margin="auto">
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Vehicle No"
                            label="Vehicle No"
                            variant="outlined"
                            name="VehicleNo"
                            onChange={(e) =>
                              vehicleNoChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.vehicleNo
                                ? filteredVehicle.vehicleNo
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Driver Name"
                            label="Driver Name"
                            variant="outlined"
                            name="driverName"
                            id="driverName"
                            onChange={(e) => driverNameChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.driverName
                                ? filteredVehicle.driverName
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Phone No"
                            label="Phone No"
                            variant="outlined"
                            name="phoneNo"
                            id="phoneNo"
                            onChange={(e) => phoneChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.driverContact
                                ? filteredVehicle.driverContact
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Insurance Expiry"
                            label="Insurance Expiry"
                            variant="outlined"
                            name="insuranceExpiry"
                            id="insuranceExpiry"
                            onChange={(e) => insuranceExpiryChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.insuranceExpiry
                                ? filteredVehicle.insuranceExpiry
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Pollution Check"
                            label="Pollution Check"
                            variant="outlined"
                            name="pollutionCheck"
                            id="pollutionCheck"
                            onChange={(e) => pollutionCheckChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.pollutionCheck
                                ? filteredVehicle.pollutionCheck
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Fitness Expiry"
                            label="Fitness Expiry"
                            variant="outlined"
                            name="fitnessExpiry"
                            id="fitnessExpiry"
                            onChange={(e) => fitnessExpiryChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.fitnessExpiry
                                ? filteredVehicle.fitnessExpiry
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Road Tax Expiry"
                            label="Road Tax Expiry"
                            variant="outlined"
                            name="roadTaxExpiry"
                            id="roadTaxExpiry"
                            onChange={(e) => roadTaxExpiryChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.roadTaxExpiry
                                ? filteredVehicle.roadTaxExpiry
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Vehicle Service Date"
                            label="Vehicle Service Date"
                            variant="outlined"
                            name="vehicle Service Date"
                            id="vehicleServiceDate"
                            onChange={(e) => vehicleServiceDateChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.vehicleServiceDate
                                ? filteredVehicle.vehicleServiceDate
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Vehicle Km Driven"
                            label="Vehicle Km Driven"
                            variant="outlined"
                            name="vehicleKmDriven"
                            id="vehicleKmDriven"
                            onChange={(e) => vehicleKmDrivenChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredVehicle && filteredVehicle.vehicleKmDriven
                                ? filteredVehicle.vehicleKmDriven
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                          >
                            update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default VehicleListScreen;
