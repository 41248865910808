import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Button, TextField, Divider} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { createBulkByProductId, } from "../../actions/bulkAction";
import { listProductDetailsByProductId } from "../../actions/productAction";
import { useNavigate } from "react-router-dom";
import { BULK_CREATE_BY_PRODUCT_ID_RESET } from "../../constants/bulkConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  unitOfMessure: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  inKgs: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 5,
    },
  },
  unitPrice: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 9,
    },
  },
  discountStartsFrom: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 9,
    },
  },
  discountPercent: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 5,
    },
  },
  cgst: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 5,
    },
  },  
  sgst: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 5,
    },
  },
  igst: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 5,
    },
  },
  tcs: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 5,
    },
  },
};

const BulkItemForm = (props) => {
  const classes = useStyles();
  const productId = props.productId;
  console.log("ProductId  from BulkItemForm Create :==>  " + productId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const productDetailsByProductId = useSelector(
    (state) => state.productDetailsByProductId
  );
  const bulkCreateByProductId = useSelector(
    (state) => state.bulkCreateByProductId
  );

  const { loading_create, success_create, bulk } = bulkCreateByProductId;
  console.log("productDetailsByProductId : ", productDetailsByProductId);

  useEffect(() => {
    if (success_create) {
      dispatch({ type: BULK_CREATE_BY_PRODUCT_ID_RESET });
      navigate(`/admin/product/bulk/${productId}`);
    } else dispatch(listProductDetailsByProductId(productId));
  }, [dispatch, productId, success_create, navigate]);

  const { loading, error, product } = productDetailsByProductId;

  if (product) {
    console.log(
      "Success Response from Product Details ID #  " +
      product._id +
      " , Name : " +
      product.name,
      product
    );
  }

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(" ######## formState.values ######## Getting Submitted to Action :==> ", formState.values);
      dispatch(
        createBulkByProductId(
          product._id,
          formState.values.unitOfMessure,
          formState.values.inKgs,
          formState.values.unitPrice,
          formState.values.mrp,
          formState.values.discountStartsFrom,
          formState.values.discountPercent,
          formState.values.cgst,
          formState.values.sgst,
          formState.values.igst,
          formState.values.tcs
        )
      );
    }
    // createBulkByProductId
    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form
        name="password-reset-form"
        method="post"
        onSubmit={handleSubmit}
      >
        <GridContainer spacing={3} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card >
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Bulk Item Entry : {product.name}
                <Divider></Divider>
              </Typography>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Unit Of Messure*"
                      variant="outlined"
                      size="small"
                      name="unitOfMessure"
                      helperText={
                        hasError("unitOfMessure")
                          ? formState.errors.unitOfMessure[0]
                          : null
                      }
                      error={hasError("unitOfMessure")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.unitOfMessure || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="In Kgs *"
                      variant="outlined"
                      size="small"
                      name="inKgs"
                      helperText={
                        hasError("inKgs")
                          ? formState.errors.inKgs[0]
                          : null
                      }
                      error={hasError("inKgs")}
                      onChange={handleChange}
                      type="inKgs"
                      value={formState.values.inKgs || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="UnitPrice *"
                      variant="outlined"
                      size="small"
                      name="unitPrice"
                      helperText={
                        hasError("unitPrice")
                          ? formState.errors.unitPrice[0]
                          : null
                      }
                      error={hasError("unitPrice")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.unitPrice || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="MRP *"
                      variant="outlined"
                      size="small"
                      name="mrp"
                      helperText={
                        hasError("mrp")
                          ? formState.errors.mrp[0]
                          : null
                      }
                      error={hasError("mrp")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.mrp || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Discount Starts From *"
                      variant="outlined"
                      size="small"
                      name="discountStartsFrom"
                      helperText={
                        hasError("discountStartsFrom")
                          ? formState.errors.discountStartsFrom[0]
                          : null
                      }
                      error={hasError("discountStartsFrom")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.discountStartsFrom || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="Discount percent *"
                      variant="outlined"
                      size="small"
                      name="discountPercent"
                      helperText={
                        hasError("discountPercent")
                          ? formState.errors.discountPercent[0]
                          : null
                      }
                      error={hasError("discountPercent")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.discountPercent || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="CGST"
                      variant="outlined"
                      size="small"
                      name="cgst"
                      helperText={
                        hasError("cgst")
                          ? formState.errors.cgst[0]
                          : null
                      }
                      error={hasError("cgst")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.cgst || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="SGST"
                      variant="outlined"
                      size="small"
                      name="sgst"
                      helperText={
                        hasError("sgst")
                          ? formState.errors.sgst[0]
                          : null
                      }
                      error={hasError("sgst")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.sgst || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="IGST"
                      variant="outlined"
                      size="small"
                      name="igst"
                      helperText={
                        hasError("igst")
                          ? formState.errors.igst[0]
                          : null
                      }
                      error={hasError("igst")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.igst || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      label="TCS"
                      variant="outlined"
                      size="small"
                      name="tcs"
                      helperText={
                        hasError("tcs")
                          ? formState.errors.tcs[0]
                          : null
                      }
                      error={hasError("tcs")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.tcs || ""}
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                    >
                      Create
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
          </GridContainer>
      </form>
    </div>
  );
};

export default BulkItemForm;






