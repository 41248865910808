import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import { Grid, Tooltip,Typography } from "@mui/material";
import VehicleForm from "./VehicleForm";
import Section from "../organisms/Section/Section";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";


const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 'auto',
    margin: `0 auto`,
  },
  section: {
    height: "50vh",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const VehicleCreateScreen = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  console.log("Scren VehicleCreateScreen  LOading");
  return (
    <div>
      <Grid container spacing={2}>
        <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                <Tooltip title="Back" arrow>
            <ArrowBackIcon
              fontSize="medium"
              onClick={() => window.history.back()}
              style={{ float: "left" , marginRight:"3em"}}
            />
          </Tooltip>
                  <Typography variant="h5" style={{ fontWeight: 500 }}>
                 New Vehicle
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <Grid item xs={12}>
          <Section className={classes.section}>
            <div className={classes.formContainer}>
              <VehicleForm />
            </div>
          </Section>
        </Grid>
      </Grid>
    </div>
  );
};

export default VehicleCreateScreen;
