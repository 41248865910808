import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import GridItem from "./Grid/GridItem";
import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Tooltip,
  CardActions,
  Button,
} from "@mui/material";
import dashboardRoutes from "./dashboardRoutes";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import VersionUpdatesListPage from "./VersionUpdates/VersionUpdatesListPage";
import { updateVersionUpdatesById } from "../actions/versionUpdatesAction";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 140,
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6
      }px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
}));

const HomeScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let renderDashboard;
  console.log(dashboardRoutes);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { role } = userInfo;

  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('md'));
  const readVersionUpdates = localStorage.getItem("readVersionUpdates")
    ? JSON.parse(localStorage.getItem("readVersionUpdates"))
    : [];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // dispatch(updateVersionUpdatesById())
    localStorage.setItem("readVersionUpdates", JSON.stringify(true));
  };

  const dialogContent = (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      style={{ width: '100%', height: '90%', textAlign: "center", justifyContent: "center", alignItems: "center", marginTop: "5rem" }}
    >
      <DialogTitle id="responsive-dialog-title">
        {"New Software Updates"}
      </DialogTitle>
      <DialogContent>
        <VersionUpdatesListPage>
        </VersionUpdatesListPage>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} align="center" style={{ justifyContent: "center", textAlign: "center", alignItems: "center" }}>
          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
            justify="center"
            style={{ textTransform: "capitalize", borderRadius: "5px" }}
            onClick={handleClose} autoFocus
          >
            Got it
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
  if (dashboardRoutes && dashboardRoutes.length > 0) {
    renderDashboard = (
      <Box sx={{ flexGrow: 1 }} >
        <Grid
          container
          className={classes.root}
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 2, sm: 4, md: 8 }}
          style={{ marginTop: "1rem" }}
          justifyContent="center"
        >
          {console.log('readVersionUpdates : ', readVersionUpdates)}
          {/* UN COMMENT WHILE PUSHING TO*/}
          {/* {!readVersionUpdates && dialogContent} */}
          {dashboardRoutes.map((item, index) => (
            // <GridItem key={index}>
            <>
              {/* ADMIN ROLE SCREENS */}
              {role &&
                role === "ADMIN_ROLE" &&
                (item.name === "Users" ||
                  item.name === "Customers" ||
                  item.name === "Suppliers" ||
                  item.name === "Categories" ||
                  item.name === "Sub-Categories" ||
                  item.name === "Products" ||
                  // item.name === "Orders" ||
                  item.name === "Store Orders" ||
                  item.name === "Stores" ||
                  item.name === "Purchase Orders" ||
                  item.name === "Warehouse Info" ||
                  item.name === "Vehicles" ||
                  item.name === "Vehicle Status" ||
                  item.name === "Vehicle Sales Stock" ||
                  item.name === "Dashboard" ||
                  item.name === "Stock Transfers" ||
                  item.name === "Vehicle Sales" ||
                  item.name === "Walkin Sales" ||
                  item.name === "Track-Stocks" ||
                  // item.name === "Transit Stock List" ||
                  // item.name === "Stock Arrival" ||
                  // item.name === "New Stock Notification Details" ||
                  item.name === "Vehicle Sales Reports" ||
                  item.name === "Walkin Sales Reports" ||
                  item.name === "Walkin Sales Vyapar" ||
                  item.name === "Item wise Sale Vyapar"
                  // item.name === "CTC Tracking"
                  
                ) && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

              {/* CEO ROLE SCREENS */}
              {role &&
                role === "CEO_ROLE" &&
                (
                  item.name === "Store Orders" ||
                  item.name === "Purchase Orders" ||
                  item.name === "Vehicle Status" ||
                  item.name === "Vehicle Sales Stock" ||
                  item.name === "Dashboard" ||
                  item.name === "Stock Transfers" ||
                  item.name === "Vehicle Sales" ||
                  item.name === "Walkin Sales" ||
                  // item.name === "Track-Stocks" ||
                  item.name === "Walkin Sales Reports" ||
                  item.name === "Vehicle Sales Reports" ||
                  item.name === "Walkin Sales Vyapar" ||
                  item.name === "Payments / Receipts" ||
                  item.name === "Online Orders" ||
                  item.name === "Item wise Sale Vyapar") && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="m"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

              {/* FINANCE_MANAGER_ROLE SCREENS */}
              {role &&
                role === "FINANCE_MANAGER_ROLE" &&
                (item.name === "Customers" ||
                  item.name === "Suppliers" ||
                  item.name === "Dashboard" ||
                  item.name === "Payments / Receipts" ||
                  item.name === "Online Orders") && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

              {/* WAREHOUSE_INCHARGE_ROLE SCREENS */}
              {role &&
                role === "WAREHOUSE_INCHARGE_ROLE" &&
                (
                  // item.name === "Orders" ||
                  item.name === "Products" ||
                  item.name === "Purchase Orders" ||
                  item.name === "Dashboard" ||
                  item.name === "Vehicle Status" ||
                  item.name === "Stock Transfers" ||
                  item.name === "Track-Stocks" ||
                  // item.name === "Transit Stock List" ||
                  item.name === "Stock Arrival" ||
                  // item.name === "New Stock Notification Details" ||
                  item.name === "Walkin Sales" ||
                  item.name === "Version Updates") && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

              {/* STORE_INCHARGE_ROLE SCREENS */}
              {role &&
                role === "STORE_INCHARGE_ROLE" &&
                (
                  item.name === "Customers" ||
                  item.name === "Suppliers" ||
                  item.name === "Products" ||
                  // item.name === "Orders" ||
                  item.name === "Store Orders" ||
                  item.name === "Purchase Orders" ||
                  item.name === "Vehicle Status" ||
                  item.name === "Vehicle Sales Stock" ||
                  item.name === "Dashboard" ||
                  item.name === "Stock Transfers" ||
                  item.name === "Track-Stocks" ||
                  // item.name === "Transit Stock List" ||
                  // item.name === "Stock Arrival" ||
                  // item.name === "New Stock Notification Details" ||
                  item.name === "Walkin Sales" ||
                  item.name === "Walkin Sales Reports" ||
                  item.name === "Payments / Receipts"||
                  item.name === "Online Orders") && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

              {/* VEHICLE_SALES_INCHARGE_ROLE SCREENS */}
              {role &&
                role === "VEHICLE_SALES_INCHARGE_ROLE" &&
                (
                  item.name === "Customers" ||
                  // item.name === "Orders" ||
                  item.name === "Products" ||
                  // item.name === "Stock Transfers" ||  THIS SHOULD HAPPEN FROM STORE_INCHARGE
                  item.name === "Vehicles" ||
                  item.name === "Vehicle Status" ||
                  // item.name === "Vehicle Sales Stock" || THIS SHOULD HAPPEN FROM STORE_INCHARGE
                  item.name === "Dashboard" ||
                  item.name === "Track Stocks" ||
                  item.name === "Vehicle Sales" ||
                  item.name === "Vehicle Sales Reports") && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}
              {/* VEHICLES_INCHARGE_ROLE SCREENS */}
              {role &&
                role === "VEHICLES_INCHARGE_ROLE" &&
                (item.name === "Vehicles" ||
                  item.name === "Vehicle Status" ) && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

              {/* DRIVER_ROLE SCREENS */}
              {role &&
                role === "DRIVER_ROLE" &&
                (item.name === "Dashboard" ||
                  item.name === "Vehicle Status" ||
                  item.name === "Vehicle Sales") && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

                {/* PRODUCTION_INCHARGE_ROLE SCREENS */}
                {role &&
                role === "PRODUCTION_INCHARGE_ROLE" &&
                (item.name === "Dashboard" ||
                item.name === "Production Units Info" ||
                item.name === "Pre Production PO" ) && (
                  <GridItem key={index}>
                    <Tooltip title={item.name} arrow>
                      <Card
                        height="175"
                        width="140"
                        style={{ margin: ".5rem" }}
                        onClick={() => {
                          console.log("Routing to Path ", item.path);
                          navigate(item.path);
                        }}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            height="175"
                            width="140"
                            image={item.imageUrl}
                            alt=""
                          />
                        </CardActionArea>
                        <CardActions
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "30px"
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            justify="center"
                            style={{
                              textTransform: "capitalize",
                              width: "10rem",
                              borderRadius: "5px",
                            }}
                          >
                            {item.name}
                          </Button>
                        </CardActions>
                      </Card>
                    </Tooltip>
                  </GridItem>
                )}

            </>
          ))}
        </Grid>
      </Box>
    );
  }
  return <React.Fragment>{renderDashboard}</React.Fragment>;
};
export default HomeScreen;
