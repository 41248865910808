import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    background: theme.palette.common.black,
    color: theme.palette.common.black,
    width: "100%",
    zIndex: 1302,
    position: "relative",
  },
  mainContainer: {
    position: "absolute",
    backgroundColor: "#26A541",
  },
  link: {
    color: "darkgray",
    fontFamily: "Arial",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none",
  },
  gridItem: {
    margin: "3em",
  },
}));

export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer className={classes.footer} style={{ position: "relative", bottom: '0px' }} >
      <Grid container justify="center" style={{ backgroundColor: "white", justifyContent: "center" }}>
        <div>
          <Typography className={classes.subtitle1} variant="subtitle1" style={{ textTransform: "none" }}>
            All rights reserved by novellogiks.com Copyright &copy; 2024
          </Typography>
        </div>
      </Grid>
      <Divider />
      <Grid container justify="center" style={{ backgroundColor: "white", justifyContent: "center" }}>
        <div> 
          <Typography className={classes.subtitle1} variant="subtitle1" style={{ textTransform: "none" }}>
          Latest Version [ PAYMENTS | WALKIN SALE & RECEIPTS MODULES DONE] as on 16/Sept/2024 10:04 AM FE BUG FIXES
          </Typography>
        </div>
      </Grid>
    </footer>
  );
}
