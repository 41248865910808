import {
  VEHICLE_DETAILS_ADD_TO_STORAGE,
  VEHICLE_DETAILS_ADD_TO_STORAGE_REQUEST,
  VEHICLE_DETAILS_ADD_TO_STORAGE_SUCCESS,
  VEHICLE_DETAILS_ADD_TO_STORAGE_FAIL,
  VEHICLE_DETAILS_ADD_TO_STORAGE_RESET,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_REQUEST,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_RESET,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_FAIL,
  INVOICE_DETAILS_ADD_TO_STORAGE,
  INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST,
  INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS,
  INVOICE_DETAILS_ADD_TO_STORAGE_FAIL,
  INVOICE_DETAILS_ADD_TO_STORAGE_RESET,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_REQUEST,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_FAIL,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_RESET,

} from "../constants/storageConstants";

export const vehicleDetailsToStorageReducer = (state = { vehicleDetailsFromStorage: "" }, action) => {
  switch (action.type) {
    case VEHICLE_DETAILS_ADD_TO_STORAGE:
      const vehicleNoDetails = action.payload;
      const {vehicleNo }  = vehicleNoDetails;
      console.log("EXEC VEHICLE_DETAILS_ADD_TO_STORAGE . vehicleNoDetails : " , vehicleNoDetails);
        return {
          ...state,
          vehicleDetailsFromStorage : vehicleNo? vehicleNo : "DUMMY VEHICLE #" ,
        };
    case VEHICLE_DETAILS_ADD_TO_STORAGE_REQUEST:
      return { loading: true };
    case VEHICLE_DETAILS_ADD_TO_STORAGE_SUCCESS:
      return { loading: false, vehicle_details_add_to_storage_success: true };
    case VEHICLE_DETAILS_REMOVE_FROM_STORAGE_RESET:
      return {
        loading: false,
      };
    case VEHICLE_DETAILS_REMOVE_FROM_STORAGE_REQUEST:
      return {
        loading: false,
      };
    case VEHICLE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS:
      return {
        loading: false,
        vehicle_details_remove_to_storage_success: true,
      };
    default:
      return state;
  }
};

export const invoiceDetailsToStorageReducer = (state = { invoiceDetailsStorage: {} }, action) => {
  switch (action.type) {
    case INVOICE_DETAILS_ADD_TO_STORAGE:
      const invoiceDetails = action.payload;
      console.log(" ***** Invoice details from Storage Reducer ", invoiceDetails , " ***** ");
      const {billDate ,description ,driverContact ,driverName ,shopAddress , shopName,shopOwnerContact ,customerName, customerEmail, customerContact, customerGst, fromName, fromWhStoreName   } = invoiceDetails ; 
  
      console.log("EXEC INVOICE_DETAILS_ADD_TO_STORAGE . billDate ,description ,driverContact ,driverName ,gst ,shift ,shopAddress , shopName,shopOwnerContact : " , billDate ,description ,driverContact ,driverName ,shopAddress , shopName,shopOwnerContact ,customerName, customerEmail, customerContact, customerGst, fromName, fromWhStoreName );
        return {
          ...state,
          invoiceDetailsStorage : {billDate ,description ,driverContact ,driverName ,shopAddress , shopName,shopOwnerContact ,customerName, customerEmail, customerContact, customerGst, fromName, fromWhStoreName },
        };
    case INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST:
      return { loading: true };
    case INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS:
      return { loading: false, invoice_details_add_to_storage_success: true };
    case INVOICE_DETAILS_ADD_TO_STORAGE_RESET:
      return {
        loading: false,
      };
    case INVOICE_DETAILS_REMOVE_FROM_STORAGE_REQUEST:
      return {
        loading: false,
      };
    case INVOICE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS:
      return {
        loading: false,
        invoice_details_remove_to_storage_success: true,
      };
    case INVOICE_DETAILS_REMOVE_FROM_STORAGE_RESET:
        return {
          loading: false,
        };
    default:
      return state;
  }
};
