export const VEHICLE_BY_ID_REQUEST = "VEHICLE_BY_ID_REQUEST";
export const VEHICLE_BY_ID_SUCCESS = "VEHICLE_BY_ID_SUCCESS";
export const VEHICLE_BY_ID_FAIL = "VEHICLE_BY_ID_FAIL";
export const VEHICLE_BY_ID_RESET = "VEHICLE_BY_ID_RESET";

export const VEHICLE_BY_NAME_REQUEST = "VEHICLE_BY_NAME_REQUEST";
export const VEHICLE_BY_NAME_SUCCESS = "VEHICLE_BY_NAME_SUCCESS";
export const VEHICLE_BY_NAME_FAIL = "VEHICLE_BY_NAME_FAIL";
export const VEHICLE_BY_NAME_RESET = "VEHICLE_BY_NAME_RESET";

export const VEHICLE_DELETE_REQUEST = "VEHICLE_DELETE_REQUEST";
export const VEHICLE_DELETE_SUCCESS = "VEHICLE_DELETE_SUCCESS";
export const VEHICLE_DELETE_FAIL = "VEHICLE_DELETE_FAIL";
export const VEHICLE_DELETE_RESET = "VEHICLE_DELETE_RESET";

export const VEHICLE_CREATE_REQUEST = "VEHICLE_CREATE_REQUEST";
export const VEHICLE_CREATE_SUCCESS = "VEHICLE_CREATE_SUCCESS";
export const VEHICLE_CREATE_FAIL = "VEHICLE_CREATE_FAIL";
export const VEHICLE_CREATE_RESET = "VEHICLE_CREATE_RESET";

export const VEHICLE_UPDATE_REQUEST = "VEHICLE_UPDATE_REQUEST";
export const VEHICLE_UPDATE_SUCCESS = "VEHICLE_UPDATE_SUCCESS";
export const VEHICLE_UPDATE_FAIL = "VEHICLE_UPDATE_FAIL";
export const VEHICLE_UPDATE_RESET = "VEHICLE_UPDATE_RESET";

export const VEHICLE_DETAILS_REQUEST = "VEHICLE_DETAILS_REQUEST";
export const VEHICLE_DETAILS_SUCCESS = "VEHICLE_DETAILS_SUCCESS";
export const VEHICLE_DETAILS_FAIL = "VEHICLE_DETAILS_FAIL";
export const VEHICLE_DETAILS_RESET = "VEHICLE_DETAILS_RESET";

export const VEHICLE_LIST_REQUEST = "VEHICLE_LIST_REQUEST";
export const VEHICLE_LIST_SUCCESS = "VEHICLE_LIST_SUCCESS";
export const VEHICLE_LIST_FAIL = "VEHICLE_LIST_FAIL";
export const VEHICLE_LIST_RESET = "VEHICLE_LIST_RESET";

export const VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_REQUEST = "VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_REQUEST";
export const VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_SUCCESS = "VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_SUCCESS";
export const VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_FAIL = "VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_FAIL";
export const VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_RESET = "VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_RESET";

export const VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_REQUEST = "VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_REQUEST";
export const VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_SUCCESS = "VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_SUCCESS";
export const VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_FAIL = "VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_FAIL";
export const VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_RESET = "VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_RESET";

export const VEHICLE_AVAILABLE_LIST_FAIL = "VEHICLE_AVAILABLE_LIST_FAIL";
export const VEHICLE_AVAILABLE_LIST_REQUEST = "VEHICLE_AVAILABLE_LIST_REQUEST";
export const VEHICLE_AVAILABLE_LIST_RESET = "VEHICLE_AVAILABLE_LIST_RESET";
export const VEHICLE_AVAILABLE_LIST_SUCCESS = "VEHICLE_AVAILABLE_LIST_SUCCESS";

export const VEHICLE_ASSIGNED_UPDATE_REQUEST = "VEHICLE_ASSIGNED_UPDATE_REQUEST";
export const VEHICLE_ASSIGNED_UPDATE_SUCCESS = "VEHICLE_ASSIGNED_UPDATE_SUCCESS";
export const VEHICLE_ASSIGNED_UPDATE_FAIL = "VEHICLE_ASSIGNED_UPDATE_FAIL";
export const VEHICLE_ASSIGNED_UPDATE_RESET = "VEHICLE_ASSIGNED_UPDATE_RESET";

export const VEHICLE_FETCH_BY_USER_NAME_REQUEST = "VEHICLE_FETCH_BY_USER_NAME_REQUEST";
export const VEHICLE_FETCH_BY_USER_NAME_SUCCESS = "VEHICLE_FETCH_BY_USER_NAME_SUCCESS";
export const VEHICLE_FETCH_BY_USER_NAME_FAIL = "VEHICLE_FETCH_BY_USER_NAME_FAIL";
export const VEHICLE_FETCH_BY_USER_NAME_RESET = "VEHICLE_FETCH_BY_USER_NAME_RESET";
