import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { Button, Grid, TextField, Typography, Divider} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { createStore } from "../../actions/storeAction";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { STORE_CREATE_RESET } from "../../constants/storeConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  storeName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  phoneNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
  address: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  managerName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  managerEmail: {
    presence: { allowEmpty: false, message: "is required" },
    email: {
      message: "doesn't look like a valid email"
    }
  },
  latitude: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
  longitude: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
  postalCode: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 6,
    },
  },
};

const StoreForm = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},  
    errors: {},
  });
  console.log(props);
  const { id } = props;
  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const storeCreated = useSelector((state) => state.storeCreated);
  const { store_create_loading, store_create_success, store } = storeCreated;

  useEffect(() => {
    if (store_create_success) {
      dispatch({ type: STORE_CREATE_RESET });
      navigate("/admin/store");
    }
  }, [navigate, store_create_success]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(formState.values);
      dispatch(
        createStore(
          formState.values.storeName,
          formState.values.phoneNo,
          formState.values.address,
          formState.values.managerName,
          formState.values.managerEmail,
          formState.values.latitude,
          formState.values.longitude,
          formState.values.postalCode
        )
      );
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              <form
                name="password-reset-form"
                method="post"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Store Name"
                      label="Store Name *"
                      variant="outlined"
                      size="small"
                      name="storeName"
                      fullWidth
                      helperText={
                        hasError("storeName")
                          ? formState.errors.storeName[0]
                          : null
                      }
                      error={hasError("storeName")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.storeName || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Phone No"
                      label="Phone No *"
                      variant="outlined"
                      size="small"
                      name="phoneNo"
                      fullWidth
                      helperText={
                        hasError("phoneNo") ? formState.errors.phoneNo[0] : null
                      }
                      error={hasError("phoneNo")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.phoneNo || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Address"
                      label="Address *"
                      variant="outlined"
                      size="small"
                      name="address"
                      fullWidth
                      helperText={
                        hasError("address") ? formState.errors.address[0] : null
                      }
                      error={hasError("address")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.address || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Manager Name"
                      label="Manager Name *"
                      variant="outlined"
                      size="small"
                      name="managerName"
                      fullWidth
                      helperText={
                        hasError("managerName")
                          ? formState.errors.managerName[0]
                          : null
                      }
                      error={hasError("managerName")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.managerName || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Manager Email"
                      label="Manager Email *"
                      variant="outlined"
                      size="small"
                      name="managerEmail"
                      fullWidth
                      helperText={
                        hasError("managerEmail")
                          ? formState.errors.managerEmail[0]
                          : null
                      }
                      error={hasError("managerEmail")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.managerEmail || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Latitude"
                      label="Latitude *"
                      variant="outlined"
                      size="small"
                      name="latitude"
                      fullWidth
                      helperText={
                        hasError("latitude")
                          ? formState.errors.latitude[0]
                          : null
                      }
                      error={hasError("latitude")}
                      onChange={handleChange}
                      type="string"
                      value={formState.values.latitude || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Longitude"
                      label="Longitude *"
                      variant="outlined"
                      size="small"
                      name="longitude"
                      fullWidth
                      helperText={
                        hasError("longitude")
                          ? formState.errors.longitude[0]
                          : null
                      }
                      error={hasError("longitude")}
                      onChange={handleChange}
                      type="string"
                      value={formState.values.longitude || ""}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Postal Code"
                      label="Postal Code *"
                      variant="outlined"
                      size="small"
                      name="postalCode"
                      fullWidth
                      helperText={
                        hasError("postalCode")
                          ? formState.errors.postalCode[0]
                          : null
                      }
                      error={hasError("postalCode")}
                      onChange={handleChange}
                      type="number"
                      value={formState.values.postalCode || ""}
                    />
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                    >
                      {!id? "Create":"Edit"}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default StoreForm;
