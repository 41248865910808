export const WALKIN_SALE_RECEIPT_CREATE_BY_STORE_REQUEST = "WALKIN_SALE_RECEIPT_CREATE_BY_STORE_REQUEST";
export const WALKIN_SALE_RECEIPT_CREATE_BY_STORE_SUCCESS = "WALKIN_SALE_RECEIPT_CREATE_BY_STORE_SUCCESS";
export const WALKIN_SALE_RECEIPT_CREATE_BY_STORE_FAIL = "WALKIN_SALE_RECEIPT_CREATE_BY_STORE_FAIL";
export const WALKIN_SALE_RECEIPT_CREATE_BY_STORE_RESET = "WALKIN_SALE_RECEIPT_CREATE_BY_STORE_RESET";

export const WALKIN_SALES_RECEIPTS_LIST_BY_STORE_REQUEST = "WALKIN_SALES_RECEIPTS_LIST_BY_STORE_REQUEST";
export const WALKIN_SALES_RECEIPTS_LIST_BY_STORE_SUCCESS = "WALKIN_SALES_RECEIPTS_LIST_BY_STORE_SUCCESS";
export const WALKIN_SALES_RECEIPTS_LIST_BY_STORE_FAIL = "WALKIN_SALES_RECEIPTS_LIST_BY_STORE_FAIL";
export const WALKIN_SALES_RECEIPTS_LIST_BY_STORE_RESET = "WALKIN_SALES_RECEIPTS_LIST_BY_STORE_RESET";

export const WALKIN_SALE_RECEIPT_DETAILS_BY_ID_REQUEST = "WALKIN_SALE_RECEIPT_DETAILS_BY_ID_REQUEST";
export const WALKIN_SALE_RECEIPT_DETAILS_BY_ID_SUCCESS = "WALKIN_SALE_RECEIPT_DETAILS_BY_ID_SUCCESS";
export const WALKIN_SALE_RECEIPT_DETAILS_BY_ID_FAIL = "WALKIN_SALE_RECEIPT_DETAILS_BY_ID_FAIL";
export const WALKIN_SALE_RECEIPT_DETAILS_BY_ID_RESET = "WALKIN_SALE_RECEIPT_DETAILS_BY_ID_RESET";







