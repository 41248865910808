import { STOCK_TRANSER_INVOICE_LIST_BY_POS_FAIL, STOCK_TRANSER_INVOICE_LIST_BY_POS_REQUEST, STOCK_TRANSER_INVOICE_LIST_BY_POS_RESET, STOCK_TRANSER_INVOICE_LIST_BY_POS_SUCCESS } from "../constants/stockTransferConstants";
import {
  STOCK_TRANSER_INVOICE_LIST_REQUEST,
  STOCK_TRANSER_INVOICE_LIST_SUCCESS,
  STOCK_TRANSER_INVOICE_LIST_FAIL,
  
  STOCK_TRANSER_INVOICE_BY_ID_REQUEST,
  STOCK_TRANSER_INVOICE_BY_ID_SUCCESS,
  STOCK_TRANSER_INVOICE_BY_ID_FAIL,

  STOCK_TRANSER_INVOICE_DELETE_BY_ID_REQUEST,
  STOCK_TRANSER_INVOICE_DELETE_BY_ID_SUCCESS,
  STOCK_TRANSER_INVOICE_DELETE_BY_ID_FAIL,

  STOCK_TRANSER_INVOICE_CREATE_RESET,
  STOCK_TRANSER_INVOICE_CREATE_FAIL,
  STOCK_TRANSER_INVOICE_CREATE_SUCCESS,
  STOCK_TRANSER_INVOICE_CREATE_REQUEST,

  STOCK_TRANSER_INVOICE_UPDATE_BY_ID_REQUEST,
  STOCK_TRANSER_INVOICE_UPDATE_BY_ID_SUCCESS,
  STOCK_TRANSER_INVOICE_UPDATE_BY_ID_FAIL,
  STOCK_TRANSER_INVOICE_UPDATE_BY_ID_RESET,
} from "../constants/stockTransferConstants";

export const stockTransferInvoiceListReducer = (state = { warehouseInvoices: [] }, action) => {
  switch (action.type) {
    case STOCK_TRANSER_INVOICE_LIST_REQUEST:
      return { loading: true, warehouseInvoices: [] };
    case STOCK_TRANSER_INVOICE_LIST_SUCCESS:
      return {
        loading: false,
        warehouseInvoices: action.payload,
      };
    case STOCK_TRANSER_INVOICE_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stockTransferInvoiceListByPosReducer = (state = { stock_transfer_invoices: [] }, action) => {
  switch (action.type) {
    case STOCK_TRANSER_INVOICE_LIST_BY_POS_REQUEST:
      return { stock_transfer_invoices_loading: true, stock_transfer_invoices: [] };
    case STOCK_TRANSER_INVOICE_LIST_BY_POS_SUCCESS:
      return {
        stock_transfer_invoices_loading: false,
        stock_transfer_invoices: action.payload,
      };
    case STOCK_TRANSER_INVOICE_LIST_BY_POS_FAIL:
      return { stock_transfer_invoices_loading: false, stock_transfer_invoices_error: action.payload };
    case STOCK_TRANSER_INVOICE_LIST_BY_POS_RESET:
      return { };
    default:
      return state;
  }
};

export const stockTransferByIdReducer = (
  state = { stock_transfer_invoice_id: {} },
  action
) => {
  switch (action.type) {
    case STOCK_TRANSER_INVOICE_BY_ID_REQUEST:
      return { stock_transfer_invoice_id_loading: true, stock_transfer_invoice_id:{}  };
    case STOCK_TRANSER_INVOICE_BY_ID_SUCCESS:
      return {
        stock_transfer_invoice_id_loading: false,
        stock_transfer_invoice_id: action.payload,
      };
    case STOCK_TRANSER_INVOICE_BY_ID_FAIL:
      return { stock_transfer_invoice_id_loading: false, stock_transfer_invoice_id_error: action.payload };
    default:
      return state;
  }
};

export const stockTransferDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCK_TRANSER_INVOICE_DELETE_BY_ID_REQUEST:
      return { stock_transfer_invoice_delete_id_loading: true };
    case STOCK_TRANSER_INVOICE_DELETE_BY_ID_SUCCESS:
      return { stock_transfer_invoice_delete_id_loading: false, stock_transfer_invoice_delete_id_success: true };
    case STOCK_TRANSER_INVOICE_DELETE_BY_ID_FAIL:
      return { stock_transfer_invoice_delete_id_loading: false, stock_transfer_invoice_delete_id_error: action.payload };
    default:
      return state;
  }
};

export const stockTransferCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STOCK_TRANSER_INVOICE_CREATE_REQUEST:
      return { wh_store_inv_create_loading: true };
    case STOCK_TRANSER_INVOICE_CREATE_SUCCESS:
      return { wh_store_inv_create_loading: false, wh_store_inv_create_success: true, wh_store_inv_create_data: action.payload };
    case STOCK_TRANSER_INVOICE_CREATE_FAIL:
      return { wh_store_inv_create_loading: false, wh_store_inv_create_error: action.payload };
    case STOCK_TRANSER_INVOICE_CREATE_RESET:
      return { wh_store_inv_create_loading: false, wh_store_inv_create_success: false, wh_store_inv_create_data: {} };
    default:
      return state;
  }
};

export const stockTransferUpdateByIdReducer = (state = { warehouseInvoice: {} }, action) => {
  switch (action.type) {
    case STOCK_TRANSER_INVOICE_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case STOCK_TRANSER_INVOICE_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, warehouseInvoice: action.payload };
    case STOCK_TRANSER_INVOICE_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case STOCK_TRANSER_INVOICE_UPDATE_BY_ID_RESET:
      return { warehouseInvoice: {} };
    default:
      return state;
  }
};

