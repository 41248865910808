import {
VERSION_UPDATES_CREATE_REQUEST,
VERSION_UPDATES_CREATE_RESET,
VERSION_UPDATES_CREATE_FAIL,
VERSION_UPDATES_CREATE_SUCCESS,

VERSION_UPDATES_BY_ID_REQUEST,
VERSION_UPDATES_BY_ID_RESET,
VERSION_UPDATES_BY_ID_SUCCESS,
VERSION_UPDATES_BY_ID_FIAL,

VERSION_UPDATES_LIST_REQUEST,
VERSION_UPDATES_LIST_SUCCESS,
VERSION_UPDATES_LIST_FAIL,
VERSION_UPDATES_LIST_RESET,

VERSION_UPDATES_DELETE_BY_ID_REQUEST,
VERSION_UPDATES_DELETE_BY_ID_RESET,
VERSION_UPDATES_DELETE_BY_ID_SUCCESS,
VERSION_UPDATES_DELETE_BY_ID_FAIL,

VERSION_UPDATES_UPDATE_BY_ID_REQUEST,
VERSION_UPDATES_UPDATE_BY_ID_RESET,
VERSION_UPDATES_UPDATE_BY_ID_SUCCESS,
VERSION_UPDATES_UPDATE_BY_ID_FAIL,
} from "../constants/versionUpdatesConstants";

export const versionUpdatesListReducer = (state = { version_updates_list: [] }, action) => {
  switch (action.type) {
    case VERSION_UPDATES_LIST_REQUEST:
      return { ...state, version_updates_list_loading: true };
    case VERSION_UPDATES_LIST_SUCCESS:
      return { version_updates_list_loading: false, version_updates_list: action.payload };
    case VERSION_UPDATES_LIST_FAIL:
      return { version_updates_list_loading: false, version_updates_list_error: action.payload };
    default:
      return state;
  }
};

export const versionUpdatesByIdReducer = (state = { version_updates_by_id: {} }, action) => {
  switch (action.type) {
    case VERSION_UPDATES_BY_ID_REQUEST:
      return { ...state, version_updates_by_id_loading: true };
    case VERSION_UPDATES_BY_ID_SUCCESS:
      return { version_updates_by_id_loading: false, version_updates_by_id: action.payload };
    case VERSION_UPDATES_BY_ID_FAIL:
      return { version_updates_by_id_loading: false, version_updates_by_id_error: action.payload };
    default:
      return state;
  }
};

export const versionUpdatesDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case VERSION_UPDATES_DELETE_BY_ID_REQUEST:
      return { version_updates_delete_by_id_loading: true };
    case VERSION_UPDATES_DELETE_BY_ID_SUCCESS:
      return { version_updates_delete_by_id_loading: false, version_updates_delete_by_id_success: true };
    case VERSION_UPDATES_DELETE_BY_ID_FAIL:
      return { version_updates_delete_by_id_loading: false, version_updates_delete_by_id_error: action.payload };
    default:
      return state;
  }
};

export const versionUpdatesCreateReducer = (state = {version_updates_create:{}}, action) => {
  switch (action.type) {
    case VERSION_UPDATES_CREATE_REQUEST:
      return { version_updates_create_loading: true };
    case VERSION_UPDATES_CREATE_SUCCESS:
      return { version_updates_create_loading: false, version_updates_create_success: true, version_updates_create:action.payload };
    case VERSION_UPDATES_CREATE_FAIL:
      return { version_updates_create_loading: false, version_updates_create_error: action.payload };
      case VERSION_UPDATES_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const versionUpdatesUpdateReducer = (state = { version_updates_update: {} }, action) => {
  switch (action.type) {
    case VERSION_UPDATES_UPDATE_BY_ID_REQUEST:
      return { version_updates_update_loading: true };
    case VERSION_UPDATES_UPDATE_BY_ID_SUCCESS:
      return { version_updates_update_loading: false, version_updates_update_success: true , version_updates_update:action.payload};
    case VERSION_UPDATES_UPDATE_BY_ID_FAIL:
      return { version_updates_update_loading: false, version_updates_update_error: action.payload };
    default:
      return state;
  }
};
