import axios from "axios";
import {
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_RESET
} from "../constants/warehouseStockConstants";

export const listStockByWarehouseName = (whName) => async (dispatch) => {
  try {
    console.log('whName : ', whName);
    dispatch({ type: WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_REQUEST });
    const { data } = await axios.get(`/api/warehouse-stock/${whName}`);
    dispatch({
      type: WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_SUCCESS,
      payload: data,
    });
    // dispatch({ type: WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_RESET });
  } catch (error) {
    dispatch({
      type: WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const  createStockByWarehouseNameAndProductName =
  (purchaseOrder, purchaseOrderProducts,whName) => async (dispatch) => {
    console.log(
      "createStockByWarehouseNameAndProductName -start ",
      purchaseOrder,
      purchaseOrderProducts,
      whName
    );

    /** 
     * LSK : USE THIS WHILE CALCIULATING THE QUANTITY OF THE EACH PRODUCT WHULE INSERTING INTO THE WH-STOCK
    */

    try {
      dispatch({
        type: WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_REQUEST,
      });
      // const whName = purchaseOrder.destinationWhStoreValue?(purchaseOrder.destinationWhStoreValue?purchaseOrder.warehouseName.warehouseName :''):'';
      console.log(
        " ******** Add Stock in Warehouse Name : whName :: ",
        whName,
        " ********  "
      );
      const { data } = await axios.post(`/api/warehouse-stock/${whName}/in`, {
        purchaseOrderProducts,
      });
      console.log("createStockByWarehouseNameAndProductName - end ");

      dispatch({
        type: WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_SUCCESS,
        payload: data,
      });

      dispatch({
        type: WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WAREHOUSE_STOCK_IN_CREATE_BY_WAREHOUSE_NAME_FAIL,
        payload: message,
      });
    }
  };

export const  updateStockInByWarehouseProduct =
  (purchaseOrder, purchaseOrderProducts) => async (dispatch) => {
    const  { fromWarehouseStoreValue , toWarehouseStoreValue } = purchaseOrder
    console.log("updateStockInByWarehouseProduct -start ");
    console.log("purchaseOrder : ", purchaseOrder ? purchaseOrder :{})
    console.log("purchaseOrderProducts Length if any : ", purchaseOrderProducts ? purchaseOrderProducts.length : 0)
    console.log("fromWarehouseStoreValue : " , fromWarehouseStoreValue , "toWarehouseStoreValue : ", toWarehouseStoreValue);
  
   

    /** 
     * LSK : USE THIS WHILE CALCIULATING THE QUANTITY OF THE EACH PRODUCT WHULE INSERTING INTO THE WH-STOCK
     * 
     * const resultOfGroupAndSum = groupAndSum(purchaseOrderProducts);
    console.log("resultOfGroupAndSum : ", resultOfGroupAndSum)
    */
   
    try {
      dispatch({
        type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_REQUEST,
      });
      console.log('Warehouse Name : ',toWarehouseStoreValue);
      const { data } = await axios.post(`/api/warehouse-stock/${toWarehouseStoreValue}/in`, 
        {purchaseOrder , purchaseOrderProducts, toWarehouseStoreValue});
      console.log("updateStockInByWarehouseProduct - end ");

      dispatch({
        type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
        payload: data,
      });

      dispatch({
        type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_FAIL,
        payload: message,
      });
    }
  };

export const updateStockByWarehouseNameAndProductName = (purchaseOrder, purchaseOrderProducts,whName) => async (dispatch, getState) => {
  console.log(
    "updateStockByWarehouseNameAndProductName -start ",
    purchaseOrder, 
    purchaseOrderProducts,
    whName
  );

  try {
    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_REQUEST,
    });
    console.log('Warehouse Name before doing OUT from WH : ',whName);
    const { data } = await axios.post(`/api/warehouse-stock/${whName}/out`, {
      purchaseOrder, 
      purchaseOrderProducts,
      whName});
    console.log("updateStockByWarehouseNameAndProductName - end ");

    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
      payload: data,
    });
    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_RESET,
      payload: data,
    });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_FAIL,
      payload: message,
    });
  }
};

export const updateStockOutByWarehouseProduct = (purchaseOrder, purchaseOrderProducts) => async (dispatch, getState) => {
  const  { fromWarehouseStoreValue } = purchaseOrder
  console.log(
    "updateStockOutByWarehouseProduct -start ",
    purchaseOrder, 
    purchaseOrderProducts
  );
  /** 
   * LSK : USE THIS WHILE CALCIULATING THE QUANTITY OF THE EACH PRODUCT WHULE INSERTING INTO THE WH-STOCK
   * 
   * const resultOfGroupAndSum = groupAndSum(purchaseOrderProducts);
  console.log("resultOfGroupAndSum : ", resultOfGroupAndSum)
  */
 
  try {
    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_REQUEST,
    });
    console.log('Warehouse Name before doing OUT from WH : ',fromWarehouseStoreValue);
    const { data } = await axios.post(`/api/warehouse-stock/${fromWarehouseStoreValue}/out`, {
      purchaseOrder, 
      purchaseOrderProducts,
      fromWarehouseStoreValue});
    console.log("updateStockByWarehouseNameAndProductName - end ");

    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
      payload: data,
    });
    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_RESET,
      payload: data,
    });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_FAIL,
      payload: message,
    });
  }
};


export const  returnStockByWarehouseNameAndProductName =
  ( whName,purchaseOrderProducts) => async (dispatch) => {
    console.log(
      "returnStockByWarehouseNameAndProductName -start ",
      purchaseOrderProducts,
      whName
    );

    try {
      dispatch({
        type: WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_REQUEST,
      });
      console.log(
        " ******** Add Stock in Warehouse Name : whName :: ",
        whName,
        " ********  "
      );
      const { data } = await axios.post(`/api/warehouse-stock/return/${whName}/in`, {
        purchaseOrderProducts,
      });
      console.log("returnStockByWarehouseNameAndProductName - end ");

      dispatch({
        type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
        payload: data,
      });

      dispatch({
        type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_FAIL,
        payload: message,
      });
    }
  };