import axios from "axios";
import {
  LIST_STOCK_BY_ALL_PRODUCTS_REQUEST,
  LIST_STOCK_BY_ALL_PRODUCTS_SUCCESS,
  LIST_STOCK_BY_ALL_PRODUCTS_FAIL,
  LIST_STOCK_BY_PRODUCT_ID_REQUEST,
  LIST_STOCK_BY_PRODUCT_ID_SUCCESS,
  LIST_STOCK_BY_PRODUCT_ID_FAIL,
  CREATE_STOCK_BY_PRODUCT_ID_REQUEST,
  CREATE_STOCK_BY_PRODUCT_ID_SUCCESS,
  CREATE_STOCK_BY_PRODUCT_ID_FAIL,
  UPDATE_STOCK_BY_PRODUCT_ID_REQUEST,
  UPDATE_STOCK_BY_PRODUCT_ID_SUCCESS,
  UPDATE_STOCK_BY_PRODUCT_ID_FAIL,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE,
  
} from "../constants/stockConstants";

export const listAllProductsStock = () => async (dispatch) => {
  try {
    dispatch({ type: LIST_STOCK_BY_ALL_PRODUCTS_REQUEST });

    const { data } = await axios.get(`/api/stock/product`);

    dispatch({
      type: LIST_STOCK_BY_ALL_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_STOCK_BY_ALL_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStockByProductId = (productId) => async (dispatch) => {
  try {
    dispatch({ type: LIST_STOCK_BY_PRODUCT_ID_REQUEST });

    const { data } = await axios.get(`/api/stock/product/${productId}`);

    dispatch({
      type: LIST_STOCK_BY_PRODUCT_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_STOCK_BY_PRODUCT_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createStockByProductId =
  (productId, countInStock) => async (dispatch, getState) => {
    try {
      console.log(
        "productId : " + productId + ", countInStock : " + countInStock
      );
      dispatch({
        type: CREATE_STOCK_BY_PRODUCT_ID_REQUEST,
      });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `/api/category/product/${productId}`,
        { countInStock },
        config
      );

      dispatch({
        type: CREATE_STOCK_BY_PRODUCT_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: CREATE_STOCK_BY_PRODUCT_ID_FAIL,
        payload: message,
      });
    }
  };

export const updateStockByProductId =
  (product, productName, uom, countInStock) => async (dispatch, getState) => {
    try {
      console.log("Exec update Stock from Action");
      console.log(product, productName, uom, countInStock);
      dispatch({
        type: UPDATE_STOCK_BY_PRODUCT_ID_REQUEST,
      });

      const { data } = await axios.put(`/api/stock/product/${product}`, {
        productName,
        uom,
        countInStock,
      });

      dispatch({
        type: UPDATE_STOCK_BY_PRODUCT_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
      }
      dispatch({
        type: UPDATE_STOCK_BY_PRODUCT_ID_FAIL,
        payload: message,
      });
    }
  };

  export const updateStockCountsByProductIds =
  (product, productName, uom, countInStock) => async (dispatch, getState) => {
    try {
      console.log("Exec update Stock from Action");
      console.log(product, productName, uom, countInStock);
      dispatch({
        type: UPDATE_STOCK_BY_PRODUCT_ID_REQUEST,
      });

      const { data } = await axios.put(`/api/stock/product/${product}`, {
        productName,
        uom,
        countInStock,
      });

      dispatch({
        type: UPDATE_STOCK_BY_PRODUCT_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
      }
      dispatch({
        type: UPDATE_STOCK_BY_PRODUCT_ID_FAIL,
        payload: message,
      });
    }
  };

  export const updateProductStockPostPlacingOrder =
  (productsQuanityOrdered) => async (dispatch) => {
    console.log(
      "Inside updateProductStockPostPlacingOrder ACTION...",
      productsQuanityOrdered
    );
    try {
      dispatch({
        type: UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST,
      });

      const { data } = await axios.put(
        `/api/stock/product`,
        productsQuanityOrdered
      );

      dispatch({
        type: UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE,
        payload: message,
      });
    }
  };