import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import GridContainer from "../../components/Grid/GridContainer.js";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { listAllOrders } from "../../actions/orderAction";
import { listAllStores } from "../../actions/storeAction";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import { getMonthNameByInt, transformToDDMMYYY } from "../../util/Utility";
import { financialYearOptions, monthOptions, quarterlyOptions, reportOptions } from "../../data/index.js";
import CardBody from "../Card/CardBody.js";
import _ from "lodash"

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "50%",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const OrderListScreen = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();
  const dispatch = useDispatch();
  const [enableAdvancedSearch, setEnableAdvancedSearch] = useState(false);
  const [reportTypeSelected, setReportTypeSelected] = useState("Monthly");
  const [monthSelected, setMonthSelected] = useState("January");
  const [quarterSelected, setQuarterSelected] = useState("Q1 [April - June]");
  const [financialYearSelected, setFinancialYearSelected] = useState("2023 - 2024");
  const [rowData, setRowData] = useState(() => [])

  useEffect(() => {
    console.log("useEffect getting called to list stores and Orders ");
    dispatch(listAllStores());
    dispatch(listAllOrders());
  }, []);

  useEffect(() => {
    console.log("useEffect getting called to list stores and Orders ");
    populateInitRowData();
    if(enableAdvancedSearch == true ) 
      resetRowData()
  }, [enableAdvancedSearch]);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const showOrderDetails = (id) => {
    navigate(`/admin/order-details/${id}`);
  };
  let renderContentUsingAGGrid = "";
  
  const filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      console.log(
        "filterLocalDateAtMidnight",
        filterLocalDateAtMidnight,
        "cellValue",
        cellValue
      );
      let dateAsString = cellValue;
      if (dateAsString == null) return -1;
      let dateParts = dateAsString.split("-");
      let cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]),
        Number(dateParts[2])
      );

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000,
  };

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Store Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "Customer Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
    },
    {
      field: "Total Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
    },
    {
      field: "Order Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
    },
    {
      field: "Details",
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Order Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showOrderDetails(params.data.Id)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      resizable: true,
      editable: true,
    }),
    []
  );
  const export_label = "Export > CSV";

  const searchHandler = () => {
    console.log("Report Type : ", reportTypeSelected, monthSelected, financialYearSelected, quarterSelected)
    filterRowDataBySearchCritieria()
  }

  const resetRowData = ()=>{
    console.log("Resetting the Data as u have Toggled the Enable Search ")
    setRowData(prev=>[]);
  }

  const populateInitRowData = () => {
    if (enableAdvancedSearch == false) {
      console.log("Setting rowdata as enable search is Not Selected")
      setRowData((prev) => {
        return orders.map((eachOrder, index) => {
          return {
            "#": index + 1,
            "Store Name": findStoreById(eachOrder.store),
            "Customer Name": eachOrder.user
              ? eachOrder.user.name
                ? eachOrder.user.name
                : ""
              : "",
            "Total Price": eachOrder.totalPrice,
            "Order Date": eachOrder.createdAt
              ? new Date(eachOrder.createdAt).toLocaleString()
              : "",
            Id: eachOrder._id,
          };
        }
        )
      }
      );
    }
  }

  const filterDataByMonthly = () => {
    let resultByMonthly = _.groupBy(orders, ({ createdAt }) => new Date(createdAt).getMonth());
    console.log('Result of Monthly : ', resultByMonthly);
    return resultByMonthly
  }

  const filterDataByQuarterly = () => {
    console.log("Exec filterDataByQuarterly .")
  }

  const filterDataByFinancialYear = () => {
    console.log("Exec filterDataByFinancialYear .")
  }

  const filterRowDataBySearchCritieria = () => {
    console.log("filterRowDataBySearchCritieria")
    switch (reportTypeSelected) {
      case "Monthly":
        const res = filterDataByMonthly(monthSelected)
        const tempRowDataValues = Object.values(res)
        console.log(Object.keys(res), Object.values(res));
        const monthName = getMonthNameByInt(parseInt(Object.keys(res)[0]))
        console.log(`Selected Month : ${monthSelected}  and Month with Data : ${monthName} `)
        if (monthName === monthSelected) {
          console.log("Matched the Search Criteria so resetting rowData : ", [...Object.values(res)][0])
          setRowData((prev) => {
            return [...Object.values(res)][0].map((eachOrder, index) => {
              return {
                "#": index + 1,
                "Store Name": findStoreById(eachOrder.store),
                "Customer Name": eachOrder.user
                  ? eachOrder.user.name
                    ? eachOrder.user.name
                    : ""
                  : "",
                "Total Price": eachOrder.totalPrice,
                "Order Date": eachOrder.createdAt
                  ? new Date(eachOrder.createdAt).toLocaleString()
                  : "",
                Id: eachOrder._id,
              };
            }
            )
          }
          );
        } else {
          console.log("Didnt Match the Search Criteria so resetting rowData to null ")
          setRowData((prev) => []);
        }
        break;
      case "Quarterly":
        filterDataByQuarterly(quarterSelected)
        break;
      case "Financial Year":
        filterDataByFinancialYear(financialYearSelected)
        break;
      default:
        break;
    }
  }

  const orderList = useSelector((state) => state.orderList);
  const { loading, error_loading_orders, orders } = orderList;
  const reportOptionsFilter = reportOptions && reportOptions.length > 0 ? reportOptions.map((eachReport, idx) => <MenuItem key={idx} value={eachReport}>{eachReport}</MenuItem>) : [];
  const monthOptionsFilter = monthOptions && monthOptions.length > 0 ? monthOptions.map((eachMonth, idx) => <MenuItem key={idx} value={eachMonth}>{eachMonth}</MenuItem>) : [];
  const quarterlyOptionsFilter = quarterlyOptions && quarterlyOptions.length > 0 ? quarterlyOptions.map((eachQtrly, idx) => <MenuItem key={idx} value={eachQtrly}>{eachQtrly}</MenuItem>) : [];
  const financialYearOptionsFilter = financialYearOptions && financialYearOptions.length > 0 ? financialYearOptions.map((eachFy, idx) => <MenuItem key={idx} value={eachFy}>{eachFy}</MenuItem>) : [];

  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;

  const renderAdvanceSearchContent = (
  <GridContainer spacing={2}>
    <Card>
      <CardBody>
        <GridContainer xs={12} sm={12} md={12} style={{justifyContent:"center", alignItems : "center"}}>
          <GridItem xs={12} sm={4} md={4} style={{textAlign:"center"}}>
            <FormControl style={{textAlign:"center"}}>
              <InputLabel id="demo-simple-select-label">
                Report By
              </InputLabel>
              <Select
                label="Report By"
                placeholder="Report By"
                variant="outlined"
                required
                size="small"
                name="Report By"
                onChange={(e) => {
                  setReportTypeSelected((prev) => e.target.value);
                }
                }
                value={reportTypeSelected}
                style={{ width: "20vh" }}
              >
                {reportOptionsFilter}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={4} md={4} style={{textAlign:"center"}}>{reportTypeSelected && reportTypeSelected === "Monthly" && (
            <FormControl style={{textAlign:"center"}}>
              <InputLabel id="demo-simple-select-label">
                Month
              </InputLabel>
              <Select
                label="Month"
                placeholder="Month"
                variant="outlined"
                required
                size="small"
                name="Month"
                onChange={(e) => {
                  console.log("Changing the Value : ", e)
                  setMonthSelected((prev) => e.target.value);
                }
                }
                value={monthSelected}
                style={{ width: "20vh" }}
              >
                {monthOptionsFilter}
              </Select>
            </FormControl>
          )
          }
           {reportTypeSelected && reportTypeSelected === "Quarterly" && (
            <FormControl style={{textAlign:"center"}}>
              <InputLabel id="demo-simple-select-label">
                Quarter
              </InputLabel>
              <Select
                label="Quarter"
                placeholder="Quarter"
                variant="outlined"
                required
                size="small"
                name="Quarter"
                onChange={(e) => {
                  setQuarterSelected((prev) => e.target.value);
                }
                }
                value={quarterSelected}
              >
                {quarterlyOptionsFilter}
              </Select>
            </FormControl>
          )
          }
          
          {reportTypeSelected && reportTypeSelected === "Financial Year" && (
            <FormControl style={{textAlign:"center"}}>
              <InputLabel id="demo-simple-select-label">
                Financial Year
              </InputLabel>
              <Select
                label="Financial Year"
                placeholder="Financial Year"
                variant="outlined"
                required
                size="small"
                name="Financial Year"
                onChange={(e) => {
                  // console.log("Changing the Value : ", e)
                  setFinancialYearSelected((prev) => e.target.value);
                }
                }
                value={financialYearSelected}
                style={{ width: "20vh" }}
              >
                {financialYearOptionsFilter}
              </Select>
            </FormControl>
          )
          }</GridItem>
          {reportTypeSelected && (<GridItem xs={12} sm={4} md={4} style={{textAlign:"center"}}>
            <Tooltip title="Search" arrow>
              <Button
                size="small"
                variant="contained"
                type="submit"
                color="secondary"
                style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                onClick={searchHandler}
              >Search</Button>
            </Tooltip>
          </GridItem>)
          }
        </GridContainer>
      </CardBody>
    </Card>
  </GridContainer>
  )

  const handleOrderItemDetails = (id) => {
    console.log("Clicked handleOrderItemDetails " + id);
    navigate(`/admin/order-details/${id}`);
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  const findStoreById = (storeId) => {
    let storeObj = null;
    let storeName = null;
    if (stores) {
      storeObj = stores.filter((eachStore) => eachStore._id === storeId);
      if (storeObj && storeObj.length > 0) {
        storeName = storeObj[0].storeName ? storeObj[0].storeName : "";
      }
    }
    return storeName;
  };
  console.log(" enableAdvancedSearch : ", enableAdvancedSearch)

  renderContentUsingAGGrid = (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: "65vh" }}
    >
      <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
    </div>
  );

  return (
    <React.Fragment>
      {loading && <Message variant="danger">{error_loading_orders}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  Orders
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        {rowData && rowData.length > 0 && (<GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "0.5rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>)}
      </GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Switch {...label} defaultChecked={enableAdvancedSearch} onChange={() => {
          setEnableAdvancedSearch((prev) => !prev);
          console.log('Clicked the Adv Search Btn : ', enableAdvancedSearch)
        }
        } />
      </GridItem>
      {enableAdvancedSearch && enableAdvancedSearch === true && renderAdvanceSearchContent}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          { renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default OrderListScreen;
