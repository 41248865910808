import axios from "axios";
import {
  VEHICLE_DETAILS_REQUEST,
  VEHICLE_DETAILS_SUCCESS,
  VEHICLE_DETAILS_FAIL,
  VEHICLE_DETAILS_RESET,
  VEHICLE_CREATE_REQUEST,
  VEHICLE_CREATE_SUCCESS,
  VEHICLE_CREATE_RESET,
  VEHICLE_CREATE_FAIL,
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
  VEHICLE_LIST_FAIL,
  VEHICLE_LIST_RESET,
  VEHICLE_DELETE_REQUEST,
  VEHICLE_DELETE_SUCCESS,
  VEHICLE_DELETE_FAIL,
  VEHICLE_DELETE_RESET,
  VEHICLE_UPDATE_REQUEST,
  VEHICLE_UPDATE_FAIL,
  VEHICLE_UPDATE_SUCCESS,
  VEHICLE_UPDATE_RESET,
  VEHICLE_AVAILABLE_LIST_FAIL,
  VEHICLE_AVAILABLE_LIST_REQUEST,
  VEHICLE_AVAILABLE_LIST_RESET,
  VEHICLE_AVAILABLE_LIST_SUCCESS,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_REQUEST,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_SUCCESS,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_RESET,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_FAIL,
  VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_FAIL,
  VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_REQUEST,
  VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_SUCCESS,
  VEHICLE_ASSIGNED_UPDATE_REQUEST,
  VEHICLE_ASSIGNED_UPDATE_SUCCESS,
  VEHICLE_ASSIGNED_UPDATE_FAIL,
  VEHICLE_ASSIGNED_UPDATE_RESET,
  VEHICLE_FETCH_BY_USER_NAME_REQUEST,
  VEHICLE_FETCH_BY_USER_NAME_SUCCESS,
  VEHICLE_FETCH_BY_USER_NAME_FAIL

} from "../constants/vehicleConstants";

export const listAllVehicles = () => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_LIST_REQUEST });

    const { data } = await axios.get(`/api/vehicle`);

    dispatch({
      type: VEHICLE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listAvailableVehicles = () => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_AVAILABLE_LIST_REQUEST });

    const { data } = await axios.get(`/api/vehicle/status/available`);

    dispatch({
      type: VEHICLE_AVAILABLE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_AVAILABLE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listVehiclesAssignedOrAvailableByWhStName = (whStName) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_REQUEST });

    const { data } = await axios.get(`/api/vehicle/assigned-or-avail/${whStName}`);

    dispatch({
      type: VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_SUCCESS,
      payload: data,
    });
    // dispatch({
    //   type: VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_RESET
    // });
  } catch (error) {
    dispatch({
      type: VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listVehiclesAvailability = () => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_LIST_REQUEST });

    const { data } = await axios.get(`/api/vehicle`);

    dispatch({
      type: VEHICLE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const vehicleDetailsById = (vehicleId) => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/vehicle/${vehicleId}`);

    dispatch({
      type: VEHICLE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchVehicleByUserName = (salesIncharge) => async (dispatch) => {
  console.log('Exec fetchVehicleByUserName ...', salesIncharge)
  try {
    dispatch({ type: VEHICLE_FETCH_BY_USER_NAME_REQUEST });

    const { data } = await axios.get(`/api/vehicle/sales-incharge/${salesIncharge}`);

    dispatch({
      type: VEHICLE_FETCH_BY_USER_NAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_FETCH_BY_USER_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createVehicle =
  (
    vehicleNo,
    reportingBranch,
    chasisNumber,
    make,
    model,
    pollutionExpiry,
    fitnessExpiry,
    insuranceExpiry,
    roadTaxExpiry,
    lastServiceDate,
    kmDrivenAfterService
  ) =>
    async (dispatch, getState) => {
      try {
        console.log("vehicleNo , reportingBranch,chasisNumber,make,model,pollutionExpiry,fitnessExpiry,insuranceExpiry,roadTaxExpiry, lastServiceDate,kmDrivenAfterService",
        vehicleNo , reportingBranch,chasisNumber,make,model,pollutionExpiry,fitnessExpiry,insuranceExpiry,roadTaxExpiry, lastServiceDate,kmDrivenAfterService);
        dispatch({
          type: VEHICLE_CREATE_REQUEST,
        });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `/api/vehicle`,
          {
            vehicleNo , reportingBranch,chasisNumber,make,model,pollutionExpiry,fitnessExpiry,insuranceExpiry,roadTaxExpiry, lastServiceDate,kmDrivenAfterService
          },
          config
        );

        dispatch({
          type: VEHICLE_CREATE_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({
          type: VEHICLE_CREATE_FAIL,
          payload: message,
        });
      }
    };

export const updateVehicleById =
  (
    vehicle
  ) =>
    async (dispatch, getState) => {
      try {
        console.log("Exec update Vehicle from Action");
        console.log("vehicle : ",vehicle );
        dispatch({
          type: VEHICLE_UPDATE_REQUEST,
        });

        const { data } = await axios.put(`/api/vehicle/${vehicle._id}`, vehicle);

        dispatch({
          type: VEHICLE_UPDATE_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Not authorized, token failed") {
        }
        dispatch({
          type: VEHICLE_UPDATE_FAIL,
          payload: message,
        });
      }
    };

export const updateVehicleAvailabilityAndAssignedStatus =
  (
    vehicleNo,
    availabilityStatus,
    whStoreName
  ) =>
    async (dispatch, getState) => {
      try {
        console.log("Exec update Vehicle from Action");
        console.log(
          vehicleNo,
          availabilityStatus,
          whStoreName
        );
        dispatch({
          type: VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_REQUEST
        });

        const { data } = await axios.put(`/api/vehicle/update-assigned-or-avail/${vehicleNo}`, {
          vehicleNo,
          availabilityStatus,
          whStoreName
        });

        dispatch({
          type: VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Not authorized, token failed") {
        }
        dispatch({
          type: VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_FAIL,
          payload: message,
        });
      }
    };

export const deleteVehicle = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_DELETE_REQUEST,
    });

    await axios.delete(`/api/vehicle/${id}`, {});

    dispatch({
      type: VEHICLE_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch({
        type: VEHICLE_DELETE_FAIL,
        payload: message,
      });
    }
    dispatch({
      type: VEHICLE_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateVehicleAssignedStatus =
  (
    vehicleNo,
    salesIncharge,
    driverName,
    selectedStatus
  ) =>
    async (dispatch, getState) => {
      try {
     
        console.log("Exec update Vehicle from Action",vehicleNo, salesIncharge, driverName, selectedStatus );
        dispatch({
          type: VEHICLE_ASSIGNED_UPDATE_REQUEST
        });

        const { data } = await axios.put(`/api/vehicle/assign/${vehicleNo}`, {
          salesIncharge,
          driverName,
          selectedStatus
        });

        dispatch({
          type: VEHICLE_ASSIGNED_UPDATE_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Not authorized, token failed") {
        }
        dispatch({
          type: VEHICLE_ASSIGNED_UPDATE_FAIL,
          payload: message,
        });
      }
    };

    export const updateBulkByProductId =
  (id, unitOfMessure, inKgs,unitPrice, mrp, discountStartsFrom,discountPercent,cgst,sgst,igst,tcs) => async (dispatch, getState) => {
    console.log(
      "Inside updateBulkByProductId " + id,
      unitOfMessure,
      inKgs,
      unitPrice,
      mrp,
      discountStartsFrom,
      discountPercent,
      cgst,
      sgst,
      igst,
      tcs
    );
    try {
      dispatch({
        type: BULK_UPDATE_BY_PRODUCT_ID_REQUEST,
      });

      const { data } = await axios.put(`/api/avail-bulk/${id}`, {
        unitOfMessure,
        inKgs,
        unitPrice,
        mrp,
        discountStartsFrom,
        discountPercent,
        cgst,
        sgst,
        igst,
        tcs
      });

      dispatch({
        type: BULK_UPDATE_BY_PRODUCT_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
      }
      dispatch({
        type: BULK_UPDATE_BY_PRODUCT_ID_FAIL,
        payload: message,
      });
    }
  };