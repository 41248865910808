import {
  SUPPLIER_DETAILS_FAIL,
  SUPPLIER_DETAILS_REQUEST,
  SUPPLIER_DETAILS_SUCCESS,
  SUPPLIER_DETAILS_RESET,

  SUPPLIER_REGISTER_FAIL,
  SUPPLIER_REGISTER_REQUEST,
  SUPPLIER_REGISTER_SUCCESS,
  SUPPLIER_REGISTER_RESET,
 
  SUPPLIER_LIST_FAIL,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_RESET,

  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAIL,
  SUPPLIER_DELETE_RESET,

  SUPPLIER_UPDATE_FAIL,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_RESET,

  SUPPLIER_LIST_BY_POS_NAME_REQUEST,
  SUPPLIER_LIST_BY_POS_NAME_SUCCESS,
  SUPPLIER_LIST_BY_POS_NAME_FAIL,
  SUPPLIER_LIST_BY_POS_NAME_RESET,

  SUPPLIER_LIST_BY_PARENT_USER_REQUEST,
  SUPPLIER_LIST_BY_PARENT_USER_RESET,
  SUPPLIER_LIST_BY_PARENT_USER_FAIL,
  SUPPLIER_LIST_BY_PARENT_USER_NAME_RESET,
  SUPPLIER_LIST_BY_PARENT_USER_SUCCESS,
} from "../constants/supplierConstants";


export const supplierRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIER_REGISTER_REQUEST:
      return { supplier_register_loading: true };
    case SUPPLIER_REGISTER_SUCCESS:
      return { supplier_register_loading: false,supplier_register_success: true, supplier_register: action.payload };
    case SUPPLIER_REGISTER_FAIL:
      return { supplier_register_loading: false, supplier_register_error: action.payload };
    case SUPPLIER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const supplierDetailsReducer = (state = { supplier: {} }, action) => {
  switch (action.type) {
    case SUPPLIER_DETAILS_REQUEST:
      return { ...state, supplier_details_loading: true };
    case SUPPLIER_DETAILS_SUCCESS:
      return { supplier_details_loading: false, supplier_details: action.payload };
    case SUPPLIER_DETAILS_FAIL:
      return { supplier_details_loading: false, supplier_details_error: action.payload };
    case SUPPLIER_DETAILS_RESET:
      return { supplier_details: {} };
    default:
      return state;
  }
};

export const supplierListReducer = (state = { suppliers_list: [] }, action) => {
  switch (action.type) {
    case SUPPLIER_LIST_REQUEST:
      return { suppliers_list_loading: true };
    case SUPPLIER_LIST_SUCCESS:
      return { suppliers_list_loading: false, suppliers_list: action.payload };
    case SUPPLIER_LIST_FAIL:
      return { suppliers_list_loading: false, suppliers_list_error: action.payload };
    case SUPPLIER_LIST_RESET:
      return { suppliers_list: [] };
    default:
      return state;
  }
};

export const supplierListByParentUserReducer = (state = { suppliers: [] }, action) => {
  switch (action.type) {
    case SUPPLIER_LIST_BY_PARENT_USER_REQUEST:
      return { supplier_list_by_parent_user_loading: true };
    case SUPPLIER_LIST_BY_PARENT_USER_SUCCESS:
      return { supplier_list_by_parent_user_loading: false, supplier_list_by_parent_user: action.payload , supplier_list_by_parent_user_success: true};
    case SUPPLIER_LIST_BY_PARENT_USER_FAIL:
      return { supplier_list_by_parent_user_loading: false, supplier_list_by_parent_user_error: action.payload };
    case SUPPLIER_LIST_BY_PARENT_USER_RESET:
      return { supplier_list_parent_user: [] };
    default:
      return state;
  }
};

export const supplierDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case SUPPLIER_DELETE_REQUEST:
      return { supplier_delete_loading: true };
    case SUPPLIER_DELETE_SUCCESS:
      return { supplier_delete_loading: false, supplier_delete_success: true };
    case SUPPLIER_DELETE_FAIL:
      return { supplier_delete_loading: false, supplier_delete_error: action.payload };
    default:
      return state;
  }
};

export const supplierUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case SUPPLIER_UPDATE_REQUEST:
      return { supplier_update_loading: true };
    case SUPPLIER_UPDATE_SUCCESS:
      return { supplier_update_loading: false, supplier_update_success: true };
    case SUPPLIER_UPDATE_FAIL:
      return { supplier_update_loading: false, supplier_update_error: action.payload };
    case SUPPLIER_UPDATE_RESET:
      return {
        supplier: {},
      };
    default:
      return state;
  }
};
