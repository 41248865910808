import axios from "axios";
import {
  WAREHOUSE_INFO_CREATE_REQUEST,
  WAREHOUSE_INFO_CREATE_SUCCESS,
  WAREHOUSE_INFO_CREATE_FAIL,
  WAREHOUSE_INFO_CREATE_RESET,
  WAREHOUSE_INFO_LIST_REQUEST,
  WAREHOUSE_INFO_LIST_SUCCESS,
  WAREHOUSE_INFO_LIST_FAIL,
  WAREHOUSE_INFO_UPDATE_REQUEST,
  WAREHOUSE_INFO_UPDATE_SUCCESS,
  WAREHOUSE_INFO_UPDATE_RESET,
  WAREHOUSE_INFO_UPDATE_FAIL,

} from "../constants/warehouseInfoConstants";

export const listAllWarehouses= () => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_INFO_LIST_REQUEST });
    const { data } = await axios.get("/api/warehouse-info");
    dispatch({
      type: WAREHOUSE_INFO_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WAREHOUSE_INFO_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createWarehouseInfos =
  (warehouseName,phoneNo,address,managerName,postalCode,managerEmail) => async (dispatch) => {
    
    console.log("createwarehouseInfo -start ", warehouseName,phoneNo,address,managerName,postalCode,managerEmail);
    try {
      dispatch({
        type: WAREHOUSE_INFO_CREATE_REQUEST,
      });

      dispatch({ type: WAREHOUSE_INFO_CREATE_REQUEST });
      const { data } = await axios.post(`/api/warehouse-info`, {
        warehouseName,
        phoneNo,
        address,
        managerName,
        postalCode,
        managerEmail
      });
      console.log("createwarehouseInfo - end ");
      dispatch({
        type: WAREHOUSE_INFO_CREATE_SUCCESS,
        payload: data,
      });
      dispatch({ type: WAREHOUSE_INFO_CREATE_RESET });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WAREHOUSE_INFO_CREATE_FAIL,
        payload: message,
      });
    }
  };

// export const updateWarehouseInfos = (id) => async (dispatch, getState) => {
//   console.log("EXEC update warehouseInfo : " + id);
// };

export const updateWarehouseInfos = 
( warehouseName, address, phoneNo, managerName, managerEmail, postalCode ) =>
async (dispatch) => {
  try {
    console.log("Exec updateWarehouseInfos from Action");
    console.log(
      warehouseName,
      phoneNo,
      address,
      managerName,
      managerEmail,
      postalCode
    );
    dispatch({
      type: WAREHOUSE_INFO_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/warehouse-info/${warehouseName}`, {
      address,
      phoneNo,
      managerName,
      managerEmail,
      postalCode,
    });

    dispatch({
      type: WAREHOUSE_INFO_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
    }
    dispatch({
      type: WAREHOUSE_INFO_UPDATE_FAIL,
      payload: message,
    });
  }
};
