import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import {  Grid, Tooltip } from "@mui/material";
import DomesticItemForm from "./DomesticItemForm";
import Section from "../organisms/Section/Section";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 'auto',
    margin: `0 auto`,
  },
  section: {
    height: "50vh",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const DomesticItemCreate = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { productId } = useParams();
  console.log("Create Domestic New Entry Screen...", productId);
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ margin: "1rem" }}>
            <Tooltip title="Back" arrow>
              <ArrowBackIcon fontSize="medium" onClick={() => window.history.back()} style={{ float: "left" }} />
            </Tooltip>
            <Tooltip title="Home" arrow>
              <HomeIcon fontSize="medium" onClick={() => navigate("/")} style={{ float: "right" }} />
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Section className={classes.section}>
            <div className={classes.formContainer}>
              <DomesticItemForm productId={productId} />
            </div>
          </Section>
        </Grid>
      </Grid>
    </div>
  );
};

export default DomesticItemCreate;
