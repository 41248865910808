import {
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_REQUEST,
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_SUCCESS,
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_FAIL,
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_RESET,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_REQUEST,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_SUCCESS,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_FAIL,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_RESET,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_REQUEST,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_SUCCESS,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_FAIL,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_RESET
  } from "../constants/walkinSaleReceiptConstants";

export const walkinSaleReceiptsListByStoreReducer = (state = { }, action) => {
  switch (action.type) {
    case WALKIN_SALES_RECEIPTS_LIST_BY_STORE_REQUEST:
      return { walkin_sale_receipts_by_store_list_loading: true, walkin_sale_receipts_by_store_list: [] };
    case WALKIN_SALES_RECEIPTS_LIST_BY_STORE_SUCCESS:
      return {
        walkin_sale_receipts_by_store_list_loading: false,
        walkin_sale_receipts_by_store_list: action.payload,
      };
    case WALKIN_SALES_RECEIPTS_LIST_BY_STORE_FAIL:
      return { walkin_sale_receipts_by_store_list_loading: false, walkin_sale_receipts_by_store_list_error: action.payload };
    default:
      return state;
  }
};

export const walkinSaleReceiptByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case WALKIN_SALE_RECEIPT_DETAILS_BY_ID_REQUEST:
      return { walkin_sale_receipt_by_id_loading: true, walkin_sale_receipt_by_id:{}  };
    case WALKIN_SALE_RECEIPT_DETAILS_BY_ID_SUCCESS:
      return {
        walkin_sale_receipt_by_id_loading: false,
        walkin_sale_receipt_by_id: action.payload,
      };
    case WALKIN_SALE_RECEIPT_DETAILS_BY_ID_FAIL:
      return { walkin_sale_receipt_by_id_loading: false, walkin_sale_receipt_by_id_error: action.payload };
    default:
      return state;
  }
};

export const walkinSaleReceiptCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WALKIN_SALE_RECEIPT_CREATE_BY_STORE_REQUEST:
      return { walkin_sale_create_by_store_receipt_loading: true };
    case WALKIN_SALE_RECEIPT_CREATE_BY_STORE_SUCCESS:
      return { walkin_sale_create_by_store_receipt_loading: false, walkin_sale_create_by_store_receipt_success: true, walkin_sale_create_by_store_receipt : action.payload };
    case WALKIN_SALE_RECEIPT_CREATE_BY_STORE_FAIL:
      return { walkin_sale_create_by_store_receipt_loading: false, walkin_sale_create_by_store_receipt_error: action.payload };
    case WALKIN_SALE_RECEIPT_CREATE_BY_STORE_RESET:
      return {};
    default:
      return state;
  }
};



