import {
  WAREHOUSE_INFO_LIST_REQUEST,
  WAREHOUSE_INFO_LIST_SUCCESS,
  WAREHOUSE_INFO_LIST_FAIL,
  
  WAREHOUSE_INFO_CREATE_RESET,
  WAREHOUSE_INFO_CREATE_FAIL,
  WAREHOUSE_INFO_CREATE_SUCCESS,
  WAREHOUSE_INFO_CREATE_REQUEST,
} from "../constants/warehouseInfoConstants";

export const warehouseInfoListReducer = (state = { warehouseInfos: [] }, action) => {
  switch (action.type) {
    case WAREHOUSE_INFO_LIST_REQUEST:
      return { loading_wh_infos: true, warehouseInfos: [] };
    case WAREHOUSE_INFO_LIST_SUCCESS:
      return {
        loading_wh_infos: false,
        warehouseInfos: action.payload,
      };
    case WAREHOUSE_INFO_LIST_FAIL:
      return { loading_wh_infos: false, wh_infos_error: action.payload };
    default:
      return state;
  }
};

export const warehouseInfoCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_INFO_CREATE_REQUEST:
      return { wh_info_loading: true };
    case WAREHOUSE_INFO_CREATE_SUCCESS:
      return { wh_info_loading: false, wh_info_create_success: true, wh_info_data: action.payload };
    case WAREHOUSE_INFO_CREATE_FAIL:
      return { wh_info_loading: false, wh_info_error: action.payload };
    case WAREHOUSE_INFO_CREATE_RESET:
      return {wh_info_loading: false};
    default:
      return state;
  }
};



