export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";
export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_UPDATE_REQUEST = "ORDER_UPDATE_REQUEST";
export const ORDER_UPDATE_SUCCESS = "ORDER_UPDATE_SUCCESS";
export const ORDER_UPDATE_FAIL = "ORDER_UPDATE_FAIL";
export const ORDER_UPDATE_RESET = "ORDER_UPDATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_LIST_REQUEST = "ORDER_LIST_REQUEST";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAIL = "ORDER_LIST_FAIL";

export const ORDER_DELIVER_REQUEST = "ORDER_DELIVER_REQUEST";
export const ORDER_DELIVER_SUCCESS = "ORDER_DELIVER_SUCCESS";
export const ORDER_DELIVER_FAIL = "ORDER_DELIVER_FAIL";
export const ORDER_DELIVER_RESET = "ORDER_DELIVER_RESET";

export const LIST_MY_ORDERS_REQUEST = "LIST_MY_ORDERS_REQUEST";
export const LIST_MY_ORDERS_SUCCESS = "LIST_MY_ORDERS_SUCCESS";
export const LIST_MY_ORDERS_FAIL = "LIST_MY_ORDERS_FAIL";

export const ORDER_PLACED = "ORDER PLACED";
export const ORDER_CONFIRMED = "ORDER CONFIRMED";
export const ORDER_PACKED = "ORDER PACKED";
export const WAITING_FOR_PICKUP = "WAITING FOR PICKUP";
export const OUT_FOR_DELIVERY = "OUT FOR DELIVERY";
export const DELIVERED = "DELIVERED";

export const ORDER_DELETE_REQUEST = "ORDER_DELETE_REQUEST";
export const ORDER_DELETE_SUCCESS = "ORDER_DELETE_SUCCESS";
export const ORDER_DELETE_FAIL = "ORDER_DELETE_FAIL";
export const ORDER_DELETE_RESET = "ORDER_DELETE_RESET";

export const ORDER_LIST_BY_STORE_REQUEST = "ORDER_LIST_BY_STORE_REQUEST";
export const ORDER_LIST_BY_STORE_SUCCESS = "ORDER_LIST_BY_STORE_SUCCESS";
export const ORDER_LIST_BY_STORE_FAIL = "ORDER_LIST_BY_STORE_FAIL";
export const ORDER_LIST_BY_STORE_RESET = "ORDER_LIST_BY_STORE_RESET";

export const ORDER_LIST_BY_MAPPED_STORE_REQUEST = "ORDER_LIST_BY_MAPPED_STORE_REQUEST";
export const ORDER_LIST_BY_MAPPED_STORE_SUCCESS = "ORDER_LIST_BY_MAPPED_STORE_SUCCESS";
export const ORDER_LIST_BY_MAPPED_STORE_FAIL = "ORDER_LIST_BY_MAPPED_STORE_FAIL";
export const ORDER_LIST_BY_MAPPED_STORE_RESET = "ORDER_LIST_BY_MAPPED_STORE_RESET"

export const ORDER_TRANSFER_REQUEST = "ORDER_TRANSFER_REQUEST";
export const ORDER_TRANSFER_SUCCESS = "ORDER_TRANSFER_SUCCESS";
export const ORDER_TRANSFER_FAIL = "ORDER_TRANSFER_FAIL";
export const ORDER_TRANSFER_RESET = "ORDER_TRANSFER_RESET";


export const ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_REQUEST =
  "ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_REQUEST";
export const ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_SUCCESS =
  "ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_SUCCESS";
export const ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_FAIL =
  "ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_FAIL";
export const ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_RESET =
  "ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_RESET";

  export const ORDER_INVOICE_GENERATE_EMAIL_REQUEST =
  "ORDER_INVOICE_GENERATE_EMAIL_REQUEST";
export const ORDER_INVOICE_GENERATE_EMAIL_SUCCESS =
  "ORDER_INVOICE_GENERATE_EMAIL_SUCCESS";
export const ORDER_INVOICE_GENERATE_EMAIL_FAIL =
  "ORDER_INVOICE_GENERATE_EMAIL_FAIL";
export const ORDER_INVOICE_GENERATE_EMAIL_RESET =
  "ORDER_INVOICE_GENERATE_EMAIL_RESET";
