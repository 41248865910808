import {
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_DELETE_BY_ID_FAIL,
  WAREHOUSE_STOCK_DELETE_BY_ID_REQUEST,
  WAREHOUSE_STOCK_DELETE_BY_ID_SUCCESS,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET,
  
  WAREHOUSE_STOCK_IN_BY_WAREHOUSE_NAME_UPDATE_REQUEST,
  WAREHOUSE_STOCK_IN_BY_WAREHOUSE_NAME_UPDATE_FAIL,
  WAREHOUSE_STOCK_IN_BY_WAREHOUSE_NAME_UPDATE_SUCCESS,
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_FAIL,
  WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_FAIL
} from "../constants/warehouseStockConstants";

export const warehouseStockListByWarehouseNameReducer = (
  state = { wh_stock_data: {} },
  action
) => {
  switch (action.type) {
    case WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_REQUEST:
      return { wh_stock_loading: true, wh_stock_data:{}  };
    case WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_SUCCESS:
      return {
        wh_stock_loading: false,
        wh_stock_data: action.payload,
      };
    case WAREHOUSE_STOCK_LIST_BY_WAREHOUSE_NAME_FAIL:
      return { wh_stock_loading: false, wh_stock_error: action.payload };
    default:
      return state;
  }
};

export const warehouseStockInUpdateByWarehouseNameReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_REQUEST:
      return { wh_stock_in_update_loading: true };
    case WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_SUCCESS:
      return { wh_stock_in_update_loading: false, wh_stock_in_update_success: true, wh_stock_in_update_data: action.payload };
    case WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_FAIL:
      return { wh_stock_in_update_loading: false, wh_stock_in_update_error: action.payload };
    case WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET:
      return {};
    default:
      return state;
  }
};

export const warehouseStockUpdateByWarehouseNameReducer = (state = { warehousePurchaseOrder: {} }, action) => {
  switch (action.type) {
    case WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_REQUEST:
      return { wh_stock_update_loading: true };
    case WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_SUCCESS:
      return { wh_stock_update_loading: false, success: true, wh_stock_update_success: action.payload };
    case WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_FAIL:
      return { lwh_stock_update_loading: false, wh_stock_update_error: action.payload };
    case WAREHOUSE_STOCK_OUT_UPDATE_BY_WAREHOUSE_NAME_RESET:
      return { wh_stock: {} };
    default:
      return state;
  }
};

export const warehouseStockReturnByWarehouseNameReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_REQUEST:
      return { wh_stock_return_loading: true };
    case WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_SUCCESS:
      return { wh_stock_return_loading: false, wh_stock_return_success: true, wh_stock_return_data: action.payload };
    case WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_FAIL:
      return { wh_stock_return_loading: false, wh_stock_return_error: action.payload };
    case WAREHOUSE_STOCK_IN_RETURN_BY_WAREHOUSE_NAME_RESET:
      return {};
    default:
      return state;
  }
};

