import axios from "axios";
import {
  ORDER_RECEIPT_CREATE_BY_STORE_REQUEST,
  ORDER_RECEIPT_CREATE_BY_STORE_SUCCESS,
  ORDER_RECEIPT_CREATE_BY_STORE_RESET,
  ORDER_RECEIPT_CREATE_BY_STORE_FAIL,
  ORDER_RECEIPT_LIST_BY_STORE_REQUEST,
  ORDER_RECEIPT_LIST_BY_STORE_SUCCESS,
  ORDER_RECEIPT_LIST_BY_STORE_RESET,
  ORDER_RECEIPT_LIST_BY_STORE_FAIL,
  ORDER_RECEIPT_DETAILS_BY_ID_REQUEST,
  ORDER_RECEIPT_DETAILS_BY_ID_SUCCESS,
  ORDER_RECEIPT_DETAILS_BY_ID_FAIL,
  ORDER_RECEIPT_DETAILS_BY_ID_RESET
} from "../constants/orderReceiptConstants";

export const createOrderReceipt = (order) => async (dispatch, getState) => {
  console.log("Inside createOrderReceipt ...", order);
  const { id, update_payment_details  } = order
  const { store  } = update_payment_details
  try {
    dispatch({
      type: ORDER_RECEIPT_CREATE_BY_STORE_REQUEST,
    });

    const config = {
    };

    const { data } = await axios.post(`/api/orders-receipts/${store}/${id}`, { update_payment_details }, config);

    dispatch({
      type: ORDER_RECEIPT_CREATE_BY_STORE_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    
    dispatch({
      type: ORDER_RECEIPT_CREATE_BY_STORE_FAIL,
      payload: message,
    });
  }
};

export const listOrdersReceiptsByStore = (pos) => async (dispatch, getState) => {
  console.log("Listing Orders Receipts By Store ", pos);
  try {
    dispatch({
      type: ORDER_RECEIPT_LIST_BY_STORE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/orders-receipts/store/${pos}`, config);

    dispatch({
      type: ORDER_RECEIPT_LIST_BY_STORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ORDER_RECEIPT_LIST_BY_STORE_FAIL,
      payload: message,
    });
  }
};

export const getOrderReceiptById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_RECEIPT_DETAILS_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders-receipts/id/${id}`, config);

    dispatch({
      type: ORDER_RECEIPT_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_RECEIPT_DETAILS_BY_ID_FAIL,
      payload: message,
    });
  }
};



