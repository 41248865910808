import React, { useState, useEffect, useMemo , useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {
  Divider,
  Autocomplete,
} from "@mui/material";
import { createStockByVehicleNoAndProductName } from "../../../actions/vehicleStockAction";
import { updateStockOutByWarehouseProduct } from "../../../actions/warehouseStockAction";
import { updateStockOutByStoreProduct } from "../../../actions/storeStockAction";
import { listStockByWarehouseName } from "../../../actions/warehouseStockAction";
import { listStockByStoreName } from "../../../actions/storeStockAction";
import { listUsers } from "../../../actions/userAction";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper
} from "@mui/material";
import { WAREHOUSE_INVOICE_CREATE_RESET } from "../../../constants/warehouseInvoiceConstants";
import {
  service_states,
  payment_modes,
  unit_of_meassures,
  warehouses,
  warehouse_store,
  warehouse_store_vehicle,
  stock_transfer_invoice_status,
  vehicle_shifts,
} from "../../../data/index";
import { VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET } from "../../../constants/vehicleStockConstants";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { listAllVehicles} from "../../../actions/vehicleAction";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const VehicleSalesStockToVehicleCreateScreen = ({ match }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vertical = 'top';
    const horizontal = 'center';
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const [open, setOpen] = React.useState(false);
  
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName } = userInfo;

  const [maxQuantityAllowed, setMaxQuantityAllowed] = React.useState("");
  const [maxQuantityAllowedErrorMessage, setMaxQuantityAllowedErrorMessage] = React.useState("");
  let gridApi = "";
  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const handleClose = () => {
    setOpen(false);
    if (vehicle_stock_in_update_success) {
        dispatch({ type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET });
        navigate("/");
    }
  };

  let renderContentUsingAGGrid = "";
  const columnDefs = [
    {
      field: "SNo",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    // {
    //   field: "Damaged",
    //   headerName: "Damaged",
    //   resizable: true,
    //   suppressSizeToFit: true, 
    //   width: 125,
    //   minWidth: 125,
    //   maxWidth: 125
    // },
    {
      field: "UnitPrice",
      headerName: "UnitPrice",
      resizable: true,
      suppressSizeToFit: true, width: 180,
      minWidth: 180,
      maxWidth: 180
    },
    // {
    //   field: "Tax",
    //   headerName: "Tax%",
    //   resizable: true,
    //   suppressSizeToFit: true, width: 150,
    //   minWidth: 150,
    //   maxWidth: 150
    // },
    {
      field: "Amount",
      headerName: "Amount",
      resizable: true,
      suppressSizeToFit: true, width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "Delete", resizable: true, suppressSizeToFit: true, width: 125, minWidth: 125, maxWidth: 125,
      cellRenderer : (params) => (
        <span><Tooltip title="Delete" arrow>
          <DeleteRoundedIcon
            size="small"
            variant="contained"
            type="submit"
            color="Gray"
            justify="center"
            onClick={() => {
              console.log("Deleting ", params);
              console.log("productsTobeAddedInInvoice : ", productsTobeAddedInInvoice);
              console.log(" Item to be Deleted at Index : ", Number(params.data.SNo) - 1);
              console.log(" Removed : ", productsTobeAddedInInvoice.splice(Number(params.data.SNo) - 1, 1));
              setProductsTobeAddedInInvoice(() => [...productsTobeAddedInInvoice]);
              console.log("After Removal , Length of productsTobeAddedInInvoice : ", productsTobeAddedInInvoice.length);
            }
            }
            style={{ marginRight: "1rem", width: "2rem" }}
          ></DeleteRoundedIcon>
        </Tooltip></span>
      ), sortable: false,
      filter: false,
      floatingFilter: false
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );

  const [productsTobeAddedInInvoice, setProductsTobeAddedInInvoice] = useState(
    () => []
  );
  const [billDate, setBillDate] = useState(new Date());

  const [productFormInputs, setProductFormInputs] = useState({
    name: "",
    quantity: "",
    pricePerUnit: "",
    tax: "",
    total: 0.0,
    subCategory: "",
    unit: 0,
  });

  const [invoiceFormInputs, setInvoiceFormInputs] = useState({
    description: "",
    total: 0.0,
    vehicleNo: "",
    driverContact: "",
    driverName: "",
    fromName: "",
    fromWarehouseStoreValue: "",
    toName: "",
    toVehicleName: "",
    stockTransferInvoiceStatus: "",
    billDate: "",
    shift: "",
  });
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const [autoCompleteValue, setAutoCompleteValue] = useState(() => "");
  const [autoCompleteFromWhStoreValue, setAutoCompleteFromWhStoreValue] =
    useState(() => "");
  const [
    autoCompleteToVehicle,
    setAutoCompleteToVehicle,
  ] = useState(() => "");
  const warehouseStockList = useSelector((state) => state.warehouseStockListByWarehouseName);
  const { wh_stock_data } = warehouseStockList;
  const storeStockList = useSelector((state) => state.storeStockListByStoreName);
  const { store_stock_data } = storeStockList;
  const vehicleList = useSelector((state) => state.vehicleList);
  const { vehicles } = vehicleList;
  
  // const availableVehicleList = useSelector((state) => state.availableVehicleList);
  // const { vehicles } = availableVehicleList;
  const warehouseInvoiceCreate = useSelector((state) => state.warehouseInvoiceCreate);
  const warehouseStockUpdated = useSelector((state) => state.warehouseStockUpdated);
  const storeStockUpdated = useSelector((state) => state.storeStockUpdated);
  const { store_stock_update_success } = storeStockUpdated;
  const { success, wh_stock_update_success } = warehouseStockUpdated;
  const vehicleStockInUpdated = useSelector((state) => state.vehicleStockInUpdated);
  const { vehicle_stock_in_update_loading, vehicle_stock_in_update_success, vehicle_stock_in_update_data, vehicle_stock_in_update_error } = vehicleStockInUpdated;
  let rowData = [];
  const {
    wi_create_loading,
    wi_create_success,
    wi_create_data,
    wi_create_error,
  } = warehouseInvoiceCreate;

  let customProducts = [];
  let customVehicles = [];
  let vehicleIncharges = [];
  let drivers = [];
    if (wh_stock_data && wh_stock_data.length > 0) {
      customProducts = wh_stock_data;
      console.log('wh_stock_data : ', wh_stock_data);
    }
    if (store_stock_data && store_stock_data.length > 0) {
      customProducts = store_stock_data;
      console.log('store_stock_data : ', store_stock_data);
    }

  if (vehicles && vehicles.length > 0) customVehicles = vehicles;
  if (users && users.length > 0) {
    vehicleIncharges = users.filter(each => each.role === "VEHICLE_SALES_INCHARGE_ROLE");
    drivers = users.filter(each => each.role === "DRIVER_ROLE");
  }

  useEffect(() => {
    dispatch(listAllVehicles());
    dispatch(listUsers());
  }, [dispatch]);

  useEffect(() => {
    if (wi_create_success || success || store_stock_update_success) {
      console.log(
        "wi_create_data Invoice ID :==> ",
        wi_create_success,
        wi_create_data
      );
      dispatch({ type: WAREHOUSE_INVOICE_CREATE_RESET });
      navigate("/dashboard");
    }
  }, [
    dispatch,
    navigate,
    wi_create_success,
    success,
    wh_stock_update_success,
    productsTobeAddedInInvoice,
    store_stock_update_success
  ]);

  useEffect(() => {
    if (vehicle_stock_in_update_success) {
      console.log(
        "vehicle_stock_in_update_success  :==> ",
        vehicle_stock_in_update_success
      );
      setOpen(() => true);
    }
  }, [ vehicle_stock_in_update_success ]);

  useEffect(() => {
    if (storeName && storeName.length > 0 && storeName.includes("STORE")) {
      console.log(" DISPLAYING THE Products from LOGGED in Store : ", storeName);
      dispatch(listStockByStoreName(storeName));
    }
  }, [dispatch, storeName]);

useEffect(() => {
  if (warehouseName && warehouseName.length > 0 && warehouseName.includes("WAREHOUSE")) {
    console.log(" DISPLAYING THE Products from LOGGED IN Warehouse : ", warehouseName);
    dispatch(listStockByWarehouseName(warehouseName));
  }
}, [dispatch, warehouseName]);


  const handleProductChange = (event) => {
    console.log('maxQuantityAllowed : ', maxQuantityAllowed)
    setMaxQuantityAllowedErrorMessage(() => "");
    setProductFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    if (Number(event.target.value) > Number(maxQuantityAllowed)){
      console.log('Number(event.target.value) > Number(maxQuantityAllowed is TRUE')
      setMaxQuantityAllowedErrorMessage(`Max Allowed Quantity is :  ${maxQuantityAllowed}`)
    }else
      console.log('Number(event.target.value) > Number(maxQuantityAllowed is FALSE')
  };

  const handleInvoiceChange = (event) => {
    setInvoiceFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCreate = (e) => {
    e.preventDefault();
    const tempSourceName = storeName ? storeName : warehouseName ? warehouseName: ""
    const tempVehicleNo = autoCompleteToVehicle?autoCompleteToVehicle.vehicleNo :""
    console.log(
      "From Source : ",tempSourceName, "Target | Destination Vehicle : ", tempVehicleNo);
    // const fromWarehouseStoreValueName = storeName ? storeName : warehouseName ? warehouseName: ""
    setInvoiceFormInputs((prevState) => ({
          ...prevState,
          fromWarehouseStoreValue: tempSourceName,
          toVehicleName: tempVehicleNo,
        }));
    
    handleCreateInvoice(
      invoiceFormInputs,
      productsTobeAddedInInvoice,
      tempSourceName,
      tempVehicleNo

    );
  };

  const handleCreateInvoice = (
    invoiceFormInputs,
    purchaseOrderProducts,
    fromWarehouseStoreValue,
    targetVehicleNo   
  ) => {
    console.log(
      "handleCreateInvoice - START",
      invoiceFormInputs,
      purchaseOrderProducts?purchaseOrderProducts.length:0,
      fromWarehouseStoreValue,
      targetVehicleNo );

    {
      /** 
        LOGIC FOR STOCK IN TO VEHICLE... NEEDS TO BE HANDLED HERE.!!!
      */
    }
    console.log(" ********* From Name : ", fromWarehouseStoreValue," ********* ");
    console.log(" ********* To Vehicle No : ", targetVehicleNo , " ********* ");
    if (targetVehicleNo && targetVehicleNo.length > 0) {
      console.log(" ******* NOW DISPATCHING TO createStockByVehicleNoAndProductName ******* ");
      dispatch(
        createStockByVehicleNoAndProductName(
          invoiceFormInputs,
          purchaseOrderProducts,
          fromWarehouseStoreValue,
          targetVehicleNo
        )
      );
    }

    /** 
      LOGIC FOR STOCK OUT OF WH... IS HANDLED HERE.!!!
    */
      // fromWarehouseStoreValue
    if (fromWarehouseStoreValue && fromWarehouseStoreValue.includes('WAREHOUSE')) {
      console.log(" ******* NOW DISPATCHING TO updateStockOutByWarehouseProduct ******* ");
      console.log(" ******* Need to update Stock By WarehouseName And ProductName from Source Warehouse ******* ");
      dispatch(
        updateStockOutByWarehouseProduct(
          invoiceFormInputs,
          purchaseOrderProducts,
          fromWarehouseStoreValue
        )
      );
    }
    /** 
     LOGIC FOR STOCK OUT OF STORE... IS HANDLED HERE.!!!
   */
     if (fromWarehouseStoreValue && fromWarehouseStoreValue.includes('STORE')) {
      console.log(" ******* NOW DISPATCHING TO updateStockOutByStoreProduct ******* ");
      console.log(" ******* Need to update Stock By Store Name And ProductName from Source Store ******* ");
      dispatch(
        updateStockOutByStoreProduct(
          invoiceFormInputs,
          purchaseOrderProducts,
          fromWarehouseStoreValue
        )
      );
    }
  };

  const handleDelete = (index) => {
    console.log("handleDelete Exec... Index : " + index);
    setProductsTobeAddedInInvoice(
      productsTobeAddedInInvoice.filter((prd, i) => {
        console.log("index : ", index, " , i : ", i);
        return i !== index;
      })
    );
  };

  const handleClearInvoiceForm = () => {
    setInvoiceFormInputs((prevState) => {
      return {
        driverContact: 0,
        description: "",
        roundOff: 0.0,
        total: 0.0,
        stateOfSupply: "",
        paymentType: "",
        storeName: "",
        warehouseName: "",
      };
    });
  };

  const handleClearProductForm = () => {
    setProductFormInputs(() => {
      return {
        name: "",
        quantity: "",
        // damaged: "",
        pricePerUnit: "",
        tax: "",
        taxAmount: "",
        subCategory: "",
        unit: 0,
        total: 0.0,
      };
    });
  };

  const handleAddProduct = () => {
    console.log(
      "SOURCE  : ",
      storeName ? storeName : warehouseName ? warehouseName: "",
      ", autoCompleteToVehicle : ",
      autoCompleteToVehicle,
      "autoCompleteValue : ",
      autoCompleteValue
    );


    setProductsTobeAddedInInvoice([
      ...productsTobeAddedInInvoice,
      {
        name: autoCompleteValue.productName,
        quantity: productFormInputs.quantity,
        pricePerUnit: productFormInputs.pricePerUnit,
        tax: productFormInputs.tax,
        isTaxable: true,
        total:
          productFormInputs.quantity * productFormInputs.pricePerUnit +
          (productFormInputs.tax / 100) *
          (productFormInputs.quantity * productFormInputs.pricePerUnit),
        subCategory: "",
      },
    ]);
    handleClearProductForm();
  };

  const handleDisabled = () => {
    console.log(
      "autoCompleteToVehicle === Vehicle : ",
      autoCompleteToVehicle
    );
    return autoCompleteToVehicle === "Vehicle" ? true : false;
  };

  const showMaximumQuantityAllowedMessage = (prd) => {
    console.log("Getting the Max Quantity Allowed for Product : ", prd);
    let selectedProductsQuantity = null;
      if (wh_stock_data && wh_stock_data.length > 0 && prd && prd.productName && prd.productName.length>0) {
        selectedProductsQuantity = wh_stock_data.filter(e => e.productName === prd.productName);
        if (selectedProductsQuantity && selectedProductsQuantity.length > 0) {
          console.log("selectedProductsQuantity : ", selectedProductsQuantity[0]);
          setMaxQuantityAllowed(()=>selectedProductsQuantity[0].countInStock);
          console.log("Max Quantity that can be Selected from WH : ", maxQuantityAllowed);
        }
      } else {
        console.log("Failed Getting the Max Quantity Allowed from WH ", invoiceFormInputs.fromName, "for Product : ", prd, " As wh_stock_data is null");
      }
      if (store_stock_data && store_stock_data.length > 0 && prd && prd.productName && prd.productName.length>0) {
        selectedProductsQuantity = store_stock_data.filter(e => e.productName === prd.productName);
        if (selectedProductsQuantity && selectedProductsQuantity.length > 0) {
          console.log("selectedProductsQuantity : ", selectedProductsQuantity[0]);
          setMaxQuantityAllowed(()=>selectedProductsQuantity[0].countInStock);
          console.log("Max Quantity that can be Selected from Store : ", maxQuantityAllowed);
        }
      } else {
        console.log("Failed Getting the Max Quantity Allowed  from STore ", invoiceFormInputs.fromName, "for Product : ", prd, " As store_stock_data is null");
      }

    // setMaxQuantityAllowed(() => maxQuantityAllowed);
  };

  let renderContent = "";

  if (productsTobeAddedInInvoice && productsTobeAddedInInvoice.length > 0) {
    rowData = productsTobeAddedInInvoice.map((eachProduct, index) => {
      return {
        "SNo": index + 1,
        ProductName: eachProduct.name,
        Quantity: eachProduct.quantity,
        UnitPrice: eachProduct.pricePerUnit,
        // Tax: eachProduct.tax,
        Amount: eachProduct.eachProductPrice ? eachProduct.eachProductPrice : 0.0,
        Delete: eachProduct._id,
      };
    });
    if (rowData) {
      renderContentUsingAGGrid = (
        <div
          className="ag-theme-quartz"
          style={{ width: "60%", height: "65vh",marginLeft:"auto", marginRight:"auto" }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            onGridReady={onGridReady}
            pagination="true"
            Quantity
          ></AgGridReact>
        </div>
      );
    }
  }

  return (
    <div className={classes.root}>
      {vehicle_stock_in_update_loading && <CustomBackdropSpinner />}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
              <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" ,alignItems:"center"}}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center",textTransform:'none' }}
                  color="black"
                >
                    Loading Stock from {storeName?storeName : warehouseName? warehouseName: ""} for Vehicle Sales
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <GridContainer spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} >
                            <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                                    <img
                                        alt="Order Placed"
                                        src={greeTickImage}
                                        style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                                    />
                                </div>
                                <Typography
                                    variant="h5"
                                    color="black"
                                    style={{
                                        margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                                        fontWeight: "600",
                                        fontSize: "1rem",
                                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                    }}
                                >
                                    Updated Payment Details
                                </Typography>
                            </Paper>
                        </Grid>
                    </GridContainer>
                </DialogContent>
            </BootstrapDialog>
        {/** VEHICLE DETAILS PANEL */}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem" }}
                  color="black"
                >
                  Vehicle Details
                </Typography>
              </div>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log("Currently Selected :", value);
                        setAutoCompleteToVehicle(value);
                        if (invoiceFormInputs.toName === "Vehicle") {
                          setInvoiceFormInputs((prevState) => ({
                            ...prevState,
                            vehicleNo: value.vehicleNo,
                          }));
                        }
                      }}
                      id="combo-box-products"
                      options={
                        customVehicles
                      }
                      size="small"
                      style={{ width: "12rem" }}
                      getOptionLabel={(option) =>
                        `${option.vehicleNo}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Select To"
                          size="small"
                          value={invoiceFormInputs.toVehicleName}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log("Currently Selected Vehicle Incharge : ", value);
                        setInvoiceFormInputs((prevState) => ({
                          ...prevState,
                          vehicleInchargeName: value && value.name ? value.name : "",
                          vehicleInchargeContact: value && value.contactNo ? value.contactNo : "",
                        }));
                      }}
                      id="combo-box-products"
                      options={
                        vehicleIncharges
                      }
                      size="small"
                      style={{ width: "12rem" }}
                      getOptionLabel={(option) =>
                        `${option.name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Vehicle Incharge"
                          placeholder="Select Vehicle Incharge"
                          size="small"
                          value={invoiceFormInputs.vehicleInchargeName}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log("Currently Selected Driver Name : ", value);
                        setInvoiceFormInputs((prevState) => ({
                          ...prevState,
                          driverName: value && value.name ? value.name : "",
                          driverContact: value && value.contactNo ? value.contactNo : "",
                        }));
                      }}
                      id="combo-box-products"
                      options={
                        drivers
                      }
                      size="small"
                      style={{ width: "12rem" }}
                      getOptionLabel={(option) =>
                        `${option.name}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Driver Name"
                          placeholder="Select Driver Name"
                          size="small"
                          value={invoiceFormInputs.driverName}
                        />
                      )}
                    />
                  </Grid>
                 
                  {/* {invoiceFormInputs.toName && invoiceFormInputs.toName === 'Vehicle' && (<Grid item xs={12} sm={4} md={3}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Shift
                      </InputLabel>
                      <Select
                        label="Shift"
                        placeholder="Shift"
                        variant="outlined"
                        required
                        size="small"
                        style={{ width: "12rem" }}
                        name="shift"
                        onChange={handleInvoiceChange}
                        value={invoiceFormInputs.shift}
                      >
                        {renderDynamicOptions("vehicle_shifts")}
                      </Select>
                    </FormControl>
                  </Grid>)} */}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/** VEHICLE DETAILS END */}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Product Details
                <Divider></Divider>
              </Typography>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log('value : ', value);
                        showMaximumQuantityAllowedMessage(value)
                        setAutoCompleteValue(value);
                        return;
                      }}
                      id="combo-box-products"
                      options={customProducts}
                      fullWidth
                      size="small"
                      // getOptionLabel={(option) => {
                      //   console.log('option : ',option);
                      //   return `${option.productName}`}}
                      getOptionLabel={(option) => `${option && option.productName && option.productName.length>0 ? option.productName : "" }`}
                      renderInput={(params) => (
                        // <TextField
                        //   {...params}
                        //   label="Select Product"
                        //   size="small"
                        //   value={productFormInputs.name}
                        // />
                        <TextField
                          {...params}
                          label="Select Product"
                          size="small"
                          value={productFormInputs.name}
                        />
                      )}
                    />
                  </Grid>
                   <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Quantity"
                      variant="outlined"
                      size="small"
                      name="quantity"
                      onChange={handleProductChange}
                      type="number"
                      value={productFormInputs.quantity || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Max Quantity allowed "
                      variant="outlined"
                      size="small"
                      disabled={true}
                      type="text"
                      value={maxQuantityAllowed || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Typography
                      variant="body2"
                      style={{ margin: "0.5rem", fontWeight: "bold", verticalAlign: "middle", justifyContent: "center", alignItems: "center" }}
                      color="red"
                    >{maxQuantityAllowedErrorMessage}</Typography>
                  </Grid>
                  <Grid item xs={12} align="center">
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      justify="center"
                      disabled={maxQuantityAllowedErrorMessage !== "" ? true : false}
                      onClick={handleAddProduct}
                      style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                    >
                      Add Product
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer spacing={2} style={{marginLeft : "auto", marginRight : "auto"}}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    {/* <TextField
                      label="Description"
                      variant="outlined"
                      size="large"
                      required
                      multiline
                      fullWidth
                      rows="3"
                      name="description"
                      onChange={handleInvoiceChange}
                      type="text"
                      value={invoiceFormInputs.description || ""}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Grid item xs={12} align="center">
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        color="secondary"
                        style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                        disabled={
                          !(
                            productsTobeAddedInInvoice &&
                            productsTobeAddedInInvoice.length > 0 &&
                            maxQuantityAllowedErrorMessage === ""
                          )
                        }
                        justify="center"
                        onClick={handleCreate}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    </div>
  );
};

export default VehicleSalesStockToVehicleCreateScreen;
