import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Typography,
  Button,
  Icon,
  Tooltip,
} from "@mui/material";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {  listOrdersByMappedStore } from "../../actions/orderAction";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import rupeeSvgIcon from "../../assets/images/currency-inr.svg";
import PaymentIcon from '@mui/icons-material/Payment';
import {convertInvoiceIdToDigits} from '../../util/Utility.js'

const OnlineOrdersListScreen = ({ match }) => {
  const [rowData, setRowData] = useState(() => [])
  const [onlineRowData, setOnlineRowData] = useState(() => [])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef();  
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const pos = storeName ? storeName : warehouseName ? warehouseName : ''

  const orderList = useSelector((state) => state.orderList);
  const { loading, error_loading_orders, orders } = orderList;

  const ordersListByMappedStore = useSelector((state) => state.ordersListByMappedStore);
  const { orders_mapped_to_store_loading, orders_mapped_to_store_error , orders_mapped_to_store } = ordersListByMappedStore;

  
  let renderContentUsingAGGrid = "";

  const routePaymentDetails = (id) => {
    console.log('ID Passed to routePaymentDetails : ', id)
    navigate(`/admin/online-order/payment/${id}`);
  };

  const populateOnlineOrdersRowData = () => {
    console.log("Populating Online Orders")
    if(orders_mapped_to_store && orders_mapped_to_store.length>0){
       setRowData((prev) => {
      return orders_mapped_to_store.map((eachOrder, index) => {
        const totalAmountPaid = eachOrder.paymentDetails.cashAmountPaid ? Number(eachOrder.paymentDetails.cashAmountPaid) : 0 + eachOrder.paymentDetails.upiAmountPaid ? Number(eachOrder.paymentDetails.upiAmountPaid) : 0
        const dueOrPaid = (eachOrder.totalPrice - totalAmountPaid) > 0 ? "DUE" : "PAID";
        const invIdInDecimal =convertInvoiceIdToDigits(eachOrder._id)
        const totalPrice = eachOrder && eachOrder.paymentDetails && eachOrder.paymentDetails.totalPrice ?Number(eachOrder.paymentDetails.totalPrice).toFixed(2) : 0.0 
        return {
          "#": index + 1,
          "Id":eachOrder._id,
          "Order No" : invIdInDecimal,
          "Store Name": eachOrder.store,
          "Customer Name": eachOrder.user
            ? eachOrder.user.name
              ? eachOrder.user.name
              : ""
            : "",
          "Total Price": totalPrice,
          "Payment / Receipts Amount": totalAmountPaid ? Number(totalAmountPaid):0.0,
          "Payment_Receipts_Status": dueOrPaid,
          "Order Date": eachOrder.billDate
            ? new Date(eachOrder.billDate).toLocaleString()
            : eachOrder.createdAt ? new Date(eachOrder.createdAt).toLocaleString() : ""
        };
      }
      )
    }
    );
    }else
      setRowData((prev) => [])
  }

  const showOrderDetails = (id) => {
    console.log("showOrderDetails : ", id);

    console.log(` ###### Now Routing to Order Details Screen ######  ${id}`);
    navigate(`/admin/order-details/${id}`);
  };

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Id",
      headerName: "Id",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "Order No",
      headerName: "Order No",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "Store Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "Customer Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
    },
    {
      field: "Total Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "Order Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "Payment / Receipts Amount",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 300,
      cellStyle: params => {
          return { textAlign: "right" };
      },
    },
    {
      field: "Payment_Receipts_Status",
      headerName: 'Payment / Receipts Status',
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 300,
      cellStyle: params => {
        if (params.value === 'DUE') {
          return { color: 'red', backgroundColor: 'white', textAlign: "center" };
        }
        else if (params.value === 'PAID') {
          return { color: 'green', backgroundColor: 'white', textAlign: "center" };
        }
        return null;
      },
      minWidth: 250,
      maxWidth: 250
    },
    {
        field: "Id",
        headerName: "Details",
        resizable: true,
        suppressSizeToFit: true,
        cellRenderer: (params) => (
          <span>
            <Tooltip title="Details" arrow>
              <ReadMoreIcon
                size="small"
                variant="contained"
                type="submit"
                color="Gray"
                justify="center"
                onClick={() => showOrderDetails(params.data.Id)}
                style={{ marginRight: "1rem", width: "2rem" ,textAlign:"center"}}
              ></ReadMoreIcon>
            </Tooltip>
          </span>
        ),
        sortable: false,
        filter: false,
        floatingFilter: false,
        width: 125,
        minWidth: 125,
        maxWidth: 125,
      },
    {
      field: "Id",
      headerName: "Receive Payment",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      cellRenderer: (params) => (
        <span >
          <Tooltip title="Receive Payment" arrow>
            <PaymentIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => routePaymentDetails(params.data.Id)}
              style={{ marginRight: "1rem", width: "2rem" ,textAlign:"center"}}
            ></PaymentIcon>
          </Tooltip>
        </span>
      ),
      cellStyle: params => {
        return { textAlign: "center" };
    },
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      resizable: true,
      editable: true,
    }),
    []
  );

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  
  const findStoreById = (storeId) => {
    let storeObj = null;
    let storeName = null;
    if (stores) {
      storeObj = stores.filter((eachStore) => eachStore._id === storeId);
      if (storeObj && storeObj.length > 0) {
        storeName = storeObj[0].storeName ? storeObj[0].storeName : "";
      }
    }
    return storeName;
  };

  let noDataFoundContent = null;

  useEffect(() => {
    if(pos && pos.length>0){
      console.log("useEffect getting called to list All Orders Payments and Receipts ");
      dispatch(listOrdersByMappedStore(pos));
    }
  }, [pos]);

  useEffect(() => {
    if(orders_mapped_to_store && orders_mapped_to_store.length>0){
      console.log("useEffect getting called to set the RowData for pOnline Orders.!");
      populateOnlineOrdersRowData()
    }
  }, [ orders_mapped_to_store ]);



  const onGridReady = (params) => {
    gridApi = params.api;
  };

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  if (rowData && rowData !== null) {
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "70vh" }}
      >
        <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
      </div>
    );
  }
  
  return (
    <React.Fragment>
      {orders_mapped_to_store_error && <Message variant="danger">{orders_mapped_to_store_error}</Message>}
      {orders_mapped_to_store_loading && <CustomBackdropSpinner />}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  Online Orders
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
      <GridContainer style={{ height: "50%", width: '100%', overflowY: 'auto' }}>
        <GridItem xs={12} sm={12} md={12} >
          {console.log('rowData Count : ', rowData?rowData.length:0)}
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : noDataFoundContent}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default OnlineOrdersListScreen;
