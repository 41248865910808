import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Typography,
  Tooltip,
} from "@mui/material";
import { listAllNewStockNotification } from "../../actions/newStockNotificationAction";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import ReadMoreIcon from "@mui/icons-material/ReadMore";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const NewStockNotificationsListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  let products = [];
  const showNewStockEntryDetails = (params) => {
    console.log("showNewStockEntryDetails : ", params);
    console.log(" params", params.data.Id, params.data);
    const id = params.data.Id;

    console.log(` ###### Now showing Transit Stock Entry Details ######  ${id}`);
    navigate(`/admin/new-stock-notifications-details/${id}`);
  };
  const columnDefs = [
    { field: "#", resizable: true, suppressSizeToFit: true, width: 75, minWidth: 75, maxWidth: 75 },
    {
      field: "VehicleNo", headerName: 'Vehicle No', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "VehicleInchargeName", headerName: 'Vehicle Incharge Name', resizable: true, suppressSizeToFit: true, width: 350,
      minWidth: 350,
      maxWidth: 350
    },

    {
      field: "DriverName", headerName: 'Driver Name', resizable: true, suppressSizeToFit: true, width: 350,
      minWidth: 350,
      maxWidth: 350
    },
    {
      field: "Source", headerName: 'Source', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "Destination", headerName: 'Destination', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "CreatedAt", headerName: 'Created At', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    
    {
      field: "Status", headerName: 'Current Status', resizable: true, suppressSizeToFit: true, width: 350,
      cellStyle: params => {
        if (params.value === 'AWAITING CONFIRMATION') {
          return { color: 'white', backgroundColor: 'orange', textAlign: "center" };
        }
        else if (params.value === 'CONFIRMED') {
          return { color: 'white', backgroundColor: 'green', textAlign: "center" };
        }
        return null;
      },
      minWidth: 350,
      maxWidth: 350
    },
    {
      field: "Id",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer : (params) => (

        <span>
          {console.log("params ", params, params.data)}
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showNewStockEntryDetails(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const dispatch = useDispatch();

  const newStockNotificationList = useSelector((state) => state.newStockNotificationList);
  const { new_stock_list, new_stock_list_loading, new_stock_list_error } = newStockNotificationList;
  // if (new_stock_list && new_stock_list.length > 0) {
  //   console.log("new_stock_list ::==>>  ", new_stock_list);
  // }
  let noDataFoundContent = null;
  useEffect(() => {
    console.log("Dispatching to List All New Notifications ");
    dispatch(listAllNewStockNotification());
  }, [dispatch, navigate]);

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  if (new_stock_list && new_stock_list.length > 0) {
    console.log("new_stock_list ::==>> ", new_stock_list);
    const entries = Object.entries(new_stock_list);
    rowData = entries.map(([key, value], index) => {
      console.log("Each Value :=>  ", value);
      return {
        "#": index + 1,
        "VehicleNo": value.vehicleNo,
        "VehicleInchargeName": value.vehicleInchargeName,
        "DriverName": value.driverName,
        "Source": value.source,
        "Destination": value.destination,
        "Status": value.status,
        "CreatedAt": new Date(value.createdAt).toDateString(),
        Id: value._id,
      };
    });
  }
  if (rowData && rowData !== null) {
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "70vh" }}
      >
        <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
      </div>
    );
  }

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <React.Fragment>
      {new_stock_list_loading && <Message variant="danger">Loading</Message>}
      {new_stock_list_loading && <CustomBackdropSpinner />}

      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  New Stock Arrival List - awaiting Confirmation
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : noDataFoundContent}
        </GridItem>
      </GridContainer>

    </React.Fragment>
  );
};

export default NewStockNotificationsListScreen;
