export const WAREHOUSE_INVOICE_LIST_REQUEST = "WAREHOUSE_INVOICE_LIST_REQUEST";
export const WAREHOUSE_INVOICE_LIST_SUCCESS = "WAREHOUSE_INVOICE_LIST_SUCCESS";
export const WAREHOUSE_INVOICE_LIST_FAIL = "WAREHOUSE_INVOICE_LIST_FAIL";

export const WAREHOUSE_INVOICE_BY_ID_REQUEST =
  "WAREHOUSE_INVOICE_BY_ID_REQUEST";
export const WAREHOUSE_INVOICE_BY_ID_SUCCESS =
  "WAREHOUSE_INVOICE_BY_ID_SUCCESS";
export const WAREHOUSE_INVOICE_BY_ID_FAIL =
  "WAREHOUSE_INVOICE_BY_ID_FAIL";

export const WAREHOUSE_INVOICE_DELETE_BY_ID_REQUEST = "WAREHOUSE_INVOICE_DELETE_BY_ID_REQUEST";
export const WAREHOUSE_INVOICE_DELETE_BY_ID_SUCCESS = "WAREHOUSE_INVOICE_DELETE_BY_ID_SUCCESS";
export const WAREHOUSE_INVOICE_DELETE_BY_ID_FAIL = "WAREHOUSE_INVOICE_DELETE_BY_ID_FAIL";

export const WAREHOUSE_INVOICE_CREATE_REQUEST = "WAREHOUSE_INVOICE_CREATE_REQUEST";
export const WAREHOUSE_INVOICE_CREATE_SUCCESS = "WAREHOUSE_INVOICE_CREATE_SUCCESS";
export const WAREHOUSE_INVOICE_CREATE_FAIL = "WAREHOUSE_INVOICE_CREATE_FAIL";
export const WAREHOUSE_INVOICE_CREATE_RESET = "WAREHOUSE_INVOICE_CREATE_RESET";

export const WAREHOUSE_INVOICE_UPDATE_BY_ID_REQUEST = "WAREHOUSE_INVOICE_UPDATE_BY_ID_REQUEST";
export const WAREHOUSE_INVOICE_UPDATE_BY_ID_SUCCESS = "WAREHOUSE_INVOICE_UPDATE_BY_ID_SUCCESS";
export const WAREHOUSE_INVOICE_UPDATE_BY_ID_FAIL = "WAREHOUSE_INVOICE_UPDATE_BY_ID_FAIL";
export const WAREHOUSE_INVOICE_UPDATE_BY_ID_RESET = "WAREHOUSE_INVOICE_UPDATE_BY_ID_RESET";

