import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Section from "../organisms/Section/Section";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import { Divider } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import {
  Grid,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  Tooltip,
  InputLabel,
  FormControl,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../actions/userAction";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { listAllStores } from "../../actions/storeAction";
import { role_types } from "../../data/index.js";
import { listAllWarehouses } from "../../actions/warehouseInfoAction";
import { getUserDetails } from "../../actions/userAction";
import { USER_UPDATE_RESET } from "../../constants/userConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  userName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 9,
    },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: {
      message: "doesn't look like a valid email",
    },
    length: {
      maximum: 300,
    },
  },
  contactNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 10,
      maximum: 10,
    },
  },
};

const AdminUsersEditPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;
  const warehouseInfoList = useSelector((state) => state.warehouseInfoList);
  const { warehouseInfos } = warehouseInfoList;

  const userUpdate = useSelector((state) => state.userUpdate);
  console.log("userUpdate : ", userUpdate);
  const { user_update_loading, user_update_error, user_update_success } =
    userUpdate;
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [role, setRole] = useState(() => {
    return "";
  });

  const [selectedStore, setSelectedStore] = useState(() => "");
  const [selectedWarehouse, setSelectedWarehouse] = useState(() => "");

  useEffect(() => {
    console.log("useEffect Getting  and Warehouse List");
    dispatch(listAllStores());
    dispatch(listAllWarehouses());
    if (user_update_success) {
      dispatch({ type: USER_UPDATE_RESET });
      navigate("/admin/users");
    }
  }, [dispatch, navigate, user_update_success]);

  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  let renderStoreOptions = "";
  if (stores && stores.length > 0) {
    renderStoreOptions = stores.map((eachStore, idx) => {
      return (
        <MenuItem key={idx} value={eachStore._id}>
          {eachStore.storeName}
        </MenuItem>
      );
    });
  }

  let renderWarehouseOptions = "";
  if (warehouseInfos && warehouseInfos.length > 0) {
    renderWarehouseOptions = warehouseInfos.map((eachWarehouse, idx) => {
      return (
        <MenuItem key={idx} value={eachWarehouse._id}>
          {eachWarehouse.warehouseName}
        </MenuItem>
      );
    });
  }

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "role_types":
        return role_types.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const handleChangeStore = (e) => {
    console.log("Store Changed  " + e.target.value);
    setSelectedStore(() => e.target.value);
  };

  const handleChangeWarehouse = (e) => {
    console.log("Warehouse Changed  " + e.target.value);
    setSelectedWarehouse(() => e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log(
      "submitHandler Exec updateAdminUser",
      formState.values,
      role,
      selectedStore,
      selectedWarehouse
    );

    dispatch(
      updateUser(
        formState.values.name,
        formState.values.userName,
        formState.values.password,
        formState.values.email,
        formState.values.contactNo,
        role,
        selectedStore,
        selectedWarehouse
      )
    );
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "select"
            ? event.target.selected
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {user_update_error && (
        <Message variant="danger">{user_update_error}</Message>
      )}
      {user_update_loading && <CustomBackdropSpinner />}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Back" arrow>
            <ArrowBackIcon
              fontSize="medium"
              onClick={() => window.history.back()}
              style={{ float: "left" }}
            />
          </Tooltip>
          <Tooltip title="Home" arrow>
            <HomeIcon
              fontSize="medium"
              onClick={() => navigate("/")}
              style={{ float: "right" }}
            />
          </Tooltip>
        </GridItem>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                  User Edit
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <Card>
          <CardBody>
            {/* <Section className={classes.section} style={{ marginTop: "-6rem" }}> */}
            {/* <div className={classes.formContainer}> */}
            <form method="post" onSubmit={submitHandler}>
              <Grid container spacing={2} justifyContent="center" marginTop="-5rem">
                <Grid item xs={12} align="center">
                  <TextField
                    placeholder="Name"
                    label="Name*"
                    variant="outlined"
                    size="small"
                    name="name"
                    style={{ width: "25vh" }}
                    helperText={
                      hasError("name") ? formState.errors.name[0] : null
                    }
                    error={hasError("name")}
                    onChange={handleChange}
                    type="text"
                    value={formState.values.name || ""}
                  />
                </Grid>
                <Grid item xs={12} align="center">
                  <TextField
                    placeholder="User Name"
                    label="User Name*"
                    variant="outlined"
                    size="small"
                    name="userName"
                    style={{ width: "25vh" }}
                    helperText={
                      hasError("userName") ? formState.errors.userName[0] : null
                    }
                    error={hasError("userName")}
                    onChange={handleChange}
                    type="text"
                    value={formState.values.userName || ""}
                  />
                </Grid>

                <Grid item xs={12} align="center">
                  <TextField
                    placeholder="E-mail"
                    label="E-mail *"
                    variant="outlined"
                    size="small"
                    name="email"
                    style={{ width: "25vh" }}
                    helperText={
                      hasError("email") ? formState.errors.email[0] : null
                    }
                    error={hasError("email")}
                    onChange={handleChange}
                    type="email"
                    value={formState.values.email || ""}
                  />
                </Grid>
                <Grid item xs={12} align="center">
                  <TextField
                    placeholder="Password"
                    label="Password *"
                    variant="outlined"
                    size="small"
                    name="password"
                    style={{ width: "25vh" }}
                    helperText={
                      hasError("password") ? formState.errors.password[0] : null
                    }
                    error={hasError("password")}
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ""}
                  />
                </Grid>

                <Grid item xs={12} align="center">
                  <TextField
                    placeholder=""
                    label="Contact No*"
                    variant="outlined"
                    size="small"
                    name="contactNo"
                    style={{ width: "25vh" }}
                    helperText={
                      hasError("contactNo")
                        ? formState.errors.contactNo[0]
                        : null
                    }
                    error={hasError("contactNo")}
                    onChange={handleChange}
                    type="number"
                    value={formState.values.contactNo || ""}
                  />
                </Grid>
                <Grid item xs={12} align="center">
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                    <Select
                      label="Role Name"
                      variant="outlined"
                      required
                      size="small"
                      name="role"
                      onChange={handleRoleChange}
                      value={role}
                      style={{ width: "25vh" }}
                    >
                      {renderDynamicOptions("role_types")}
                    </Select>
                  </FormControl>
                </Grid>
                {role !== "" && role === "STORE_INCHARGE_ROLE" && (
                  <Grid item xs={12} align="center">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Store Name
                      </InputLabel>

                      <Select
                        placeholder="Store Name"
                        label="Store Name "
                        variant="outlined"
                        size="small"
                        name="role"
                        onChange={handleChangeStore}
                        value={selectedStore}
                        style={{ width: "25vh" }}
                      >
                        {renderStoreOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {role !== "" && role === "WAREHOUSE_INCHARGE_ROLE" && (
                  <Grid item xs={12} align="center">
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Warehouse Name
                      </InputLabel>

                      <Select
                        placeholder="Warehouse Name"
                        label="Warehouse Name "
                        variant="outlined"
                        size="small"
                        name="role"
                        onChange={handleChangeWarehouse}
                        value={selectedWarehouse}
                        style={{ width: "25vh" }}
                      >
                        {renderWarehouseOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} align="center">
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={submitHandler}
                    style={{
                      textTransform: "capitalize",
                      width: "20vh",
                      borderRadius: "5px",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </form>
            {/* </div>
            </Section> */}
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
};

export default AdminUsersEditPage;