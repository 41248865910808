import React, { useState, useEffect } from "react";
import {
    Grid,
    Paper,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../assets/images/green_tick.png";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },

    control: {
        padding: theme.spacing(2),
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleGreen: {
        color: "#26A541",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "Roboto",
        marginBottom: "3px",
        textDecoration: "none",
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        textAlign: "left",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
}));

const ThanksConfirmationDialog = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log("Props Passed to ThanksConfirmationDialog are  : ",props);
    const { openDialog } = props 
    const [open, setOpen] = React.useState(openDialog);
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
          padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
          padding: theme.spacing(1),
        },
      }));
    
    const showSuccessDialog = () => {
        console.log("Exe showSuccessDialog Displaying Success Image");
        setOpen(() => true);
    }

    const handleClose = () => {
        // setOpen(false);
        // if (store_po_create_success) {
            setOpen(false);
            // dispatch({ type: VEHICLE_INVOICE_TO_SHOP_CREATE_RESET });
            navigate("/admin/purchase-orders");
        // }
        // navigate("/admin/purchase-orders");
      };
    useEffect(() => {
        console.log("useEffect Getting Called for Thanks Confirmation Dialog");
        showSuccessDialog()
        /**
         * if (vehicle_shop_invoice_create_success && wh_stock_update_success) {
          dispatch({ type: VEHICLE_INVOICE_TO_SHOP_CREATE_RESET });
          navigate("/admin/vehicle-sales-invoice/list");
        }
        else if (wh_stock_update_success) {
          dispatch({ type: WALKIN_SALES_INVOICE_CREATE_RESET });
          showSuccessDialog()
        }
        }, [ dispatch, navigate, wh_stock_update_success ]);
        **/
      }, []);

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <GridContainer spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} >
                            <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                                    <img
                                        alt="Order Placed"
                                        src={greeTickImage}
                                        style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                                    />
                                </div>
                                <Typography
                                    variant="h5"
                                    color="black"
                                    style={{
                                        margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                                        fontWeight: "600",
                                        fontSize: "1rem",
                                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                    }}
                                >
                                    Thanks , your order is placed.
                                </Typography>
                            </Paper>
                        </Grid>
                    </GridContainer>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    )

}

export default ThanksConfirmationDialog;

