import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  deleteStore,
  listAllStores,
  updateStore,
} from "../../actions/storeAction";
import Paper from "@mui/material/Paper";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
  Divider,
} from "@mui/material";
import { STORE_UPDATE_RESET } from "../../constants/storeConstants";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Message from "../Message.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useNavigate } from "react-router-dom";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";

const styles = {
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      fontWeight: "700",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardStoreWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  trStyle: {
    borderBottom: "3px",
    borderColor: "gray",
  },
};

const StoreListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "StoreName",
      headerName: "Store Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: "Address",
      headerName: "Address",
      resizable: true,
      suppressSizeToFit: true,
      width: 600,
      minWidth: 600,
      maxWidth: 600,
    },
    {
      field: "PhoneNo",
      headerName: "Phone No",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "ManagerName",
      headerName: "Manager Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "Email",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "Latitude",
      headerName: "Latitude",
      resizable: true,
      suppressSizeToFit: true,
      width: 180,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      field: "Longitude",
      headerName: "Longitude",
      resizable: true,
      suppressSizeToFit: true,
      width: 180,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      field: "PostalCode",
      headerName: "Postal Code",
      resizable: true,
      suppressSizeToFit: true,
      width: 180,
      minWidth: 180,
      maxWidth: 180,
    },
    {
      field: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Edit" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Delete Store" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("Deleting ", params.value)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const export_label = "Export > CSV";
  const [open, setOpen] = useState(() => false);
  const [filteredStore, setFilteredStore] = useState(() => {
    return "";
  });

  const dispatch = useDispatch();
  const storesList = useSelector((state) => state.storesList);
  const { loading, error, stores } = storesList;

  const storeDeleted = useSelector((state) => state.storeDeleted);
  const { success_delete } = storeDeleted;
  if (success_delete) console.log("Deleted Store Success");

  const storeUpdated = useSelector((state) => state.storeUpdated);
  const { success_update } = storeUpdated;

  useEffect(() => {
    console.log("useEffect Getting Called StoreListScreen For First Time");
    dispatch(listAllStores());
    if (success_update || success_delete) {
      navigate("/admin/store");
    }
  }, [dispatch, navigate, success_update, success_delete]);

  const createStoreHandler = (store) => {
    navigate("/admin/store/new");
  };

  const storeNameChangeHandler = (nm) => {
    setFilteredStore({ ...filteredStore, storeName: nm });
    console.log(filteredStore);
  };

  const addressChangeHandler = (addres) => {
    setFilteredStore({ ...filteredStore, address: addres });
    console.log(filteredStore);
  };
  const phoneChangeHandler = (ph) => {
    setFilteredStore({ ...filteredStore, phoneNo: ph });
    console.log(filteredStore);
  };
  const managerNameChangeHandler = (managerName) => {
    setFilteredStore({ ...filteredStore, managerName: managerName });
    console.log(filteredStore);
  };
  const managerEmailChangeHandler = (managerEmail) => {
    setFilteredStore({ ...filteredStore, managerEmail: managerEmail });
    console.log(filteredStore);
  };
  const latitudeChangeHandler = (latitude) => {
    setFilteredStore({ ...filteredStore, latitude: latitude });
    console.log(filteredStore);
  };
  const longitudeChangeHandler = (longitude) => {
    setFilteredStore({ ...filteredStore, longitude: longitude });
    console.log(filteredStore);
  };
  const postalCodeChangeHandler = (postalCode) => {
    setFilteredStore({ ...filteredStore, postalCode: postalCode });
    console.log(filteredStore);
  };

  const handleEdit = (params) => {
    const {data} = params;
  //   const id = data.Edit;
  // const {StoreName ,Address} = data;
    console.log("data : " , data); 
    setOpen(true);
    setFilteredStore({
      _id: params.data.Edit,
      storeName: params.data.StoreName,
      address: params.data.Address,
      managerName: params.data.ManagerName,
      managerEmail: params.data.Email,
      phoneNo: params.data.PhoneNo,
      latitude: params.data.Latitude,
      longitude: params.data.Longitude,
      postalCode: params.data.PostalCode
    });
  };
  // const handleEdit = (params) => {
  //   // setOpen(true);
  //   const {data} = params;
  //   const id = data.Edit;
  //   console.log("ID SELECTED : " , id);
  //   navigate(`/admin/store/edit/${id}`);
  // };

  const handleDelete = (store) => {
    console.log("handleDelete Exec..." + store._id);
    console.log("ID SELECTED : " + store._id);
    dispatch(deleteStore(store._id));
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    console.log(filteredStore);
    dispatch(
      updateStore(
        filteredStore._id,
        filteredStore.storeName,
        filteredStore.address,
        filteredStore.phoneNo,
        filteredStore.managerName,
        filteredStore.managerEmail,
        filteredStore.latitude,
        filteredStore.longitude,
        filteredStore.postalCode
      )
    );
    setOpen(false);
    setFilteredStore({});
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  if (stores && stores.length > 0) {
    rowData = stores.map((eachStore, index) => {
      return {
        "#": index + 1,
        StoreName: eachStore.storeName,
        Address: eachStore.address,
        PhoneNo: eachStore.phoneNo,
        ManagerName: eachStore.managerName,
        Email: eachStore.managerEmail,
        Latitude: eachStore.latitude,
        Longitude: eachStore.longitude,
        PostalCode: eachStore.postalCode,
        Edit: eachStore._id,
        Delete: eachStore._id,
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
      </div>
    );
  }
  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
          <Tooltip title="Create Store" arrow>
            <AddCircleIcon
              fontSize="medium"
              style={{ float: "left" }}
              onClick={createStoreHandler}
            />
          {/*</Tooltip>
          <Tooltip title="Home" arrow>
            <HomeIcon
              fontSize="medium"
              onClick={() => navigate("/")}
              style={{ float: "right" }}
  />*/}
  </Tooltip> 
        </GridItem>
        <>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem", textAlign: "center" }}
                  color="black"
                >
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em" }}
                    />
                  </Tooltip>
                  Stores
                </Typography>
                </CardHeader>
              </Card>
            </GridItem>
          </GridContainer>
        </>
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "0.5rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader>
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    ></Typography>
                    <h2 align="center"> Edit Store</h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={submitHandler}>
                      <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        margin="auto"
                      >
                        <Grid item xs={12} align="center">
                          <TextField
                            label="Store Name"
                            className={classes.inputText}
                            placeholder="Store Name"
                            variant="outlined"
                            name="storeName"
                            style={{width:"32vh"}}
                            onChange={(e) =>
                              storeNameChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredStore && filteredStore.storeName
                                ? filteredStore.storeName
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            label="Address"
                            className={classes.inputText}
                            placeholder="Address"
                            variant="outlined"
                            name="Address"
                            style={{width:"32vh"}}
                            id="address"
                            onChange={(e) =>
                              addressChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredStore && filteredStore.address
                                ? filteredStore.address
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Phone No"
                            placeholder="Phone No"
                            variant="outlined"
                            name="phoneNo"
                            style={{width:"32vh"}}
                            id="phoneNo"
                            onChange={(e) => phoneChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              filteredStore && filteredStore.phoneNo
                                ? filteredStore.phoneNo
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Manager Name"
                            placeholder="Manager Name"
                            variant="outlined"
                            name="managerName"
                            style={{width:"32vh"}}
                            id="managerName"
                            onChange={(e) =>
                              managerNameChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredStore && filteredStore.managerName
                                ? filteredStore.managerName
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Manager Email"
                            placeholder="Manager Email"
                            variant="outlined"
                            name="managerEmail"
                            style={{width:"32vh"}}
                            id="managerEmail"
                            onChange={(e) =>
                              managerEmailChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredStore && filteredStore.managerEmail
                                ? filteredStore.managerEmail
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Latitude"
                            placeholder="Latitude"
                            variant="outlined"
                            name="latitude"
                            style={{width:"32vh"}}
                            id="latitude"
                            onChange={(e) =>
                              latitudeChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredStore && filteredStore.latitude
                                ? filteredStore.latitude
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Longitude"
                            placeholder="Longitude"
                            variant="outlined"
                            name="longitude"
                            style={{width:"32vh"}}
                            id="longitude"
                            onChange={(e) =>
                              longitudeChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredStore && filteredStore.longitude
                                ? filteredStore.longitude
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            label="Postal Code"
                            placeholder="Postal Code"
                            variant="outlined"
                            name="postalCode"
                            style={{width:"32vh"}}
                            id="postalCode"
                            onChange={(e) =>
                              postalCodeChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              filteredStore && filteredStore.postalCode
                                ? filteredStore.postalCode
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{
                              textTransform: "capitalize",
                              width: "20vh",
                              borderRadius: "5px",
                            }}
                          >
                            update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default StoreListScreen;
