export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_REQUEST = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_REQUEST";
export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SUCCESS = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SUCCESS";
export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_FAIL = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_FAIL";
export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_RESET = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_RESET";

export const NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_REQUEST = "NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_REQUEST";
export const NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS = "NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS";
export const NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_FAIL = "NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_FAIL";
export const NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_RESET = "NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_RESET";

export const NEW_STOCK_NOTIFICATION_LIST_REQUEST = "NEW_STOCK_NOTIFICATION_LIST_REQUEST";
export const NEW_STOCK_NOTIFICATION_LIST_SUCCESS = "NEW_STOCK_NOTIFICATION_LIST_SUCCESS";
export const NEW_STOCK_NOTIFICATION_LIST_FAIL = "NEW_STOCK_NOTIFICATION_LIST_FAIL";
export const NEW_STOCK_NOTIFICATION_LIST_RESET = "NEW_STOCK_NOTIFICATION_LIST_RESET";

export const NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_REQUEST = "NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_REQUEST";
export const NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_SUCCESS = "NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_SUCCESS";
export const NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_FAIL = "NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_FAIL";
export const NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_RESET = "NEW_STOCK_NOTIFICATION_DETAILS_BY_ID_RESET";

export const NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_REQUEST = "NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_REQUEST";
export const NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_SUCCESS = "NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_SUCCESS";
export const NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_FAIL = "NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_FAIL";
export const NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_RESET = "NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_RESET";

export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST";
export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS";
export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL";
export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET";

export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST";
export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS";
export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL";
export const NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET = "NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET";

export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_REQUEST = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_REQUEST";
export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_SUCCESS = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_SUCCESS";
export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_FAIL = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_FAIL";
export const NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_RESET = "NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_RESET";

