import React, { useEffect, useState, useMemo ,useCallback} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import DialogContent from "@mui/material/DialogContent";
import MoodEditor from '../AgGridCustomComponents/moodEditor';
import NumericCellEditor from '../AgGridCustomComponents/numericCellEditor';

import {
  Typography,
  Button,
  Tooltip,
  Grid,
  TextField,
  Divider,
  Dialog
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import { listNewStockNotificationById, updateNewStockNotificationStatus } from "../../actions/newStockNotificationAction";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

import { updateStockInByWarehouseProduct } from "../../actions/warehouseStockAction";
import { updateStockInByStoreProduct } from "../../actions/storeStockAction";
import { WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET } from "../../constants/warehouseStockConstants";
import { updateTransitStockConfirmStatusByVehicleNoAndProductName } from "../../actions/transitStockAction";
const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const NewStockNotificationDetailsScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  let products = [];
  const [open, setOpen] = useState(() => false);

  const handleEdit = (params) => {
    console.log("params passed : ", params);
  }

  const onRowEditingStarted = useCallback((event) => {
    console.log('never called - not doing row editing');
  }, []);

  const onRowEditingStopped = useCallback((event) => {
    console.log('never called - not doing row editing');
  }, []);

  const onCellEditingStarted = useCallback((event) => {
    console.log('cellEditingStarted');
  }, []);

  const onCellEditingStopped = useCallback((event) => {
    console.log('cellEditingStopped');
  }, []);

  const cellEditorSelector = (params) => {
    console.log("params.data.type :: ",params.data);
    if (params.data.CountInStock) {
      return {
        component: NumericCellEditor,
      };
    }
    return undefined;
  };

  const columnDefs = [
    { field: "#", resizable: true, suppressSizeToFit: true, width: 75, minWidth: 75, maxWidth: 75 },
    {
      field: "ProductName", headerName: 'Product Name', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "CountInStock", headerName: 'Count In Stock', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "ActualStockCount", headerName: 'Actual Stock Count', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      editable: true,
      cellEditorSelector: cellEditorSelector,
    },
    {
      field: "MissingStock", headerName: 'Missing Stock', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      editable: true,
      cellEditorSelector: cellEditorSelector,
    },
    {
      field: "DamagedStock", headerName: 'Damaged Stock', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300,
      editable: true,
      cellEditorSelector: cellEditorSelector,
    },
    {
      field: "StockInTime", headerName: 'Stock In Time', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "StockOutTime", headerName: 'Stock Out Time', resizable: true, suppressSizeToFit: true, width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "Edit", headerName: 'Edit', resizable: true, suppressSizeToFit: true, width: 100, minWidth: 100, maxWidth: 100,
      cellRenderer : (params) => (
        <span><Tooltip title="Edit Arrival Stock" arrow>
          <EditRoundedIcon
            size="small"
            variant="contained"
            type="submit"
            color="Gray"
            justify="center"
            onClick={() => handleEdit(params)}
            style={{ marginRight: "1rem", width: "2rem" }}
          ></EditRoundedIcon>
        </Tooltip></span>
      ), sortable: false,
      filter: false,
      floatingFilter: false
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log("New Notification Entry Confiration Screen...id ::==>> ", id);
  const newStockNotificationList = useSelector((state) => state.newStockNotificationList);
  const { new_stock_list, new_stock_list_loading, new_stock_list_error } = newStockNotificationList;
  const warehouseStockInUpdate = useSelector((state) => state.warehouseStockInUpdate);
  const { wh_stock_in_update_data, wh_stock_in_update_success } = warehouseStockInUpdate;
  const newStockNotificationUpdateConfirmStatus = useSelector((state) => state.newStockNotificationUpdateConfirmStatus);
  const { new_notification_stock_update_success, new_notification_stock_update_loading, new_notification_stock_update_error } = newStockNotificationUpdateConfirmStatus;

  if (new_stock_list && new_stock_list.length > 0) {
    products = new_stock_list[0];
    console.log("products : ", products.products);
  }
  let noDataFoundContent = null;
  useEffect(() => {
    console.log("Dispatching to List All New Notifications ");
    dispatch(listNewStockNotificationById(id));
  }, [dispatch, navigate]);

  useEffect(() => {
    console.log("useEffect Getting Called Post Stock In Update of New Stock Notification ");
    if (wh_stock_in_update_success) {
      dispatch({ type: WAREHOUSE_STOCK_IN_UPDATE_BY_WAREHOUSE_NAME_RESET });
      dispatch(updateNewStockNotificationStatus(id));
      console.log("STEP 1. UPDATE TRANSIT STOCK STATUS TO CONFIRMED");
      console.log("STEP 2. UPDATE NEW STOCK NOTIFICATION STATUS TO CONFIRMED");
      navigate("/admin/new-stock-notifications-list");
    }
  }, [dispatch, navigate, wh_stock_in_update_success]);

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const getDestinationValue = (sourceDestination) => {
    console.log("Processsing for : sourceDestination ::==> ", sourceDestination);
    const srcDestKey = sourceDestination.toLowerCase().indexOf('_wh') !== -1 ? 'Warehouse' : (sourceDestination.toLowerCase().indexOf('_st') !== -1 ? 'Store' : null)
    const srcDestValue = sourceDestination.substring(0, sourceDestination.lastIndexOf('_'))
    console.log("sourceDestination Processed Key and Value  srcDestKey : ", srcDestKey, "srcDestValue : ", srcDestValue);
    return { key: srcDestKey, value: srcDestValue }
  }

  const confirmHandler = () => {
    console.log("Clicked YES");
    let destKey = null;
    let destValue = null;
    let tempSecDest = null;

    Object.entries(transit_stock_by_vehicle_no_source_dest).map(([key, value], index) => {
      console.log(`${index} : ${key} ::==> ${value}`);
    });
    console.log("Before Confirming New Stock Arrival  : ", new_stock_list)
    setOpen(() => false);
    const destination = new_stock_list ? new_stock_list[0] ? new_stock_list[0].destination : null : null;
    if (destination) {
      tempSecDest = getDestinationValue(destination);
      destKey = tempSecDest.key;
      destValue = tempSecDest.value;
    }
    console.log('tempSecDest : ', tempSecDest, 'destKey : ', destKey, 'destValue : ', destValue);
    if (destKey === 'Warehouse') {
      /** 
        LOGIC FOR STOCK IN TO WH... IS HANDLED HERE.!!!
      */
      console.log(" ******* NOW DISPATCHING TO updateStockInByWarehouseProduct ******* ");
      dispatch(
        updateStockInByWarehouseProduct(
          null,
          new_stock_list[0].products,
          null,
          destination
        )
      );
    } else if (destKey === 'Store') {
      /** 
        LOGIC FOR STOCK INTO STORE... IS HANDLED HERE.!!!
      */
      console.log(" ******* NOW DISPATCHING TO updateStockInByStoreProduct ******* ");
      dispatch(
        updateStockInByStoreProduct(
          null,
          new_stock_list[0].products,
          null,
          destination
        )
      );
    }
  }

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  if (new_stock_list && new_stock_list.length > 0) {
    console.log("new_stock_list ::==>> ", new_stock_list);
    rowData = new_stock_list[0].products.map((eachItem, index) => {
      console.log("eachItem : ", eachItem);
      return {
        "#": index + 1,
        "ProductName": eachItem.productName,
        "CountInStock": eachItem.countInStock,
        "MissingStock": 0,
        "DamagedStock": 0,
        "StockInTime": eachItem.stockInTime,
        "StockOutTime": eachItem.stockOutTime,
      };
    });
    if (rowData && rowData !== null && products.products && products.products.length > 0) {
      renderContentUsingAGGrid = (
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            onGridReady={onGridReady}
            pagination="true"
            onRowEditingStarted={onRowEditingStarted}
            onRowEditingStopped={onRowEditingStopped}
            onCellEditingStarted={onCellEditingStarted}
            onCellEditingStopped={onCellEditingStopped}
          ></AgGridReact>
        </div>
      );
    }
  }

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <React.Fragment>
      {new_stock_list_loading && <Message variant="danger">Loading</Message>}
      {new_stock_list_loading && <CustomBackdropSpinner />}

      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{ fontWeight: 500 }}>
                  New Stock Arrival - Awaiting Confirmation
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
            <Card>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    Vehicle No #
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={new_stock_list ? new_stock_list[0] ? new_stock_list[0].vehicleNo : "" : ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    Status :
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={new_stock_list ? new_stock_list[0] ? new_stock_list[0].status : "" : ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    Source :
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={new_stock_list ? new_stock_list[0] ? new_stock_list[0].source : "" : ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    Destination :
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={new_stock_list ? new_stock_list[0] ? new_stock_list[0].destination : "" : ""}
                      disabled={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4} md={3}>
                    Vehicle Incharge Name :
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={new_stock_list ? new_stock_list[0] ? new_stock_list[0].vehicleInchargeName : "" : ""}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    Driver Name :
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      variant="outlined"
                      size="small"
                      value={new_stock_list ? new_stock_list[0] ? new_stock_list[0].driverName : "" : ""}
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : noDataFoundContent}
        </GridItem>
      </GridContainer>
      {rowData && rowData.length > 0 && (<GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody style={{ textAlign: "center" }}>
              <Button
                size="small"
                variant="contained"
                type="submit"
                color="secondary"
                justify="center"
                onClick={() => setOpen(true)}
                style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px", alignItems: "center", justifyContent: "center", textAlign: "center" }}
              >
                Confirm
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)} style={{ height: '100%', width: '100%' }}>
          <DialogContent>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader >
                  <Typography variant="subtitle" style={{ fontWeight: 300 }}>
                    Stock is delivered ?
                  </Typography>
                  <Divider />
                </CardHeader>
                <CardBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        color="secondary"
                        justify="center"
                        onClick={confirmHandler}
                        style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                      >
                        Yes
                      </Button>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </DialogContent>
        </Dialog>
      </GridContainer>
      )
      }
    </React.Fragment>
  );
};

export default NewStockNotificationDetailsScreen;
