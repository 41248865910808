import axios from "axios";
import {
  NEW_STOCK_NOTIFICATION_LIST_REQUEST,
  NEW_STOCK_NOTIFICATION_LIST_FAIL,
  NEW_STOCK_NOTIFICATION_LIST_SUCCESS,
  NEW_STOCK_NOTIFICATION_LIST_RESET,
  NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_REQUEST,
  NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS,
  NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_RESET,
  NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_FAIL,
  NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST,
  NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
  NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
  NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET,
  NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_REQUEST,
  NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_RESET,
  NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_FAIL,
  NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_SUCCESS,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_REQUEST,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_FAIL,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_RESET,
  NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_SUCCESS,
} from "../constants/newStockNotificationConstants";

export const listAllNewStockNotification = () => async (dispatch) => {
  try {
    console.log('Exec listAllNewStockNotification : ');
    dispatch({ type: NEW_STOCK_NOTIFICATION_LIST_REQUEST });
    const { data } = await axios.get("/api/new-stock-notification");
    dispatch({
      type: NEW_STOCK_NOTIFICATION_LIST_SUCCESS,
      payload: data,
    });
    dispatch({ type: NEW_STOCK_NOTIFICATION_LIST_RESET });
  } catch (error) {
    dispatch({
      type: NEW_STOCK_NOTIFICATION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listNewStockNotificationById = (id) => async (dispatch) => {
  try {
    console.log('listNewStockNotificationById : ', id);
    dispatch({ type: NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_REQUEST });
    const { data } = await axios.get(`/api/new-stock-notification/details/${id}`);
    dispatch({
      type: NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_SUCCESS,
      payload: data,
    });
    dispatch({ type: NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_RESET });
  } catch (error) {
    dispatch({
      type: NEW_STOCK_NOTIFICATION_BY_NOTIFICATION_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listStockByDestinationWarehouseStoreNameNotification = (whStName) => async (dispatch) => {
  try {
    console.log('listStockByDestinationWarehouseStoreNameNotification : ', whStName);
    dispatch({ type: NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST });
    const { data } = await axios.get(`/api/new-stock-notification/${whStName}`);
    dispatch({
      type: NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
      payload: data,
    });
    dispatch({ type: NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET });
  } catch (error) {
    dispatch({
      type: NEW_STOCK_NOTIFICATION_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const  createNewStockNotificationByVehicleDestinationAndProductName =
  ( products, destination) => async (dispatch) => {

    console.log(
      "createNewStockNotificationByVehicleDestinationAndProductName -start ",
      " products :: ", products," destination :: ",destination
    );

    try {
      dispatch({
        type: NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_REQUEST,
      });
      console.log(
        " ******** Add Stock in Warehouse Name : ********  "
      );
      const { data } = await axios.post(`/api/new-stock-notification/${destination}`, {
        products,
      });
      console.log("createStockByWarehouseStoreNameAndProductNameNotification - end ");

      dispatch({
        type: NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_SUCCESS,
        payload: data,
      });

      dispatch({
        type: NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: NEW_STOCK_NOTIFICATION_CREATE_BY_DESTINATION_VEHICLE_PRODUCT_FAIL,
        payload: message,
      });
    }
  };

export const  updateNewStockNotificationByDestinationWarehouseStoreNameAndProductName =
  (invDetails, purchaseOrderProducts,frNmValue,toNmValue) => async (dispatch, getState) => {
    console.log(
      "updateNewStockNotificationByWarehouseStoreNameAndProductName -start ",
      invDetails,
      purchaseOrderProducts,
      frNmValue,
      toNmValue
    );
    const { warehouseStoreName } = toNmValue;
    console.log(' Checking for purchaseOrderProducts : ',purchaseOrderProducts,'destinationWhStoreValue :',warehouseStoreName);
   
    try {
      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST,
      });
      console.log('Warehouse Name : ',warehouseStoreName);
      const { data } = await axios.post(`/api/new-notification-stock/${warehouseStoreName}/in`, {purchaseOrderProducts});
      console.log("updateStockByWarehouseStoreNameAndProductNameInToWh - end ");

      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
        payload: data,
      });

      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
        payload: message,
      });
    }
  };


  export const  updateNewStockNotificationStatus =
  (newStockNotificationId) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const { name, role } = userInfo 
    console.log(
      "updateNewStockNotificationStatus CONFIRM STATUS -start ",
      newStockNotificationId," userLogin : ", userInfo
    );
    console.log(' Checking for newStockNotificationId : ',newStockNotificationId);
    
    try {
      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_REQUEST,
      });
      const { data } = await axios.put(`/api/new-stock-notification/confirm-status/${newStockNotificationId}/${name}`);
      console.log("updateNewStockNotificationStatus CONFIRM STATUS- end ");

      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_SUCCESS,
        payload: data,
      });

      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: NEW_STOCK_NOTIFICATION_UPDATE_STATUS_BY_ID_FAIL,
        payload: message,
      });
    }
  };
