import {
  WALKIN_SALES_INVOICE_CREATE_FAIL,
  WALKIN_SALES_INVOICE_CREATE_REQUEST,
  WALKIN_SALES_INVOICE_CREATE_RESET,
  WALKIN_SALES_INVOICE_CREATE_SUCCESS,
  WALKIN_SALES_INVOICE_LIST_FAIL,
  WALKIN_SALES_INVOICE_LIST_REQUEST,
  WALKIN_SALES_INVOICE_LIST_SUCCESS,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
  WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
  WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST,
  WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS,
  WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL,
  WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_RESET,
  WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_FAIL,
  WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS,
  WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST
} from "../constants/walkinSalesInvoiceConstants";

export const walkinSalesInvoiceListReducer = (state = { walkin_sales_invoice_data: [] }, action) => {
  switch (action.type) {
    case WALKIN_SALES_INVOICE_LIST_REQUEST:
      return { walkin_sales_invoice_loading: true, walkin_sales_invoice_data: [] };
    case WALKIN_SALES_INVOICE_LIST_SUCCESS:
      return {
        walkin_sales_invoice_loading: false,
        walkin_sales_invoice_data: action.payload,
      };
    case WALKIN_SALES_INVOICE_LIST_FAIL:
      return { walkin_sales_invoice_loading: false, walkin_sales_invoice_error: action.payload };
    default:
      return state;
  }
};

export const walkinSalesInvoiceCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WALKIN_SALES_INVOICE_CREATE_REQUEST:
      return { walkin_sales_invoice_create_loading: true };
    case WALKIN_SALES_INVOICE_CREATE_SUCCESS:
      return { walkin_sales_invoice_create_loading: false, walkin_sales_invoice_create_success: true, walkin_sales_invoice_create_data: action.payload };
    case WALKIN_SALES_INVOICE_CREATE_FAIL:
      return { walkin_sales_invoice_create_loading: false, walkin_sales_invoice_create_error: action.payload };
    case WALKIN_SALES_INVOICE_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const walkinSalesInvoiceDetailsByIdReducer = (
  state = { walkin_sales_invoice_by_id_data: {} },
  action
) => {
  switch (action.type) {
    case WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST:
      return { walkin_sales_invoice_by_id_loading: true, walkin_sales_invoice_by_id_data:{}  };
    case WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS:
      return {
        walkin_sales_invoice_by_id_loading: false,
        walkin_sales_invoice_by_id_data: action.payload,
      };
    case WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL:
      return { walkin_sales_invoice_by_id_loading: false, walkin_sales_invoice_by_id_error: action.payload };
    case WALKIN_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET:
      return { vehicle_invoice_by_invoice_no_data: {} };
    default:
      return state;
  }
};

export const walkinSalesInvoicePaymentDetailsUpdateByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_REQUEST:
      return { walkin_sales_invoice_receipt_update_loading: true };
    case WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_SUCCESS:
      return { walkin_sales_invoice_receipt_update_loading: false, walkin_sales_invoice_receipt_update_success: true, walkin_sales_invoice_receipt_update: action.payload };
    case WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_FAIL:
      return { walkin_sales_invoice_receipt_update_loading: false, walkin_sales_invoice_receipt_update_error: action.payload };
    case WALKIN_SALE_INVOICE_RECEIPT_DETAILS_UPDATE_BY_INVOICE_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const walkinSalesInvoicesListByPosReducer = (
  state = { walkin_sales_invoice_pos_data: {} },
  action
) => {
  switch (action.type) {
    case WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST:
      return { walkin_sales_invoice_pos_loading: true, walkin_sales_invoice_pos_data:{}  };
    case WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS:
      return {
        walkin_sales_invoice_pos_loading: false,
        walkin_sales_invoice_pos_data: action.payload,
      };
    case WALKIN_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL:
      return { walkin_sales_invoice_pos_loading: false, walkin_sales_invoice_pos_error: action.payload };
    default:
      return state;
  }
};