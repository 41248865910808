import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { listCategories } from "../../actions/categoryAction";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { createSubCategoryByCategory } from "../../actions/subCategoryAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  description: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
};

const SubCategoryForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedCategoryId, setSelectedCategoryId] = useState(() => "");
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;
  let cats = categories ? categories.categories : [];

  const subCategoryCreated = useSelector((state) => state.subCategoryCreated);
  const { success_create, subcategory } = subCategoryCreated;

  let renderCategoriesOptions = "";
  if (cats && cats.length > 0) {
    renderCategoriesOptions = cats.map((eachCategory, idx) => {
      return (
        <MenuItem key={idx} value={eachCategory._id}>
          {eachCategory.name}
        </MenuItem>
      );
    });
  }

  if (success_create) {
    console.log("Success Response to redirecting to Sub Category List");
    navigate("/admin/subcategories");
  }

  const handleChangeCategory = (e) => {
    console.log("Category Changed  " + e.target.value);
    setSelectedCategoryId(() => e.target.value);
  };

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(formState.values, selectedCategoryId);
      dispatch(
        createSubCategoryByCategory(
          formState.values.name,
          formState.values.description,
          selectedCategoryId
        )
      );
    }

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const customGrayColor = "#E9EBEE";
  return (
    <div className={classes.root}>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color={{ customGrayColor }}>
            </CardHeader>
            <CardBody>
              <form
                name="password-reset-form"
                method="post"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={3}>
                    <FormControl>
                      <InputLabel id="demo-simple-select-label">
                        Category
                      </InputLabel>
                      <Select
                        label="Category"
                        value={selectedCategoryId}
                        onChange={handleChangeCategory}
                        placeholder="Category"
                        style={{ width: "15rem" }}
                        size="small"
                        variant="outlined"
                        required
                      >
                        {renderCategoriesOptions}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Name"
                      label="Name*"
                      variant="outlined"
                      size="small"
                      name="name"
                      fullWidth
                      helperText={
                        hasError("name") ? formState.errors.name[0] : null
                      }
                      error={hasError("name")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.name || ""}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={3}>
                    <TextField
                      placeholder="Description"
                      label="Description *"
                      variant="outlined"
                      size="small"
                      name="description"
                      fullWidth
                      helperText={
                        hasError("description")
                          ? formState.errors.description[0]
                          : null
                      }
                      error={hasError("description")}
                      onChange={handleChange}
                      type="text"
                      value={formState.values.description || ""}
                    />
                  </Grid>
                    <Grid item xs={12} align="center">
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        color="secondary"
                        justify="center"
                        style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                      >
                        Create
                      </Button>

                    </Grid>
                  </Grid>
              </form>

            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>





  );

  //             <Grid container spacing={2}>
  //               <Grid item xs={12}>
  //                 <Select
  //                   value={selectedCategoryId}
  //                   onChange={handleChangeCategory}
  //                   placeholder="Category"
  //                   label="Category*"
  //                   style={{ width: "20rem" }}
  //                 >
  //                   {renderCategoriesOptions}
  //                 </Select>
  //               </Grid>
  //               <Grid item xs={12}>
  //                 <TextField
  //                   placeholder="Name"
  //                   label="Name*"
  //                   variant="outlined"
  //                   size="small"
  //                   name="name"
  //                   fullWidth
  //                   helperText={
  //                     hasError("name") ? formState.errors.name[0] : null
  //                   }
  //                   error={hasError("name")}
  //                   onChange={handleChange}
  //                   type="text"
  //                   value={formState.values.name || ""}
  //                 />
  //               </Grid>
  //               <Grid item xs={12}>
  //                 <TextField
  //                   placeholder="Description"
  //                   label="Description *"
  //                   variant="outlined"
  //                   size="small"
  //                   name="description"
  //                   fullWidth
  //                   helperText={
  //                     hasError("description")
  //                       ? formState.errors.description[0]
  //                       : null
  //                   }
  //                   error={hasError("description")}
  //                   onChange={handleChange}
  //                   type="description"
  //                   value={formState.values.description || ""}
  //                 />
  //               </Grid>
  //               <Grid item xs={12} align="center">
  //                 <Button
  //                   size="small"
  //                   variant="contained"
  //                   type="submit"
  //                   color="secondary"
  //                   justify="center"
  //                   style={{ marginLeft: "1rem", textTransform: 'capitalize' }}
  //                 >
  //                   Create
  //                 </Button>
  //               </Grid>
  //             </Grid>
  //           </form>
  //         </CardBody>
  //       </Card>
  //     </GridItem>
  //   </GridContainer>
  // </div>

};

export default SubCategoryForm;


















