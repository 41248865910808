import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_UPDATE_RESET,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_FAIL,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_RESET,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_RESET,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_RESET,
  ORDER_CREATE_RESET,
  LIST_MY_ORDERS_REQUEST,
  LIST_MY_ORDERS_SUCCESS,
  LIST_MY_ORDERS_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_RESET,
  ORDER_LIST_BY_STORE_REQUEST,
  ORDER_LIST_BY_STORE_FAIL,
  ORDER_LIST_BY_STORE_SUCCESS,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_REQUEST,
  ORDER_UPDATE_PAYMENT_DETAILS_RESET,
  ORDER_UPDATE_PAYMENT_DETAILS_FAIL,
  ORDER_UPDATE_PAYMENT_DETAILS_SUCCESS,
  ORDER_TRANSFER_REQUEST,
  ORDER_TRANSFER_SUCCESS,
  ORDER_TRANSFER_FAIL,
  ORDER_TRANSFER_RESET,
  ORDER_LIST_BY_MAPPED_STORE_REQUEST,
  ORDER_LIST_BY_MAPPED_STORE_FAIL,
  ORDER_LIST_BY_MAPPED_STORE_SUCCESS,
  ORDER_LIST_BY_MAPPED_STORE_RESET,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_RESET,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_FAIL,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_SUCCESS,
  ORDER_DETAILS_RESET,
} from "../constants/orderConstants";

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      };
    case ORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const orderUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_UPDATE_SUCCESS:
      return {
        loading: false,
        update_success: true,
        order: action.payload,
      };
    case ORDER_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_UPDATE_RESET:
      return {
        loading: false,
        update_success: false,
        order: {},
      };
    default:
      return state;
  }
};

export const orderTransferReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_TRANSFER_REQUEST:
      return {
        order_transfer_loading: true,
      };
    case ORDER_TRANSFER_SUCCESS:
      return {
        order_transfer_loading: false,
        order_transfer_success: true,
        order: action.payload,
      };
    case ORDER_TRANSFER_FAIL:
      return {
        order_transfer_loading: false,
        order_transfer_error: action.payload,
      };
    case ORDER_TRANSFER_RESET:
      return {
        order_transfer_loading: false,
        order_transfer_success: false,
        order_transfer_error: false,
        order: {},
      };
    default:
      return state;
  }
};

export const orderDetailsReducer = (
  state = { loading: true, order: { orderItems: [], shippingAddress: {} } },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        order: action.payload,
      };
    case ORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
      case ORDER_DETAILS_RESET:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return {
        loading: true,
      };
    case ORDER_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_PAY_RESET:
      return {};
    default:
      return state;
  }
};

export const orderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELIVER_REQUEST:
      return {
        loading: true,
      };
    case ORDER_DELIVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case ORDER_DELIVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_DELIVER_RESET:
      return {};
    default:
      return state;
  }
};

export const listMyOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case LIST_MY_ORDERS_REQUEST:
      return {
        loading: true,
      };
    case LIST_MY_ORDERS_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case LIST_MY_ORDERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_LIST_MY_RESET:
      return { orders: [] };
    default:
      return state;
  }
};

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case ORDER_LIST_SUCCESS:
      return {
        loading: false,
        orders: action.payload,
      };
    case ORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const orderListByStoreReducer = (
  state = { storeOrders: [] },
  action
) => {
  switch (action.type) {
    case ORDER_LIST_BY_STORE_REQUEST:
      return {
        loading_store_orders: true,
      };
    case ORDER_LIST_BY_STORE_SUCCESS:
      return {
        loading_store_orders: false,
        storeOrders: action.payload,
      };
    case ORDER_LIST_BY_STORE_FAIL:
      return {
        loading_store_orders: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return {
        loading: true,
      };
    case ORDER_DELETE_SUCCESS:
      return {
        loading: false,
        delete_success: true,
        order: action.payload,
      };
    case ORDER_DELETE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ORDER_DELETE_RESET:
      return {
        loading: false,
        delete_success: false,
        order: {},
      };
    default:
      return state;
  }
};

export const orderUpdatePaymentDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_REQUEST:
      return {
        update_payment_details_loading: true,
      };
    case ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_SUCCESS:
      return {
        update_payment_details_loading: false,
        update_payment_details_success: true,
        update_payment_details: action.payload,
      };
    case ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_FAIL:
      return {
        update_payment_details_loading: false,
        update_payment_details_error: action.payload,
      };
    case ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const ordersListByMappedStoreReducer = (
  state = { orders_mapped_to_store: [] },
  action
) => {
  switch (action.type) {
    case ORDER_LIST_BY_MAPPED_STORE_REQUEST:
      return {
        orders_mapped_to_store_loading: true,
      };
    case ORDER_LIST_BY_MAPPED_STORE_SUCCESS:
      return {
        orders_mapped_to_store_loading: false,
        orders_mapped_to_store: action.payload,
      };
    case ORDER_LIST_BY_MAPPED_STORE_FAIL:
      return {
        orders_mapped_to_store_loading: false,
        orders_mapped_to_store_error: action.payload,
      };
    case ORDER_LIST_BY_MAPPED_STORE_RESET:
      return {};
    default:
      return state;
  }
};
