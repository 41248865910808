import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  Tooltip,
  Divider,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DialogContent from "@mui/material/DialogContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import { listStockByVehicleNoSourceDestination } from "../../actions/transitStockAction";
import { createNewStockNotificationByVehicleDestinationAndProductName } from "../../actions/newStockNotificationAction";
import { updateTransitStockStatusToAwaitingConfirmation } from "../../actions/transitStockAction";
import { TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET } from "../../constants/transitStockConstants";

export default function TransitStockEntryDetailsScreen() {
  const { veh, src, dest } = useParams();
  const [open, setOpen] = useState(() => false);
  console.log("Transit Stock Entry Details Screen FOR TransitStockId : ", veh, src, dest);
  const styles = {
    formControl: {
      margin: "1rem",
      minWidth: "20rem",
    },
    cardTitleWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,10)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleGreen: {
      color: "#26A541",
      marginTop: "0px",
      minHeight: "auto",
      fontFamily: "Roboto",
      marginBottom: "3px",
      textDecoration: "none",
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "capitalize",
      textAlign: "left",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const transitStockListByVehicleNoSourceDestination = useSelector((state) => state.transitStockListByVehicleNoSourceDestination);
  const newStockNotificationCreate = useSelector((state) => state.newStockNotificationCreate);
  const { transit_stock_by_vehicle_no_source_dest, transit_stock_by_vehicle_no_source_dest_error, transit_stock_by_vehicle_no_source_dest_loading } = transitStockListByVehicleNoSourceDestination;
  const { new_stock_create_success, new_stock_create_error, new_stock } = newStockNotificationCreate;

  useEffect(() => {
    if (veh && src && dest) dispatch(listStockByVehicleNoSourceDestination(veh, src, dest));
  }, [dispatch, veh]);

  useEffect(() => {
    console.log("useEffect Getting Called Transit Stock Entry Details Screen");
    if (new_stock_create_success) {
      dispatch({ type: TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET });
      navigate("/admin/transit-stock/list");
    }
  }, [dispatch, navigate, new_stock_create_success]);

  const columnDefs = [
    { field: '#', width: 100, suppressSizeToFit: true },
    { field: 'ProductName', width: 500, suppressSizeToFit: true },
    { field: 'CountInStock', width: 300 },
    {
      field: 'Status', width: 300,
      cellStyle: params => {
        if (params.value === 'YET TO DELIVER') {
          return { color: 'white', backgroundColor: 'red', textAlign: "center" };
        }
        else if (params.value === 'DELIVERED - AWAITING CONFIRMATION') {
          return { color: 'white', backgroundColor: 'orange', textAlign: "center" };
        }
        else if (params.value === 'DELIVERED - CONFIRMED') {
          return { color: 'white', backgroundColor: 'green', textAlign: "center" };
        }
        return null;
      }
    },
  ]
  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: true,
    floatingFilter: true,
    resizable: true,
  }), []
  )
  let rowData = [];
  const falseValue = false;
  const trueValue = true;

  let gridApi = "";
  let gridColumnApi;
  const onGridReady = (params) => {
    gridApi = params.api;
  };
  const exportData = () => {
    gridApi.exportDataAsCsv();
  };
  let renderContentUsingAGGrid = "";

  const groupByProperty = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
  const confirmHandler = () => {
    console.log("Clicked YES in confirmHandler of Transit Stock Entry Details ");
    console.log("transit_stock_by_vehicle_no_source_dest :: ", transit_stock_by_vehicle_no_source_dest ," dest :: ", dest, "src :: ", src, "vehNo :: ",veh );
    Object.entries(transit_stock_by_vehicle_no_source_dest).map(([key, value],index) => {
      console.log( `${index} : ${key} ::==> ${value}`); 
    });
    // const groupedByDestinationName = groupByProperty(transit_stock_by_vehicle_no_source_dest,'destinationName');
    console.log("Before Submiting to Stock Arrival transit_stock_by_vehicle_no_source_dest : ", transit_stock_by_vehicle_no_source_dest)
    dispatch(createNewStockNotificationByVehicleDestinationAndProductName(transit_stock_by_vehicle_no_source_dest, dest));

    //UPDATE THE TRANSIT STOCK STATUS to AWAITING FOR CONFIRMATION AFTER CLICKING DELIVER ALL BUTTON.!
    console.log("UPDATE THE TRANSIT STOCK STATUS to AWAITING FOR CONFIRMATION AFTER CLICKING DELIVER ALL BUTTON.", transit_stock_by_vehicle_no_source_dest)
    dispatch(updateTransitStockStatusToAwaitingConfirmation(veh,src, dest,transit_stock_by_vehicle_no_source_dest));

    setOpen(() => false);
  }

  const checkIfDisable = () => {
    if (transit_stock_by_vehicle_no_source_dest && transit_stock_by_vehicle_no_source_dest.length > 0) {
      console.log("checkIfDisable : ", transit_stock_by_vehicle_no_source_dest.filter(e => e.status === 'YET TO DELIVER'));
      const filteredArray = transit_stock_by_vehicle_no_source_dest.filter(e => e.status === 'YET TO DELIVER');
      console.log("filteredArray : ", filteredArray);
      if (filteredArray && filteredArray.length > 0)
        return falseValue;
      else
        return trueValue;
    }
  }

  if (transit_stock_by_vehicle_no_source_dest && transit_stock_by_vehicle_no_source_dest.length > 0) {
    const yetTocount = transit_stock_by_vehicle_no_source_dest.filter(e => e.status === 'YET TO DELIVER').length;
    console.log("Checking to disable if Yet To Count :  ", yetTocount);
    rowData = transit_stock_by_vehicle_no_source_dest.map((prd, index) => {
      return {
        '#': index + 1,
        'ProductName': prd.productName,
        'CountInStock': prd.countInStock,
        'Status': prd.status,
      }
    });

    renderContentUsingAGGrid = (
      <div>
        <div className="ag-theme-quartz" style={{ width: "100%", height: '400px' }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            onGridReady={onGridReady}
            pagination="true"
          >
          </AgGridReact>
        </div>
        <GridItem xs={12} align="center" style={{ margin: "1rem" }}>
          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
            fullWidth={true}
            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            onClick={() => setOpen(true)}
            disabled={transit_stock_by_vehicle_no_source_dest && checkIfDisable()}
            justify="center"
          >
            Deliver all Stock
          </Button>
        </GridItem>
      </div>
    )
  }

  return (
    <React.Fragment>
      {transit_stock_by_vehicle_no_source_dest_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon fontSize="medium" style={{ float: "right", margin: "1rem" }} onClick={onExportClick} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon fontSize="medium" onClick={() => window.history.back()} style={{ float: "left", marginRight: "3em" }} />
              </Tooltip>
              <Typography variant="h5" style={{ fontWeight: 500 }}>
                Transit Stock Entry Details
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{renderContentUsingAGGrid ? renderContentUsingAGGrid : null}</CardBody>
          </Card>
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)} style={{ height: '100%', width: '100%' }}>
          <DialogContent>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader >
                  <Typography variant="subtitle" style={{ fontWeight: 300 }}>
                    Stock is delivered ?
                  </Typography>
                  <Divider />
                </CardHeader>
                <CardBody>
                  <Grid container spacing={2}>
                    <Grid item xs={12} align="center">
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        color="secondary"
                        justify="center"
                        onClick={confirmHandler}
                        style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                      >
                        Yes
                      </Button>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
}
