import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Section from "../organisms/Section/Section";
import SubCategoryForm from "./SubCategoryForm";
import { Grid, Tooltip,Typography } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";

const SubCategoryCreate = ({ match }) => {
  const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
    formContainer: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
      maxWidth: 'auto',
      margin: `0 auto`,
    },
    section: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  }));
  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={1}>
        <GridItem xs={12} style={{ marginTop: "0.5rem" }}>
        <Tooltip title="Back" arrow>
  <ArrowBackIcon fontSize="medium" onClick={()=>window.history.back()} style={{ float: "left" }} />
  </Tooltip>
          
        </GridItem>
      </Grid>
      <div>
      <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
          <Typography variant="h5" style={{ fontWeight: 500, textAlign:"center" }}>
           <b>New SubCategory</b>
          </Typography>
        </CardHeader>
      </Card> 
    </GridItem>
  </GridContainer>
  </div>
      {/* <Section> */}
        <div className={classes.formContainer}>
          <SubCategoryForm />
        </div>
      {/* </Section> */}
    </div>
  );
};

export default SubCategoryCreate;
