import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { createVersionUpdates } from "../../actions/versionUpdatesAction.js";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_REGISTER_RESET } from "../../constants/customerConstants.js";
import { VERSION_UPDATES_CREATE_RESET } from "../../constants/versionUpdatesConstants.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  description: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 500,
    },
  },
};

const VersionUpdatesCreateForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const versionUpdatesCreated = useSelector((state) => state.versionUpdatesCreated);
  const { version_updates_create_loading, version_updates_create_error, version_updates_create_success, version_updates_create } = versionUpdatesCreated;
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;


  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (version_updates_create_success) {
      console.log('Registered New Version Update so Routing to List Page: ')
      dispatch({
        type: VERSION_UPDATES_CREATE_RESET,
      });
      navigate('/admin/version-updates');
    }
  }, [version_updates_create_success, navigate, dispatch])

  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "select"
            ? event.target.selected
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", formState.values);
    event.preventDefault();
    console.log("formState.isValid : ", formState.isValid);
    console.log(formState.isValid);
    if (formState.isValid)
      dispatch(
        createVersionUpdates(
          formState.values.description,
        )
      );

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {version_updates_create_error && <Message variant="danger">{version_updates_create_error}</Message>}
      {version_updates_create_loading && <CustomBackdropSpinner />}
      <form method="post" onSubmit={handleSubmit}>

        <Grid container spacing={2} justifyContent="center" marginTop="auto">
          <Grid item xs={12} align="center">
            <TextField
              label="Description"
              variant="outlined"
              name="description"
              error={hasError("description")}
              onChange={handleChange}
              autoFocus
              size="small"
              maxRows="5"
              minRows="5"
              multiline
              value={formState.values.description || ""}
              type="text"
              fullWidth
              helperText={
                hasError("description") ? formState.errors.description[0] : null
              }
              InputProps={{
                classes: { input: classes.input },
              }}
            />

          </Grid>
          <Grid item xs={12} align="center">
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{
                textTransform: "capitalize",
                width: "25vh",
                borderRadius: "5px",
              }}
            >
              Add Software Version
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default VersionUpdatesCreateForm;
