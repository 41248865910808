import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import {
  Tooltip,
  Typography,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
  Input,
  AlertTitle,
  duration,
} from "@mui/material";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportToExcel } from "../../util/exportToExcelUtil.js";
import { convertInvoiceIdToDigits, getFirstAndlastDayOfCurrentMonth, getFirstAndlastDayOfPreviousMonth } from '../../util/Utility.js'
import { formatToLocaleCurrency } from "../../util/Utility.js";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { financialYearOptions, quarterlyOptions, reportDurations } from "../../data/index.js";
import * as XLSX from 'xlsx';
import { syncVyaparWalkinSaleInvoices, listVyaparWalkinSaleByStore } from "../../actions/walkinVyaparSaleReportAction.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Message from "../Message.js";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField/DateField';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';


import { listAllStores } from "../../actions/storeAction.js";
// import { DatePicker } from "@mui/x-date-pickers";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "300",
      lineHeight: "1",
    },
  },
};

const WalkinVyaparSaleReport = ({ match }) => {
  const useStyles = makeStyles(styles);
  const gridRef = useRef();

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;
  useEffect(() => {
    console.log("Use Effect Gets Called for fetching the Vyapar Sale Report from MIS...!");
    dispatch(listVyaparWalkinSaleByStore('DLF_STORE'))
  }, []);

  const tempColDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    // {
    //   field: "Id",
    //   resizable: true,
    //   suppressSizeToFit: true,
    //   width: 275,
    //   minWidth: 275,
    //   maxWidth: 275,
    //   filter: false,
    // },
    {
      field: "InvoiceNo",
      headerName: "Invoice No",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false
    },
    {
      field: "Date",
      headerName: "Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false,
    },
    {
      field: "StoreName",
      headerName: "Store Name",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "left" };
      },
      width: 175,
      minWidth: 175,
      maxWidth: 175,
      filter: false,
    },
    {
      field: "PartyName",
      headerName: "Party Name",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "left" };
      },
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: false
    },
    {
      field: "PartyPhoneNo",
      headerName: "Party Phone No",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false
    },
    {
      field: "TransactionType",
      headerName: "Transaction Type",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "center" };
      },
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: false
    },
    {
      field: "TotalAmount",
      headerName: "Total Amount",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "right" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "PaidAmount",
      headerName: "Paid Amount",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "right" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "PaymentType",
      headerName: "Payment Type",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "center" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false
    },
    {
      field: "BalanceDue",
      headerName: "Balance Due",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "right" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "PaymentStatus",
      headerName: "Payment Status",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        // console.log("PaymentStatus", params.data.PaymentStatus)
        const payStatus = params.data.PaymentStatus
        // console.log(" dueDate is Number .")
        if (payStatus === "Paid") {
          return { color: '008200', backgroundColor: "#D0F0C0", textAlign: "center" };
        } else {
          return { color: '#FF0800', backgroundColor: "#FBCEB1", textAlign: "center" };
        }
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false
    },

  ]
  const [loadingExcelData, setLoadingExcelData] = useState(false);
  const [customers, setCustomers] = useState(() => []);
  const [selectedStoreName, setSelectedStoreName] = useState(() => "VNK_STORE");
  const [paymentTypes, setPaymentTypes] = useState(() => []);
  const [currentMonthData, setCurrentMonthData] = useState(() => []);
  const [previousMonthData, setPreviousMonthData] = useState(() => []);
  const [storeWiseData, setStoreWiseData] = useState(() => []);
  const [fourQuartersData, setFourQuartersData] = useState(() => { });
  const [currentQuarterData, setCurrentQuarterData] = useState(() => { });
  const [selectedCustomer, setSelectedCustomer] = useState(() => "");
  const [selectedPaymentType, setSelectedPaymentType] = useState(() => "");
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(() => tempColDefs)
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName, role, userName } = userInfo;

  const [posName, setPosName] = useState(() => "");
  const [sumOfPaid, setSumOfPaid] = useState(() => 0.00);
  const [sumOfUnpaid, setSumOfUnpaid] = useState(() => 0.00);
  const [sumOfTotalSale, setSumOfTotalSale] = useState(() => 0.00);
  const [reportDuration, setReportDuration] = useState(() => "");
  const [quarterSelected, setQuarterSelected] = useState(() => "Q2 [July - September]");
  const [financialSelectedYear, setFinancialSelectedYear] = useState("2023 - 2024");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [yearlyData, setYearlyData] = useState(() => []);
  const [customDataBetweenStartEndDates, setCustomDataBetweenStartEndDates] = useState(() => []);
  const [posStoreOrWarehouse, setPosStoreOrWarehouse] = useState(() => "");
  const [firstAndLastDayOfMonth, setFirstAndLastDayOfMonth] = useState("");
  const [firstAndLastDayOfCurrentMonth, setFirstAndLastDayOfCurrentMonth] = useState("");
  const [firstAndLastDayOfPreviousMonth, setFirstAndLastDayOfPreviousMonth] = useState("");
  const [customStartDate, setCustomStartDate] = useState(() => "");
  const [customEndDate, setCustomEndDate] = useState(() => "");
  const quarterlyOptionsFilter = quarterlyOptions && quarterlyOptions.length > 0 ? quarterlyOptions.map((eachQtrly, idx) => <MenuItem key={idx} value={eachQtrly}>{eachQtrly}</MenuItem>) : [];
  const financialYearOptionsFilter = financialYearOptions && financialYearOptions.length > 0 ? financialYearOptions.map((eachFy, idx) => <MenuItem key={idx} value={eachFy}>{eachFy}</MenuItem>) : [];

  let currentMonthFirstLastDate = ""
  let previousMonthFirstLastDate = ""
  let salesReportByProducts = [];
  let renderContentUsingAGGrid = "";
  const [salesContentUsingAGGrid, setSalesContentUsingAGGrid] = useState(() => null);
  const [salesContentProductWiseUsingAGGrid, setSalesContentProductWiseUsingAGGrid] = useState(() => null);
  let noDataFoundContent = ""
  const [open, setOpen] = React.useState(true);


  useEffect(() => {
    console.log("Use Effect Gets Called for fetching FirstAndlastDayOfMonth..STRICTLY ONLY ONCE.!");
    currentMonthFirstLastDate = getFirstAndlastDayOfCurrentMonth()
    previousMonthFirstLastDate = getFirstAndlastDayOfPreviousMonth()
      setFirstAndLastDayOfMonth(() => currentMonthFirstLastDate.firstDay + ' To ' + currentMonthFirstLastDate.lastDay)
      setFirstAndLastDayOfMonth(() => previousMonthFirstLastDate.firstDay + ' To ' + previousMonthFirstLastDate.lastDay)
  }, []);

  const walkinVyaparSaleByPos = useSelector((state) => state.walkinVyaparSaleByPos);
  const { walkin_vyapar_sale_report_pos_loading, walkin_vyapar_sale_report_pos_data, walkin_vyapar_sale_report_pos_error } = walkinVyaparSaleByPos;

  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const saveToRowData = (data) => {
    console.log('############ saveToRowData  ############')
    setRowData(() => data.map((saleByPos, index) => {
      const invIdInDecimal = convertInvoiceIdToDigits(saleByPos._id)
      return {
        "#": index + 1,
        InvoiceNo: saleByPos.invoiceNo ? saleByPos.invoiceNo : "",
        StoreName: saleByPos.storeName ? saleByPos.storeName : "",
        Date: saleByPos.date
          ? saleByPos.date
          : "",
        PartyName: saleByPos.partyName && saleByPos.partyName
          ? saleByPos.partyName : "",
        PartyPhoneNo: saleByPos.partyPhoneNo
          ? saleByPos.partyPhoneNo
          : "",
        TransactionType: saleByPos.transactionType
          ? saleByPos.transactionType
          : "",
        PaymentType: saleByPos.paymentType
          ? saleByPos.paymentType
          : "",
        BalanceDue: saleByPos.balanceDue
          ? saleByPos.balanceDue
          : 0.0,
        PaymentStatus: saleByPos.paymentStatus
          ? saleByPos.paymentStatus
          : "",
        TotalAmount: saleByPos.totalAmount
          ? formatToLocaleCurrency(saleByPos.totalAmount)
          : 0.0,
        PaidAmount: saleByPos.paidAmount
          ? formatToLocaleCurrency(saleByPos.paidAmount)
          : 0.0,
      };
    }));
  }
  // Calculate the sum of all the totalAmount if the paymentStatus is Paid and paymentType is 'Cash'
  const sumPaid = (data) => {
    console.log('$$$$$ Exec sumPaid $$$$')
    let paidTotal = 0;
    if (data && data.length > 0) {
      paidTotal = data
        .filter(item => item.paymentStatus === 'Paid')
        .reduce((sum, item) => sum + item.totalAmount, 0);
      console.log('$$$$$ Sum of totalAmount for Paid ', paidTotal);
      if (paidTotal)
        setSumOfPaid(() => paidTotal.toFixed(2))
      else
        setSumOfPaid(() => 0.00)
    } else
      setSumOfPaid(() => 0.00)
  }

  // Calculate the sum of all the totalAmount if the paymentStatus is UnPaid 
  const sumUnpaid = (data) => {
    console.log('$$$$$ Exec sumUnpaid $$$$')
    let unPaidTotal = 0;
    if (data && data.length > 0) {
      unPaidTotal = data
        .filter(item => item.paymentStatus === 'Unpaid')
        .reduce((sum, item) => sum + item.totalAmount, 0);
      console.log('$$$$$ Sum of totalAmount for UnPaid ', unPaidTotal);
      if (unPaidTotal) {
        setSumOfUnpaid(() => unPaidTotal.toFixed(2))
      } else
        setSumOfUnpaid(() => 0.00)
    } else
      setSumOfUnpaid(() => 0.00)
  }

  const sumTotalSaleAmount = (data) => {
    console.log('$$$$$ Exec sumTotalSaleAmount $$$$')
    let totalSaleAmount = 0;
    if (data && data.length > 0) {
      totalSaleAmount = data.reduce((sum, item) => sum + item.totalAmount, 0);
      if (totalSaleAmount) {
        setSumOfTotalSale(() => totalSaleAmount.toFixed(2))
      } else {
        setSumOfTotalSale(() => 0.00)
      }
    } else {
      setSumOfTotalSale(() => 0.00)
      console.log('$$$$$ Sum of Total Sale Amount  ', totalSaleAmount);
    }
  }

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      ensureDomOrder: true
    }),
    []
  );

  const syncNow = () => {
    if (rowData) {
      console.log('########## PERFORMING MIS SYNC WITH VYAPAR ##########')
      dispatch(syncVyaparWalkinSaleInvoices(rowData, selectedStoreName))
    } else {
      console.error('########## FAILED PERFORMING MIS SYNC WITH VYAPAR AS NO DATA EXISTS ##########')
    }
  }

  const uniquePartyNames = () => {
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      const uniqueCustomers = [...new Set(walkin_vyapar_sale_report_pos_data.map(item => item.partyName))]
      console.log('uniqueCustomers : ', uniqueCustomers ? uniqueCustomers.length : 0)
      if (uniqueCustomers)
        setCustomers(() => uniqueCustomers)
    }
  }
  const uniquePaymentTypes = () => {
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      const uniquePaymentTypes = [...new Set(walkin_vyapar_sale_report_pos_data.map(item => item.paymentType))]
      console.log('uniquePaymentTypes : ', uniquePaymentTypes ? uniquePaymentTypes.length : 0)
      if (uniquePaymentTypes)
        setPaymentTypes(() => uniquePaymentTypes)
    }
  }

  const prepareDataForSelectedCustomer = () => {
    console.log('Exec prepareDataForSelectedCustomer ...')
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 && selectedCustomer && selectedCustomer.length > 0) {
      const salesByCustomerName = walkin_vyapar_sale_report_pos_data.filter(each => each.partyName === selectedCustomer)
      if (salesByCustomerName && salesByCustomerName.length > 0) {
        sumPaid(salesByCustomerName)
        sumUnpaid(salesByCustomerName)
        sumTotalSaleAmount(salesByCustomerName)
        uniquePartyNames(salesByCustomerName)
        saveToRowData(salesByCustomerName)
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSelectedYear = () => {
    console.log('Exec prepareDataForSelectedYear ...')
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 && selectedYear && selectedYear.length > 0) {
      const salesByCustomerName = walkin_vyapar_sale_report_pos_data.filter(each => each.partyName === selectedCustomer)
      if (salesByCustomerName) {
        sumPaid(salesByCustomerName)
        sumUnpaid(salesByCustomerName)
        sumTotalSaleAmount(salesByCustomerName)
        uniquePartyNames(salesByCustomerName)
        // saveToRowData(salesByCustomerName)

      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSelectedPaymentType = () => {
    console.log('Exec prepareDataForSelectedPaymentType ...')
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 && selectedPaymentType && selectedPaymentType.length > 0) {
      const salesByPaymentType = walkin_vyapar_sale_report_pos_data.filter(each => each.paymentType === selectedPaymentType)
      if (salesByPaymentType && salesByPaymentType.length > 0) {
        sumPaid(salesByPaymentType)
        sumUnpaid(salesByPaymentType)
        sumTotalSaleAmount(salesByPaymentType)
        uniquePaymentTypes(salesByPaymentType)
        saveToRowData(salesByPaymentType);
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const fetchByStoreName = () => {
    console.log('Exec fetchByStoreName')
    const filterSalesByStoreName = walkin_vyapar_sale_report_pos_data.filter(each => each.storeName === selectedStoreName)
    if (filterSalesByStoreName) {
      setStoreWiseData(() => filterSalesByStoreName)
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 Count of filterSalesByStoreName : ', filterSalesByStoreName ? filterSalesByStoreName.length : 0)
    } else {
      console.log('\u1F624 \u1F624  \u1F624  \u1F624  Count of filterSalesByStoreName IS ZERO: ')
    }
  }

  const prepareDataForSelectedCustomerAndPaymentType = () => {
    console.log('Exec prepareDataForSelectedCustomerAndPaymentType ...')
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      const filterSalesByCustomerName = walkin_vyapar_sale_report_pos_data.filter(each => each.partyName === selectedCustomer)
      if (filterSalesByCustomerName && filterSalesByCustomerName.length > 0) {
        const salesByCustomerAndPaymentType = filterSalesByCustomerName.filter(each => each.paymentType === selectedPaymentType)
        if (salesByCustomerAndPaymentType && salesByCustomerAndPaymentType.length > 0) {
          sumPaid(salesByCustomerAndPaymentType)
          sumUnpaid(salesByCustomerAndPaymentType)
          sumTotalSaleAmount(salesByCustomerAndPaymentType)
          uniquePaymentTypes(salesByCustomerAndPaymentType)
          saveToRowData(salesByCustomerAndPaymentType);
        }
      }

    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSelectedCustomerReportDuartion = () => {
    console.log('Exec prepareDataForSelectedCustomerReportDuartion ...', selectedCustomer, reportDuration)
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      const filterSalesByCustomerName = walkin_vyapar_sale_report_pos_data.filter(each => each.partyName === selectedCustomer)
      if (filterSalesByCustomerName && filterSalesByCustomerName.length > 0) {
        switch (reportDuration) {
          case "currentMonth":
            setCurrentMonthData(() => []);
            fetchByCurrentMonth(filterSalesByCustomerName)
            if (currentMonthData && currentMonthData.length > 0) {
              console.log('currentMonthData  : ', currentMonthData ? currentMonthData.length : 0)
              sumPaid(currentMonthData)
              sumUnpaid(currentMonthData)
              sumTotalSaleAmount(currentMonthData)
              saveToRowData(currentMonthData)
            }
            break;
          case "previousMonth":
            setPreviousMonthData(() => [])
            fetchByPreviousMonth(filterSalesByCustomerName)
            if (previousMonthData && previousMonthData.length > 0) {
              console.log('previousMonthData : ', previousMonthData ? previousMonthData.length : 0)
              sumPaid(previousMonthData)
              sumUnpaid(previousMonthData)
              sumTotalSaleAmount(previousMonthData)
              saveToRowData(previousMonthData)
            }
            break;
          case "quarterly":
            setCurrentQuarterData(() => [])
            fetchByQuarter(filterSalesByCustomerName)
            if (currentQuarterData && currentQuarterData.length > 0) {
              console.log('Current Quarter Data: ', currentQuarterData ? currentQuarterData.length : 0)
              sumPaid(currentQuarterData)
              sumUnpaid(currentQuarterData)
              sumTotalSaleAmount(currentQuarterData)
              saveToRowData(currentQuarterData)
            }
            break;
          case "yearly":
            fetchByYear(filterSalesByCustomerName)
            if (yearlyData && yearlyData.length > 0) {
              console.log('yearlyData : ', yearlyData ? yearlyData.length : 0)
              sumPaid(yearlyData)
              sumUnpaid(yearlyData)
              sumTotalSaleAmount(yearlyData)
              saveToRowData(yearlyData)
            }
            break;
          case "custom":

            break;

          default:
            break;
        }
      }

    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSelectedPaymentTypeReportDuartion = () => {
    console.log('Exec prepareDataForSelectedPaymentTypeReportDuartion ...', selectedPaymentType, reportDuration)
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      const filterSalesByPaymentType = walkin_vyapar_sale_report_pos_data.filter(each => each.paymentType === selectedPaymentType)
      if (filterSalesByPaymentType && filterSalesByPaymentType.length > 0) {
        switch (reportDuration) {
          case "currentMonth":
            setCurrentMonthData(() => []);
            fetchByCurrentMonth(filterSalesByPaymentType)
            if (currentMonthData && currentMonthData.length > 0) {
              console.log('currentMonthData  : ', currentMonthData ? currentMonthData.length : 0)
              sumPaid(currentMonthData)
              sumUnpaid(currentMonthData)
              sumTotalSaleAmount(currentMonthData)
              saveToRowData(currentMonthData)
            }
            break;
          case "previousMonth":
            setPreviousMonthData(() => [])
            fetchByPreviousMonth(filterSalesByPaymentType)
            if (previousMonthData && previousMonthData.length > 0) {
              console.log('previousMonthData : ', previousMonthData ? previousMonthData.length : 0)
              sumPaid(previousMonthData)
              sumUnpaid(previousMonthData)
              sumTotalSaleAmount(previousMonthData)
              saveToRowData(previousMonthData)
            }
            break;
          case "quarterly":
            setCurrentQuarterData(() => [])
            fetchByQuarter(filterSalesByPaymentType)
            if (currentQuarterData && currentQuarterData.length > 0) {
              console.log('Current Quarter Data: ', currentQuarterData ? currentQuarterData.length : 0)
              sumPaid(currentQuarterData)
              sumUnpaid(currentQuarterData)
              sumTotalSaleAmount(currentQuarterData)
              saveToRowData(currentQuarterData)
            }
            break;
          case "yearly":
            fetchByYear(filterSalesByPaymentType)
            if (yearlyData && yearlyData.length > 0) {
              console.log('yearlyData : ', yearlyData ? yearlyData.length : 0)
              sumPaid(yearlyData)
              sumUnpaid(yearlyData)
              sumTotalSaleAmount(yearlyData)
              saveToRowData(yearlyData)
            }
            break;
          case "custom":

            break;

          default:
            break;
        }
      }

    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSelectedCustomerPaymentTypeDuration = () => {
    console.log('Exec prepareDataForSelectedCustomerPaymentTypeDuration ...', selectedCustomer, selectedPaymentType, reportDuration)
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      const filterSalesByCustomerName = walkin_vyapar_sale_report_pos_data.filter(each => each.partyName === selectedCustomer)
      if (filterSalesByCustomerName && filterSalesByCustomerName.length > 0) {
        const filterSalesByCustomerPaymentType = filterSalesByCustomerName.filter(each => each.paymentType === selectedPaymentType)
        if (filterSalesByCustomerPaymentType && filterSalesByCustomerPaymentType.length > 0) {
          switch (reportDuration) {
            case "currentMonth":
              setCurrentMonthData(() => [])
              const filteredData = filterByCurrentMonth(filterSalesByCustomerPaymentType)
              if (filteredData && filteredData.length > 0) {
                console.log('filterSalesByCustomerPaymentType  : ', filteredData ? filteredData.length : 0)
                sumPaid(filteredData)
                sumUnpaid(filteredData)
                sumTotalSaleAmount(filteredData)
                saveToRowData(filteredData)
              } else {
                sumPaid([])
                sumUnpaid([])
                sumTotalSaleAmount([])
                setRowData(()=>[])
              }
              break;
            case "previousMonth":
              setPreviousMonthData(() => [])
              console.log('Mateched previousMonth in prepareDataForSelectedCustomerPaymentTypeDuration', selectedCustomer, selectedPaymentType, reportDuration)
              const filteredPrevMonData = filterByPreviousMonth(filterSalesByCustomerPaymentType)
              if (filteredPrevMonData && filteredPrevMonData.length > 0) {
                console.log('previousMonthData : ', filteredPrevMonData ? filteredPrevMonData.length : 0)
                sumPaid(filteredPrevMonData)
                sumUnpaid(filteredPrevMonData)
                sumTotalSaleAmount(filteredPrevMonData)
                saveToRowData(filteredPrevMonData)
              }
              else {
                sumPaid([])
                sumUnpaid([])
                sumTotalSaleAmount([])
                setRowData(()=>[])
              }
              break;
            case "quarterly":
              setCurrentQuarterData(() => [])
              console.log('Mateched quarterly in prepareDataForSelectedCustomerPaymentTypeDuration', selectedCustomer, selectedPaymentType, reportDuration)
              const filteredByCurrentQuarterData = filterByQuarter(filterSalesByCustomerPaymentType)
              if (filteredByCurrentQuarterData && filteredByCurrentQuarterData.length > 0) {
                console.log('Current Quarter Data: ', filteredByCurrentQuarterData ? filteredByCurrentQuarterData.length : 0)
                sumPaid(filteredByCurrentQuarterData)
                sumUnpaid(filteredByCurrentQuarterData)
                sumTotalSaleAmount(filteredByCurrentQuarterData)
                saveToRowData(filteredByCurrentQuarterData)
              }
              else {
                sumPaid([])
                sumUnpaid([])
                sumTotalSaleAmount([])
                setRowData(()=>[])
              }
              break;
            case "yearly":
              fetchByYear(filterSalesByCustomerPaymentType)
              if (yearlyData && yearlyData.length > 0) {
                console.log('yearlyData : ', yearlyData ? yearlyData.length : 0)
                sumPaid(yearlyData)
                sumUnpaid(yearlyData)
                sumTotalSaleAmount(yearlyData)
                saveToRowData(yearlyData)
              }
              break;
            case "custom":

              break;

            default:
              break;
          }
        }
      }

    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSelectedDuration = () => {
    console.log('Exec prepareDataForSelectedDuration ...', reportDuration)
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 && reportDuration) {
      switch (reportDuration) {
        case "currentMonth":
          fetchByCurrentMonth(walkin_vyapar_sale_report_pos_data)
          if (currentMonthData && currentMonthData.length > 0) {
            console.log('currentMonthData : ', currentMonthData ? currentMonthData.length : 0)
            sumPaid(currentMonthData)
            sumUnpaid(currentMonthData)
            sumTotalSaleAmount(currentMonthData)
            saveToRowData(currentMonthData)
          }
          break;
        case "previousMonth":
          fetchByPreviousMonth(walkin_vyapar_sale_report_pos_data)
          if (previousMonthData && previousMonthData.length > 0) {
            console.log('previousMonthData : ', previousMonthData ? previousMonthData.length : 0)
            sumPaid(previousMonthData)
            sumUnpaid(previousMonthData)
            sumTotalSaleAmount(previousMonthData)
            saveToRowData(previousMonthData)
          }
          break;
        case "quarterly":
          fetchByQuarter(walkin_vyapar_sale_report_pos_data)
          if (currentQuarterData && currentQuarterData.length > 0) {
            console.log('Current QuarterData : ', currentQuarterData ? currentQuarterData.length : 0)
            sumPaid(currentQuarterData)
            sumUnpaid(currentQuarterData)
            sumTotalSaleAmount(currentQuarterData)
            saveToRowData(currentQuarterData)
          }
          break;
        case "yearly":
          fetchByYear(walkin_vyapar_sale_report_pos_data)
          if (yearlyData && yearlyData.length > 0) {
            console.log('yearlyData : ', yearlyData ? yearlyData.length : 0)
            sumPaid(yearlyData)
            sumUnpaid(yearlyData)
            sumTotalSaleAmount(yearlyData)
            saveToRowData(yearlyData)
          }
          break;
        case "custom":
          break;
        default:
          break;
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSalesReport = () => {
    console.log('Exec prepareDataForSalesReport ...')
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      sumPaid(walkin_vyapar_sale_report_pos_data)
      sumUnpaid(walkin_vyapar_sale_report_pos_data)
      sumTotalSaleAmount(walkin_vyapar_sale_report_pos_data)
      uniquePartyNames(walkin_vyapar_sale_report_pos_data)
      uniquePaymentTypes(walkin_vyapar_sale_report_pos_data)
      saveToRowData(walkin_vyapar_sale_report_pos_data)
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const fetchByCurrentMonth = (data) => {
    console.log('Exec fetchByCurrentMonth')
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
    const currentYear = currentDate.getFullYear();
    const filteredByCurrentMonth = data.filter(record => {
      const [day, month, year] = record.date.split('/').map(Number);
      return month === currentMonth && year === currentYear;
    });
    if (filteredByCurrentMonth && filteredByCurrentMonth.length > 0) {
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 Count of filteredByCurrentMonth : ', filteredByCurrentMonth && filteredByCurrentMonth.length > 0 ? filteredByCurrentMonth.length : 0)
      setCurrentMonthData(() => filteredByCurrentMonth);
      saveToRowData(filteredByCurrentMonth);
    } else {
      saveToRowData([]);
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00  Count of filteredByCurrentMonth IS ZERO so RESETING ROWDATA TO []: ')

    }
  }

  const filterByCurrentMonth = (data) => {
    console.log('Exec filterByCurrentMonth')
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const filteredByCurrentMonth = data.filter(record => {
      const [day, month, year] = record.date.split('/').map(Number);
      return month === currentMonth && year === currentYear;
    });
    if (filteredByCurrentMonth && filteredByCurrentMonth.length > 0) {
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 Count of filterByCurrentMonth : ', filteredByCurrentMonth && filteredByCurrentMonth.length > 0 ? filteredByCurrentMonth.length : 0)

    } else {
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00  Count of filterByCurrentMonth IS ZERO .!!! ')
    }
    return filteredByCurrentMonth
  }

  const fetchByPreviousMonth = (data) => {
    console.log('Exec fetchByPreviousMonth')
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let previousMonth = currentMonth - 1;
    let previousYear = currentYear;

    if (previousMonth < 0) {
      previousMonth = 11; // December
      previousYear = currentYear - 1;
    }
    const filteredByPreviousMonth = data.filter(record => {
      const [day, month, year] = record.date.split('/').map(Number);
      return month === previousMonth + 1 && year === previousYear;
    });
    if (filteredByPreviousMonth && filteredByPreviousMonth.length > 0) {
      console.log('filteredByPreviousMonth : ', filteredByPreviousMonth)
      // setPreviousMonthData(() => filteredByPreviousMonth)
      saveToRowData(filteredByPreviousMonth)
      console.log(' filteredByPreviousMonth : ', filteredByPreviousMonth ? filteredByPreviousMonth[0] : "")
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 Count of filteredByPreviousMonth : ', filteredByPreviousMonth ? filteredByPreviousMonth.length : 0)
    } else {
      console.log('\u1F624 \u1F624  \u1F624  \u1F624  Count of filteredByPreviousMonth IS ZERO: ')
    }

  }

  const filterByPreviousMonth = (data) => {
    console.log('Exec fetchByPreviousMonth')
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    let previousMonth = currentMonth - 1;
    let previousYear = currentYear;

    if (previousMonth < 0) {
      previousMonth = 11; // December
      previousYear = currentYear - 1;
    }
    const filteredByPreviousMonth = data.filter(record => {
      const [day, month, year] = record.date.split('/').map(Number);
      return month === previousMonth + 1 && year === previousYear;
    });
    if (filteredByPreviousMonth && filteredByPreviousMonth.length > 0) {
      console.log('\uD83D\uDE00  filteredByPreviousMonth : ', filteredByPreviousMonth.length)
    } else {
      console.log('\u1F624 \u1F624  \u1F624  \u1F624  Count of filteredByPreviousMonth IS ZERO: ')
    }
    return filteredByPreviousMonth;
  }

  const getQuarter = (month) => {
    if (month >= 4 && month <= 6) {
      return 'Q1';
    } else if (month >= 7 && month <= 9) {
      return 'Q2';
    } else if (month >= 10 && month <= 12) {
      return 'Q3';
    } else {
      return 'Q4';
    }
  } 

  const filterByQuarter = () => {
    console.log('Exec filterByQuarter ')
    let tempCurQtrData 
    const quarters = {
      Q1: [],
      Q2: [],
      Q3: [],
      Q4: []
    };

    walkin_vyapar_sale_report_pos_data.forEach(record => {
      const [day, month, year] = record.date.split('/').map(Number);
      const quarter = getQuarter(month);
      quarters[quarter].push(record);
    });
    if (quarters ) {
      setFourQuartersData(() => quarters)
      const tempCurMonth = new Date().getMonth()
      const currentQuarter = getQuarter(Number(tempCurMonth) + 1);
      console.log('currentQuarter : ', currentQuarter)
      switch (currentQuarter) {
        case 'Q1':
          console.log('MATCHED Q1 : Total Nof Records in Q1 : ',quarters && quarters.Q1 ? quarters.Q1.length : 0)
          tempCurQtrData =  quarters.Q1
          break;
        case 'Q2':
          console.log('MATCHED Q2 : Total Nof Records in Q2 : ',quarters && quarters.Q2 ? quarters.Q2.length : 0)
          tempCurQtrData =   quarters.Q2
          break;
        case 'Q3':
          console.log('MATCHED Q3 : Total Nof Records in Q3 : ',quarters && quarters.Q3 ? quarters.Q3.length : 0)
          tempCurQtrData =   quarters.Q3
          break;
        case 'Q4':
          console.log('MATCHED Q4 : Total Nof Records in Q4 : ',quarters && quarters.Q4 ? quarters.Q4.length : 0)
          tempCurQtrData =   quarters.Q4
          break;
        default:
          break;
      }
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 tempCurQtrData : ', tempCurQtrData ? tempCurQtrData.length:0)
      // setCurrentQuarterData(() =>tempCurQtrData);
      console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 Data by Quarters Q2 : ', quarters && quarters.Q2 ? quarters.Q2.length : 0)
    } else
      console.log('\u1F624 \u1F624  \u1F624  \u1F624  Count of quarters IS ZERO: ')
    return tempCurQtrData;
  }

  const fetchByYear = (data) => {
    console.log('Exec fetchByYear')
    if (data && data.length > 0) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const filteredByYear = data.filter(record => {
        const [day, month, year] = record.date && record.date.split('/').map(Number);
        return year === currentYear;
      });
      if (filteredByYear) {
        console.log('\uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 Count of filteredByYear ::==> ', filteredByYear ? filteredByYear.length : 0)
        setYearlyData(() => filteredByYear)
      } else
        console.log('\u1F624 \u1F624  \u1F624  \u1F624  Count of filteredByYear IS ZERO: ')
    } else
      console.log('\u1F624 \u1F624  \u1F624  \u1F624  walkin_vyapar_sale_report_pos_data IS ZERO: ')
  }

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split('/').map(Number);
    return new Date(year, month - 1, day);  // JavaScript months are 0-based
  }

  const filterRecordsByDate = (records, startDateStr, endDateStr) => {
    const startDate = parseDate(startDateStr);
    const endDate = parseDate(endDateStr);

    return records.filter(record => {
      const recordDate = parseDate(record.date);
      return recordDate >= startDate && recordDate <= endDate;
    });
  }

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  {/**
    COMMENTING FOR TESTING LATER UN COMMENT TO FETCH THE STORES 
    useEffect(() => {
    console.log("Use Effect Gets Called Only Once and sets the POS NAME.");
    if (storeName || warehouseName)
      setPosName(storeName ? storeName : warehouseName ? warehouseName : "")
    dispatch(listAllStores());
  }, [dispatch]);*/}

  const resetRowData = () => {
    setRowData(() => [])
  }

  useEffect(() => {
    // console.log('\uD83D\uDE00 Use Effect getting Called FIRST TIME WHEN NO SELECTION IS MADE AND  walkin_vyapar_sale_report_pos_data HAS DATA \uD83D\uDE00')
    if (walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0) {
      console.log('\uD83D\uDE00 Use Effect getting Called FIRST TIME WHEN NO SELECTION IS MADE AND  walkin_vyapar_sale_report_pos_data HAS DATA \uD83D\uDE00')
      prepareDataForSalesReport()
      // fetchByCurrentMonth(walkin_vyapar_sale_report_pos_data)
      // fetchByPreviousMonth(walkin_vyapar_sale_report_pos_data)
      // fetchByQuarter()
      // fetchByYear(walkin_vyapar_sale_report_pos_data)
    }
  }, [walkin_vyapar_sale_report_pos_data, selectedCustomer, selectedPaymentType, reportDuration]);


  useEffect(() => {
    if (selectedCustomer && selectedCustomer.length > 0 && selectedPaymentType === "" && reportDuration === "") {
      console.log('Use Effect Called For Change in Valid Selected Customer && NO payment Type && no reportDuration :==> ', selectedCustomer)
      prepareDataForSelectedCustomer()
    }else if (selectedCustomer && selectedCustomer.length > 0 && reportDuration && reportDuration.length > 0 && selectedPaymentType === "") {
      console.log('Use Effect Called For Change in Valid Selected Customer && ReportDuration | NO payment Type :==> ', selectedCustomer, reportDuration)
      prepareDataForSelectedCustomerReportDuartion()
    }else if (selectedPaymentType && selectedPaymentType.length > 0 && selectedCustomer === "" && reportDuration === "") {
      console.log('Use Effect Called For Change in Valid payment Type && NO Selected payment Type , No Report Duration ', selectedPaymentType)
      prepareDataForSelectedPaymentType()
    }else if (selectedPaymentType && selectedPaymentType.length > 0 && selectedCustomer && selectedCustomer.length > 0 && reportDuration === "") {
      console.log('Use Effect Called For Change in Valid Selected Customer | Selected payment Type && No Report Duration ', selectedPaymentType, selectedCustomer)
      prepareDataForSelectedCustomerAndPaymentType()
    }else if (selectedPaymentType && selectedPaymentType.length > 0 && selectedCustomer && selectedCustomer.length > 0 && reportDuration && reportDuration.length > 0) {
      console.log('Use Effect Called For Change in Valid Selected Customer && Selected payment Type && Selected reportDuration', selectedPaymentType, selectedCustomer, reportDuration)
      prepareDataForSelectedCustomerPaymentTypeDuration()
    }else if (reportDuration && reportDuration.length > 0 && selectedPaymentType === "" && selectedCustomer === "") {
      console.log('Use Effect Called For Change in Valid Selected reportDuration | NO payment Type  && | NO selectedCustomer:==> ', reportDuration)
      prepareDataForSelectedDuration()
    }else if (reportDuration && reportDuration.length > 0 && selectedPaymentType && selectedPaymentType.length > 0 && selectedCustomer === "" ) {
      console.log('Use Effect Called For Change in Valid Selected reportDuration | Valid payment Type  && | NO selectedCustomer:==> ', reportDuration)
      prepareDataForSelectedPaymentTypeReportDuartion()
    }
  }, [selectedCustomer, selectedPaymentType, reportDuration]);

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  const handleFileUpload = (event) => {
    setLoadingExcelData(true);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // Assuming we want to read the first sheet
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      console.log('data : ', data)

      if (data.length > 0) {
        const headers = data[0];
        const rows = data.slice(1);

        setColumnDefs(headers.map(header => ({ headerName: header, field: header })));
        setRowData(rows.map((row) => {
          const rowDataObj = {};
          headers.forEach((header, index) => {
            rowDataObj[header] = row[index];
          });
          return rowDataObj;
        }));
        setLoadingExcelData(() => false)
      }
    };


    reader.readAsArrayBuffer(file);
  };

  return (
    <React.Fragment>
      {walkin_vyapar_sale_report_pos_error && <Box sx={{ width: '100%' }}>
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Typography
              variant="h6"
              style={{ margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}
              color="black"
            >{walkin_vyapar_sale_report_pos_error}
            </Typography>
          </Alert>
        </Collapse>
      </Box>
      }
      {walkin_vyapar_sale_report_pos_loading && <CustomBackdropSpinner />}
      {loadingExcelData && <CustomBackdropSpinner />}
      {/** SELECT DURATION OF SALES SECTION START */}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <Typography
              variant="h6"
              style={{ margin: "0.5rem" }}
              color="gray"
            >
              Walkin Vyapar Sales Report
              {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
            </Typography>
            <CardBody>
              {/** CUSTOMER NAME START */}
              {walkin_vyapar_sale_report_pos_data && <GridContainer spacing={2} >
                <GridItem xs={12} sm={6} md={6} >
                  <Autocomplete
                    disablePortal
                    onChange={(event, value) => {
                      if (value) {
                        console.log("Chosen Customer :==> ", value);
                        setSelectedCustomer(value);
                        return;
                      } else {
                        console.log("Clicked X Btn")
                        // setRowData(() => walkin_vyapar_sale_report_pos_data)
                        setSelectedCustomer(() => "")
                        return;
                      }
                    }}
                    fullWidth
                    id="combo-box-products"
                    options={customers}
                    size="small"
                    getOptionLabel={(option) => {
                      if (option) {
                        return `${option ? option : ''}`
                      }
                    }
                    }
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Customer Name"
                        size="small"
                        value={selectedCustomer && selectedCustomer ? selectedCustomer : ""}
                      />
                    )}
                  />
                </GridItem>
                {/* </GridContainer>} */}
                {/** CUSTOMER NAME END */}
                {/** PAYMENT MODE  START */}
                {/* {walkin_vyapar_sale_report_pos_data &&  */}
                <GridItem xs={12} sm={6} md={6} >
                  <Autocomplete
                    disablePortal
                    onChange={(event, value) => {
                      if (value) {
                        console.log("Chosen Payment Mode :==> ", value);
                        setSelectedPaymentType(value);
                        return;
                      } else {
                        console.log("Clicked X Btn")
                        // setRowData(() => walkin_vyapar_sale_report_pos_data)
                        setSelectedPaymentType(() => "")
                        return;
                      }
                    }}
                    fullWidth
                    id="combo-box-paytypes"
                    options={paymentTypes}
                    size="small"
                    getOptionLabel={(option) => {
                      if (option) {
                        return `${option ? option : ''}`
                      }
                    }
                    }
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Payment Type"
                        size="small"
                        value={selectedPaymentType && selectedPaymentType ? selectedPaymentType : ""}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>}
              {/** PAYMENT MODE  END */}
              {/** STORE NAME , DURATION START */}
              <GridContainer spacing={2}>
                {role && role !== "ADMIN_ROLE" && <GridItem xs={12} sm={6} md={6} style={{ marginTop: "1rem" }}>
                  <Autocomplete
                    disablePortal
                    onChange={(event, v) => {
                      if (v) {
                        const selectRptDr = v && v.value ? v.value : null
                        console.log('AutoCom : Value : ', v.value)
                        setReportDuration(() => selectRptDr)
                        if (selectRptDr === 'currentMonth') {
                          console.log("Chosen Value :==> ", selectRptDr, 'currentMonthFirstLastDate : ', currentMonthFirstLastDate)
                          setFirstAndLastDayOfMonth(() => currentMonthFirstLastDate);
                        } else if (selectRptDr === 'previousMonth') {
                          console.log("Chosen Value :==> ", selectRptDr, 'previousMonthFirstLastDate : ', previousMonthFirstLastDate)
                          setFirstAndLastDayOfPreviousMonth(() => previousMonthFirstLastDate);
                        } else if (selectRptDr === 'quarterly') {
                          console.log("Chosen Value :==> ", selectRptDr, 'Quarterly : ')
                        } else if (selectRptDr === 'yearly') {
                          console.log("Chosen Value :==> ", selectRptDr, 'previousMonthFirstLastDate : ', previousMonthFirstLastDate)
                        } else if (selectRptDr === 'custom') {
                          console.log("Chosen Value :==> ", selectRptDr, 'previousMonthFirstLastDate : ', previousMonthFirstLastDate)
                        }
                      }
                      else {
                        console.log("Clicked X Btn of Report Duration ...! ")
                        setReportDuration(() => "")
                      }
                    }}
                    fullWidth
                    id="combo-box-products"
                    options={reportDurations}
                    size="small"
                    getOptionLabel={(option) => {
                      if (option) {
                        return `${option ? option.title : ''}`
                      }
                    }
                    }
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Duration"
                        size="small"
                        value={reportDuration && reportDuration ? reportDuration : ""}
                      />
                    )}
                  />
                </GridItem>
                }

                {reportDuration && reportDuration === "yearly" && (
                  <FormControl style={{ textAlign: "center", paddingTop: "1rem", width: "50%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Yearly
                    </InputLabel>
                    <Select
                      label="Year"
                      placeholder="Year"
                      variant="outlined"
                      required
                      size="small"
                      name="yearly"
                      onChange={(e) => {
                        if (e.target.value) {
                          setSelectedYear((prev) => e.target.value);
                        }
                      }
                      }
                      value={selectedYear}
                    >
                      {financialYearOptionsFilter}
                    </Select>
                  </FormControl>
                )
                }
                {reportDuration && reportDuration === "custom" && (
                  <GridItem xs={12} sm={6} md={6} style={{ marginTop: "1rem" }}>
                    <GridContainer spacing={2} >
                      <GridItem xs={12} sm={6} md={6} style={{ textAlign: "left", justifyContent: "center", alignItems: "center", color: "gray" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ padding: "0px" }} fullWidth>
                          <DateField
                            label="Dash separator"
                            value={customStartDate}
                            defaultValue={dayjs(new Date())}
                            format="MM-DD-YYYY"
                            fullWidth
                            size="small"
                          />
                        </LocalizationProvider>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={6} style={{ textAlign: "right", justifyContent: "center", alignItems: "center", color: "gray" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ padding: "0px" }}>
                          <DateField
                            label="Dash separator"
                            value={customEndDate}
                            defaultValue={dayjs(new Date())}
                            format="MM-DD-YYYY"
                            fullWidth
                            size="small"
                          />
                        </LocalizationProvider>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                )
                }
                {(reportDuration === 'currentMonth' || reportDuration === 'previousMonth') && <GridItem xs={12} sm={6} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                  <TextField
                    label="Between"
                    placeholder="Between"
                    variant="outlined"
                    size="small"
                    required
                    name="between"
                    disabled
                    type="text"
                    fullWidth
                    style={{ textAlign: "center", paddingTop: "1rem" }}
                    value={firstAndLastDayOfMonth ? firstAndLastDayOfMonth : ''}
                  />
                </GridItem>}
                {reportDuration && reportDuration === "quarterly" && <GridItem xs={12} sm={6} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                  <TextField
                    label="Quarterly Duration"
                    placeholder="Quarterly Duration"
                    variant="outlined"
                    size="small"
                    required
                    name="between"
                    disabled
                    type="text"
                    fullWidth
                    style={{ textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "1rem" }}
                    value={quarterlyOptions[1]}
                  />
                </GridItem>}
                {role && <GridItem xs={12} sm={12} md={12} style={{ marginTop: '1rem', padding: '0px' }}>
                  <Autocomplete
                    disablePortal
                    onChange={(event, value) => {
                      console.log("LSK DEBUGGING THE SELECTED FROM WH NAME", value);
                      setSelectedStoreName(value)
                    }
                    }
                    id="combo-box-products"
                    options={stores ? stores : []}
                    size="small"
                    fullWidth
                    getOptionLabel={(option) =>
                      `${option.storeName}`
                    }
                    disabled // UNCOMMENT WHEN SUPPORTING MULTIPLE STORES
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Store Name"
                        size="small"
                        value={selectedStoreName}
                      />
                    )}
                  />
                </GridItem>
                }
              </GridContainer>
              {/** STORE NAME , DURATION END */}
              {walkin_vyapar_sale_report_pos_data && <GridContainer
                className={classes.root}
                justify="center"
                alignItems="center"
              >
                <GridItem >
                  <Tooltip title={'PAID'} arrow>
                    <Card className={classes.paper}
                      style={{
                        justifyContent: "center",
                        marginLeft: "auto",
                        alignItems: "center",
                        width: '12rem',
                        height: '7.5rem',
                        backgroundColor: "#C7F6C7"
                      }}>

                      <Typography
                        style={{
                          marginTop: "1rem",
                          justifyContent: "left",
                          textAlign: "left",
                          alignItems: "left",
                        fontFamily: 'Montserrat, Arial, sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.1rem"
                        }}
                      >Paid
                      </Typography>

                      <Typography
                        style={{
                          margin: "0.5rem",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                        fontFamily: 'Montserrat, Arial, sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.25rem"
                        }}
                      ><CurrencyRupeeIcon
                        size="small"
                        variant="contained"
                        type="submit"
                        color="Gray"
                        justify="center"
                        style={{ marginRight: "0.5rem", width: "1rem" }}
                      >
                        </CurrencyRupeeIcon> {sumOfPaid ? formatToLocaleCurrency(sumOfPaid) : 0.00}</Typography>
                    </Card>
                  </Tooltip>
                </GridItem>
                <span style={{
                  margin: "0.5rem",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                fontFamily: 'Montserrat, Arial, sans-serif',
                  fontWeight: "600",
                  color: "black",
                  fontSize: "1.2rem"
                }}> + </span>
                <GridItem >
                  <Tooltip title={'UN PAID'} arrow>
                    <Card className={classes.paper} col
                      style={{
                        justifyContent: "center",
                        marginLeft: "auto",
                        alignItems: "center",
                        width: '12rem',
                        height: '7.5rem',
                        backgroundColor: "#ADD8E6"
                      }}>

                      <Typography
                        style={{
                          marginTop: "1rem",
                          justifyContent: "left",
                          textAlign: "left",
                          alignItems: "left",
                        fontFamily: 'Montserrat, Arial, sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.1rem"
                        }}
                      >Un Paid
                      </Typography>
                      <Typography
                        style={{
                          margin: "0.5rem",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                        fontFamily: 'Montserrat, Arial, sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.25rem"
                        }}
                      ><CurrencyRupeeIcon
                        size="small"
                        variant="contained"
                        type="submit"
                        color="Gray"
                        justify="center"
                        style={{ marginRight: "0.5rem", width: "1rem" }}
                      >
                        </CurrencyRupeeIcon> 
                        { sumOfUnpaid ? formatToLocaleCurrency(sumOfUnpaid ):0.0 }
                        </Typography>
                    </Card>
                  </Tooltip>
                </GridItem>
                <span style={{
                  margin: "0.5rem",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                fontFamily: 'Montserrat, Arial, sans-serif',
                  fontWeight: "600",
                  color: "black",
                  fontSize: "1.2rem"
                }}> = </span>
                <GridItem >
                  <Tooltip title={'TOTAL'} arrow>
                    <Card className={classes.paper}
                      style={{
                        justifyContent: "center",
                        marginLeft: "auto",
                        alignItems: "center",
                        width: '12rem',
                        height: '7.5rem',
                        backgroundColor: '#FFBE9F'
                      }}>

                      <Typography
                        style={{
                          marginTop: "1rem",
                          justifyContent: "left",
                          textAlign: "left",
                          alignItems: "left",
                        fontFamily: 'Montserrat, Arial, sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.1rem"
                        }}
                      >Total
                      </Typography>
                      <Typography
                        style={{
                          margin: "0.5rem",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                        fontFamily: 'Montserrat, Arial, sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.25rem"
                        }}
                      ><CurrencyRupeeIcon
                        size="small"
                        variant="contained"
                        type="submit"
                        color="Gray"
                        justify="center"
                        style={{ marginRight: "0.5rem", width: "1rem" }}
                      >
                        </CurrencyRupeeIcon> 
                        {/* {sumOfTotalSale ? Number(formatToLocaleCurrency(sumOfTotalSale)).toLocaleString("en-IN") : 0.00} */}
                        {sumOfTotalSale ? formatToLocaleCurrency(sumOfTotalSale ):0.0}
                        </Typography>
                    </Card>
                  </Tooltip>
                </GridItem>
              </GridContainer>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* </GridContainer> */}
      {/** SELECT DURATION OF SALES SECTION END */}
      {role && role === "ADMIN_ROLE" && <GridContainer>
        <GridItem xs={12} sm={6} style={{ alignItems: 'center', justifyContent: "center", textAlign: "center" }}>
          <label htmlFor="files" style={{
            background: "grey",
            padding: "5px 10px",
            backgroundColor: '#f0c14b',
            width: '10rem',
            borderRadius: '5px',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
          }}>
            Upload Vyapar Excel
          </label>
          <Input
            id="files"
            type="file"
            size="small"
            variant="contained"
            onChange={handleFileUpload}
            accept=".xlsx, .xls"
            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px", visibility: 'hidden', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          </Input>
        </GridItem>
        <GridItem xs={12} sm={6} style={{ alignItems: 'center', justifyContent: "center", textAlign: "center" }}>

          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
            justify="center"
            onClick={syncNow}
            disabled={rowData && rowData.length === 0}
            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
          >
            SYNC NOW
          </Button>
        </GridItem>
      </GridContainer>}
      {role && role === "CEO_ROLE" && rowData &&
        <GridItem xs={12} sm={6} md={6} style={{ textAlign: "center", marginBottom: "1rem" }}>
          <Tooltip title="Download Full Sale Report" arrow>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick={onExportClick}
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            >
              <SaveAltIcon
                fontSize="medium"
              />
              Sale Report
            </Button>
          </Tooltip>
        </GridItem>
      }
      <GridContainer>
        <GridItem xs={12} style={{ alignItems: 'center', justifyContent: "center", textAlign: "center" }}>
          <div
            className="ag-theme-quartz"
            style={{ width: "100%", height: "65vh" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={rowData && rowData.length > 100 ? 20 : 10}
              cacheBlockSize={rowData && rowData.length > 100 ? 20 : 10}
              suppressExcelExport={false}
              popupParent={popupParent}
            />
          </div>
        </GridItem>
      </GridContainer>

      <GridContainer spacing={2} alignItems="center" justify="center">
        {/* <GridItem xs={12} sm={12} md={12}> */}
        <Card>
          <CardHeader>
            <GridItem xs={12} sm={12} md={12}>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="Gray"
              >
                Party Summary Statement
                {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
              </Typography>
            </GridItem>
          </CardHeader>
          <CardBody>
            <GridContainer spacing={2} >
              <GridItem xs={12} sm={4} md={4}>
                <span
                  style={{ fontSize: "1rem", fontWeight: "600", marginLeft: "1rem", color: "gray" }}
                >
                  Total Sale :
                  <CurrencyRupeeIcon
                    size="small"
                    variant="contained"
                    type="submit"
                    color="Gray"
                    style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                  >
                  </CurrencyRupeeIcon>
                  {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                </span>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <span
                  style={{ fontSize: "1rem", fontWeight: "600", marginLeft: "1rem", color: "gray" }}
                >
                  Total Purchase :
                  <CurrencyRupeeIcon
                    size="small"
                    variant="contained"
                    type="submit"
                    color="Gray"
                    style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                  >
                  </CurrencyRupeeIcon>
                  {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                </span>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <span
                  style={{ fontSize: "1rem", fontWeight: "600", marginLeft: "1rem", color: "gray" }}
                >
                  Total Expense :
                  <CurrencyRupeeIcon
                    size="small"
                    variant="contained"
                    type="submit"
                    color="Gray"
                    style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                  >
                  </CurrencyRupeeIcon>
                  {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                </span>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Typography
                  color="Gray"
                  style={{ fontSize: "1rem", fontWeight: "600", marginLeft: "1rem" }}
                >
                  Total Money-In :
                  <CurrencyRupeeIcon
                    size="small"
                    variant="contained"
                    type="submit"
                    color="Gray"
                    style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                  >
                  </CurrencyRupeeIcon>
                  {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Typography
                  color="Gray"
                  style={{ fontSize: "1rem", fontWeight: "600", marginLeft: "1rem" }}
                >
                  Total Money-Out :
                  <CurrencyRupeeIcon
                    size="small"
                    variant="contained"
                    type="submit"
                    color="Gray"
                    style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                  >
                  </CurrencyRupeeIcon>
                  {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                </Typography>
              </GridItem>
              <GridItem xs={12} sm={4} md={4}>
                <Typography
                  color="Gray"
                  style={{ fontSize: "1rem", fontWeight: "600", marginLeft: "1rem" }}
                >
                  Total Receivables :
                  <CurrencyRupeeIcon
                    size="small"
                    variant="contained"
                    type="submit"
                    color="Gray"
                    style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                  >
                  </CurrencyRupeeIcon>
                </Typography>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </React.Fragment>
  );
};

export default WalkinVyaparSaleReport;
