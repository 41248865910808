export const WAREHOUSE_PURCHASE_ORDER_LIST_REQUEST = "WAREHOUSE_PURCHASE_ORDER_LIST_REQUEST";
export const WAREHOUSE_PURCHASE_ORDER_LIST_SUCCESS = "WAREHOUSE_PURCHASE_ORDER_LIST_SUCCESS";
export const WAREHOUSE_PURCHASE_ORDER_LIST_FAIL = "WAREHOUSE_PURCHASE_ORDER_LIST_FAIL";

export const WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST = "WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST";
export const WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS = "WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS";
export const WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL = "WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL";

export const WAREHOUSE_PURCHASE_ORDER_BY_ID_REQUEST =
  "WAREHOUSE_PURCHASE_ORDER_BY_ID_REQUEST";
export const WAREHOUSE_PURCHASE_ORDER_BY_ID_SUCCESS =
  "WAREHOUSE_PURCHASE_ORDER_BY_ID_SUCCESS";
export const WAREHOUSE_PURCHASE_ORDER_BY_ID_FAIL =
  "WAREHOUSE_PURCHASE_ORDER_BY_ID_FAIL";

export const WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST = "WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST";
export const WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS = "WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS";
export const WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_FAIL = "WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_FAIL";

export const WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST = "WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST";
export const WAREHOUSE_PURCHASE_ORDER_CREATE_SUCCESS = "WAREHOUSE_PURCHASE_ORDER_CREATE_SUCCESS";
export const WAREHOUSE_PURCHASE_ORDER_CREATE_FAIL = "WAREHOUSE_PURCHASE_ORDER_CREATE_FAIL";
export const WAREHOUSE_PURCHASE_ORDER_CREATE_RESET = "WAREHOUSE_PURCHASE_ORDER_CREATE_RESET";

export const WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_REQUEST = "WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_REQUEST";
export const WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS = "WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS";
export const WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_FAIL = "WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_FAIL";
export const WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_RESET = "WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_RESET";

