import axios from "axios";
import {
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_REQUEST,
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_SUCCESS,
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_RESET,
  WALKIN_SALE_RECEIPT_CREATE_BY_STORE_FAIL,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_REQUEST,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_SUCCESS,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_RESET,
  WALKIN_SALES_RECEIPTS_LIST_BY_STORE_FAIL,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_REQUEST,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_SUCCESS,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_FAIL,
  WALKIN_SALE_RECEIPT_DETAILS_BY_ID_RESET
} from "../constants/walkinSaleReceiptConstants";

export const createWalkinSaleReceipt = (receipt) => async (dispatch, getState) => {
  console.log("Inside createWalkinSaleReceipt ...", receipt);
  const { id, walkin_sales_invoice_receipt_update  } = receipt
  const { posName  } = walkin_sales_invoice_receipt_update
  
  try {
    dispatch({
      type: WALKIN_SALE_RECEIPT_CREATE_BY_STORE_REQUEST,
    });

    const config = {
    };

    const { data } = await axios.post(`/api/walkin-sales-receipts/${posName}/${id}`, { walkin_sales_invoice_receipt_update }, config);

    dispatch({
      type: WALKIN_SALE_RECEIPT_CREATE_BY_STORE_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    
    dispatch({
      type: WALKIN_SALE_RECEIPT_CREATE_BY_STORE_FAIL,
      payload: message,
    });
  }
};

export const listWalkinSalesReceiptsByStore = (pos) => async (dispatch, getState) => {
  console.log("Listing WalkinSales Receipts By Store ", pos);
  try {
    dispatch({
      type: WALKIN_SALES_RECEIPTS_LIST_BY_STORE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/walkin-sales-receipts/store/${pos}`, config);

    dispatch({
      type: WALKIN_SALES_RECEIPTS_LIST_BY_STORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: WALKIN_SALES_RECEIPTS_LIST_BY_STORE_FAIL,
      payload: message,
    });
  }
};

export const getWalkinSaleReceiptById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WALKIN_SALE_RECEIPT_DETAILS_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/walkin-sales-receipts/id/${id}`, config);

    dispatch({
      type: WALKIN_SALE_RECEIPT_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: WALKIN_SALE_RECEIPT_DETAILS_BY_ID_FAIL,
      payload: message,
    });
  }
};



