import axios from "axios";
import {
  PDF_INVOICE_BY_ID_REQUEST,
  PDF_INVOICE_BY_ID_SUCCESS,
  PDF_INVOICE_BY_ID_FAIL,
  PDF_INVOICE_BY_ID_RESET,
  PDF_INVOICE_CREATE_REQUEST,
  PDF_INVOICE_CREATE_FAIL,
  PDF_INVOICE_CREATE_RESET,
  PDF_INVOICE_CREATE_SUCCESS,
  PDF_INVOICE_DETAILS_REQUEST,
  PDF_INVOICE_DETAILS_SUCCESS,
  PDF_INVOICE_DETAILS_FAIL,
  PDF_INVOICE_DETAILS_RESET,
  PDF_INVOICE_LIST_REQUEST,
  PDF_INVOICE_LIST_SUCCESS,
  PDF_INVOICE_LIST_FAIL,
  PDF_INVOICE_LIST_RESET
} from "../constants/pdfInvoiceConstants";

export const listAllPdfInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: PDF_INVOICE_LIST_REQUEST });

    const { data } = await axios.get(`/api/pdfInvoices`);

    dispatch({
      type: PDF_INVOICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PDF_INVOICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const pdfInvoiceById = (id) => async (dispatch) => {
  try {
    dispatch({ type: PDF_INVOICE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/pdfInvoices/${id}`);

    dispatch({
      type: PDF_INVOICE_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PDF_INVOICE_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createPdfInvoice = ( customerDetails, orderItems, paymentDetails ) => async (dispatch, getState) => {
  console.log("Exec createPdfInvoice :  orderItems : ", orderItems ," paymentDetails ", paymentDetails);
      try {
        dispatch({
          type: PDF_INVOICE_CREATE_REQUEST,
        });
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        const { data } = await axios.post(
          `/api/pdf-invoice/create`,
          {
            customerDetails , orderItems, paymentDetails
          },
          config
        );

        dispatch({
          type: PDF_INVOICE_CREATE_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;

        dispatch({
          type: PDF_INVOICE_CREATE_FAIL,
          payload: message,
        });
      }
};
