import React, { useState, useEffect } from "react";
import { Box, Divider, MenuItem, Select, FormControl, InputLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../actions/cartAction";
import rupeeSvgIcon from "../assets/images/currency-inr.svg";
import HomeIcon from "@mui/icons-material/Home";
import { makeStyles, useTheme } from "@mui/styles";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  Icon,
  Paper,
  Tooltip,
} from "@mui/material";
import { DeleteForeverRounded } from "@mui/icons-material";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import Card from "./Card/Card";
import CardBody from "./Card/CardBody";
import CardHeader from "./Card/CardHeader";
import { useNavigate } from "react-router-dom";
import { addDecimals } from "../util/Utility";
import { incrementQuantity, decrementQuantity } from "../actions/cartAction";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainContainer: {
    marginTop: "5em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em",
    },
  },
  heroTextContainer: {
    minWidth: "21em",
    maxWidth: "50em",
    marginLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "30em",
      marginTop: "2em",
    },
  },
  animation: {
    maxWidth: "50em",
    minWidth: "21em",
    // marginTop: "2em",
    marginLeft: "10%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "30em",
      marginTop: "2em",
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    // whiteSpace: "nowrap",
    // marginBottom: theme.spacing(1),
  },
  childPaper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imageIcon: {
    height: "1rem",
  },
  iconRoot: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

export default function CartScreen() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customColor = "#f0c14b";
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const [selectedQuantity, setSelectedQuantity] = useState(0);

  const updateLocalStorage = (items) => {
    console.log("updateLocalStorage Init", items);
    localStorage.setItem('cartItems', JSON.stringify(items));
    console.log("updateLocalStorage End");
  };

  // const incrementQuantity = (curItem) => {
  //   console.log("incrementQuantity :: ",curItem);
  //   const updatedItems = cartItems.map(item => {
  //     console.log("item : ",item, " curItem : ", curItem);
  //     return item.name === curItem.name ? { ...item, quantity: Number(curItem.quantity) + 1 } : item
  //   }
  //   );
  //   console.log("updatedItems : ", updatedItems);
    
  //   updateLocalStorage(updatedItems);
  // };

  // const decrementQuantity = (curItem) => {
  //   console.log("decrementQuantity :: ",curItem);
  //   const updatedItems = cartItems.map(item => {
  //     console.log("item : ",item, " curItem : ", curItem);
  //     return item.name === curItem.name && Number(item.quantity) > 1 ? { ...item, quantity: Number(item.quantity) - 1 } : item
  //   }
  //   );
  //   console.log("updatedItems : ", updatedItems);
  //   // setCartItems(updatedItems);
  //   updateLocalStorage(updatedItems);
  // };
  const removeProduct = () => {
    console.log("Product removed from cart");
  };

  /**

    /** CALCULATION OF TAX AND DISCOUNT LOGIC */
  /**calculateItemCostPriceIncludingTax(
    productFormInputs.unitPrice,
    productFormInputs.inKgs,
    productFormInputs.quantity,
    productFormInputs.cgst,
    productFormInputs.sgst,
    productFormInputs.igst,
    productFormInputs.tcs,
    productFormInputs.discountPercent,
    productFormInputs.discountStartsFrom,
    productFormInputs.customerType
  );
}, [productFormInputs.quantity]); */

  const removeFromCartHandler = (name) => {
    console.log("Item Name being removed :==> " + name);
    dispatch(removeFromCart(name));
  };

  const calculateUnitPrice = (unitPrice, qtySelected) => {
    const itemPrice = (unitPrice * qtySelected).toFixed(2);
    console.log("Exc calculateUnitPrice for QTY :unitPrice :==> ", unitPrice, "qtySelected :==> ", qtySelected, "Returning calculated Item Price ::=>> ", itemPrice);
    return itemPrice;
  };

  const checkoutHandler = () => {
    navigate("/payment");
  };

  const calculateItemCostPriceIncludingTax = (item, updatedQty) => {

    const { unitPrice, inKgs, quantity, cgst, sgst, igst, tcs, discountPercent, discountStartsFrom, customerType } = item;
    console.log("Item : ", item, " updatedQty : ", updatedQty);
    console.log(
      "Reading Details ...unitPrice ",
      unitPrice,
      inKgs,
      // quantity,
      cgst,
      sgst,
      igst,
      tcs,
      discountPercent,
      discountStartsFrom,
      customerType
    );

    const cgstPrice = unitPrice * updatedQty * (cgst / 100);
    const sgstPrice = unitPrice * updatedQty * (sgst / 100);
    const igstPrice = unitPrice * updatedQty * (igst / 100);
    const tcsPrice = unitPrice * updatedQty * (tcs / 100);
    let itemsDiscountPrice = 0;
    let totalTaxPrice = 0;
    console.log(" cgst : ", cgst, "cgstPrice : ", cgstPrice, "sgst : ", sgst, "sgstPrice : ", sgstPrice, "igst : ",
      igst, "igstPrice : ", igstPrice, "tcs : ", tcs, "tcsPrice : ", tcsPrice);
    const itemsActualWeight = updatedQty * inKgs;
    console.log("inKgs : ", inKgs, "Updated items Quantity ::==>> ", updatedQty * inKgs);
    const eachItemIndividualTotalPrice = unitPrice * updatedQty;
    let calculatedItemCostPriceIncludingDiscountAndTax = 0;

    if (itemsActualWeight >= discountStartsFrom) {
      console.log(
        " \uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00 Eligible for Discount Price on this Item inKgs  \uD83D\uDE00 \uD83D\uDE00 \uD83D\uDE00",
        inKgs,
        "items Quantity Ordered : ",
        updatedQty * inKgs, "discountPercent : ", discountPercent
      );
      itemsDiscountPrice = ((unitPrice * updatedQty) * (discountPercent / 100));
      console.log("itemsDiscountPrice ::==>>  ", itemsDiscountPrice);

      console.log("cgstPrice , sgstPrice , igstPrice , tcsPrice : ", cgstPrice, sgstPrice, igstPrice, tcsPrice);
      totalTaxPrice = (cgstPrice + sgstPrice + igstPrice + tcsPrice);
      console.log("eachItemIndividualTotalPrice : ", eachItemIndividualTotalPrice, " , itemsDiscountPrice : ", itemsDiscountPrice, "totalTaxPrice : ", totalTaxPrice);
      calculatedItemCostPriceIncludingDiscountAndTax = eachItemIndividualTotalPrice - itemsDiscountPrice;
      console.log("calculatedItemCostPriceIncludingDiscountAndTax : ", calculatedItemCostPriceIncludingDiscountAndTax);
    } else {
      console.log(
        " \u1F624 \u1F624  \u1F624  \u1F624  NOT Eligible for Discount Price on this Item , itemsActualWeight: \u1F624  \u1F624 \u1F624  \u1F624 ",
        itemsActualWeight,
        "as discountStartsFrom : ",
        discountStartsFrom, ":(("
      );

      calculatedItemCostPriceIncludingDiscountAndTax =
        eachItemIndividualTotalPrice;
      console.log("calculatedItemCostPriceIncludingDiscountAndTax : ", calculatedItemCostPriceIncludingDiscountAndTax);
    }

    console.log(
      "calculatedItemCostPriceIncludingDiscountAndTax : ",
      calculatedItemCostPriceIncludingDiscountAndTax
    );
    console.log("*********    BEFORE SETTING TAX AND DISCOUNT.. JUST CHECKING ALL ********* ");

    item.cgstPrice = cgstPrice;
    item.sgstPrice = sgstPrice;
    item.igstPrice = igstPrice;
    item.tcsPrice = tcsPrice;
    item.totalTaxPrice = totalTaxPrice;
    item.itemsDiscountPrice = itemsDiscountPrice;

    console.log(" ################ After Processing Item : ", item, " ################ ");

    // dispatch(addToCart(item));
  };

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem" }}
                  color="black"
                >
                  <i>
                    Cart Items
                  </i>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>
              {cartItems.length === 0 ? (
                <div>Cart is empty</div>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Paper className={classes.paper}>
                      <Grid item>
                        {cartItems.map((item, indx) => (
                          <Grid
                            container
                            key={indx}
                            style={{
                              marginTop: "0.75rem",
                              marginBottom: "0.75rem",
                            }}
                          >
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={9} style={{
                                  justifyContent: "center",
                                  textAlign: "left",
                                  alignItems: "center",
                                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                  fontWeight: "700",
                                  fontSize: "1.25rem"
                                }}>
                                  {item.name}
                                </Grid>
                                <Grid
                                  item
                                  xs={3}
                                  align="center"
                                  style={{
                                    justifyContent: "center",
                                    justifyItems: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <button onClick={() => dispatch(decrementQuantity(item))} style = {{
                                    margin:"5px",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    width : "1.5rem",
                                    height : "2rem",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    justifyItems:"center",
                                    }}
                                    >-</button>
                                  <TextField
                                    label="Quantity"
                                    variant="outlined"
                                    size="small"
                                    style= {{width:"5rem",  margin:"5px"}}
                                    name="quantity"
                                    required
                                    value={item.quantity}
                                    onChange={(e) => {
                                      calculateItemCostPriceIncludingTax(item, Number(e.target.value));
                                      const unitPriceByQuantitySelected =
                                        calculateUnitPrice(item.unitPrice,
                                          Number(e.target.value)
                                        );
                                      item.quantity = Number(e.target.value);
                                      console.log("item : ", item, " , quantity : ", e.target.value, "unitPriceByQuantitySelected : ", unitPriceByQuantitySelected);
                                      dispatch(
                                        addToCart(
                                          item,
                                          Number(e.target.value)
                                        )
                                      );
                                    }}
                                    type="text"
                                  />
                                  <button onClick={() => dispatch(incrementQuantity(item))} style = {{
                                    padding: "5px",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                    width : "1.5rem",
                                    height : "2rem",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    justifyItems:"center",
                                    margin:"5px"
                                    }}>+</button>
                                </Grid>
                              </Grid>
                              <Grid container style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                <Grid item xs={3} style={{
                                  justifyContent: "center",
                                  textAlign: "center",
                                  alignItems: "center",
                                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                  fontWeight: "600",
                                  fontSize: "0.85rem"
                                }}>
                                  Unit Price:{" "}
                                  <Icon >
                                    <img
                                      alt="curency inr"
                                      src={rupeeSvgIcon}
                                      className={classes.imageIcon}
                                    />
                                  </Icon>
                                  {item.unitPrice ? addDecimals(item.unitPrice) : 0.0}
                                </Grid>
                                <Grid item xs={6} style={{
                                  justifyContent: "center",
                                  textAlign: "center",
                                  alignItems: "center",
                                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                  fontWeight: "600",
                                  fontSize: "0.85rem"
                                }}>
                                  UOM : {item.uom || ''} kgs
                                </Grid>
                                <Grid item xs={2} style={{
                                  justifyContent: "center",
                                  textAlign: "center",
                                  alignItems: "center",
                                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                  fontWeight: "600",
                                  fontSize: "0.85rem"
                                }}>
                                  In Kgs : {item.inKgs}
                                </Grid>
                                <Grid item xs={1}>
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() =>
                                      removeFromCartHandler(item.name)
                                    }
                                  >
                                    <DeleteForeverRounded color={customColor} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                              <Divider></Divider>
                            </Grid>
                          </Grid>
                        ))
                        }
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper className={classes.paper}>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          color="gray"
                          style={{
                            alignContent: "center",
                            textAlign: "center",
                          }}
                        >
                          Subtotal (
                          {cartItems.reduce(
                            (acc, item) => acc + Number(item.quantity),
                            0
                          )}
                          ) items
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Icon classes={{ root: classes.iconRoot }}>
                          <img
                            alt="curency inr"
                            src={rupeeSvgIcon}
                            className={classes.imageIcon}
                          />
                        </Icon>
                        {cartItems
                          .reduce(
                            (acc, item) =>
                              acc +
                              Number(item.quantity) * item.unitPrice,
                            0
                          )
                          .toFixed(2)}
                      </Grid>
                      <Grid item xs={12}>
                        <Tooltip title="Proceed to Checkout" arrow>
                          <Button
                            disabled={cartItems.length === 0}
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                            onClick={checkoutHandler}
                          >
                            <Typography
                              style={{
                                margin: "0.5rem",
                                justifyContent: "center",
                                textAlign: "center",
                                alignItems: "center",
                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                fontWeight: "600",
                                fontSize: "0.85rem"
                              }}
                            >Buy Now</Typography>
                            {/* Proceed to Buy */}
                          </Button>
                        </Tooltip>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              )}
            </CardBody>
          </Card>
        </GridItem>
      </Box>
    </React.Fragment>
  );
}
