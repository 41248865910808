import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardBody from "../Card/CardBody.js";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  listAllVehicles,
} from "../../actions/vehicleAction.js";
import { Typography, Grid, Button, TextField, Tooltip, Divider, Autocomplete } from "@mui/material";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import Message from "../Message.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { useNavigate } from "react-router-dom";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { listUsers } from "../../actions/userAction.js";
import {  updateVehicleAssignedStatus} from "../../actions/vehicleAction.js";
import { VEHICLE_ASSIGNED_UPDATE_RESET } from "../../constants/vehicleConstants.js";

const styles = {
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      fontWeight: "700",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardVehicleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  trStyle: {
    borderBottom: "3px",
    borderColor: "gray",
  },
};

const VehicleStatusListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const vehicleUpdateAssigned = useSelector((state) => state.vehicleUpdateAssigned);
  const {vehicle_update_assigned_loading, vehicle_update_assigned_success, vehicle_update_assigned_error} = vehicleUpdateAssigned

  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, role, userName } = userInfo;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const gridRef = useRef();
  const navigate = useNavigate();

  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      filter: false,
      floatingFilter: false,
      width: 75,
      minWidth: 75,
      maxWidth: 75
    },
    {
      field: "VehicleNo",
      headerName: "Vehicle No",
      resizable: true,
      suppressSizeToFit: true,
      filter: true,
      floatingFilter: false,
      width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "VehicleSalesInchargeName",
      headerName: "Sales Incharge",
      resizable: true,
      suppressSizeToFit: true,
      filter: true,
      floatingFilter: false,
      width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    
    {
      field: "DriverName",
      headerName: "Driver Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      filter: true,
      floatingFilter: false,
      minWidth: 300,
      maxWidth: 300
    },
    
    {
      field: "AvailabilityStatus",
      headerName: "Availability Status",
      resizable: true,
      suppressSizeToFit: true,
      filter: true,
      floatingFilter: false,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      cellStyle: params => {
        if (params.value === 'ENGAGED') {
          return { color: 'white', backgroundColor: 'red', textAlign: "center" };
        }
        else if (params.value === 'AVAILABLE') {
          return { color: 'white', backgroundColor: 'green', textAlign: "center" };
        }
        return null;
      }
    },
    // {
    //   field: "AssignedTo",
    //   headerName: "Assigned To",
    //   resizable: true,
    //   suppressSizeToFit: true,
    //   filter: true,
    //   floatingFilter: false,
    //   width: 250,
    //   minWidth: 250,
    //   maxWidth: 250
    // },
    {
      field: "AssignedDate",
      headerName: "Assigned Date",
      resizable: true,
      suppressSizeToFit: true,
      filter: true,
      floatingFilter: false,
      width: 150,
      minWidth: 150,
      maxWidth: 150
    },
    {
      field: "Id",
      headerName: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit Details" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const export_label = "Export > CSV";
  const [open, setOpen] = useState(() => false);
  const [openThanksDialog, setOpenThanksDialog] = useState(() => false);
  const [selectedSalesIncharge, setSelectedSalesIncharge] = useState(() => "");
  const [selectedDriverName, setSelectedDriverName] = useState(() => "");
  const [selectedStatus, setSelectedStatus] = useState(() => "");
  const [ salesIncharges, setSalesIncharges ] = useState(()=>[]); 
  const [ drivers, setDrivers ] = useState(()=>[]); 
  
  const [filteredVehicle, setFilteredVehicle] = useState(() => {
    return "";
  });
  const [editableRecord, setEditableRecord] = useState(() => {
    return {};
  });

  const dispatch = useDispatch();
  const vehicleList = useSelector((state) => state.vehicleList);
  const { loading, error, vehicles } = vehicleList;

  const populateSalesInchargesDrivers = ()=>{
    if (users && users.length > 0) {
      // Extract the list of Sales Incharges assigned  from vehiclesList
      const assignedSalesIncharges = vehicles ? vehicles.map(vehicle => vehicle.assignedTo):[];
       // Extract the list of Drivers assigned in vehiclesList
      const assignedDrivers = vehicles ? vehicles.map(vehicle => vehicle.driverName):[];
    
      // Filter the users who are not assigned to any vehicle
      const unassignedSalesIncharges = users.filter(user => !assignedSalesIncharges.includes(user.userName));


      // Filter the users who are not assigned to any vehicle
      const unassignedDrivers = users.filter(user => !assignedDrivers.includes(user.userName));

      setSalesIncharges(() => unassignedSalesIncharges.filter(each => each.role === "VEHICLE_SALES_INCHARGE_ROLE").map(each=> each.userName))
      console.log('unassignedSalesIncharges : ', unassignedSalesIncharges? unassignedSalesIncharges.length : "NO unassignedSalesIncharges")
      setDrivers(()=>unassignedDrivers.filter(each => each.role === "DRIVER_ROLE").map(each=> each.userName))
      console.log('unassignedDrivers : ', unassignedDrivers? unassignedDrivers.length : "NO unassignedDrivers")
      
      console.log('salesIncharges : ',salesIncharges?salesIncharges.length : 0, drivers?drivers.length : 0)
    }
  } 
useEffect(()=>{
  console.log('useEffect getting Called ')
  if(vehicle_update_assigned_success){
    setOpenThanksDialog(()=>true)
    setEditableRecord(()=>{})
    setOpen(()=>false)
    dispatch(listAllVehicles());
    dispatch(listUsers());
  }
},[vehicle_update_assigned_success, dispatch, navigate])


  
  useEffect(()=>{
    if(users && users.length > 0){
      console.log('Invoke populateSalesInchargesDrivers Only Once...')
      populateSalesInchargesDrivers();
    }
  },[users]);

  useEffect(() => {
    console.log("useEffect Getting Called VehicleListScreen For First Time");
    dispatch(listAllVehicles());
    dispatch(listUsers());
  }, [dispatch]);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const handleEdit = (params) => {
    console.log("ID SELECTED : ", params);
    console.log(" params", params.data);
    setOpen(true);
    setEditableRecord(()=>({
      // _id: params.data.Id,
      vehicleNo: params.data && params.data.VehicleNo ? params.data.VehicleNo : '',
      salesIncharge: params.data && params.data.VehicleSalesInchargeName ? params.data.VehicleSalesInchargeName  : '',
      driverName: params.data && params.data.DriverName ? params.data.DriverName  : '' ,
      status: params.data && params.data.AvailabilityStatus? params.data.AvailabilityStatus  : '' ,
    }));
  };

  const assignVehicle = (e)=>{
    console.log('Exec assignVehicle ', editableRecord.vehicleNo, editableRecord.salesIncharge, editableRecord.driverName, selectedStatus)
    // const vehNo = editableRecord.vehicleNo ? editableRecord.vehicleNo : ''
    dispatch(updateVehicleAssignedStatus(editableRecord.vehicleNo, editableRecord.salesIncharge, editableRecord.driverName,editableRecord.status.toUpperCase()))
    setOpen(false);
    setEditableRecord({});
  }

  if (vehicles && vehicles.length > 0) {
    rowData = vehicles.map((eachVehicle, index) => {
      return {
        "#": index + 1,
        VehicleNo: eachVehicle.vehicleNo,
        VehicleSalesInchargeName: eachVehicle.assignedTo ? eachVehicle.assignedTo : "",
        DriverName: eachVehicle.driverName,
        "AvailabilityStatus": eachVehicle.availabilityStatus,
        "AssignedDate": eachVehicle.assignedDate,
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "75%", height: "65vh", marginLeft:"auto", marginRight:"auto"}}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
          style={{textAlign:"center", justifyContent:"center", alignItems:"center"}}
        ></AgGridReact>
      </div>
    );
  }
  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      { vehicle_update_assigned_loading && <CustomBackdropSpinner />}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  Vehicles Availability Status
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "0.5rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} >
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          {console.log('edit Rec : ', editableRecord)}
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >

                    </Typography>
                    <h2 align="center"> Assign Vehicle #  {editableRecord && editableRecord.vehicleNo ? editableRecord.vehicleNo : ""}</h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                      <Grid container spacing={2} justifyContent="center" margin="auto">
                        <Grid item xs={12} style={{textAlign:"center",justifyContent:"center" ,alignItems:"center",margin:"0.5rem"}}>
                          <TextField
                            className={classes.inputText}
                            placeholder="Vehicle No"
                            variant="outlined"
                            name="VehicleName"
                            disabled
                            type="text"
                            size="small"
                            fullWidth
                            value={
                              editableRecord && editableRecord.vehicleNo ? editableRecord.vehicleNo : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center",justifyContent:"center" ,alignItems:"center",margin:"0.5rem"}}>
                          <Autocomplete
                            disablePortal
                            value={editableRecord && editableRecord.salesIncharge? editableRecord.salesIncharge : ""}
                            disabled={editableRecord && editableRecord.status && editableRecord.status==="ENGAGED"}
                            defaultValue={salesIncharges && salesIncharges[0] ? salesIncharges[0]  : ''}
                            onChange={(event, value) => {
                              if (value) {
                                setEditableRecord((prev)=>({...prev,salesIncharge: value}))
                                console.log("Chosen Item Name :==> ", value);
                              } else {
                                console.log("Clicked X Btn")
                              }
                            }}
                            fullWidth
                            id="combo-box-products"
                            options={salesIncharges}
                            size="small"
                            getOptionLabel={(option) =>`${option ? option : ''}`}
                            clearOnBlur={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Sales Incharge"
                                size="small"
                                value={selectedSalesIncharge ? selectedSalesIncharge : ""}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center",justifyContent:"center" ,alignItems:"center",margin:"0.5rem"}}>
                          {console.log('DN : ', editableRecord && editableRecord.driverName ? editableRecord.driverName  : '')}
                          <Autocomplete
                            disablePortal
                            value={editableRecord && editableRecord.driverName ? editableRecord.driverName : ""}
                            disabled={editableRecord && editableRecord.status && editableRecord.status==="ENGAGED"}
                            defaultValue={drivers && drivers[0] ? drivers[0]  : ''}
                            onChange={(event, value) => {
                              if (value) {
                                console.log("Chosen Driver Name :==> ", value);
                                // setSelectedDriverName(()=>value);
                                setEditableRecord((prev)=>({...prev,driverName: value}))
                              } else {
                                console.log("Clicked X Btn")
                              }
                            }}
                            fullWidth
                            id="combo-box-products"
                            options={drivers}
                            size="small"
                            getOptionLabel={(option) =>`${option ? option : ''}`}
                            isOptionEqualToValue={(option, value) => option === value}
                            clearOnBlur={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Driver"
                                size="small"
                                value={editableRecord && editableRecord.driverName?editableRecord.driverName :""}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center",justifyContent:"center" ,alignItems:"center",margin:"0.5rem"}}>
                          <Autocomplete
                            disablePortal
                            value={editableRecord && editableRecord.status ? editableRecord.status  : '' }
                            onChange={(event, value) => {
                              if (value) {
                                console.log("Chosen Driver Name :==> ", value);
                                setEditableRecord((prev)=>({...prev,status: value}))
                              } else {
                                console.log("Clicked X Btn")
                              }
                            }}
                            fullWidth
                            id="combo-box-products"
                            getOptionLabel={(option) =>`${option ? option : ''}`}
                            options={["Available", "Engaged", "Under Maintenance"]}
                            isOptionEqualToValue={(option, value) => option === value}
                            size="small"
                            clearOnBlur={true}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Availability Status"
                                size="small"
                                value={selectedStatus? selectedStatus : ""}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} style={{textAlign:"center",justifyContent:"center" ,alignItems:"center",margin:"0.5rem"}}>
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            onClick={assignVehicle}
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                          >
                            update
                          </Button>
                        </Grid>
                      </Grid>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
        <Dialog open={openThanksDialog} onClose={() =>{
          console.log('Performed onClose on Thanks Dialog')
          dispatch({type : VEHICLE_ASSIGNED_UPDATE_RESET})
          setOpenThanksDialog(()=>false)
        }
        }
          >
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >
                    </Typography>
                    <h2 align="center"> Updated Successfuly {editableRecord && editableRecord.vehicleNo ? editableRecord.vehicleNo : ""}</h2>
                    <Divider />
                  </CardHeader>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default VehicleStatusListScreen;
