export const STORE_LIST_REQUEST = "STORE_LIST_REQUEST";
export const STORE_LIST_SUCCESS = "STORE_LIST_SUCCESS";
export const STORE_LIST_FAIL = "STORE_LIST_FAIL";

export const STORE_BY_ID_REQUEST = "STORE_BY_ID_REQUEST";
export const STORE_BY_ID_SUCCESS = "STORE_BY_ID_SUCCESS";
export const STORE_BY_ID_FAIL = "STORE_BY_ID_FAIL";

export const STORE_BY_NAME_REQUEST = "STORE_BY_NAME_REQUEST";
export const STORE_BY_NAME_SUCCESS = "STORE_BY_NAME_SUCCESS";
export const STORE_BY_NAME_FAIL = "STORE_BY_NAME_FAIL";

export const STORE_DELETE_REQUEST = "STORE_DELETE_REQUEST";
export const STORE_DELETE_SUCCESS = "STORE_DELETE_SUCCESS";
export const STORE_DELETE_FAIL = "STORE_DELETE_FAIL";
export const STORE_DELETE_RESET = "STORE_DELETE_RESET";

export const STORE_CREATE_REQUEST = "STORE_CREATE_REQUEST";
export const STORE_CREATE_SUCCESS = "STORE_CREATE_SUCCESS";
export const STORE_CREATE_FAIL = "STORE_CREATE_FAIL";
export const STORE_CREATE_RESET = "STORE_CREATE_RESET";

export const STORE_UPDATE_REQUEST = "STORE_UPDATE_REQUEST";
export const STORE_UPDATE_SUCCESS = "STORE_UPDATE_SUCCESS";
export const STORE_UPDATE_FAIL = "STORE_UPDATE_FAIL";
export const STORE_UPDATE_RESET = "STORE_UPDATE_RESET";

