export const PURCHASE_ORDER_LIST_REQUEST = "PURCHASE_ORDER_LIST_REQUEST";
export const PURCHASE_ORDER_LIST_SUCCESS = "PURCHASE_ORDER_LIST_SUCCESS";
export const PURCHASE_ORDER_LIST_FAIL = "PURCHASE_ORDER_LIST_FAIL";

export const PURCHASE_ORDER_BY_ID_REQUEST =
  "PURCHASE_ORDER_BY_ID_REQUEST";
export const PURCHASE_ORDER_BY_ID_SUCCESS =
  "PURCHASE_ORDER_BY_ID_SUCCESS";
export const PURCHASE_ORDER_BY_ID_FAIL =
  "PURCHASE_ORDER_BY_ID_FAIL";

export const PURCHASE_ORDER_DELETE_BY_ID_REQUEST = "PURCHASE_ORDER_DELETE_BY_ID_REQUEST";
export const PURCHASE_ORDER_DELETE_BY_ID_SUCCESS = "PURCHASE_ORDER_DELETE_BY_ID_SUCCESS";
export const PURCHASE_ORDER_DELETE_BY_ID_FAIL = "PURCHASE_ORDER_DELETE_BY_ID_FAIL";

export const PURCHASE_ORDER_CREATE_REQUEST = "PURCHASE_ORDER_CREATE_REQUEST";
export const PURCHASE_ORDER_CREATE_SUCCESS = "PURCHASE_ORDER_CREATE_SUCCESS";
export const PURCHASE_ORDER_CREATE_FAIL = "PURCHASE_ORDER_CREATE_FAIL";
export const PURCHASE_ORDER_CREATE_RESET = "PURCHASE_ORDER_CREATE_RESET";

export const PURCHASE_ORDER_UPDATE_BY_ID_REQUEST = "PURCHASE_ORDER_UPDATE_BY_ID_REQUEST";
export const PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS = "PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS";
export const PURCHASE_ORDER_UPDATE_BY_ID_FAIL = "PURCHASE_ORDER_UPDATE_BY_ID_FAIL";
export const PURCHASE_ORDER_UPDATE_BY_ID_RESET = "PURCHASE_ORDER_UPDATE_BY_ID_RESET";

