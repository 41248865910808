import {
  LIST_STOCK_BY_ALL_PRODUCTS_REQUEST,
  LIST_STOCK_BY_ALL_PRODUCTS_SUCCESS,
  LIST_STOCK_BY_ALL_PRODUCTS_FAIL,
  UPDATE_STOCK_BY_PRODUCT_ID_REQUEST,
  UPDATE_STOCK_BY_PRODUCT_ID_SUCCESS,
  UPDATE_STOCK_BY_PRODUCT_ID_FAIL,
  UPDATE_STOCK_BY_PRODUCT_ID_RESET,
  CREATE_STOCK_BY_PRODUCT_ID_REQUEST,
  CREATE_STOCK_BY_PRODUCT_ID_SUCCESS,
  CREATE_STOCK_BY_PRODUCT_ID_FAIL,
  CREATE_STOCK_BY_PRODUCT_ID_RESET,
} from "../constants/stockConstants";

export const allProductsCountInStockReducer = (state = {}, action) => {
  switch (action.type) {
    case LIST_STOCK_BY_ALL_PRODUCTS_REQUEST:
      return { loading: true };
    case LIST_STOCK_BY_ALL_PRODUCTS_SUCCESS:
      return { loading: false, allProductsStockInfo: action.payload };
    case LIST_STOCK_BY_ALL_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const stockUpdateByProductIdReducer = (
  state = { store: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_STOCK_BY_PRODUCT_ID_REQUEST:
      return { loading: true };
    case UPDATE_STOCK_BY_PRODUCT_ID_SUCCESS:
      return { loading: false, success_update: true, store: action.payload };
    case UPDATE_STOCK_BY_PRODUCT_ID_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_STOCK_BY_PRODUCT_ID_RESET:
      return { store: {}, loading: false, success_update: false };
    default:
      return state;
  }
};

export const stockCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_STOCK_BY_PRODUCT_ID_REQUEST:
      return { loading: true };
    case CREATE_STOCK_BY_PRODUCT_ID_SUCCESS:
      return { loading: false, success_create: true, stock: action.payload };
    case CREATE_STOCK_BY_PRODUCT_ID_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_STOCK_BY_PRODUCT_ID_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};
