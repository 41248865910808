import axios from "axios";
import {
  SUPPLIER_DETAILS_FAIL,
  SUPPLIER_DETAILS_REQUEST,
  SUPPLIER_DETAILS_SUCCESS,
  SUPPLIER_DETAILS_RESET,

  SUPPLIER_REGISTER_FAIL,
  SUPPLIER_REGISTER_REQUEST,
  SUPPLIER_REGISTER_SUCCESS,
  SUPPLIER_REGISTER_RESET,
 
  SUPPLIER_LIST_FAIL,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_LIST_REQUEST,
  SUPPLIER_LIST_RESET,

  SUPPLIER_DELETE_REQUEST,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DELETE_FAIL,
  SUPPLIER_DELETE_RESET,

  SUPPLIER_UPDATE_FAIL,
  SUPPLIER_UPDATE_SUCCESS,
  SUPPLIER_UPDATE_REQUEST,
  SUPPLIER_UPDATE_RESET,

  SUPPLIER_LIST_BY_PARENT_USER_REQUEST,
  SUPPLIER_LIST_BY_PARENT_USER_RESET,
  SUPPLIER_LIST_BY_PARENT_USER_SUCCESS,
  SUPPLIER_LIST_BY_PARENT_USER_FAIL,
} from "../constants/supplierConstants.js";


export const register =
  (name, contactNo, email, billingAddress, parentUser, gst,city, district,state, pincode, pos) =>
  async (dispatch) => {
    console.log('EXEC Supplier Register  name, contactNo, email, billingAddress, parentUser, gst,city, district, pincode, pos')
    try {
      dispatch({
        type: SUPPLIER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("Before Perfoming role logic : ");

      const { data } = await axios.post(
        "/api/supplier",
        { name, contactNo, email, billingAddress, parentUser, gst, city, district, pincode, pos},
        config
      );

      dispatch({
        type: SUPPLIER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: SUPPLIER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSupplierDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUPPLIER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/supplier/${id}`);

    dispatch({
      type: SUPPLIER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SUPPLIER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const listSuppliers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SUPPLIER_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/supplier`);

    dispatch({
      type: SUPPLIER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SUPPLIER_LIST_FAIL,
      payload: message,
    });
  }
};

export const listSuppliersByParentUser = (parentUser) => async (dispatch, getState) => {
  console.log('Exec listSuppliersByParentUser', parentUser)
  try {
    dispatch({
      type: SUPPLIER_LIST_BY_PARENT_USER_REQUEST,
    });

    const { data } = await axios.get(`/api/supplier/parent-user/${parentUser}`);

    dispatch({
      type: SUPPLIER_LIST_BY_PARENT_USER_SUCCESS,
      payload: data,
    });
    // dispatch({ type: SUPPLIER_LIST_BY_PARENT_USER_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SUPPLIER_LIST_BY_PARENT_USER_FAIL,
      payload: message,
    });
  }
};

export const deleteSupplier = (id) => async (dispatch, getState) => {
  console.log("deleteSupplier ", id);
  try {
    dispatch({
      type: SUPPLIER_DELETE_REQUEST,
    });

    await axios.delete(`/api/supplier/${id}`);

    dispatch({ type: SUPPLIER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SUPPLIER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateSupplier = (name, contactNo, email, billingAddress, gst,city, district,state, pincode, pos, parentUser) => async (dispatch, getState) => {
  console.log('updateSupplier : ',name, contactNo, email, billingAddress, gst,city, district,state, pincode, pos, parentUser)
  try {
    dispatch({
      type: SUPPLIER_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/supplier/details/${name}`, {
      name, contactNo, email, billingAddress, gst,city, district,state, pincode, pos, parentUser
    }, {});

    dispatch({ type: SUPPLIER_UPDATE_SUCCESS , payload: data });

    dispatch({ type: SUPPLIER_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: SUPPLIER_UPDATE_FAIL,
      payload: message,
    });
  }
};
