import React, { useState, useEffect } from "react";
import { resetCartItems } from "../../actions/cartAction";
import {
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Button,
    Tooltip,
    Box,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Icon,
    Typography,
    TextField,
} from "@mui/material";
import Message from "../Message";
import vttUpiScanner from "../../assets/images/VTT_UPI_SCANNER.jpeg";
import rupeeSvgIcon from "../../assets/images/currency-inr.svg";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CustomizedDialogs from "../Dialog/CustomizedDialogs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import { payment_mode_single_multiple, payment_types } from "../../data/index";
import GridItem from "../Grid/GridItem";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { updateProductStockPostPlacingOrder } from "../../actions/stockAction";
import { createOrder } from "../../actions/orderAction";
import { ORDER_CREATE_RESET } from "../../constants/orderConstants";
import { createInvoice } from "../../actions/vehicleSalesInvoiceAction";
import { VEHICLE_INVOICE_TO_SHOP_CREATE_RESET } from "../../constants/vehicleSalesInvoiceConstants";
import { updateStockWhenOutFromVehicleNoAndProductName } from "../../actions/vehicleStockAction";
import GridContainer from "../Grid/GridContainer";
import { updateStockOutByWarehouseProduct } from "../../actions/warehouseStockAction";
import { updateStockOutByStoreProduct } from "../../actions/storeStockAction";
import { createWalkinSaleInvoice } from "../../actions/walkinSaleInvoiceAction";
import { WALKIN_SALES_INVOICE_CREATE_RESET } from "../../constants/walkinSalesInvoiceConstants";
import { addDecimals, formatToLocaleCurrency } from "../../util/Utility";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../assets/images/green_tick.png";
import { createPdfInvoice } from "../../actions/pdfInvoiceAction";
import { createWhatsAppInvoice } from "../../actions/whatsAppInvoiceAction";
import { createWalkinSaleReceipt } from "../../actions/walkinSaleReceiptAction";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },

    control: {
        padding: theme.spacing(2),
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleGreen: {
        color: "#26A541",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "Roboto",
        marginBottom: "3px",
        textDecoration: "none",
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        textAlign: "left",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
}));

const WalkinSalePayment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { customerDetails, invoiceDetails, productsInCart, storeName, warehouseName } = props
    console.log('props Passed to WalkinSalePayment are : ', props)
    console.log('invoiceDetails : ', invoiceDetails, " customerDetails : ", customerDetails)
    console.log('productsInCart : ', productsInCart)
    const posName = storeName && storeName.length > 0 ? storeName : warehouseName && warehouseName.length > 0 ? warehouseName : ""
    const posStoreOrWarehouse = posName && posName.length > 0 && posName.includes('STORE') ? 'Store' : posName.includes('WAREHOUSE') ? 'Warehouse' : null
    console.log('posName : ', posName, 'posStoreOrWarehouse : ', posStoreOrWarehouse)
    const [isCash, setIsCash] = useState(() => false);
    const [isUpi, setIsUpi] = useState(() => false);
    const [isCashRadioItem, setIsCashRadioItem] = useState(() => false);
    const [isUpiRadioItem, setIsUpiRadioItem] = useState(() => false);
    const [paymentMode, setPaymentMode] = useState(() => "Single");
    const [paymentType, setPaymentType] = useState(() => []);
    const [cashAmount, setCashAmount] = useState(() => null);
    const [upiAmount, setUpiAmount] = useState(() => null);
    const [amountPaid, setAmountPaid] = useState(() => null);
    const [paidTo, setPaidTo] = useState(() => "");
    const [upiReference, setUpiReference] = useState(() => "");
    const [balanceAmountTobePaid, setBalanceAmountTobePaid] = useState(() => 0.0);
    const [totalAmountTobePaid, setTotalAmountTobePaid] = useState(() => 0.0);
    const vertical = 'top';
    const horizontal = 'center';
    const [customError, setCustomError] = useState(() => []);

    const [errorObject, setErrorObject] = useState(() => null);
    const [itemsPriceObject, setItemsPriceObject] = useState(() => { });
    const [checked, setChecked] = React.useState(false);

    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        if (walkin_sales_invoice_create_success) {
            dispatch({ type: WALKIN_SALES_INVOICE_CREATE_RESET });
            navigate("/admin/walkin-sales-invoice/list");
        }
    };

    const warehouseStockUpdated = useSelector((state) => state.warehouseStockUpdated);
    const { wh_stock_update_success, wh_stock_update_error } = warehouseStockUpdated;

    const storeStockUpdated = useSelector((state) => state.storeStockUpdated);
    const { store_stock_update_success, store_stock_update_error } = storeStockUpdated;

    const walkinSalesInvoiceCreate = useSelector((state) => state.walkinSalesInvoiceCreate);
    const { walkin_sales_invoice_create_success, walkin_sales_invoice_create_data, walkin_sales_invoice_create_error } = walkinSalesInvoiceCreate;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [openSnackBar, setOpenSnackBar] = React.useState(true);

    const placeOrderHandler = () => {
        console.log(" *******  Clicked  placeOrderHandler  *******  ");
        console.log("Products in Cart : cart :==> ", productsInCart? productsInCart.length : 'No Products in Cart');
        console.log("Amoun paid Details  cashAmountPaid :==> ", cashAmount, "upiAmount : ", upiAmount);
        console.log("customerDetails :==> ", customerDetails);

        let paymentMadeDetails = {};

        paymentMadeDetails = {
            cashAmountPaid: cashAmount ? cashAmount : 0.0,
            upiAmountPaid: upiAmount ? upiAmount : 0.0,
            paidTo: (paidTo) ? paidTo : "",
            discountApplied: itemsPriceObject.itemsDiscountPrice ? itemsPriceObject.itemsDiscountPrice : 0.0,
            orderTotal: itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice - itemsPriceObject.totalDiscountPrice : itemsPriceObject.totalCartItemsPrice,
        };

        console.log(" ####### paymentMadeDetails : ", paymentMadeDetails, " ####### ");

        if (productsInCart && invoiceDetails && posName && posStoreOrWarehouse) {
            console.log("Processing for POS Sale Invoice ");
            console.log("productsInCart: ", productsInCart ? productsInCart : ' Zero Records');
            /** 
              LOGIC FOR STOCK OUT OF WH... IS HANDLED HERE.!!!
            */

            if (posStoreOrWarehouse === "Warehouse") {
                console.log(" ******* NOW DISPATCHING TO createWalkinSaleInvoice ******* ");
                dispatch(createWalkinSaleInvoice({
                    customerDetails: customerDetails,
                    orderItems: productsInCart,
                    paymentDetails: paymentMadeDetails,
                    itemsPrice: itemsPriceObject && itemsPriceObject.totalCartItemsPrice && itemsPriceObject.totalDiscountPrice ? (itemsPriceObject.totalCartItemsPrice - itemsPriceObject.totalDiscountPrice) : itemsPriceObject.totalCartItemsPrice,
                    taxPrice: itemsPriceObject && itemsPriceObject.totalTaxPrice ? itemsPriceObject.totalTaxPrice : 0.0,
                    invoiceDetails: invoiceDetails,
                    posName: posName
                }));
                console.log(" ******* NOW DISPATCHING TO updateStockOutByWarehouseProduct ******* ");
                dispatch(
                    updateStockOutByWarehouseProduct(
                        invoiceDetails,
                        productsInCart,
                        posName
                    )
                );
                dispatch(resetCartItems());

            }
            /** 
               LOGIC FOR STOCK OUT OF STORE... IS HANDLED HERE.!!!
            */
            if (posStoreOrWarehouse === "Store") {
                console.log(" ******* NOW DISPATCHING TO createWalkinSaleInvoice ******* ");

                dispatch(createWalkinSaleInvoice({
                    customerDetails,
                    productsInCart,
                    paymentDetails: paymentMadeDetails,
                    itemsPrice: itemsPriceObject && itemsPriceObject.totalCartItemsPrice && itemsPriceObject.totalDiscountPrice ? (itemsPriceObject.totalCartItemsPrice - itemsPriceObject.totalDiscountPrice) : itemsPriceObject.totalCartItemsPrice,
                    taxPrice: itemsPriceObject && itemsPriceObject.totalTaxPrice ? itemsPriceObject.totalTaxPrice : 0.0,
                    invoiceDetails,
                    posName
                }, posName));
                console.log(" ******* NOW DISPATCHING TO updateStockOutByStoreProduct ******* ");
                dispatch(
                    updateStockOutByStoreProduct(
                        invoiceDetails,
                        productsInCart,
                        posName
                    )
                );
                dispatch(resetCartItems());
            }
        }
        else
            console.log("UNABLE TO PROCEED AS productsInCart && invoiceDetails && posName && posStoreOrWarehouse are NULL .!");
        {/**
            LOGIC TO CREATE PDF INVOICE MORE OR LESS IF WAREHOUSE || STORE  ||.
        */}
        console.log('******* NOW DISPATCHING TO createPdfInvoice *******')
        dispatch(createPdfInvoice(
            invoiceDetails, productsInCart, paymentMadeDetails
        ))
        {/**
            LOGIC TO SEND INVOICE OVER WHATSAPP MORE OR LESS IF WAREHOUSE || STORE  ||.
        */}
        console.log('*******  DISPATCHING TO createWhatsAppInvoice  IS COMMENTED FOR NOW *******')
        // dispatch(createWhatsAppInvoice(
        //     "walkin-sale",invoiceDetails, productsInCart, paymentMadeDetails,
        // ))
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackBar(false);
    };

    const calculateItemsPriceObject = (items) => {
        let itmsPriObj;
        console.log('Exec calculateItemsPriceObject')
        console.log('itemsPrice of Items in Cart: ')
        items.map((eachItem) => console.log('Each Items eachItem.unitPrice:', eachItem.unitPrice, 'Quantity Selected : ', eachItem.quantity))
        let itemsPrice = 
        // formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) => acc + eachItem.unitPrice * eachItem.quantity,
                0
            )
        // );
        console.log('itemsDiscountPrice of Items in Cart: ')
        items.map((eachItem) => console.log('Each Items eachItem.itemsDiscountPrice:', eachItem.itemsDiscountPrice))
        let itemsDiscountPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.itemsDiscountPrice ? Number(eachItem.itemsDiscountPrice) : 0.0),
                0
            )
        );

        let totalTax = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.productTax ? Number(eachItem.productTax) : 0.0),
                0
            )
        );
        let totalTaxAmount = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.productTaxAmount ? Number(eachItem.productTaxAmount) : 0.0),
                0
            )
        );
        itmsPriObj = {
            totalTaxPrice: totalTaxAmount,
            totalTax: totalTax,
            totalDiscountPrice: itemsDiscountPrice ? Number(itemsDiscountPrice) : 0.0,
            totalCartItemsPrice: itemsPrice ? Number(itemsPrice) : 0.0,
        };
        console.log('CALCULATED itmsPriObj : ', itmsPriObj, ' NOW SETTING TO STATE')
        console.log('###### totalTax : ', totalTax, ' totalTaxAmount : ', totalTaxAmount, ' ######')
        setItemsPriceObject(() => itmsPriObj);

    };

    useEffect(() => {
        console.log('useEffect getting Called as productsInCart are getting Changed')
        if (productsInCart && productsInCart.length > 0) {
            calculateItemsPriceObject(productsInCart);
        }
    }, [productsInCart]);

    useEffect(() => {
        console.log("useEffect Getting Called on WalkinSalePayment Screen as Success on Create New Invoice", walkin_sales_invoice_create_success);
        if (walkin_sales_invoice_create_success && walkin_sales_invoice_create_data) {
            console.log("walkin_sales_invoice_create_success :==> ", walkin_sales_invoice_create_success);
            const { _id } = walkin_sales_invoice_create_data
            const receiptObj = { id: _id, walkin_sales_invoice_receipt_update: walkin_sales_invoice_create_data }
            dispatch(createWalkinSaleReceipt(receiptObj))
            setOpen(() => true);
        }
    }, [walkin_sales_invoice_create_success]);

    const handlePaymentModeChange = (e) => {
        console.log("Exec handlePaymentModeChange ", e);
        console.log(
            "e.target.name :==> ",
            e.target.name,
            " , e.target.value :==> ",
            e.target.value
        );
        clearFormValueForPaymentModeChange();
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
        setPaymentMode((prev) => e.target.value)
    };

    const handleChangeCheckbox = (event) => {
        console.log("event : ", event.target.name, event.target.checked);
        switch (event.target.name) {
            case "isCash":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => true)
                    setPaymentType(() => [...paymentType, "cash"])

                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.UN checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => false)
                    setAmountPaid((prev) => prev - upiAmount);
                    console.log(" ***** Deducting cashAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setCashAmount(() => 0.0)
                    setPaidTo(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i === "cash")],
                    );
                }
                break;
            case "isUpi":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => true)
                    setPaymentType(() => [...paymentType, "upi"],
                    );
                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target. UN checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => false)
                    setAmountPaid((prev) => prev - (cashAmount));
                    console.log(" ***** Deducting upiAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setUpiAmount(() => 0.0)
                    setUpiReference(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i !== "upi")],
                    );
                }
                break;
            default:
                break;
        }
    };

    const handleChangeRadioOptions = (event) => {
        event.persist();
        clearFormValueForSinglePaymentType();
        console.log(
            "handleChange Radio event : ",
            event.target.name,
            event.target.value
        );
        if (event.target.value === "cash") {
            setIsCashRadioItem(() => true)
            setIsUpiRadioItem(() => false)
            setPaymentType(["cash"])
            console.log("Have Set Cash Radio Item..");
        }
        else if (event.target.value === "upi") {
            setIsCashRadioItem(() => false)
            setIsUpiRadioItem(() => true)
            setPaymentType(["upi"])
            console.log("Have Set upi  Radio Item..");
        }
    };

    const handleChangeUpiReference = (event) => {
        event.persist();
        const upiReferenceValue = event.target.value;
        setUpiReference(() => upiReferenceValue);
    };

    const handleChangePaidTo = (event) => {
        event.persist();
        const paidToValue = event.target.value;
        setPaidTo(() => paidToValue);
    };

    const clearFormValueForPaymentModeChange = () => {
        console.log("clearFormValueForPaymentModeChange ");
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
    };

    const clearFormValueForSinglePaymentType = () => {
        console.log("clearFormValueForSinglePaymentType ");
        setIsCashRadioItem((prev) => false)
        setIsUpiRadioItem((prev) => false)
        setCashAmount(() => null)
        setUpiAmount(() => null)
        setUpiReference(() => "")
        setPaidTo(() => "")
        setAmountPaid(() => null)
    };

    const renderCashDetails =
        (
            <Grid container spacing={2}>
                <GridItem item xs={12} sm={12} md={12} style={{ margin: "0.5rem", textAlign: "center" }}>
                    <TextField
                        label="Cash Amount"
                        required
                        variant="outlined"
                        size="small"
                        name="cashAmount"
                        style={{
                            width: "12rem",
                            textAlign: "center",
                            marginTop: "0.5rem"
                        }}
                        onChange={(e) => {
                            const cashValue = e.target.value;
                            console.log("CASH Amount : ", cashValue)
                            validateInputLessThanOrderTotal(cashValue);
                            validateCashInputLessThanTwoLakhs(cashValue)
                            // CHECK IF ABOVE TWO CONDITIONS ARE VALUD THEN ONLY PERFORM THE BELOW STATE UPDATE
                            setCashAmount((prev) => Number(cashValue));
                            setAmountPaid((prev) => {
                                if (isUpi) {
                                    return (upiAmount ? Number(upiAmount) : 0.0) + (cashValue ? Number(cashValue) : 0.0)
                                } else
                                    return cashValue ? Number(cashValue) : 0.0

                            })
                            calculateBalanceAmountIfVer(0, Number(cashValue));
                        }}
                        value={cashAmount ? Number(cashAmount) : null}
                        type="number"
                    />
                    {errorObject && errorObject.errorMessage && (<Typography
                        variant="h5"
                        style={{ textAlign: "center", fontSize: "1rem", margin: "1rem", color: "red" }}
                    >
                        {errorObject.errorMessage}
                    </Typography>)}
                </GridItem>
                <GridItem item xs={12} sm={12} md={12} style={{ margin: "0.5rem", textAlign: "center" }}>
                    <TextField
                        label="Cash Paid To"
                        required
                        variant="outlined"
                        size="small"
                        name="paidTo"
                        style={{
                            width: "12rem",
                            textAlign: "center",
                        }}
                        onChange={handleChangePaidTo}
                        value={paidTo ? paidTo : ""}
                        type="text"
                    />
                </GridItem>
            </Grid>
        );

    const renderUpiScannerInformation = (
        <Grid container spacing={1} justify="center" style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <Grid item xs={12} style={{ justifyContent: "center", alignItems: "center", verticalAlign: "center", marginLeft: "auto" }}>
                <img
                    alt="Vishudha UPI Scanner"
                    src={vttUpiScanner}
                    style={{ width: "23vh", height: "23vh", verticalAlign: "center", color: "gray", justifyContent: "center", alignItems: "center" }}
                    className={classes.imageIcon}
                />
            </Grid>
            <Divider />
        </Grid>
    );

    const renderUpiDetails =    (
        <Grid container spacing={2}>
            {checked && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
                <Alert onClose={handleCloseSnackBar} severity="warning" sx={{ width: '100%', textTransform: "none" }}>
                    Please make the payment and enter the Valid Transaction id in the UPI Ref field
                </Alert>
            </Snackbar>)}
            <GridItem item xs={12} sm={12} md={12} style={{ margin: "0.5rem", textAlign: "center" }}>
                <TextField
                    label="UPI Amount"
                    required
                    variant="outlined"
                    size="small"
                    name="upiAmount"
                    style={{
                        width: "12rem",
                        textAlign: "center",
                        marginTop: "0.5rem"
                    }}
                    onChange={(e) => {
                        const upiValue = e.target.value;
                        console.log("UPI Amount : ", upiValue)
                        validateInputLessThanOrderTotal(upiValue);
                        setUpiAmount((prev) => Number(upiValue));
                        setAmountPaid((prev) => {
                            if (isCash) {
                                return (cashAmount ? Number(cashAmount) : 0.0) + (upiValue ? Number(upiValue) : 0.0)
                            } else {
                                return upiValue ? Number(upiValue) : 0.0
                            }
                        })
                        // setAmountPaid((prev) => Number(amountPaid) + (upiValue ? Number(upiValue) : 0.0));
                        calculateBalanceAmountIfVer(Number(upiValue), 0);
                    }}
                    value={upiAmount ? Number(upiAmount) : null}
                    type="number"
                />
                {errorObject && errorObject.errorMessage && (<Typography
                    variant="h5"
                    style={{ textAlign: "left", fontSize: "0.75rem", margin: "1rem", color: "red" }}
                >
                    {errorObject.errorMessage}
                </Typography>)}
            </GridItem>
            <GridItem item xs={12} sm={12} md={12} style={{ margin: "0.5rem", textAlign: "center" }}>
                <TextField
                    label="Upi Ref"
                    required
                    variant="outlined"
                    size="small"
                    name="upiReference"
                    xs={{ width: "8rem" }}
                    style={{
                        width: "12rem",
                        textAlign: "center",
                    }}
                    onChange={handleChangeUpiReference}
                    value={upiReference ? upiReference : ""}
                    type="text"
                />
            </GridItem>
        </Grid>
    );

    const clearFormValueForMultiplePaymentType = () => {
        console.log("clearFormValueForMultiplePaymentType ");
        setIsCash((prev) => false)
        setIsUpi((prev) => false)
        setCashAmount(() => null)
        setUpiAmount(() => null)
        setUpiReference(() => "")
        setPaidTo(() => "")
        setAmountPaid(() => null)
    };
    const renderMultiplePaymentModesContent = (
        <React.Fragment>
            <FormControl
                sx={{ marginRight: "3rem" }}
                component="fieldset"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    height: "3vh"
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCash}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isCash}
                            name="isCash"
                        />
                    }
                    label="Cash"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isUpi}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isUpi}
                            name="isUpi"
                        />
                    }
                    label="UPI"
                />

            </FormControl>
        </React.Fragment>
    );

    const renderSinglePaymentModeContent = (
        <React.Fragment>
            <FormControl
                component="fieldset"
                sx={{ marginRight: "3rem" }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    margin: "1rem"

                    // height: "3vh"
                }}
            >
                <RadioGroup
                    row={true}
                    aria-label="paymentType"
                    name="paymentType"
                    value={paymentType}
                    style={{ marginLeft: "0.5rem", textAlign: "center", alignItems: "center", justifyContent: "center" }}
                    onChange={(e) => handleChangeRadioOptions(e)}
                >
                    <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                    <FormControlLabel value="upi" control={<Radio />} label="Upi" />
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );
    const renderOrderDetails =
        (
            <Grid container spacing={1} justify="center">
                <Grid item xs={12}>
                    <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ marginLeft: "3rem", fontWeight: "600" }}>
                                Tax included
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ textAlign: "right", marginRight: "1rem", alignItems: "center", justifyContent: "center", fontWeight: '600' }}>
                                <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                                    <img
                                        alt="curency inr"
                                        src={rupeeSvgIcon}
                                        style={{ height: "1rem", verticalAlign: "center", color: "gray" }}
                                        className={classes.imageIcon}
                                    />
                                </Icon>

                                {itemsPriceObject && itemsPriceObject.totalTaxPrice ? formatToLocaleCurrency(Number(itemsPriceObject.totalTaxPrice).toFixed(2)) : 0.0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ marginLeft: "3rem", fontWeight: "600" }}>
                                Discount applied
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ textAlign: "right", marginRight: "1rem", alignItems: "center", justifyContent: "center", fontWeight: '600' }}>
                                <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                                    <img
                                        alt="curency inr"
                                        src={rupeeSvgIcon}
                                        style={{ height: "1rem", verticalAlign: "center", color: "gray" }}
                                        className={classes.imageIcon}
                                    />
                                </Icon>
                                {itemsPriceObject && itemsPriceObject.totalDiscountPrice ? formatToLocaleCurrency(itemsPriceObject.totalDiscountPrice) : 0.0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ marginLeft: "3rem", fontWeight: "600" }}>
                                Order Total
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ textAlign: "right", marginRight: "1rem", alignItems: "center", justifyContent: "center", fontWeight: '600' }}>
                                <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                                    <img
                                        alt="curency inr"
                                        src={rupeeSvgIcon}
                                        style={{ height: "1rem", verticalAlign: "center", color: "gray" }}
                                        className={classes.imageIcon}
                                    />
                                </Icon>
                                {itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? formatToLocaleCurrency(itemsPriceObject.totalCartItemsPrice) : 0.0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ marginLeft: "3rem", fontWeight: "600" }}>
                                Paid
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ textAlign: "right", marginRight: "1rem", alignItems: "center", justifyContent: "center", fontWeight: '600' }}>
                                <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                                    <img
                                        alt="curency inr"
                                        src={rupeeSvgIcon}
                                        style={{ height: "1rem", verticalAlign: "center", color: "gray" }}
                                        className={classes.imageIcon}
                                    />
                                </Icon>
                                {/* {Number(amountPaid).toLocaleString("en-IN")}  */}
                                {amountPaid ? formatToLocaleCurrency(amountPaid) : 0.0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ marginLeft: "3rem", fontWeight: "600" }}>
                                Balance
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" style={{ textAlign: "right", marginRight: "1rem", alignItems: "center", justifyContent: "center", fontWeight: '600' }}>
                                <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                                    <img
                                        alt="curency inr"
                                        src={rupeeSvgIcon}
                                        style={{ height: "1rem", verticalAlign: "center", color: "gray" }}
                                        className={classes.imageIcon}
                                    />
                                </Icon>
                                {balanceAmountTobePaid ? formatToLocaleCurrency(balanceAmountTobePaid) : 0.0} </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
            </Grid>
        );


    const renderPaymentModeSummary = (
        <Grid container>
            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={2}>
                    <Grid item={true} xs={12} sm={6} md={6} >
                        <Grid item xs={12} >
                            <GridItem item xs={12} style={{ marginBottom: "0.25rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                <FormControl style={{ textAlign: "center", paddingTop: "1rem" }}>
                                    <InputLabel id="demo-simple-select-label">
                                        Payment Mode
                                    </InputLabel>
                                    <Select
                                        label="Payment Mode"
                                        placeholder="Payment Mode"
                                        variant="outlined"
                                        required
                                        size="small"
                                        name="paymentMode"
                                        onChange={handlePaymentModeChange}
                                        value={paymentMode}
                                        style={{ width: "9rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}
                                    >
                                        {/* {()=> payment_mode_single_multiple.map((each, idx) => (<MenuItem key={idx} value={each}>{each}</MenuItem>))} */}
                                        <MenuItem key="Single" value="Single">Single</MenuItem>
                                        <MenuItem key="Multiple" value="Multiple">Multiple</MenuItem>
                                        <MenuItem key="Multiplsfsf" value="sdfad">afcds</MenuItem>
                                    </Select>
                                </FormControl>
                            </GridItem>
                        </Grid>
                        <Grid item xs={12} >
                            {paymentMode === "Single" &&
                                renderSinglePaymentModeContent}
                            {paymentMode === "Multiple" &&
                                renderMultiplePaymentModesContent}
                        </Grid>
                        {isCash || isCashRadioItem ? (
                            <Grid item xs={12} sm={6} md={12} style={{ margin: "0.5rem" }}>
                                {renderCashDetails}
                            </Grid>
                        ) : null}
                        {isUpi || isUpiRadioItem ? (
                            <Grid item xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
                                {renderUpiDetails}
                            </Grid>
                        ) : null}
                    </Grid>
                    {(paymentType.includes("upi") || isUpi) && (
                        <Grid item={true} xs={12} sm={6} md={6} >
                            {renderUpiScannerInformation}
                        </Grid>
                    )}
                    {/*
                            PLACE ORDER BUTTON : START
                            */}
                    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                        <Grid
                            item={true}
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ marginBottom: "1rem", textAlign: "center" }}
                        >
                            <Button
                                size="small"
                                variant="contained"
                                type="submit"
                                color="secondary"
                                onClick={placeOrderHandler}
                                fullWidth={true}
                                style={{
                                    textTransform: "capitalize",
                                    width: "25vh",
                                    borderRadius: "5px",
                                }}
                                disabled={((isUpi === true || isUpiRadioItem === true) && upiReference && upiReference.length > 0) ?
                                    false : ((isCash === true || isCashRadioItem === true) && paidTo && paidTo.length > 0) ?
                                        false : (errorObject && errorObject.errorMessage && errorObject.errorMessage.length === 0 ? false : true)}
                            >
                                Place Order
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )

    const validateInputLessThanOrderTotal = (val) => {
        console.log("amountPaid : ", val);
        const orderItemsTotalPrice = itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice : 0.0;
        if (val > orderItemsTotalPrice) {
            setErrorObject({ errorMessage: "Amount can't exceed  " + orderItemsTotalPrice });
        }
        else
            setErrorObject(null);
        return;
    }
    const validateCashInputLessThanTwoLakhs = (val) => {
        console.log(' ******* validateCashInputLessThanTwoLakhs ******* ', val)
        console.log("Cash amountPaid : ", val, 'cashAmount : ', cashAmount);
        if (val && Number(val) >= 200000) {
            setErrorObject({ errorMessage: "Cash Amount can't exceed 2 Laks !" });
        }
        else
            setErrorObject(null);
        return;
    }

    const calculateBalanceAmountIfVer = (upi, cash) => {
        let balanceAmount = 0.0;
        let totalOfDifferentModes = 0.0;
        console.log("Exec calculateBalanceAmountIfVer : UPI Amount : ", upi, " , cashAmount : ", cash);

        if (paymentMode === "Single") {
            if (isUpiRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (upi ? Number(upi) : 0.0);
                console.log("totalOfDifferentModes When isUpi is Selected :=> ", totalOfDifferentModes);
            } else if (isCashRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (cash ? Number(cash) : 0.0);
                console.log("totalOfDifferentModes  When isCash is Selected:=> ", totalOfDifferentModes);
            }
        }

        if (paymentMode === "Multiple") {
            if (paymentType.includes("cash")) {
                console.log("totalOfDifferentModes When Multiple  && isCash is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (cash
                        ? Number(cash)
                        : 0.0)
            }
            else if (paymentType.includes("upi")) {
                console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (upi
                        ? Number(upi)
                        : 0.0)
            }
            console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
        }

        console.log("paymentType.includes(upi) ", paymentType.includes("upi"));
        console.log("paymentType.includes(cash) ", paymentType.includes("cash"));

        console.log("itemsPriceObject :=> ", itemsPriceObject);
        console.log("totalOfDifferentModes : ", totalOfDifferentModes, " UPI : ", upi, " CASH : ", cash, "itemsPriceObject.totalCartItemsPrice", itemsPriceObject.totalCartItemsPrice);
        balanceAmount = (Number(itemsPriceObject.totalCartItemsPrice) - Number(itemsPriceObject.totalDiscountPrice)) - Number(totalOfDifferentModes);
        console.log("balanceAmount : ", balanceAmount);
        setBalanceAmountTobePaid((prev) => balanceAmount);
    };

    return (
        <React.Fragment>
            {customError && customError.errorMessage && customError.errorMessage !== "" && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    Please Ensure Cash Amount doesn't exceed 2 Laks.!
                </Alert>
            </Snackbar>)}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <GridContainer spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} >
                            <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                                    <img
                                        alt="Order Placed"
                                        src={greeTickImage}
                                        style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                                    />
                                </div>
                                <Typography
                                    variant="h5"
                                    color="black"
                                    style={{
                                        margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                                        fontWeight: "600",
                                        fontSize: "1rem",
                                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                    }}
                                >
                                    Thanks , your order is placed.
                                </Typography>
                            </Paper>
                        </Grid>
                    </GridContainer>
                </DialogContent>
            </BootstrapDialog>
            <GridContainer spacing={2} alignItems="center" justify="center">
                <Grid item xs={12} sm={6} md={6}>
                    <Paper style={{ margin: "1rem" }}>
                        {renderOrderDetails}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Paper style={{ margin: "1rem" }}>
                        {/* {renderPaymentSummary} */}
                        {/* {renderPaymentModeContent} */}
                        {renderPaymentModeSummary}
                    </Paper>
                </Grid>
            </GridContainer>
            {/* <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card >
                        <CardBody>
                            <Grid container spacing={2}>
                                <Grid item={true} xs={12} sm={6} md={6} >
                                    <Grid item xs={12} >
                                        <GridItem item xs={12} style={{ marginBottom: "0.25rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                            <FormControl style={{ textAlign: "center", paddingTop: "1rem" }}>
                                                <InputLabel id="demo-simple-select-label">
                                                    Payment Mode
                                                </InputLabel>
                                                <Select
                                                    label="Payment Mode"
                                                    placeholder="Payment Mode"
                                                    variant="outlined"
                                                    required
                                                    size="small"
                                                    name="paymentMode"
                                                    onChange={handlePaymentModeChange}
                                                    value={paymentMode}
                                                    style={{ width: "9rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}
                                                >
                                                    <MenuItem key="Single" value="Single">Single</MenuItem>
                                                    <MenuItem key="Multiple" value="Multiple">Multiple</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </GridItem>
                                    </Grid>
                                    <Grid item xs={12} >
                                        {paymentMode === "Single" &&
                                            renderSinglePaymentModeContent}
                                        {paymentMode === "Multiple" &&
                                            renderMultiplePaymentModesContent}
                                    </Grid>
                                    {isCash || isCashRadioItem ? (
                                        <Grid item xs={12} sm={6} md={12} style={{ margin: "0.5rem" }}>
                                            {renderCashDetails}
                                        </Grid>
                                    ) : null}
                                    {isUpi || isUpiRadioItem ? (
                                        <Grid item xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
                                            {renderUpiDetails}
                                        </Grid>
                                    ) : null}
                                </Grid>
                                {(paymentType.includes("upi") || isUpi) && (
                                    <Grid item={true} xs={12} sm={6} md={6} >
                                        {renderUpiScannerInformation}
                                    </Grid>
                                )}
                                <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                                    <Grid
                                        item={true}
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        style={{ marginBottom: "1rem", textAlign: "center" }}
                                    >
                                        <Button
                                            size="small"
                                            variant="contained"
                                            type="submit"
                                            color="secondary"
                                            onClick={placeOrderHandler}
                                            fullWidth={true}
                                            style={{
                                                textTransform: "capitalize",
                                                width: "25vh",
                                                borderRadius: "5px",
                                            }}
                                            disabled={((isUpi === true || isUpiRadioItem === true) && upiReference && upiReference.length > 0) ?
                                                false : ((isCash === true || isCashRadioItem === true) && paidTo && paidTo.length > 0) ?
                                                    false : (errorObject && errorObject.errorMessage && errorObject.errorMessage.length === 0 ? false : true)}
                                        >
                                            Place Order
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid> */}
        </React.Fragment>
    );
};

export default WalkinSalePayment;
