export const LIST_STOCK_BY_ALL_PRODUCTS_REQUEST =
  "LIST_STOCK_BY_ALL_PRODUCTS_REQUEST";
export const LIST_STOCK_BY_ALL_PRODUCTS_SUCCESS =
  "LIST_STOCK_BY_ALL_PRODUCTS_SUCCESS";
export const LIST_STOCK_BY_ALL_PRODUCTS_FAIL =
  "LIST_STOCK_BY_ALL_PRODUCTS_FAIL";

export const LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_REQUEST =
  "LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_REQUEST";
export const LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_SUCCESS =
  "LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_SUCCESS";
export const LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_FAIL =
  "LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_FAIL";
export const LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_RESET =
  "LIST_WAREHOUSE_STOCK_BY_ALL_PRODUCTS_RESET";

export const LIST_STORE_STOCK_BY_ALL_PRODUCTS_REQUEST =
  "LIST_STORE_STOCK_BY_ALL_PRODUCTS_REQUEST";
export const LIST_STORE_STOCK_BY_ALL_PRODUCTS_SUCCESS =
  "LIST_STORE_STOCK_BY_ALL_PRODUCTS_SUCCESS";
export const LIST_STORE_STOCK_BY_ALL_PRODUCTS_FAIL =
  "LIST_STORE_STOCK_BY_ALL_PRODUCTS_FAIL";
export const LIST_STORE_STOCK_BY_ALL_PRODUCTS_RESET =
  "LIST_STORE_STOCK_BY_ALL_PRODUCTS_RESET";


export const LIST_STOCK_BY_PRODUCT_ID_REQUEST =
  "LIST_STOCK_BY_PRODUCT_ID_REQUEST";
export const LIST_STOCK_BY_PRODUCT_ID_SUCCESS =
  "LIST_STOCK_BY_PRODUCT_ID_SUCCESS";
export const LIST_STOCK_BY_PRODUCT_ID_FAIL = "LIST_STOCK_BY_PRODUCT_ID_FAIL";



export const CREATE_STOCK_BY_PRODUCT_ID_REQUEST =
  "CREATE_STOCK_BY_PRODUCT_ID_REQUEST";
export const CREATE_STOCK_BY_PRODUCT_ID_SUCCESS =
  "CREATE_STOCK_BY_PRODUCT_ID_SUCCESS";
export const CREATE_STOCK_BY_PRODUCT_ID_FAIL =
  "CREATE_STOCK_BY_PRODUCT_ID_FAIL";
export const CREATE_STOCK_BY_PRODUCT_ID_RESET =
  "CREATE_STOCK_BY_PRODUCT_ID_RESET";

export const UPDATE_STOCK_BY_PRODUCT_ID_REQUEST =
  "UPDATE_STOCK_BY_PRODUCT_ID_REQUEST";
export const UPDATE_STOCK_BY_PRODUCT_ID_SUCCESS =
  "UPDATE_STOCK_BY_PRODUCT_ID_SUCCESS";
export const UPDATE_STOCK_BY_PRODUCT_ID_FAIL =
  "UPDATE_STOCK_BY_PRODUCT_ID_FAIL";
export const UPDATE_STOCK_BY_PRODUCT_ID_RESET =
  "UPDATE_STOCK_BY_PRODUCT_ID_RESET";

  export const UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST =
  "UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST";
export const UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS =
  "UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS";
export const UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE =
  "UPDATE_STOCK_COUNT_BY_PRODUCT_ID_FAILURE";
