import {
  WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_FAIL,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_REQUEST,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_RESET,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_SUCCESS,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_FAIL,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_REQUEST,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_SUCCESS,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_RESET,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_REQUEST,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_RESPONSE,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_SUCCESS,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_FAIL,
  WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_RESET,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_REQUEST,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_SUCCESS,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_RESET,
  WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_FAIL,
} from "../constants/walkinVyaparItemSaleReportConstants";

export const walkinVyaparSyncItemSalesInvoicesReducer = (state = {}, action) => {
  switch (action.type) {
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_REQUEST:
      return { walkin_vyapar_item_sale_report_sync_loading: true };
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_SUCCESS:
      return { walkin_vyapar_item_sale_report_sync_loading: false, walkin_vyapar_item_sale_report_sync_success: true, walkin_vyapar_item_sale_report_sync_data: action.payload };
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_FAIL:
      return { walkin_vyapar_item_sale_report_sync_loading: false, walkin_vyapar_item_sale_report_sync_error: action.payload };
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_RESET:
      return {};
    default:
      return state;
  }
};


export const walkinVyaparItemSaleByStoreReducer = (
  state = { walkin_vyapar_item_sale_by_store_data: {} },
  action
) => {
  switch (action.type) {
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_REQUEST:
      return { walkin_vyapar_item_sale_by_store_loading: true, walkin_vyapar_item_sale_by_store_data: {} };
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_SUCCESS:
      return {
        walkin_vyapar_item_sale_by_store_loading: false,
        walkin_vyapar_item_sale_by_store_data: action.payload,
      };
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_FAIL:
      return { walkin_vyapar_item_sale_by_store_loading: false, walkin_vyapar_item_sale_by_store_error: action.payload };
    case WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_RESET:
      return {};
    default:
      return state;
  }
};