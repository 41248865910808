export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_REQUEST = "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_REQUEST";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_SUCCESS = "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_SUCCESS";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_FAIL = "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_FAIL";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_RESET = "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_STORE_RESET";

export const WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_REQUEST = "WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_REQUEST";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_SUCCESS = "WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_SUCCESS";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_FAIL = "WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_FAIL";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_RESET = "WALKIN_VYAPAR_ITEM_SALE_REPORT_CREATE_RESET";

export const WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_REQUEST = "WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_REQUEST";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_SUCCESS = "WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_SUCCESS";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_FAIL = "WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_FAIL";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_RESET = "WALKIN_VYAPAR_ITEM_SALE_REPORT_SYNC_RESET";

export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_REQUEST =
  "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_REQUEST";
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_SUCCESS =
  "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_SUCCESS";  
export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_FAIL =
  "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_FAIL";
  export const WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_RESET =
  "WALKIN_VYAPAR_ITEM_SALE_REPORT_BY_POS_RESET";

export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST";
export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS";
export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL";
export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET";

export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_REQUEST = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_REQUEST";
export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_SUCCESS = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_SUCCESS";
export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_FAIL = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_FAIL";
export const WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_RESET = "WALKIN_VYAPAR_ITEM_SALE_INVOICE_DETAILS_BY_POS_RESET";
