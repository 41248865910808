import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import { useNavigate, useParams } from "react-router-dom";
import GridContainer from "../Grid/GridContainer.js";
import HomeIcon from "@mui/icons-material/Home";
import { vehicleDetailsById } from "../../actions/vehicleAction.js";
import rupeeSvgIcon from "../../assets/images/currency-inr.svg";
import { CurrencyRupee, DeleteForever } from "@mui/icons-material";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import Box from "@mui/material/Box";
import {
  Paper,
  Grid,
  Typography,
  Tooltip,
  Button,
  Divider,
  Icon,
} from "@mui/material";
import Message from "../Message.js";
import { VEHICLE_BY_ID_REQUEST } from "../../constants/vehicleConstants.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainContainer: {
    marginTop: "4em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em",
    },
  },
  heroTextContainer: {
    minWidth: "21em",
    maxWidth: "50em",
    // marginLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "30em",
      marginTop: "1em",
    },
  },
  animation: {
    maxWidth: "50em",
    minWidth: "21em",
    marginTop: "-6%",
    // marginBottom: "-6%",
    marginLeft: "1%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "30em",
      marginTop: "1em",
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imageIcon: {
    height: "1rem",
  },
  iconRoot: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

export default function VehicleDetailsScreen() {
  const classes = useStyles();
  const { id } = useParams();
  console.log("Vehicle ID : ", id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const vehicleDetails = useSelector((state) => state.vehicleDetails);
  const { loading, error, vehicle } = vehicleDetails;

  useEffect(() => {
    if (id) dispatch(vehicleDetailsById(id));
  }, [dispatch, id]);

  return loading ? (
    <CustomBackdropSpinner />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Box sx={{ flexGrow: 1 }}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
          <Tooltip title="Back" arrow>
            <ArrowBackIcon
              fontSize="medium"
              onClick={() => window.history.back()}
              style={{ float: "left" }}
            />
          </Tooltip>
          <Tooltip title="Home" arrow>
            <HomeIcon
              fontSize="medium"
              onClick={() => navigate("/")}
              style={{ float: "right" }}
            />
          </Tooltip>
        </GridItem>
      </GridContainer>

    </Box>
  );
}
