import {
  VEHICLE_DETAILS_ADD_TO_STORAGE,
  VEHICLE_DETAILS_ADD_TO_STORAGE_REQUEST,
  VEHICLE_DETAILS_ADD_TO_STORAGE_SUCCESS,
  VEHICLE_DETAILS_ADD_TO_STORAGE_FAIL,
  VEHICLE_DETAILS_ADD_TO_STORAGE_RESET,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_REQUEST,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_FAIL,
  VEHICLE_DETAILS_REMOVE_FROM_STORAGE_RESET,
  INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST,
  INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS,
  INVOICE_DETAILS_ADD_TO_STORAGE_FAIL,
  INVOICE_DETAILS_ADD_TO_STORAGE_RESET,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_REQUEST,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_FAIL,
  INVOICE_DETAILS_REMOVE_FROM_STORAGE_RESET,
  INVOICE_DETAILS_ADD_TO_STORAGE,
  WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST,
  WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS,
  WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_FAIL,
  WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_RESET,
  ADD_WALKIN_SALES_INVOICE_DETAILS_TO_STORAGE
} from "../constants/storageConstants";


export const addVehicleDetailsToStorage = (vehicleNo) => async (
  dispatch,
  getState
) => {
  console.log(" About to Add Vehicle Details to Storage ::=>> ",vehicleNo);
  console.log("vehicleNo : ",vehicleNo);
  
  dispatch({
    type: VEHICLE_DETAILS_ADD_TO_STORAGE,
    payload: {
      vehicleNo: vehicleNo ,
    },
  });
  localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
  localStorage.setItem("vehicleDetailsFromLocalStorage", JSON.stringify(getState().vehicleDetailsFromStorage.vehicleDetailsFromStorage));
};

export const addInvoiceDetailsToStorage = (invoiceDetailsObject) => async (
  dispatch,
  getState
) => {
  console.log(" DEBUG DETAIL IN Invoice Details Storage Object ::=>> ",invoiceDetailsObject);
  const {billDate ,description ,driverContact ,driverName ,shopAddress , shopName,shopOwnerContact ,customerName, customerEmail, customerContact, customerGst, fromName, fromWhStoreName   } = invoiceDetailsObject ; 
  console.log("billDate ,description ,driverContact ,driverName ,gst ,shift ,shopAddress , shopName,shopOwnerContact : ",billDate ,description ,driverContact ,driverName ,shopAddress , shopName,shopOwnerContact ,customerName, customerEmail, customerContact, customerGst , fromName, fromWhStoreName );
  
  dispatch({
    type: INVOICE_DETAILS_ADD_TO_STORAGE,
    payload: {
      billDate ,description ,driverContact ,driverName ,shopAddress , shopName,shopOwnerContact ,customerName, customerEmail, customerContact, customerGst ,fromName, fromWhStoreName 
    },
  });
  localStorage.setItem("invoiceDetailsFromLocalStorage", JSON.stringify(getState().invoiceDetailsFromStorage));
};

export const addWalkinSalesInvoiceDetailsToStorage = (walkinSalesInvoiceDetailsObject) => async (
  dispatch,
  getState
) => {
  console.log(" About to Add Walkin Sales Invoice DetailsToStorage to Storage ::=>> ",walkinSalesInvoiceDetailsObject);
  const {billDate ,description , warehouseOrStoreName , customerGst,customerEmail, customerName,customerContact    } = walkinSalesInvoiceDetailsObject ; 
  console.log("billDate ,description , warehouseOrStoreName , customerGst,customerEmail, customerName,customerContact  : ",billDate ,description , warehouseOrStoreName , customerGst,customerEmail, customerName,customerContact  );
  
  dispatch({
    type: ADD_WALKIN_SALES_INVOICE_DETAILS_TO_STORAGE,
    payload: {
      billDate ,description , warehouseOrStoreName , customerGst,customerEmail, customerName,customerContact  
    },
  });
  localStorage.setItem("walkinSalesInvoiceDetailsFromStorage", JSON.stringify(getState().walkinSalesInvoiceDetailsFromStorage));
};

export const removeVehicleDetailsFromStorage = (vehicleNo) => (dispatch, getState) => {
  dispatch({
    type: VEHICLE_DETAILS_REMOVE_FROM_STORAGE_REQUEST,
  });
  dispatch({
    type: VEHICLE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS,
    payload: vehicleNo,
  })
  localStorage.setItem('vehicleDetailsFromStorage', JSON.stringify(getState().vehicleDetailsFromStorage))
}

export const removeInvoiceDetailsFromStorage = (invoiceNo) => (dispatch, getState) => {
  dispatch({
    type: INVOICE_DETAILS_REMOVE_FROM_STORAGE_REQUEST,
  });
  dispatch({
    type: INVOICE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS,
    payload: invoiceNo,
  });

  localStorage.setItem('invoiceDetailsFromStorage', JSON.stringify(getState().invoiceDetailsFromStorage))
}

export const resetVehicleDetailsFromStorage = () => (dispatch, getState) => {
  dispatch({
    type: VEHICLE_DETAILS_ADD_TO_STORAGE_RESET,
  })
  localStorage.setItem('vehicleDetailsFromStorage', JSON.stringify(getState().vehicleDetailsFromStorage))
}

export const resetInvoiceDetailsFromStorage = () => (dispatch, getState) => {
  dispatch({
    type: INVOICE_DETAILS_ADD_TO_STORAGE_RESET,
  })
  localStorage.setItem('invoiceDetailsFromStorage', JSON.stringify(getState().invoiceDetailsFromStorage))
}


