import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Writeup from "../components/Writeup";
import HomeScreen from "./HomeScreen";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: "5rem",
    width: "5rem",
  },
  control: {
    padding: theme.spacing(2),
  },
  imageContainer: {
    height: "5rem",
    width: "5rem",
    backgroundRepeat: "no-repeat",
    backgroundSize: "5rem 5rem",
    backgroundImage: `url(${"/images/products/Chilli.jpg"})`,
  },
}));

const Dashboard = ({ history }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }
  }, [dispatch, navigate, userInfo]);

  let renderContent = (
    <React.Fragment>
      {" "}
      <Grid item xs={12}>
        <HomeScreen />
      </Grid>
    </React.Fragment>
  );
  return <React.Fragment>{userInfo && renderContent}</React.Fragment>;
};
export default Dashboard;
