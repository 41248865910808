import React, { useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import {
  Button, Divider, Grid, TextField
} from "@mui/material";
import {
  Tooltip,
  Typography
} from "@mui/material";
import { listWarehousePurchaseOrderById } from "../../../actions/warehousePurchaseOrderAction";
import { listStorePurchaseOrderById } from "../../../actions/storePurchaseOrderAction";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import RenderOrderSummary from "./RenderOrderSummary";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  mainContainer: {
    marginTop: "4em",
    [theme.breakpoints.down("md")]: {
      marginTop: "3em",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "2em",
    },
  },
  heroTextContainer: {
    minWidth: "21em",
    maxWidth: "50em",
    // marginLeft: "1em",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "30em",
      marginTop: "1em",
    },
  },
  animation: {
    maxWidth: "50em",
    minWidth: "21em",
    marginTop: "-6%",
    // marginBottom: "-6%",
    marginLeft: "1%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      maxWidth: "30em",
      marginTop: "1em",
    },
  },
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imageIcon: {
    height: "1rem",
  },
  iconRoot: {
    textAlign: "center",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
}));

export default function PurchaseOrderProductDetails() {
  const { id, warehouseOrStore } = useParams();
  console.log(`Purchase Order ID : , ${id}, warehouseOrStore :  ${warehouseOrStore}`);
  let rowData = [];
  const styles = {
    formControl: {
      margin: "1rem",
      minWidth: "20rem",
    },
    cardTitleWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,10)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0",
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF",
      },
    },
    cardTitleGreen: {
      color: "#26A541",
      marginTop: "0px",
      minHeight: "auto",
      fontFamily: "Roboto",
      marginBottom: "3px",
      textDecoration: "none",
      fontSize: "1rem",
      fontWeight: 500,
      textTransform: "capitalize",
      textAlign: "left",
      "& small": {
        color: "#777",
        fontSize: "65%",
        fontWeight: "400",
        lineHeight: "1",
      },
    },
  };
  const useStyles = makeStyles(styles);
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const columnDefs = [
    { field: '#', width: 100, suppressSizeToFit: true },
    { field: 'Product Name', width: 400, suppressSizeToFit: true, filter: true },
    { field: 'Qty', width: 150 },
    // { field: 'Metrics', width: 100, suppressSizeToFit: true },
    { field: 'Price', width: 150 },
    { field: 'Mrp', width: 150 },
    { field: 'Tax %', width: 150 },
    {
      field: "TaxAmount",
      headerName: "Tax Amount",
      resizable: true,
      suppressSizeToFit: true, width: 150,suppressSizeToFit: true,
    },
    { field: 'Total', width: 200 },
]
  

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: false,
    floatingFilter: false,
    resizable: true,
  }), []
  )

  const export_label = 'Export > CSV'
  let gridApi = "";
  let gridColumnApi;
  let loader = "";



  let renderContentUsingAGGrid = "";

  const autoSizeAll = () => {
    const allColumnIds = [];
    gridColumnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    this.gridColumnApi.autoSizeColumns(allColumnIds, false);
  }

  let data ;
  
  useEffect(() => {
    if (id && (warehouseOrStore !== null || warehouseOrStore !== undefined || warehouseOrStore !== "")) {
      if (warehouseOrStore === "Warehouse")
        dispatch(listWarehousePurchaseOrderById(id));
      else if (warehouseOrStore === "Store")
        dispatch(listStorePurchaseOrderById(id));
    }
  }, [dispatch, id]);

  let warehousePurchaseOrderById = useSelector((state) => state.warehousePurchaseOrderById);
  let storePurchaseOrderById = useSelector((state) => state.storePurchaseOrderById);
  // let purchaseOrderById = useSelector((state) => state.warehousePurchaseOrderById);

  if ((warehouseOrStore !== null || warehouseOrStore !== undefined || warehouseOrStore !== "") && (warehouseOrStore === "Warehouse")) {
    warehousePurchaseOrderById = useSelector((state) => state.warehousePurchaseOrderById);
    console.log("Prepareing rowData ..Warehouse .! ")
    let { wh_po_data, wh_po_error, wh_po_loading } = warehousePurchaseOrderById;
    data = wh_po_data;
    loader = wh_po_loading;
    if (wh_po_data && wh_po_data._id && wh_po_data._id.length > 0) {
      console.log("wh_po_data", wh_po_data);
      rowData = wh_po_data.purchaseOrderProducts.map((prd, index) => {
        console.log("Each product Details are : ", prd);
        return {
          '#': index + 1,
          'Product Name': prd.productName? prd.productName : "",
          'Mrp': prd.mrp ?prd.mrp :0,
          'Qty': prd.countInStock || 0,
          'DamagedStock': prd.countOfDamagedStock || 0,
          'Price': prd.pricePerUnit,
          'Total': (Number(prd.countInStock) * Number(prd.pricePerUnit)) + (Number(prd.countInStock) * Number(prd.pricePerUnit)) * prd.tax / 100,
          'Mrp': prd.mrp,
          'Tax %': prd.tax,
          'TaxAmount':  Math.ceil((((Number(prd.pricePerUnit )* Number(prd.countInStock)) * 100)/(100 + Number(prd.tax))) * (Number(prd.tax) / 100)),
          'MfgDate': prd.mfgDate,
          'ExpDate': prd.expDate,
          'BatchNo': prd.batchNo,
          
        }
      })
      if (rowData && rowData.length > 0) {
        renderContentUsingAGGrid = (
          <div className="ag-theme-quartz" style={{ width: "100%", height: '55vh' }}>
            <AgGridReact
             ref={gridRef}
             rowData={rowData}
             columnDefs={columnDefs}
             defaultColDef={defaultColDef}
             rowStyle={{ textAlign: "left", alignItems: "center" }}
             suppressExcelExport={false}
             popupParent={popupParent}
             pagination="true"
            >
            </AgGridReact>
          </div>
        )
      }
    }
  } else if ((warehouseOrStore !== null || warehouseOrStore !== undefined || warehouseOrStore !== "") && (warehouseOrStore === "Store")) {
    console.log("Prepareing rowData for Store...! ")
    storePurchaseOrderById = useSelector((state) => state.storePurchaseOrderById);
    let { store_po_data, store_po_error, store_po_loading } = storePurchaseOrderById;
    let {
      advanceAmount,
      balanceAmount,
      contactNo,
      description,
      partyName,
      paymentType,
      purchaseOrderStatus,
      stateOfSupply,
      totalPoAmount,
      totalPoQty,
      totalPoTaxAmount,
      // metrics
    } = store_po_data;
    data = store_po_data;
    loader = store_po_loading;

    if (store_po_data && store_po_data._id && store_po_data._id.length > 0) {
      rowData = store_po_data.purchaseOrderProducts.map((prd, index) => {
        return {
          '#': index + 1,
          'Product Name': prd.productName,
          'Qty': prd.countInStock || 0,
          // 'Metrics': prd.metrics,
          'Mrp':prd.mrp||0,
          'DamagedStock': prd.countOfDamagedStock || 0,
          'Price': prd.pricePerUnit || 0.0,
          'Mrp': prd.mrp || 0.0,
          'Tax %': prd.tax || 0.0,
          'TaxAmount':  Math.ceil((((Number(prd.pricePerUnit )* Number(prd.countInStock)) * 100)/(100 + Number(prd.tax))) * (Number(prd.tax) / 100)),
          'MfgDate': prd.mfgDate,
          'ExpDate': prd.expDate,
          'BatchNo': prd.batchNo,
          'Total': prd.eachProductPrice,
        }
      })
      if (rowData && rowData.length > 0) {
        renderContentUsingAGGrid = (
          <div className="ag-theme-quartz" style={{ width: "100%", height: '400px' }}>
            <AgGridReact
             ref={gridRef}
             rowData={rowData}
             columnDefs={columnDefs}
             defaultColDef={defaultColDef}
             rowStyle={{ textAlign: "left", alignItems: "center" }}
             suppressExcelExport={false}
             popupParent={popupParent}
             pagination="true"
            >
            </AgGridReact>
          </div>
        )
      }
    }
  }


  const renderPurchaseOrderHeader = ()=>{
    return (<React.Fragment>
  <GridItem xs={12} sm={12} md={12}>
    <Card>
      <CardHeader>
        <Tooltip title="Back" arrow>
          <ArrowBackIcon fontSize="medium" onClick={() => window.history.back()} style={{ float: "left", marginRight: "3em" }} />
        </Tooltip>
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          Purchase Order Details
        </Typography>
      </CardHeader>
    </Card>
  </GridItem>
  </React.Fragment>
  )
}

  return (
    <React.Fragment>
      {loader && <CustomBackdropSpinner />}
      <GridContainer>
        {renderPurchaseOrderHeader?renderPurchaseOrderHeader():null}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody>{renderContentUsingAGGrid ? renderContentUsingAGGrid : null}</CardBody>
          </Card>
        </GridItem>
        <RenderOrderSummary data = {data}/>
      </GridContainer>
    </React.Fragment>
  );
}
