import React, { useEffect,useState ,useMemo, useRef} from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import HomeIcon from "@mui/icons-material/Home";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import {
  Tooltip,
  TableCell,
  Typography
} from "@mui/material";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { listOrdersByStore,listAllOrders } from "../../actions/orderAction";
import { listAllStores } from "../../actions/storeAction";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import {
  Grid,
  Button,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "50%",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};
const StoreOrderListScreen = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
 
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openSnackBar, setOpenSnackBar] = React.useState(true);
  
  const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') 
      return;
    setOpenSnackBar(false);
  };
  const vertical = 'top' ;
  const horizontal = 'center';
  let renderContentUsingAGGrid = "";
  let renderStoreOptions = "";
  let gridApi = "";

  const currencyFormatter = (params) => {
    return formatNumber(params.value);
  };
  
  const formatNumber = (number) => {
    const formmattedNumber =  number.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR'
      });
    console.log('formmattedNumber : ',formmattedNumber);
  return formmattedNumber;
  };
  const columnDefs = [
    { field: "#",resizable: true, suppressSizeToFit: true,width: 75, minWidth: 75, maxWidth: 100 },
    //{ field: "Order Id" ,resizable: true, suppressSizeToFit: true},
    { field: "Customer Name",headerName: 'Customer Name',resizable: true, suppressSizeToFit: true},
    { field: "Total Price" ,resizable: true, suppressSizeToFit: true,valueFormatter: currencyFormatter },
    { field: "Order Date",resizable: true, suppressSizeToFit: true},
    { field: "Delivery Status",headerName: 'Delivery Status',resizable: true, suppressSizeToFit: true},
    { field: "" ,cellRendererFramework:(params)=>(
      <span><Tooltip title="Order Details" arrow>
                    <ReadMoreIcon
                      size="small"
                      variant="contained"
                      type="submit"
                      color="Gray"
                      justify="center"
                      onClick={() => console.log(params)}
                      style={{ marginRight: "1rem" ,width:"2rem"}}
                    ></ReadMoreIcon>
                  </Tooltip></span>
  ), sortable: false,
  filter: false,
  floatingFilter: false},
  

  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      editable:true
    }),
    []
  );
  let rowData = [];
  const [storeSelected , setStoreSelected] = useState(()=>"");
  const [fromDate,setFromDate] = useState(()=>"");
  const [toDate,setToDate] = useState(()=>"");
  const export_label = 'Export > CSV'
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listAllStores());
  }, [dispatch]);

  const storesList = useSelector((state) => state.storesList);
  const { stores_loading, stores_errors, stores } = storesList;

  const handleOrderItemDetails = (id) => {
    console.log("Clicked handleOrderItemDetails " + id);
    navigate(`/admin/order-details/${id}`);
  };
  const handleChangeStore = (e) => {
    console.log("Store Changed  " , e.target.value);
    setStoreSelected( e.target.value);
    dispatch(listOrdersByStore(e.target.value));
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };


  if (stores && stores.length > 0) {
    renderStoreOptions = stores.map((eachStore, idx) => {
      console.log(eachStore, idx)
      return (
        <MenuItem key={idx} value={eachStore._id}>
          {eachStore.storeName}
        </MenuItem>
      );
    });
  }

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log(userInfo);
  const { store } = userInfo;

  useEffect(() => {
    dispatch(listOrdersByStore(store));
  }, [dispatch, store,storeSelected]);
  
  const orderListByStore = useSelector((state) => state.orderListByStore);
  const { loading_store_orders, error, storeOrders } = orderListByStore;

   if (storeOrders && storeOrders.length > 0) {
    rowData = storeOrders.map((eachOrder, index) => {
      console.log('each Store Order  : ',eachOrder);
     
      return {
        "#": index + 1,
       // "Order Id": eachOrder._id,
        "Customer Name": eachOrder.user?(eachOrder.user.name?eachOrder.user.name:""):"",
        "Total Price": eachOrder.totalPrice,
        "Order Date": eachOrder.createdAt,
        "Delivery Status":eachOrder.isDelivered?(eachOrder.isDelivered==true?"Delivered":"Yet to Deliver"):"Yet to Deliver",
        "" : ""
      };
    })
  renderContentUsingAGGrid = (
      <div className="ag-theme-quartz" style={{ width: "100%", height: '65vh' }}>
        <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
      </div>
  )
}
  return (
    <React.Fragment>
      {loading_store_orders && <Message variant="danger">{error}</Message>}
      {loading_store_orders && <CustomBackdropSpinner />}
      <GridContainer>
      <Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar} key = {vertical + horizontal}>
        <Alert onClose={handleCloseSnackBar} severity="warning" sx={{ width: '100%' }}>
          Please Select Store and Date Filters to Proceed!
        </Alert>
      </Snackbar>
        <GridItem xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
          <Tooltip title="Home" arrow>
            <HomeIcon fontSize="medium" onClick={() => navigate("/")} style={{ float: "right" }} />
          </Tooltip>
        </GridItem>
        <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                <Typography variant="h5" style={{ fontWeight: 500 }}>
                 Store Orders
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon fontSize="medium" style={{ float: "right",margin:"0.5rem" }} onClick={onExportClick} />
          </Tooltip>
        </GridItem>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Select Store
                        </InputLabel>
                        <Select
                          label="Select Store"
                          placeholder="Select Store"
                          variant="outlined"
                          required
                          size="small"
                          name="Select Store"
                          onChange={handleChangeStore}
                          value={storeSelected}
                          style={{ width: "25vh" ,marginBottom:"2rem"}}
                        >
                          {renderStoreOptions}
                        </Select>
                      </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={fromDate} label="From Date" style={{ width: "25vh" ,margin:"1rem"}} size="small" onChange={(newValue) => {
              setFromDate(newValue);
              console.log("newValue" + newValue);
              }} renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={toDate} label="To Date" style={{ width: "25vh" ,margin:"1rem"}} size="small" onChange={(newValue) => {
              setToDate(newValue);
              console.log("newValue" + newValue);
              }} renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
        </Grid>
        </Grid> 
        <Grid item xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </Grid>
      </GridContainer>
    </React.Fragment>
  );
};
export default StoreOrderListScreen;
