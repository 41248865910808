import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import GridItem from "../Grid/GridItem";
import { Grid, Tooltip, Typography } from "@mui/material";
import StoreForm from "./StoreForm";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import GridContainer from "../Grid/GridContainer";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 'auto',
    margin: `0 auto`,
  },
  section: {
    height: "50vh",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StoreCreate = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Grid container spacing={2}>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left" }}
                />
                <Typography variant="h5" style={{ marginLeft : "4rem", fontWeight: 500 , textAlign:"center"}}>
                  New Store
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <StoreForm />
      </Grid>
    </div>
  );
};

export default StoreCreate;
