import axios from "axios";
import {
  STORE_STOCK_LIST_BY_STORE_NAME_REQUEST,
  STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_LIST_BY_STORE_NAME_FAIL,
  STORE_STOCK_LIST_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_REQUEST,
  STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_FAIL,
  STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_RESET,
  STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_RESET,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_FAIL,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_RESET,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_SUCCESS,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_REQUEST,
  UPDATE_RETURN_STOCK_TO_STORE_FROM_VEHICLE_NO_REQUEST,
  UPDATE_RETURN_STOCK_TO_STORE_FROM_VEHICLE_NO_SUCCESS,
  UPDATE_RETURN_STOCK_TO_STORE_FROM_VEHICLE_NO_FAIL
} from "../constants/storeStockConstants";
import { groupAndSum } from "../util/Utility";

export const listStockByStoreName = (storeName) => async (dispatch) => {
  try {
    console.log('Exec listStockByStoreName for storeName : ', storeName);
    dispatch({ type: STORE_STOCK_LIST_BY_STORE_NAME_REQUEST });
    const { data } = await axios.get(`/api/store-stock/${storeName}`);
    dispatch({
      type: STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: STORE_STOCK_LIST_BY_STORE_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const  createStockByStoreNameAndProductName =
  (purchaseOrder, purchaseOrderProducts,storeName) => async (dispatch) => {
    console.log(
      "createStockByStoreNameAndProductName -start ",
      purchaseOrder,
      purchaseOrderProducts,
      storeName
    );

    /** 
     * LSK : USE THIS WHILE CALCIULATING THE QUANTITY OF THE EACH PRODUCT WHULE INSERTING INTO THE WH-STOCK
    */
   
    try {
      dispatch({
        type: STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST,
      });
      const { data } = await axios.post(`/api/store-stock/${storeName}/in`, {purchaseOrder , purchaseOrderProducts, storeName});
      console.log("createStockByStoreNameAndProductName - end ");

      dispatch({
        type: STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS,
        payload: data,
      });

      dispatch({
        type: STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL,
        payload: message,
      });
    }
  };

export const  updateStockInByStoreProduct =
  (purchaseOrder, purchaseOrderProducts) => async (dispatch) => {
    const  { fromWarehouseStoreValue , toWarehouseStoreValue } = purchaseOrder
    console.log("purchaseOrder : ", purchaseOrder ? purchaseOrder :{})
    console.log("purchaseOrderProducts Length if any : ", purchaseOrderProducts ? purchaseOrderProducts.length : 0)
    console.log("fromWarehouseStoreValue : " , fromWarehouseStoreValue , "toWarehouseStoreValue : ", toWarehouseStoreValue);
    console.log(' Checking for  Products in Invoice : ',purchaseOrderProducts ? purchaseOrderProducts.length : 0 );  
    try {
      dispatch({
        type: STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST,
      }); 
      const { data } = await axios.post(`/api/store-stock/${toWarehouseStoreValue}/in`, { purchaseOrder , purchaseOrderProducts, toWarehouseStoreValue});
      console.log("createStockByStoreNameAndProductName - end ");

      dispatch({
        type: STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS,
        payload: data,
      });

      dispatch({
        type: STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL,
        payload: message,
      });
    }
  };

export const updateStockOutByStoreProduct = (purchaseOrder, purchaseOrderProducts,fromWarehouseStoreValue) => async (dispatch, getState) => {
  // const  { fromWarehouseStoreValue , toWarehouseStoreValue } = purchaseOrder
   
    console.log("purchaseOrder : ", purchaseOrder ? purchaseOrder :{})
    console.log("purchaseOrderProducts Length if any : ", purchaseOrderProducts ? purchaseOrderProducts.length : 0)
    console.log("fromWarehouseStoreValue : " , fromWarehouseStoreValue);
  
    try {
      dispatch({
        type: STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_REQUEST,
      });
      console.log('Store Stock before doing OUT from Store : ',fromWarehouseStoreValue);
      const { data } = await axios.post(`/api/store-stock/${fromWarehouseStoreValue}/out`, {
        purchaseOrder, 
        purchaseOrderProducts,
        fromWarehouseStoreValue
      });
      console.log("updateStockOutByStoreProduct - end ");
  
      dispatch({
        type: STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_SUCCESS,
        payload: data,
      });
      dispatch({
        type: STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_RESET,
        payload: data,
      });
  
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: STORE_STOCK_OUT_UPDATE_BY_STORE_NAME_FAIL,
        payload: message,
      });
    }
  };

  export const  updateReturnStockToStoreByProductNames =
  ( availableProductsInVehicle) => async (dispatch) => {
    console.log( "updateReturnStockToStoreByProductName -start ", availableProductsInVehicle ? availableProductsInVehicle.length:0);

    try {
      dispatch({
        type: UPDATE_RETURN_STOCK_TO_STORE_FROM_VEHICLE_NO_REQUEST ,
      });
      
      const { data } = await axios.post(`/api/store-stock/return`, {
        availableProductsInVehicle,
      });
      console.log("updateReturnStockToStoreByProductNames - end ");

      dispatch({
        type: UPDATE_RETURN_STOCK_TO_STORE_FROM_VEHICLE_NO_SUCCESS,
        payload: data,
      });

    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: UPDATE_RETURN_STOCK_TO_STORE_FROM_VEHICLE_NO_FAIL,
        payload: message,
      });
    }
  };

  export const listProductsStockCountAcrossAllStores = (productName) => async (dispatch) => {
    try {
      console.log('Exec listProductsStockCountAcrossAllStores for productName : ', productName);
      dispatch({ type: STORES_STOCK_COUNT_BY_PRODUCT_NAME_REQUEST });
      const { data } = await axios.post(`/api/store-stock/product`,{productName : productName});
      dispatch({
        type: STORES_STOCK_COUNT_BY_PRODUCT_NAME_SUCCESS,
        payload: data,
      });
      // dispatch({ type: STORES_STOCK_COUNT_BY_PRODUCT_NAME_RESET });
    } catch (error) {
      dispatch({
        type: STORES_STOCK_COUNT_BY_PRODUCT_NAME_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };