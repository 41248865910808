export const VEHICLE_DETAILS_ADD_TO_STORAGE = "VEHICLE_DETAILS_ADD_TO_STORAGE";
export const VEHICLE_DETAILS_ADD_TO_STORAGE_REQUEST = "VEHICLE_DETAILS_ADD_TO_STORAGE_REQUEST";
export const VEHICLE_DETAILS_ADD_TO_STORAGE_SUCCESS = "VEHICLE_DETAILS_ADD_TO_STORAGE_SUCCESS";
export const VEHICLE_DETAILS_ADD_TO_STORAGE_FAIL = "VEHICLE_DETAILS_ADD_TO_STORAGE_FAIL";
export const VEHICLE_DETAILS_ADD_TO_STORAGE_RESET = "VEHICLE_DETAILS_ADD_TO_STORAGE_RESET";

export const VEHICLE_DETAILS_REMOVE_FROM_STORAGE_REQUEST = "VEHICLE_DETAILS_REMOVE_FROM_STORAGE_REQUEST";
export const VEHICLE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS = "VEHICLE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS";
export const VEHICLE_DETAILS_REMOVE_FROM_STORAGE_FAIL = "VEHICLE_DETAILS_REMOVE_FROM_STORAGE_FAIL";
export const VEHICLE_DETAILS_REMOVE_FROM_STORAGE_RESET = "VEHICLE_DETAILS_REMOVE_FROM_STORAGE_RESET";

export const INVOICE_DETAILS_ADD_TO_STORAGE = "INVOICE_DETAILS_ADD_TO_STORAGE";
export const INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST = "INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST";
export const INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS = "INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS";
export const INVOICE_DETAILS_ADD_TO_STORAGE_FAIL = "INVOICE_DETAILS_ADD_TO_STORAGE_FAIL";
export const INVOICE_DETAILS_ADD_TO_STORAGE_RESET = "INVOICE_DETAILS_ADD_TO_STORAGE_RESET";

export const INVOICE_DETAILS_REMOVE_FROM_STORAGE_REQUEST = "INVOICE_DETAILS_REMOVE_FROM_STORAGE_REQUEST";
export const INVOICE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS = "INVOICE_DETAILS_REMOVE_FROM_STORAGE_SUCCESS";
export const INVOICE_DETAILS_REMOVE_FROM_STORAGE_FAIL = "INVOICE_DETAILS_REMOVE_FROM_STORAGE_FAIL";
export const INVOICE_DETAILS_REMOVE_FROM_STORAGE_RESET = "INVOICE_DETAILS_REMOVE_FROM_STORAGE_RESET";

export const ADD_WALKIN_SALES_INVOICE_DETAILS_TO_STORAGE = "ADD_WALKIN_SALES_INVOICE_DETAILS_TO_STORAGE";
export const WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST = "WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_REQUEST";
export const WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS = "WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_SUCCESS";
export const WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_FAIL = "WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_FAIL";
export const WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_RESET = "WALKIN_SALES_INVOICE_DETAILS_ADD_TO_STORAGE_RESET";
