export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_REQUEST = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_REQUEST";
export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SUCCESS = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SUCCESS";
export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_FAIL = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_FAIL";
export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_RESET = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_RESET";

export const TRANSIT_STOCK_DETAILS_BY_ID_REQUEST = "TRANSIT_STOCK_DETAILS_BY_ID_REQUEST";
export const TRANSIT_STOCK_DETAILS_BY_ID_SUCCESS = "TRANSIT_STOCK_DETAILS_BY_ID_SUCCESS";
export const TRANSIT_STOCK_DETAILS_BY_ID_FAIL = "TRANSIT_STOCK_DETAILS_BY_ID_FAIL";
export const TRANSIT_STOCK_DETAILS_BY_ID_RESET = "TRANSIT_STOCK_DETAILS_BY_ID_RESET";

export const TRANSIT_STOCK_BY_VEHICLE_NO_REQUEST =
  "TRANSIT_STOCK_BY_VEHICLE_NO_REQUEST";
export const TRANSIT_STOCK_BY_VEHICLE_NO_SUCCESS =
  "TRANSIT_STOCK_BY_VEHICLE_NO_SUCCESS";
export const TRANSIT_STOCK_BY_VEHICLE_NO_FAIL =
  "TRANSIT_STOCK_BY_VEHICLE_NO_FAIL";
  export const TRANSIT_STOCK_BY_VEHICLE_NO_RESET =
  "TRANSIT_STOCK_BY_VEHICLE_NO_RESET";

export const TRANSIT_STOCK_DELETE_BY_ID_REQUEST = "TRANSIT_STOCK_DELETE_BY_ID_REQUEST";
export const TRANSIT_STOCK_DELETE_BY_ID_SUCCESS = "TRANSIT_STOCK_DELETE_BY_ID_SUCCESS";
export const TRANSIT_STOCK_DELETE_BY_ID_FAIL = "TRANSIT_STOCK_DELETE_BY_ID_FAIL";

export const TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST = "TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST";
export const TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS = "TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS";
export const TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL = "TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL";
export const TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET = "TRANSIT_STOCK_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET";

export const TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST = "TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST";
export const TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS = "TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS";
export const TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL = "TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL";
export const TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET = "TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET";

export const TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST = "TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST";
export const TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS = "TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS";
export const TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL = "TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL";
export const TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET = "TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET";

export const DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_REQUEST = "DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_REQUEST";
export const DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_SUCCESS = "DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_SUCCESS";
export const DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_FAIL = "DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_FAIL";
export const DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_RESET = "DELETE_STOCK_FROM_TRANSIT_BY_VEHICLE_NO_RESET";

export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_REQUEST = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_REQUEST";
export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_SUCCESS = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_SUCCESS";
export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_FAIL = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_FAIL";
export const TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_RESET = "TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_RESET";

export const TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST = "TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST";
export const TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS = "TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS";
export const TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL = "TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL";
export const TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET = "TRANSIT_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET";

export const TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST
 = "TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST";
export const TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS = "TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS";
export const TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL = "TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL";
export const TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET = "TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET";

export const TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_REQUEST = "TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_REQUEST";
export const TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_SUCCESS = "TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_SUCCESS";
export const TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_FAIL = "TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_FAIL";
export const TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_RESET = "TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_RESET";