import {
  STORE_STOCK_LIST_BY_STORE_NAME_REQUEST,
  STORE_STOCK_LIST_BY_STORE_NAME_FAIL,
  STORE_STOCK_LIST_BY_STORE_NAME_RESET,
  STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_BY_STORE_NAME_UPDATE_REQUEST,
  STORE_STOCK_IN_BY_STORE_NAME_UPDATE_FAIL,
  STORE_STOCK_IN_BY_STORE_NAME_UPDATE_SUCCESS,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_RESET,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_REQUEST,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_FAIL,
  STORE_STOCK_IN_RETURN_BY_STORE_NAME_SUCCESS,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_REQUEST,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_SUCCESS,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_FAIL,
  STORES_STOCK_COUNT_BY_PRODUCT_NAME_RESET
} from "../constants/storeStockConstants";

export const storeStockListByStoreNameReducer = (
  state = { store_stock_data: {} },
  action
) => {
  switch (action.type) {
    case STORE_STOCK_LIST_BY_STORE_NAME_REQUEST:
      return { store_stock_loading: true, store_stock_data: {} };
    case STORE_STOCK_LIST_BY_STORE_NAME_SUCCESS:
      return {
        store_stock_loading: false,
        store_stock_data: action.payload,
      };
    case STORE_STOCK_LIST_BY_STORE_NAME_FAIL:
      return { store_stock_loading: false, store_stock_error: action.payload };
    default:
      return state;
  }
};

export const storeStockCreateByStoreNameReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_REQUEST:
      return { store_stock_create_loading: true };
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_SUCCESS:
      return { store_stock_create_loading: false, store_stock_create_success: true, store_stock_create_data: action.payload };
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_FAIL:
      return { store_stock_create_loading: false, store_stock_create_error: action.payload };
    case STORE_STOCK_IN_CREATE_BY_STORE_NAME_RESET:
      return {};
    default:
      return state;
  }
};

export const storeStockUpdateByStoreNameReducer = (state = { store_stock: {} }, action) => {
  switch (action.type) {
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_REQUEST:
      return { store_stock_update_loading: true };
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_SUCCESS:
      return { store_stock_update_loading: false, store_stock_update_success: true, store_stock_update_data: action.payload };
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_FAIL:
      return { lstore_stock_update_loading: false, store_stock_update_error: action.payload };
    case STORE_STOCK_IN_UPDATE_BY_STORE_NAME_RESET:
      return { store_stock: {} };
    default:
      return state;
  }
};

export const storeStockReturnByStoreNameReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_STOCK_IN_RETURN_BY_STORE_NAME_REQUEST:
      return { st_stock_return_loading: true };
    case STORE_STOCK_IN_RETURN_BY_STORE_NAME_SUCCESS:
      return { st_stock_return_loading: false, st_stock_return_success: true, st_stock_return_data: action.payload };
    case STORE_STOCK_IN_RETURN_BY_STORE_NAME_FAIL:
      return { st_stock_return_loading: false, st_stock_return_error: action.payload };
    case STORE_STOCK_IN_RETURN_BY_STORE_NAME_RESET:
      return {};
    default:
      return state;
  }
};

export const productsStockCountAcrossAllStoresReducer = (state = {}, action) => {
  switch (action.type) {
    case STORES_STOCK_COUNT_BY_PRODUCT_NAME_REQUEST:
      return { products_stock_count_all_stores_loading: true };
    case STORES_STOCK_COUNT_BY_PRODUCT_NAME_SUCCESS:
      return { products_stock_count_all_stores_loading: false, products_stock_count_all_stores_success: true, products_stock_count_all_stores_data: action.payload };
    case STORES_STOCK_COUNT_BY_PRODUCT_NAME_FAIL:
      return { products_stock_count_all_stores_loading: false, products_stock_count_all_stores_error: action.payload };
    case STORES_STOCK_COUNT_BY_PRODUCT_NAME_RESET:
      return {};
    default:
      return state;
  }
}