import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../Card/Card";
import CardHeader from "../../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridItem from "../../../Grid/GridItem";
import GridContainer from "../../../Grid/GridContainer";
import {
  Tooltip,
  Typography,
  Button
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import PaymentIcon from '@mui/icons-material/Payment'; 
import { listAllWarehouses } from "../../../../actions/warehouseInfoAction";
import { listAllStores } from "../../../../actions/storeAction";
import { listWalkinSaleInvoices, listWalkinSaleInvoicesByPosName } from "../../../../actions/walkinSaleInvoiceAction";
import CustomBackdropSpinner from "../../../CustomBackdropSpinner.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportToExcel } from "../../../../util/exportToExcelUtil.js";
import {convertInvoiceIdToDigits} from '../../../../util/Utility.js'

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "300",
      lineHeight: "1",
    },
  },
};

const WalkinSalesInvoicesListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const gridRef = useRef();

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [posName, setPosName] = useState(() => "");
  const [posStoreOrWarehouse, setPosStoreOrWarehouse] = useState(() => "");
  let rowData = [];
  let salesReportByProducts = [];
  let renderContentUsingAGGrid = "";
  const [salesContentUsingAGGrid, setSalesContentUsingAGGrid] = useState(() => null);
  const [salesContentProductWiseUsingAGGrid, setSalesContentProductWiseUsingAGGrid] = useState(() => null);
  let noDataFoundContent = ""

  useEffect(() => {
    console.log("Use Effect Gets Called for Listing All Store Warehouses...!");
    dispatch(listAllStores());
    dispatch(listAllWarehouses());
  }, [dispatch]);

  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const warehouseInfoList = useSelector(
    (state) => state.warehouseInfoList
  );
  const storesList = useSelector(
    (state) => state.storesList
  );

  const { loading_wh_infos, warehouseInfos, wh_infos_error } = warehouseInfoList;// warehouseInfoList
  const { stores_loading, stores, stores_error } = storesList; //storesList
  const walkinSalesInvoicesListByPos = useSelector(
    (state) => state.walkinSalesInvoicesListByPos
  );
  const walkinSalesInvoiceList = useSelector((state) => state.walkinSalesInvoiceList);
  const { walkin_sales_invoice_loading, walkin_sales_invoice_data } = walkinSalesInvoiceList;

  const { walkin_sales_invoice_pos_loading, walkin_sales_invoice_pos_data, walkin_sales_invoice_pos_error } = walkinSalesInvoicesListByPos; //storesList
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const routePaymentDetails = (id) => {
    console.log('ID Passed to routePaymentDetails : ', id)
    navigate(`/admin/walkin-sales-invoice/payment/${id}`);
  };

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Id",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter :true,
    },
    {
      field: "Order No",
      headerName: "Order No",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "BillDate",
      headerName: "Bill Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle: params => {
        return { textAlign: "left" };
      },
    },
    {
      field: "ItemsPrice",
      headerName: "Order Total",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "UpiAmountPaid",
      headerName: "Upi Amount Paid",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "CashAmountPaid",
      headerName: "Cash Amount Paid",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "Id",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showInvoiceDetails(params.data.Details)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: params => {
        return { textAlign: "center" };
    },
    },
    {
      field: "Id",
      headerName: "Receive Payment",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      cellRenderer: (params) => (
        <span >
          <Tooltip title="Receive Payment" arrow>
            <PaymentIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => routePaymentDetails(params.data.Id)}
              style={{ marginRight: "1rem", width: "2rem" ,textAlign:"center"}}
            ></PaymentIcon>
          </Tooltip>
        </span>
      ),
      cellStyle: params => {
        return { textAlign: "center" };
    },
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      ensureDomOrder: true
    }),
    []
  );


  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const groupInvoicesByName = (invoices) => {
    console.log('######### Exec groupInvoicesByName . invoices passed : ', invoices ? invoices.length : 0, '#########')
    const grouped = {};

    if (invoices && invoices.length > 0) {
      invoices.forEach(invoice => {
        invoice.invoiceProducts.forEach(product => {
          const productName = product.name;
          if (!grouped[productName]) {
            grouped[productName] = {
              quantity: 0,
              totalCost: 0,
              invoices: []
            };
          }
          grouped[productName].quantity += product.quantity;
          grouped[productName].totalCost += product.itemCost;
          grouped[productName].invoices.push({
            _id: invoice._id,
            quantity: product.quantity,
            itemCost: product.itemCost,
            billDate: invoice.billDate,
            posName: invoice.posName
          });
        });
      });
      console.log('######### groupInvoicesByName #########', grouped);
    } else grouped = null;
    return grouped
  }

  const transformGroupInvoicesByName = (processedData) => {
    console.log('######### Exec transformGroupInvoicesByName : ', processedData ? processedData.length : 0, '#########')
    let transformedData = []
    if (processedData && processedData.length) {
      processedData.map((each) => {
        console.log(' $$$$$$$ each : each', each)
        if (each.invoiceProducts && each.invoiceProducts) {
          const { _id, billDate, itemsPrice, posName } = each
          const invProducts = each.invoiceProducts;
          const custDetails = each.customerDetails
          invProducts.map((eachInvProd, indx) => {
            console.log('eachInvProd ::==>> ', eachInvProd)
            console.log(' ************ ', _id, billDate, itemsPrice, posName, custDetails.name, custDetails.customerName, custDetails.contactNo,
              custDetails.contactNo, eachInvProd.name, eachInvProd.quantity, itemsPrice, ' ************')
              const invIdInDecimal =convertInvoiceIdToDigits(_id)
            transformedData.push({
              'InvoiceId': invIdInDecimal, 
              'Bill Date': billDate ? billDate : '', 
              'Id': _id, 
              // 'Point Of Sale': posName ? posName : '',
              'Customer Name': custDetails.name ? custDetails.name : custDetails.customerName ? custDetails.customerName : '',
              // 'Contact No': custDetails.contactNo ? custDetails.contactNo : '', 
              'Product': eachInvProd.name ? eachInvProd.name : '',
              'Quantity': eachInvProd.quantity, 
              'Items Price': eachInvProd && eachInvProd.unitPrice && eachInvProd.quantity ? eachInvProd.unitPrice * eachInvProd.quantity : '0.0'
            })
          })
        }
      }
      )
      console.log('######## transformedData ######## ', transformedData ? transformedData.length : 0)
      console.log('######## transformedData ######## ', transformedData ? transformedData : "")
    }
    return transformedData
  }

  // const processDataAndExport = (walkin_sales_invoice_data) => {

  //   // STEP 1 : GROUP THE DATA | PROCESS THE DATA
  //   const resultOfGroupInvoicesByName = groupInvoicesByName(walkin_sales_invoice_data)
  //   console.log('######## Exec STEP 1 : resultOfGroupInvoicesByName ######## ')

  //   // STEP 2 : TRANSFORM THE GROUP THE DATA
  //   const resultOfTransformGroupInvoicesByName = transformGroupInvoicesByName(resultOfGroupInvoicesByName)
  //   console.log('######## Exec STEP 2 : resultOfGroupInvoicesByName ######## ')

  //   // STEP 3 : EXPORT THE PROCESSED DATA TO EXCEL
  //   exportToExcel(resultOfTransformGroupInvoicesByName)
  //   console.log('######## Exec STEP 3 : exportToExcel ######## ')
  // }

  const populatePosDetails = () => {
    if (warehouseName && warehouseName.length > 0 && warehouseName.includes("_WAREHOUSE")) {
      console.log("warehouseName  : ", warehouseName);
      setPosName(() => warehouseName);
      setPosStoreOrWarehouse(() => "Warehouse");
    } else if (storeName && storeName.length > 0 && storeName.includes("_STORE")) {
      console.log("storeName  : ", storeName);
      setPosName(() => storeName);
      setPosStoreOrWarehouse(() => "Store");
    }
  }
  const DetailsCellRenderer = ({ props }) => {
    console.log("Params of DetailsCellRenderer", props)
    return (
      <Tooltip title="Details" arrow>
        <ReadMoreIcon
          size="small"
          variant="contained"
          type="submit"
          color="Gray"
          justify="center"
          // onClick={() => showInvoiceDetails(params.data.Id)}
          onClick={() => console.log("Need to Invoke showInvoiceDetails(params.data.Id)", props)}
          style={{ marginRight: "1rem", width: "2rem" }}
        ></ReadMoreIcon>
      </Tooltip>
    );
  };

  const prepareDataForProductWiseSaleReport = () => {
    console.log('Exec prepareDataForProductWiseSaleReport ...')
    if (role === 'CEO_ROLE' && walkin_sales_invoice_data && walkin_sales_invoice_data.length > 0) {
      if (walkin_sales_invoice_data) {
        const tempsalesReportByProducts = transformGroupInvoicesByName(walkin_sales_invoice_data)
        if (tempsalesReportByProducts) {
          console.log('tempsalesReportByProducts Records processed : ', tempsalesReportByProducts ? tempsalesReportByProducts.length : 0)
          setSalesContentProductWiseUsingAGGrid(() => tempsalesReportByProducts)
        }
      }
    } else if ((role === 'WAREHOUSE_INCHARGE_ROLE' || role === 'STORE_INCHARGE_ROLE') && walkin_sales_invoice_pos_data && walkin_sales_invoice_pos_data.length > 0) {
      if (walkin_sales_invoice_pos_data) {
        const tempsalesReportByProducts = transformGroupInvoicesByName(walkin_sales_invoice_pos_data)
        if (tempsalesReportByProducts) {
          console.log('tempsalesReportByProducts Records processed : ', tempsalesReportByProducts ? tempsalesReportByProducts.length : 0)
          setSalesContentProductWiseUsingAGGrid(() => tempsalesReportByProducts)
        }
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSalesReport = () => {
    console.log('Exec prepareDataForSalesReport ...')
    if (role === 'CEO_ROLE' && walkin_sales_invoice_data && walkin_sales_invoice_data.length > 0) {
      rowData = walkin_sales_invoice_data.sort((a, b) => {
        return new Date(b.billDate) - new Date(a.billDate);
      }).map((salesByPos, index) => {
        const invIdInDecimal =convertInvoiceIdToDigits(salesByPos._id)
        return {
          "#": index + 1,
          "Id":eachOrder._id,
          'Order No' : invIdInDecimal,
          // PosName: salesByPos.posName ? salesByPos.posName : "",
          BillDate: salesByPos.billDate
            ? salesByPos.billDate
            : "",
          CustomerName: salesByPos.customerDetails && salesByPos.customerDetails.name
            ? salesByPos.customerDetails.name: "",
          // CustomerContact: salesByPos.customerDetails.contactNo
          //   ? salesByPos.customerDetails.contactNo
          //   : "",
          ItemsPrice: salesByPos.itemsPrice
            ? salesByPos.itemsPrice
            : 0.0,
          TaxPrice: salesByPos.taxPrice
            ? salesByPos.taxPrice
            : 0.0,
          DiscountApplied: salesByPos.paymentDetails.discountApplied
            ? salesByPos.paymentDetails.discountApplied
            : 0.0,
          UpiAmountPaid: salesByPos.paymentDetails.upiAmountPaid
            ? salesByPos.paymentDetails.upiAmountPaid
            : 0.0,
          CashAmountPaid: salesByPos.paymentDetails.cashAmountPaid
            ? salesByPos.paymentDetails.cashAmountPaid
            : 0.0,
          Details: salesByPos._id,
        };
      });

      if (rowData) {
        renderContentUsingAGGrid = (
          <div
            className="ag-theme-quartz"
            style={{ width: "100%", height: "65vh" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowStyle={{ textAlign: "left", alignItems: "center" }}
              suppressExcelExport={false}
              popupParent={popupParent}
              pagination="true"
            ></AgGridReact>
          </div>
        );
        setSalesContentUsingAGGrid(() => renderContentUsingAGGrid)
      }
    } else if ((role === 'WAREHOUSE_INCHARGE_ROLE' || role === 'STORE_INCHARGE_ROLE') && walkin_sales_invoice_pos_data && walkin_sales_invoice_pos_data.length > 0) {
      rowData = walkin_sales_invoice_pos_data.sort((a, b) => {
        return new Date(b.billDate) - new Date(a.billDate);
      }).map((salesByPos, index) => {
        const invIdInDecimal =convertInvoiceIdToDigits(salesByPos._id)
        return {
          "#": index + 1,
          Id:salesByPos._id,
          'Order No' : invIdInDecimal,
          // PosName: salesByPos.posName ? salesByPos.posName : "",
          BillDate: salesByPos.billDate
            ? salesByPos.billDate
            : "",
          CustomerName: salesByPos.customerDetails.customerName
            ? salesByPos.customerDetails.customerName
            : salesByPos.customerDetails.name ? salesByPos.customerDetails.name : "",
          // CustomerContact: salesByPos.customerDetails.contactNo
          //   ? salesByPos.customerDetails.contactNo
          //   : salesByPos.customerDetails.contactNo ? salesByPos.customerDetails.contactNo : "",
          ItemsPrice: salesByPos ? salesByPos.invoiceProducts.map((each) => each.itemCost).reduce((acc, amount) => acc + Number(amount), 0.0)
            : 0.0,
          TaxPrice: salesByPos.taxPrice
            ? salesByPos.taxPrice
            : 0.0,
          DiscountApplied: salesByPos.paymentDetails.discountApplied
            ? salesByPos.paymentDetails.discountApplied
            : 0.0,
          UpiAmountPaid: salesByPos.paymentDetails.upiAmountPaid
            ? salesByPos.paymentDetails.upiAmountPaid
            : 'N/A',
          CashAmountPaid: salesByPos.paymentDetails.cashAmountPaid
            ? salesByPos.paymentDetails.cashAmountPaid
            : 0.0,
          Details: salesByPos._id,
        };
      });
      if (rowData) {
        renderContentUsingAGGrid = (
          <>
            <div
              className="ag-theme-quartz"
              style={{ width: "100%", height: "65vh" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowStyle={{ textAlign: "left", alignItems: "center" }}
                pagination="true"
              ></AgGridReact>
            </div>
          </>
        );
        setSalesContentUsingAGGrid(() => renderContentUsingAGGrid)
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const createHandler = (category) => {
    navigate("/admin/walkin-sales-invoice/new");
  };

  const showInvoiceDetails = (id) => {
    navigate(`/admin/walkin-sales-invoice/${id}`);
  };

  useEffect(() => {
    console.log("Use Effect Gets Called Only when PO is by WH or Store.");
    if (role === "CEO_ROLE") {
      console.log('Dispatching to List All the Walkin Sales Invoices...! ');
      dispatch(listWalkinSaleInvoices());
    }
    else if (role === "WAREHOUSE_INCHARGE_ROLE" && warehouseName) {// HANDLE FOR WH INCHARGE ROLES
      populatePosDetails();
      if (posStoreOrWarehouse && posStoreOrWarehouse.length > 0)
        dispatch(listWalkinSaleInvoicesByPosName(posName));
    }
    else if (role === "STORE_INCHARGE_ROLE" && storeName) {// HANDLE FOR WH INCHARGE ROLES
      populatePosDetails();
      if (posStoreOrWarehouse && posStoreOrWarehouse.length > 0)
        dispatch(listWalkinSaleInvoicesByPosName(posName));
    }
  }, [dispatch, posName, stores, warehouseInfos]);

  useEffect(() => {
    console.log('Use Effect getting Called as walkin_sales_invoice_data, walkin_sales_invoice_pos_data have Changed')
    prepareDataForSalesReport()
  }, [walkin_sales_invoice_data, walkin_sales_invoice_pos_data]);


  useEffect(() => {
    console.log('Use Effect getting Called as salesReportByProducts has Changed')
    prepareDataForProductWiseSaleReport()
  }, [walkin_sales_invoice_data, walkin_sales_invoice_pos_data]);


  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <React.Fragment>
      {(walkin_sales_invoice_loading || walkin_sales_invoice_pos_loading) && <CustomBackdropSpinner />}
      {(role === 'STORE_INCHARGE_ROLE' || role === 'WAREHOUSE_INCHARGE_ROLE') && (<GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
        <Tooltip title="Create Warehouse or Walkin Sales Invoice" arrow>
          <AddCircleIcon
            fontSize="medium"
            style={{ float: "left" }}
            onClick={createHandler}
          />
        </Tooltip>
      </GridItem>)}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                Walkin Sales Invoices
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={2} alignItems="center" justify="center">
      {salesContentUsingAGGrid && (
        <GridItem xs={12} sm={6} md={6} style={{textAlign:"center"}}>
          <Tooltip title="Download Sale Report" arrow>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick={onExportClick}
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            >
              <SaveAltIcon
              fontSize="medium"
              style={{ float: "left", marginRight:"10px" }}
            />
              Sale Report
            </Button>
          </Tooltip>
        </GridItem>
      )
      }
      {console.log('Content length of salesContentProductWiseUsingAGGrid : ', salesContentProductWiseUsingAGGrid ? salesContentProductWiseUsingAGGrid.length : 0)}
      {salesContentProductWiseUsingAGGrid && salesContentProductWiseUsingAGGrid.length > 0 && (
         <GridItem xs={12} sm={6} md={6} style={{textAlign:"center"}}>
          <Tooltip title="Download Product Wise Sale Report" arrow>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick={() => exportToExcel(salesContentProductWiseUsingAGGrid)}
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            >
             <SaveAltIcon
              fontSize="medium"
              style={{ float: "left" ,marginRight:"10px"}}
            /> Product Sale
            </Button>
          </Tooltip>
        </GridItem>
      )
      }
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.cardTitleWhite}>Sales Invoices </h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {console.log('salesContentUsingAGGrid : ', salesContentUsingAGGrid ? salesContentUsingAGGrid.length : 0)}
          {salesContentUsingAGGrid ? salesContentUsingAGGrid : noDataFoundContent}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default WalkinSalesInvoicesListScreen;
