import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import {
  AnalyticsRounded,
  ExitToApp,
} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import HistoryEduIcon from "@mui/icons-material/HistoryOutlined";
import Tooltip from "@mui/material/Tooltip";
import logo from "../assets/images/logo.jpg";
import logoNoBg from "../assets/images/logo-worked.png";
import appTitle from "../assets/images/app_title.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { logout } from "../actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from '@mui/icons-material/Person';
import { InputLabel } from "@mui/material";
import CampaignIcon from '@mui/icons-material/Campaign';

const useStyles = makeStyles((theme) => ({
  primary: "",
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appLogo: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    fontStyle: "roboto",
    fontFamily: "roboto",
    color: "white",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  sectionDesktop: {
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function CustomHeader() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const userLogin = useSelector((state) => state.userLogin ? state.userLogin:{});
  const { loading, error, userInfo } = userLogin;
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const calculateCartCount = () => {
    console.log("Calculate Cart Count");
    let tempCartCount = 0;
    tempCartCount = cartItems.reduce(
      (acc, item) => acc + Number(item.quantity),
      0
    );
    console.log("Cart Count Result  : ", tempCartCount);
    return <>{tempCartCount}</>;
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMyOrders = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleAboutUs = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleCart = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/shoppingcart");
  };
  const handleNewStockArrival = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/admin/new-stock-notifications-list");
  };
  const handleSoftwareUpdates = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate("/admin/version-updates");
  };
  const handleProfile = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMenuLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    console.log("Clicked Logout");
    dispatch(logout());
    navigate("/signin");
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleUserDetails = (event) => {
    console.log("Clicked handleUserDetails  ");
    setAnchorEl(event.currentTarget);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      { userInfo && userInfo.name && userInfo.name.length>0 ? (
        <div>
          <MenuItem onClick={() => navigate("/")}>
            <Tooltip title="Home" arrow>
              <IconButton
                aria-label="Home"
                color="inherit"
                onClick={() => navigate("/")}
              >
                <HomeIcon />
              </IconButton>
            </Tooltip>
            Home
          </MenuItem>
          <MenuItem>
          <Tooltip title="Home" arrow>
              <IconButton
                aria-label="User Details"
                color="inherit"
                onClick={handleUserDetails}
              >
                <PersonIcon />
              </IconButton>
              </Tooltip>
              User 
          </MenuItem>
          <MenuItem onClick={handleMenuLogout}>
            <Tooltip title="Logout" arrow>
              <IconButton aria-label="Logout" color="inherit">
                <ExitToApp />
              </IconButton>
            </Tooltip>
            Logout
          </MenuItem>
        </div>
      ) : (
        ""
      )}
    </Menu>
  );
  return (
    <div className={classes.grow}>
      <AppBar position="fixed">
        <Toolbar style={{height:"5rem"}}>
            <img
              className="img-thumbnail"
              alt=" Logo"
              src={logoNoBg}
              style={{
                height: "7rem",
                width: "7rem",
              }}
            />
          <img
              className="img-thumbnail"
              alt="Title"
              src={appTitle}
              style={{
                width: "23vh",
                height:"3.75rem"
              }}
            />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <CssBaseline />
            {userInfo &&
              (userInfo.role !== "" ) && (
                <><Tooltip title="Home" arrow>
                  <IconButton
                    aria-label="Home"
                    color="inherit"
                    onClick={() => navigate("/")}
                  >
                    <HomeIcon />
                  </IconButton>
                </Tooltip>
                </>
              )}
          
            {userInfo && userInfo.userName && (
              <Tooltip title="Software Updates" arrow>
                <IconButton
                  aria-label=""
                  color="inherit"
                  onClick={handleSoftwareUpdates}
                >
                  <CampaignIcon />
                </IconButton>
              </Tooltip>
            )}
            {userInfo && userInfo.name && userInfo.name.length>0 && (
              <Tooltip title="User Details" arrow>
                <IconButton
                  aria-label="Welcome"
                  color="inherit"
                  onClick={handleUserDetails}
                >
                  <PersonIcon />
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={handleClose}>Name      : {userInfo && userInfo.name ? userInfo.name :""}</MenuItem>
                    <MenuItem onClick={handleClose}>User Name : {userInfo && userInfo.userName ? userInfo.userName :""}</MenuItem>
                    <MenuItem onClick={handleClose}>Role      :{userInfo && userInfo.role ? userInfo.role :""}</MenuItem>
                    <MenuItem onClick={handleClose}>POS       : {userInfo && userInfo.storeName ? userInfo.storeName : ""}</MenuItem>
                    <MenuItem onClick={handleMenuLogout}>
                        <ExitToApp />
                      Logout
                    </MenuItem>
                  </Menu>

                </IconButton>
              </Tooltip>
            )} 
          </div>
          <div className={classes.sectionMobile}>
            <Tooltip title="More" arrow>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
}
