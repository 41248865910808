import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Grid, Button, TextField, Link } from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/userAction";
import Message from "./Message";
import CustomBackdropSpinner from "./CustomBackdropSpinner";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  userName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8,
    },
  },
};

const LoginForm = () => {
  const classes = useStyles();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(() => {
    if (userInfo) navigate("/");
    else navigate("/signin");
  }, [userInfo, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (formState.isValid) {
      console.log(formState.values);
      dispatch(login(formState.values.userName, formState.values.password));
    }
    localStorage.setItem("readVersionUpdates", JSON.stringify(false));
 
    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };
  const handleSignUp = (e) => {
    console.log("Clicked Sign Up");
    e.preventDefault();
    navigate("/admin/users/create");
  };

  const handlePasswordReset = (e) => {
    console.log("Clicked Reset Password ");
    e.preventDefault();
    navigate("/admin/users/reset/password");
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {error && (
            <Message variant="danger" severity="error">
              {error}
            </Message>
          )}
        </Grid>
      </Grid>

      {loading && <CustomBackdropSpinner />}
      <form method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="center" margin="auto">
          <Grid item xs={12}>
            <TextField
              label="User Name*"
              variant="outlined"
              size="small"
              name="userName"
              fullWidth
              helperText={
                hasError("userName") ? formState.errors.userName[0] : null
              }
              error={hasError("userName")}
              onChange={handleChange}
              type="text"
              style={{ color: "black" }}
              value={formState.values.userName || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Password *"
              variant="outlined"
              size="small"
              name="password"
              fullWidth
              helperText={
                hasError("password") ? formState.errors.password[0] : null
              }
              error={hasError("password")}
              onChange={handleChange}
              type="password"
              value={formState.values.password || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            >
              Sign in
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              Don't have an account?
              <Link component="button" variant="subtitle1" onClick={handleSignUp}>
                Sign Up
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              align="center"
            >
              Forgot Password ?
              <Link component="button" variant="subtitle1" onClick={handlePasswordReset}>
                reset here 
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default LoginForm;
