import React from "react";
import PropTypes from "prop-types";
import AOS from "aos";
import HomeIcon from "@mui/icons-material/Home";
import { makeStyles, useTheme } from "@mui/styles";
import {
  useMediaQuery,
  Grid,
  ListItem,
  ListItemText,
  Button,
  Typography,
  Tooltip,
} from "@mui/material";
import CardBase from "../../components/organisms/CardBase";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import { useNavigate } from "react-router-dom";
import Section from "../organisms/Section/Section";

const useStyles = makeStyles((theme) => ({
  cardBase: {
    boxShadow: "none",
    borderRadius: theme.spacing(1),
    "& .card-base__content": {
      padding: theme.spacing(1),
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3),
      },
    },
  },
  avatar: {
    width: 110,
    height: 110,
    borderRadius: "100%",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)",
  },
  listItem: {
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  listItemAvatar: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      marginBottom: theme.spacing(2),
    },
  },
  listItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    margin: 0,
    height: "100%",
  },
  title: {
    fontWeight: "bold",
  },
}));

const ReportsDashboardScreen = (props) => {
  const { data, className, ...rest } = props;
  const { modules } = data;
  console.log(data);
  // console.log(modules);
  const classes = useStyles();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const routeToReportModule = () => {
    navigate("/admin/reports/module");
  };

  React.useEffect(() => {
    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: "ease-in-out",
    });
  }, []);
  return (
    <div>
      <Section>
        <GridContainer>
          <GridItem xs={6} sm={6} md={6}>
            <Tooltip title="Home" arrow>
              <Button
                size="small"
                variant="contained"
                type="submit"
                color="secondary"
                justify="center"
                onClick={() => navigate("/")}
                style={{ marginLeft: "0.5rem" }}
              >
                <HomeIcon fontSize="medium" />
              </Button>
            </Tooltip>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Typography variant="h5">Reports</Typography>
          </GridItem>
        </GridContainer>
      </Section>

      <Grid container spacing={isMd ? 2 : 1}>
        {data.map((item, index) => (
          <Grid item xs={6} sm={6} md={4} key={index} data-aos="fade-up">
            <CardBase className={classes.cardBase} liftUp>
              <ListItem disableGutters className={classes.listItem}>
                <ListItemText
                  className={classes.listItemText}
                  primary={item.title}
                  primaryTypographyProps={{
                    className: classes.title,
                    variant: "h6",
                    align: "center",
                  }}
                  secondaryTypographyProps={{
                    color: "nlBlack",
                    align: "center",
                  }}
                />
              </ListItem>
              <Tooltip title={`${item.subtitle} Report`} arrow>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  onClick={routeToReportModule}
                >
                  {item.subtitle}
                </Button>
              </Tooltip>
            </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

ReportsDashboardScreen.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
};

export default ReportsDashboardScreen;
