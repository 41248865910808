import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import {
  Tooltip,
  Typography,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import { listAllWarehouses } from "../../actions/warehouseInfoAction";
import { listAllStores } from "../../actions/storeAction";
import { listWalkinSaleInvoices, listWalkinSaleInvoicesByPosName } from "../../actions/walkinSaleInvoiceAction";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportToExcel } from "../../util/exportToExcelUtil.js";
import { convertInvoiceIdToDigits, getFirstAndlastDayOfCurrentMonth, getFirstAndlastDayOfPreviousMonth } from '../../util/Utility.js'
import { addDecimals } from "../../util/Utility";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { financialYearOptions, quarterlyOptions } from "../../data/index.js";


const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "300",
      lineHeight: "1",
    },
  },
};

const WalkinSalesReport = ({ match }) => {
  const useStyles = makeStyles(styles);
  const gridRef = useRef();

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [posName, setPosName] = useState(() => "");
  const [reportDuration, setReportDuration] = useState(() => "currentMonth");
  const [quarterSelected, setQuarterSelected] = useState(() => "Q2 [July - September]");
  const [financialYearSelected, setFinancialYearSelected] = useState("2023 - 2024");
  const [posStoreOrWarehouse, setPosStoreOrWarehouse] = useState(() => "");
  const [firstAndLastDayOfMonth, setFirstAndLastDayOfMonth] = useState("");
  const [firstAndLastDayOfCurrentMonth, setFirstAndLastDayOfCurrentMonth] = useState("");
  const [firstAndLastDayOfPreviousMonth, setFirstAndLastDayOfPreviousMonth] = useState("");
  const quarterlyOptionsFilter = quarterlyOptions && quarterlyOptions.length > 0 ? quarterlyOptions.map((eachQtrly, idx) => <MenuItem key={idx} value={eachQtrly}>{eachQtrly}</MenuItem>) : [];
  const financialYearOptionsFilter = financialYearOptions && financialYearOptions.length > 0 ? financialYearOptions.map((eachFy, idx) => <MenuItem key={idx} value={eachFy}>{eachFy}</MenuItem>) : [];

  let rowData = [];
  let currentMonthFirstLastDate = ""
  let previousMonthFirstLastDate = ""
  let salesReportByProducts = [];
  let renderContentUsingAGGrid = "";
  const [salesContentUsingAGGrid, setSalesContentUsingAGGrid] = useState(() => null);
  const [salesContentProductWiseUsingAGGrid, setSalesContentProductWiseUsingAGGrid] = useState(() => null);
  let noDataFoundContent = ""

  useEffect(() => {
    console.log("Use Effect Gets Called for Listing All Store Warehouses...!")
    dispatch(listAllStores())
    dispatch(listAllWarehouses())
  }, [dispatch]);

  useEffect(() => {
    console.log("Use Effect Gets Called for fetching FirstAndlastDayOfMonth...!");
    currentMonthFirstLastDate = getFirstAndlastDayOfCurrentMonth()
    previousMonthFirstLastDate = getFirstAndlastDayOfPreviousMonth()
    if (reportDuration === 'currentMonth') {
      // console.log('****** firstLastDate of Current Month ****** ', currentMonthFirstLastDate)
      setFirstAndLastDayOfMonth(() => currentMonthFirstLastDate.firstDay + ' To ' + currentMonthFirstLastDate.lastDay)
    }
    else if (reportDuration === 'previousMonth') {
      // console.log('****** firstLastDate of Previous Month ****** ', previousMonthFirstLastDate)
      setFirstAndLastDayOfMonth(() => previousMonthFirstLastDate.firstDay + ' To ' + previousMonthFirstLastDate.lastDay)
    }
  }, [reportDuration]);


  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const warehouseInfoList = useSelector(
    (state) => state.warehouseInfoList
  );
  const storesList = useSelector(
    (state) => state.storesList
  );

  const { loading_wh_infos, warehouseInfos, wh_infos_error } = warehouseInfoList;// warehouseInfoList
  const { stores_loading, stores, stores_error } = storesList; //storesList
  const walkinSalesInvoicesListByPos = useSelector(
    (state) => state.walkinSalesInvoicesListByPos
  );
  const walkinSalesInvoiceList = useSelector((state) => state.walkinSalesInvoiceList);
  const { walkin_sales_invoice_loading, walkin_sales_invoice_data } = walkinSalesInvoiceList;

  const { walkin_sales_invoice_pos_loading, walkin_sales_invoice_pos_data, walkin_sales_invoice_pos_error } = walkinSalesInvoicesListByPos; //storesList
  const popupParent = useMemo(() => {
    return document.body;
  }, []);
  const columnDefs = [
    {
      field: "Date",
      headerName: "Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true
    },
    {
      field: "Id",
      resizable: true,
      headerName: "Invoice No",
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    // {
    //   field: "PosName",
    //   headerName: "POS Name",
    //   resizable: true,
    //   suppressSizeToFit: true,
    //   width: 200,
    //   minWidth: 200,
    //   maxWidth: 200,
    //   filter: true,
    //   type: 'leftAligned'
    // },
    {
      field: "CustomerName",
      headerName: "Party Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      type: 'leftAligned'
    },
    {
      field: "TransactionType",
      headerName: "Transaction Type",
      resizable: true,
      suppressSizeToFit: true,
      width: 175,
      minWidth: 175,
      maxWidth: 175,
      filter: true,
      type: 'leftAligned'
    },
    // {
    //   field: "CustomerContact",
    //   headerName: "Customer#",
    //   resizable: true,
    //   suppressSizeToFit: true,
    //   width: 150,
    //   minWidth: 150,
    //   maxWidth: 150,
    //   filter: true,
    //   type: 'rightAligned'
    // },
    {
      field: "PaymentType",
      headerName: "Payment Type",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      type: 'rightAligned',
      cellDataType: "text"
    },
    {
      field: "ItemsPrice",
      headerName: "Amount",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      type: 'rightAligned'
    },
    {
      field: "BalanceDue",
      headerName: "Balance Due",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      type: 'rightAligned',
      cellDataType: "text"
    },
    {
      field: "UpiAmountPaid",
      headerName: "Upi Amount Paid",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      type: 'rightAligned',
      cellDataType: "text"
    },
    {
      field: "CashAmountPaid",
      headerName: "Cash Amount Paid",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      type: 'rightAligned'
    },
    {
      field: "Status",
      headerName: "Status",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "Id",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              // onClick={() => console.log("params", params.data.Details)}
              onClick={() => showInvoiceDetails(params.data.Details)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      ensureDomOrder: true
    }),
    []
  );

  const populatePosDetails = () => {
    if (warehouseName && warehouseName.length > 0 && warehouseName.includes("_WAREHOUSE")) {
      console.log("warehouseName  : ", warehouseName);
      setPosName(() => warehouseName);
      setPosStoreOrWarehouse(() => "Warehouse");
    } else if (storeName && storeName.length > 0 && storeName.includes("_STORE")) {
      console.log("storeName  : ", storeName);
      setPosName(() => storeName);
      setPosStoreOrWarehouse(() => "Store");
    }
  }

  const prepareDataForSalesReport = () => {
    console.log('Exec prepareDataForSalesReport ...')
    if (role === 'CEO_ROLE' && walkin_sales_invoice_data && walkin_sales_invoice_data.length > 0) {
      rowData = walkin_sales_invoice_data.map((salesByPos, index) => {
        console.log('salesByPos : ', salesByPos)
        const invIdInDecimal = convertInvoiceIdToDigits(salesByPos._id)
        const upiPaid = salesByPos.paymentDetails && salesByPos.paymentDetails.upiAmountPaid ? salesByPos.paymentDetails.upiAmountPaid : 0.0
        const cashPaid = salesByPos.paymentDetails && salesByPos.paymentDetails.cashAmountPaid ? salesByPos.paymentDetails.cashAmountPaid : 0.0
        const orderTotal = salesByPos.itemsPrice ? salesByPos.itemsPrice : 0.0
        const balanceDue = Number(orderTotal) - (Number(upiPaid) + Number(cashPaid));
        const paymentStatus = balanceDue && Number(balanceDue) > 0 ? 'Un Paid' : 'Paid'
        // console.log('balanceDue : ', balanceDue)
        return {
          "#": index + 1,
          Date: salesByPos.billDate
            ? salesByPos.billDate
            : "",
          Id: invIdInDecimal,
          CustomerName: salesByPos.customerDetails && salesByPos.customerDetails.name
            ? salesByPos.customerDetails.name : "",
          TransactionType: "Sale",
          ItemsPrice: salesByPos.itemsPrice
            ? addDecimals(salesByPos.itemsPrice)
            : 0.0,
          PaymentType: 'Cash',
          BalanceDue: balanceDue ? balanceDue : 0.0,
          TaxPrice: salesByPos.taxPrice
            ? salesByPos.taxPrice
            : 0.0,
          UpiAmountPaid: salesByPos.paymentDetails && salesByPos.paymentDetails.upiAmountPaid
            ? addDecimals(salesByPos.paymentDetails.upiAmountPaid)
            : 0.0,
          CashAmountPaid: salesByPos.paymentDetails && salesByPos.paymentDetails.cashAmountPaid
            ? addDecimals(salesByPos.paymentDetails.cashAmountPaid)
            : 0.0,
          Status: paymentStatus ? paymentStatus : '',
          Details: salesByPos._id,
        };
      });

      if (rowData) {
        renderContentUsingAGGrid = (
          <div
            className="ag-theme-quartz"
            style={{ width: "100%", height: "65vh" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowStyle={{ textAlign: "left", alignItems: "center" }}
              suppressExcelExport={false}
              popupParent={popupParent}
              pagination="true"
            ></AgGridReact>
          </div>
        );
        setSalesContentUsingAGGrid(() => renderContentUsingAGGrid)
      }
    } else if ((role === 'WAREHOUSE_INCHARGE_ROLE' || role === 'STORE_INCHARGE_ROLE') && walkin_sales_invoice_pos_data && walkin_sales_invoice_pos_data.length > 0) {
      rowData = walkin_sales_invoice_pos_data.map((salesByPos, index) => {
        const invIdInDecimal = convertInvoiceIdToDigits(salesByPos._id)
        const upiPaid = salesByPos.paymentDetails && salesByPos.paymentDetails.upiAmountPaid ? salesByPos.paymentDetails.upiAmountPaid : 0.0
        const cashPaid = salesByPos.paymentDetails && salesByPos.paymentDetails.cashAmountPaid ? salesByPos.paymentDetails.cashAmountPaid : 0.0
        const orderTotal = salesByPos.itemsPrice ? salesByPos.itemsPrice : 0.0
        const balanceDue = Number(orderTotal) - (Number(upiPaid) + Number(cashPaid));
        const paymentStatus = balanceDue && Number(balanceDue) > 0 ? 'Un Paid' : 'Paid'
        // console.log('balanceDue : ', balanceDue)
        return {
          "#": index + 1,
          Date: salesByPos.billDate
            ? salesByPos.billDate
            : "",
          Id: invIdInDecimal,
          CustomerName: salesByPos.customerDetails && salesByPos.customerDetails.name
            ? salesByPos.customerDetails.name : "",
          TransactionType: "Sale",
          ItemsPrice: salesByPos.itemsPrice
            ? addDecimals(salesByPos.itemsPrice)
            : 0.0,
          PaymentType: 'Cash',
          BalanceDue: balanceDue ? balanceDue : 0.0,
          TaxPrice: salesByPos.taxPrice
            ? salesByPos.taxPrice
            : 0.0,
          UpiAmountPaid: salesByPos.paymentDetails && salesByPos.paymentDetails.upiAmountPaid
            ? addDecimals(salesByPos.paymentDetails.upiAmountPaid)
            : 0.0,
          CashAmountPaid: salesByPos.paymentDetails && salesByPos.paymentDetails.cashAmountPaid
            ? addDecimals(salesByPos.paymentDetails.cashAmountPaid)
            : 0.0,
          Status: paymentStatus ? paymentStatus : '',
          Details: salesByPos._id,
        };
      });
      if (rowData) {
        renderContentUsingAGGrid = (
          <>
            <div
              className="ag-theme-quartz"
              style={{ width: "100%", height: "65vh" }}
            >
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowStyle={{ textAlign: "left", alignItems: "center" }}
                pagination="true"
              // domLayout='autoHeight'
              ></AgGridReact>
            </div>
          </>
        );
        setSalesContentUsingAGGrid(() => renderContentUsingAGGrid)
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const showInvoiceDetails = (id) => {
    navigate(`/admin/walkin-sales-invoice/${id}`);
  };

  useEffect(() => {
    console.log("Use Effect Gets Called Only when PO is by WH or Store.");
    if (role === "CEO_ROLE") {
      console.log('Dispatching to List All the Walkin Sales Invoices...! ');
      dispatch(listWalkinSaleInvoices());
    }
    else if ((role === "WAREHOUSE_INCHARGE_ROLE" && warehouseName) || (role === "STORE_INCHARGE_ROLE" && storeName)) {// HANDLE FOR WH INCHARGE ROLES
      populatePosDetails();
      if (posStoreOrWarehouse && posStoreOrWarehouse.length > 0)
        dispatch(listWalkinSaleInvoicesByPosName(posName));
    }
  }, [dispatch, posName, role]);

  useEffect(() => {
    console.log('Use Effect getting Called as walkin_sales_invoice_data, walkin_sales_invoice_pos_data have Changed')
    prepareDataForSalesReport()
  }, [walkin_sales_invoice_data, walkin_sales_invoice_pos_data]);

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  return (
    <React.Fragment>
      {(walkin_sales_invoice_loading || walkin_sales_invoice_pos_loading) && <CustomBackdropSpinner />}
      {(role === 'STORE_INCHARGE_ROLE' || role === 'WAREHOUSE_INCHARGE_ROLE') && (<GridItem xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>

      </GridItem>)}
      {/** SELECT DURATION OF SALES SECTION START */}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <Typography
              variant="h6"
              style={{ margin: "0.5rem" }}
              color="black"
            >
              Walkin Sales Report Duration
            </Typography>
            <CardBody>
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={4} md={3}>
                  {/* <GridItem item xs={12} style={{ marginBottom: "0.25rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}> */}
                  <FormControl style={{ textAlign: "center", paddingTop: "0.5rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Duration
                    </InputLabel>
                    <Select
                      label="Duration"
                      placeholder="Duration Mode"
                      variant="outlined"
                      required
                      size="small"
                      name="reportDuration"
                      onChange={(e) => {
                        console.log('On Change', e.target.value)
                        setReportDuration(e.target.value)
                        if (e.target.value === 'currentMonth') {
                          console.log('currentMonthFirstLastDate : ', currentMonthFirstLastDate)
                          setFirstAndLastDayOfMonth(() => currentMonthFirstLastDate);
                        } else if (e.target.value === 'previousMonth') {
                          console.log('previousMonthFirstLastDate : ', previousMonthFirstLastDate)
                          setFirstAndLastDayOfMonth(() => previousMonthFirstLastDate);
                        } else if (e.target.value === 'quarterly') {
                          console.log('Quarterly : ', previousMonthFirstLastDate)
                          setFirstAndLastDayOfMonth(() => previousMonthFirstLastDate);
                        }
                      }
                      }
                      value={reportDuration}
                      style={{ width: "9rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}
                    >
                      <MenuItem key="currentMonth" value="currentMonth">This Month</MenuItem>
                      <MenuItem key="previousMonth" value="previousMonth">Previous Month</MenuItem>
                      <MenuItem key="quarterly" value="quarterly">This Quarter</MenuItem>
                      <MenuItem key="yearly" value="yearly">Yearly</MenuItem>
                      <MenuItem key="custom" value="custom">Custom</MenuItem>

                    </Select>
                  </FormControl>
                  {/* </GridItem> */}
                </GridItem>
                {reportDuration && reportDuration === "quarterly" && (
                  <FormControl style={{ textAlign: "center", paddingTop: "0.5rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Quarterly
                    </InputLabel>
                    <Select
                      label="Quarter"
                      placeholder="Quarter"
                      variant="outlined"
                      required
                      size="small"
                      name="quarterly"
                      onChange={(e) => {
                        if (e.target.value) {
                          setQuarterSelected((prev) => e.target.value);
                        }
                      }
                      }
                      value={quarterSelected}
                    >
                      {quarterlyOptionsFilter}
                    </Select>
                  </FormControl>
                )
                }
                {reportDuration && reportDuration === "yearly" && (
                  <FormControl style={{ textAlign: "center", paddingTop: "0.5rem" }}>
                    <InputLabel id="demo-simple-select-label">
                      Yearly
                    </InputLabel>
                    <Select
                      label="Year"
                      placeholder="Year"
                      variant="outlined"
                      required
                      size="small"
                      name="yearly"
                      onChange={(e) => {
                        if (e.target.value) {
                          setFinancialYearSelected((prev) => e.target.value);
                        }
                      }
                      }
                      value={financialYearSelected}
                    >
                      {financialYearOptionsFilter}
                    </Select>
                  </FormControl>
                )
                }
                {(reportDuration === 'currentMonth' || reportDuration === 'previousMonth') && <GridItem xs={12} sm={4} md={3}>
                  <TextField
                    label="Between"
                    placeholder="Between"
                    variant="outlined"
                    size="small"
                    required
                    name="between"
                    disabled
                    type="text"
                    style={{ width: "12rem", textAlign: "center", paddingTop: "0.5rem" }}
                    value={firstAndLastDayOfMonth ? firstAndLastDayOfMonth : ''}
                  />
                </GridItem>}
              </GridContainer>
              {/**
       * PAID UNPAID BALANCE SECTION START
       */}

              <GridContainer
                className={classes.root}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 2, sm: 4, md: 6 }}
                justify="center"
                alignItems="center"
              >
                <GridItem >
                  <Tooltip title={'PAID'} arrow>
                    <Card className={classes.paper}
                      onClick={() => {
                        console.log("Routing to Product Path ");
                        // navigate(`/subcategory/${each._id}`);
                      }}
                      style={{
                        justifyContent: "center",
                        marginLeft: "auto",
                        alignItems: "center",
                        width: '10rem',
                        height: '5rem',
                        backgroundColor: "#C7F6C7"
                      }}>

                      <Typography
                        style={{
                          marginTop: "1rem",
                          justifyContent: "left",
                          textAlign: "left",
                          alignItems: "left",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "500",
                          color: "black",
                          fontSize: "1.1rem"
                        }}
                      >Paid
                      </Typography>

                      <Typography
                        style={{
                          margin: "0.5rem",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.60rem"
                        }}
                      ><CurrencyRupeeIcon
                        size="small"
                        variant="contained"
                        type="submit"
                        color="Gray"
                        justify="center"
                        style={{ marginRight: "0.5rem", width: "1rem" }}
                      >
                        </CurrencyRupeeIcon> 10.00</Typography>
                    </Card>
                  </Tooltip>
                </GridItem>
                <span style={{
                  margin: "0.5rem",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "600",
                  color: "black",
                  fontSize: "1.2rem"
                }}> + </span>
                <GridItem >
                  <Tooltip title={'UN PAID'} arrow>
                    <Card className={classes.paper} col
                      onClick={() => {
                        console.log("Routing to Product Path ");
                      }}
                      style={{
                        justifyContent: "center",
                        marginLeft: "auto",
                        alignItems: "center",
                        width: '10rem',
                        height: '5rem',
                        backgroundColor: "#ADD8E6"
                      }}>

                      <Typography
                        style={{
                          marginTop: "1rem",
                          justifyContent: "left",
                          textAlign: "left",
                          alignItems: "left",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "500",
                          color: "black",
                          fontSize: "1.1rem"
                        }}
                      >Un Paid
                      </Typography>
                      <Typography
                        style={{
                          margin: "0.5rem",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.60rem"
                        }}
                      ><CurrencyRupeeIcon
                        size="small"
                        variant="contained"
                        type="submit"
                        color="Gray"
                        justify="center"
                        style={{ marginRight: "0.5rem", width: "1rem" }}
                      >
                        </CurrencyRupeeIcon> 20.00</Typography>
                    </Card>
                  </Tooltip>
                </GridItem>
                <span style={{
                  margin: "0.5rem",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                  fontWeight: "600",
                  color: "black",
                  fontSize: "1.2rem"
                }}> = </span>
                <GridItem >
                  <Tooltip title={'TOTAL'} arrow>
                    <Card className={classes.paper}
                      onClick={() => {
                        console.log("Routing to Product Path ");
                      }}
                      style={{
                        justifyContent: "center",
                        marginLeft: "auto",
                        alignItems: "center",
                        width: '10rem',
                        height: '5rem',
                        backgroundColor: '#FFBE9F'
                      }}>

                      <Typography
                        style={{
                          marginTop: "1rem",
                          justifyContent: "left",
                          textAlign: "left",
                          alignItems: "left",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "500",
                          color: "black",
                          fontSize: "1.1rem"
                        }}
                      >Total
                      </Typography>
                      <Typography
                        style={{
                          margin: "0.5rem",
                          justifyContent: "center",
                          textAlign: "center",
                          alignItems: "center",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "600",
                          color: "black",
                          fontSize: "1.60rem"
                        }}
                      ><CurrencyRupeeIcon
                        size="small"
                        variant="contained"
                        type="submit"
                        color="Gray"
                        justify="center"
                        style={{ marginRight: "0.5rem", width: "1rem" }}
                      >
                        </CurrencyRupeeIcon> 20.00</Typography>
                    </Card>
                  </Tooltip>
                </GridItem>
              </GridContainer>
              {/**
       * PAID UNPAID BALANCE SECTION END
       */}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/** SELECT DURATION OF SALES SECTION END */}

      {/**
       * PAID UNPAID BALANCE SECTION START
       */}

      {/**
       * PAID UNPAID BALANCE SECTION END
       */}

      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <h4 className={classes.cardTitleWhite}>Sales Invoices </h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {console.log('salesContentUsingAGGrid : ', salesContentUsingAGGrid ? salesContentUsingAGGrid.length : 0)}
          {salesContentUsingAGGrid ? salesContentUsingAGGrid : noDataFoundContent}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default WalkinSalesReport;
