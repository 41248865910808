import axios from "axios";
import {
  STORE_PAYMENT_CREATE_BY_STORE_REQUEST,
  STORE_PAYMENT_CREATE_BY_STORE_SUCCESS,
  STORE_PAYMENT_CREATE_BY_STORE_RESET,
  STORE_PAYMENT_CREATE_BY_STORE_FAIL,
  STORE_PAYMENT_LIST_BY_STORE_REQUEST,
  STORE_PAYMENT_LIST_BY_STORE_SUCCESS,
  STORE_PAYMENT_LIST_BY_STORE_RESET,
  STORE_PAYMENT_LIST_BY_STORE_FAIL,
  STORE_PAYMENT_DETAILS_BY_ID_REQUEST,
  STORE_PAYMENT_DETAILS_BY_ID_SUCCESS,
  STORE_PAYMENT_DETAILS_BY_ID_FAIL,
  STORE_PAYMENT_DETAILS_BY_ID_RESET
} from "../constants/storePaymentConstants";

export const createStorePayment = (order) => async (dispatch, getState) => {
  console.log("STORE PAYMENT ACTION : Inside createStorePayment ...", order);

  const { storeName } = order
  try {
    dispatch({
      type: STORE_PAYMENT_CREATE_BY_STORE_REQUEST,
    });

    const config = {
    };

    const { data } = await axios.post(`/api/store-payments/store/${storeName}`, { order }, config);

    dispatch({
      type: STORE_PAYMENT_CREATE_BY_STORE_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    
    dispatch({
      type: STORE_PAYMENT_CREATE_BY_STORE_FAIL,
      payload: message,
    });
  }
};

export const listPaymentsByStore = (store) => async (dispatch, getState) => {
  console.log("Listing Orders Receipts By Store ", store);
  try {
    dispatch({
      type: STORE_PAYMENT_LIST_BY_STORE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/store-payments/store/${store}`, config);

    dispatch({
      type: STORE_PAYMENT_LIST_BY_STORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: STORE_PAYMENT_LIST_BY_STORE_FAIL,
      payload: message,
    });
  }
};

export const getStorePaymentById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STORE_PAYMENT_DETAILS_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/store-payment/id/${id}`, config);

    dispatch({
      type: STORE_PAYMENT_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: STORE_PAYMENT_DETAILS_BY_ID_FAIL,
      payload: message,
    });
  }
};



