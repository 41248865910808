export const VEHICLE_SALE_INVOICE_LIST_REQUEST = "VEHICLE_SALE_INVOICE_LIST_REQUEST";
export const VEHICLE_SALE_INVOICE_LIST_SUCCESS = "VEHICLE_SALE_INVOICE_LIST_SUCCESS";
export const VEHICLE_SALE_INVOICE_LIST_FAIL = "VEHICLE_SALE_INVOICE_LIST_FAIL";
export const VEHICLE_SALE_INVOICE_LIST_RESET = "VEHICLE_SALE_INVOICE_LIST_RESET";

export const VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_REQUEST = "VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_REQUEST";
export const VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_SUCCESS = "VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_SUCCESS";
export const VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_FAIL = "VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_FAIL";
export const VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET = "VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET";

export const VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_REQUEST =
  "VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_REQUEST";
export const VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_SUCCESS =
  "VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_SUCCESS";
export const VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_FAIL =
  "VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_FAIL";
  export const VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_RESET =
  "VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_RESET";

export const VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_REQUEST = "VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_REQUEST";
export const VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_SUCCESS = "VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_SUCCESS";
export const VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_FAIL = "VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_FAIL";

export const VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_REQUEST = "VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_REQUEST";
export const VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_SUCCESS = "VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_SUCCESS";
export const VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_FAIL = "VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_FAIL";
export const VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_RESET = "VEHICLE_SALE_INVOICE_UPDATE_BY_VEHICLE_NO_RESET";

export const VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST =
  "VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST";
export const VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS =
  "VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS";
export const VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL =
  "VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL";
  export const VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET =
  "VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET";

  export const VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_REQUEST = 
  "VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_REQUEST";
  export const VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_SUCCESS =
  "VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_SUCCESS";
  export const VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_FAIL =
  "VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_FAIL";
  export const VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_RESET =
  "VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_RESET";

  export const VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_REQUEST = 
  "VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_REQUEST";
  export const VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_SUCCESS =
  "VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_SUCCESS";
  export const VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_FAIL =
  "VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_FAIL";
  export const VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_RESET =
  "VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_RESET";
