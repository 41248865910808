import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_INCREMENT_ITEM_QUANTITY,
  CART_DECREMENT_ITEM_QUANTITY,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from "../constants/cartConstants";
export const cartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const cartItem = action.payload;
      console.log("EXEC CART_ADD_ITEM", cartItem);
      console.log("ITEMS IN CART OF STORE STATE :==>  ", state.cartItems);
      state.cartItems.map(e => console.log(" ******* Each Item In STORE STATE CARTITEMS ******* : ", e));
      const { name, quantity, unitPrice, discountPercent, discountStartsFrom, cgst, cgstPrice, sgst, sgstPrice, igst, igstPrice, tcs, tcsPrice, itemCost } = cartItem;
      console.log("name, quantity, unitPrice, discountPercent : ", cartItem.name, cartItem.quantity, cartItem.unitPrice, cartItem.discountPercent);

      const existsItem = state.cartItems.find(
        (x) => x.name === cartItem.name
      );
      console.log("RESULT OF EXISTS ITEM : ", existsItem);
      if (existsItem) {
        console.log("Items Exists in CART : ", existsItem);
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product === existsItem.name ? cartItem : x
          ),
        };
      } else {
        console.log("Items DOESNT Exists in CART : ");
        return {
          ...state,
          cartItems: [...state.cartItems, cartItem],
        };
      }
    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((x) => x.name !== action.payload),
      };
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };
    case CART_INCREMENT_ITEM_QUANTITY:
      const { item } = action.payload;
      console.log("EXEC CART_INCREMENT_ITEM_QUANTITY", action, action.payload);
      console.log("action  : ", action.type, "payload : ",  action.payload);
      console.log("item : ", item);
      console.log("ITEMS IN CART OF STORE STATE :==>  ", state.cartItems);
      const matchedItem = state.cartItems.find(
        (x) => x.name === item.name
      );
      console.log("RESULT OF MATCHED ITEM : ", matchedItem);
      if (matchedItem) {
        console.log("Items Exists in CART SO MATCHED : ", matchedItem);
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.name === matchedItem.name ? { ...x, quantity: Number(x.quantity) + 1 } : x
          ),
        };
      }
    case CART_DECREMENT_ITEM_QUANTITY:
      console.log("EXEC CART_DECREMENT_ITEM_QUANTITY action.type :  ", action.type, "action.payload : ",  action.payload, "Name : ", action.payload.item.name);
      console.log("ITEMS IN CART OF STORE STATE :==>  ", state.cartItems);
      const fountItem = state.cartItems.find(
        (x) => x.name === action.payload.item.name
      );
      console.log("RESULT OF FIND ITEM : ", fountItem);
      if (fountItem) {
        console.log("Items Exists in CART SO MATCHED : ", fountItem);
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.name === fountItem.name ? { ...x, quantity: Number(x.quantity) > 1 ? Number(x.quantity) - 1 : 1 } : x
          ),
        };
      }
    default:
      return state;
  }
};
