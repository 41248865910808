import {
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_REQUEST,
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_FAIL,
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_RESET,
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_SUCCESS,

  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST,
  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS,
  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL,
  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET,
  
  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST,
  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS,
  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET,
  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL,
 
} from "../constants/vehicleStockConstants";

export const vehicleStockListByVehicleNoReducer = (
  state = { vehicle_stock_by_vehicle_no: [] },
  action
) => {
  switch (action.type) {
    case VEHICLE_STOCK_LIST_BY_VEHICLE_NO_REQUEST:
      return { 
        vehicle_stock_by_vehicle_no_loading: true, vehicle_stock_by_vehicle_no_data:[]  
      };
    case VEHICLE_STOCK_LIST_BY_VEHICLE_NO_SUCCESS:
      return {
        vehicle_stock_by_vehicle_no_loading: false,
        vehicle_stock_by_vehicle_no_success : true,
        vehicle_stock_by_vehicle_no_data: action.payload,
      };
    case VEHICLE_STOCK_LIST_BY_VEHICLE_NO_FAIL:
      return { vehicle_stock_by_vehicle_no_loading: false, 
        vehicle_stock_by_vehicle_no_error: action.payload };
      
    case VEHICLE_STOCK_LIST_BY_VEHICLE_NO_RESET:
      return {};
    default:
      return state;
  }
};

export const vehicleStockCreateByVehicleNoReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST:
      return { vehicle_stock_create_loading: true };
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS:
      return { vehicle_stock_create_loading: false, vehicle_stock_create_success: true, vehicle_stock_create_data: action.payload };
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL:
      return { vehicle_stock_create_loading: false, vehicle_stock_create_error: action.payload };
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET:
      return {};
    default:
      return state;
  }
};

export const vehicleStockOutUpdateByVehicleNoReducer = (state = { vehicle_stock: {} }, action) => {
  switch (action.type) {
    case VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST:
      return { vehicle_stock_out_update_loading: true };
    case VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS:
      return { vehicle_stock_out_update_loading: false, vehicle_stock_out_update_success: true, vehicle_stock_out_update_data: action.payload };
    case VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL:
      return { vehicle_stock_out_update_loading: false, vehicle_stock_out_update_error: action.payload };
    case VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET:
      return {};
    default:
      return state;
  }
};

export const vehicleStockInUpdateByVehicleNoReducer = (state = { vehicle_stock: {} }, action) => {
  switch (action.type) {
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST:
      return { vehicle_stock_in_update_loading: true };
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS:
      return { vehicle_stock_in_update_loading: false, vehicle_stock_in_update_success: true, vehicle_stock_in_update_data: action.payload };
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL:
      return { vehicle_stock_in_update_loading: false, vehicle_stock_in_update_error: action.payload };
    case VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET:
      return { };
    default:
      return state;
  }
};

