import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "./Message";
import { createOrder, generateInvoiceEmail } from "../actions/orderAction";
import { updateProductStockPostPlacingOrder } from "../actions/stockAction";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import {
  Button,
  Paper,
  useMediaQuery,
  Grid,
  Typography,
  Icon,
  Card,
  Tooltip,
  Box,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { Divider } from "@mui/material";
import rupeeSvgIcon from "../assets/images/currency-inr.svg";
import GridContainer from "./Grid/GridContainer";
import GridItem from "./Grid/GridItem";
import CustomBackdropSpinner from "./CustomBackdropSpinner";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import CardHeader from "./Card/CardHeader";
import CardBody from "./Card/CardBody";
import { Section } from "./organisms";
import { adminInformation } from "../data/index";
import {
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_REQUEST,
  UPDATE_STOCK_COUNT_BY_PRODUCT_ID_SUCCESS,
} from "../constants/stockConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imageIcon: {
    height: "1rem",
  },
  iconRoot: {
    textAlign: "center",
  },
}));

const PlaceOrderScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin; // CUSTOMER EMAIL

  const storeInfo =
    localStorage.getItem("storeInfo") !== "undefined"
      ? JSON.parse(localStorage.getItem("storeInfo"))
      : "undefined";
  const storeInfoByZipcode =
    localStorage.getItem("storeInfoByZipcode") !== "undefined"
      ? JSON.parse(localStorage.getItem("storeInfoByZipcode"))
      : "undefined"; // STORE MANAGER EMAIL ID
  console.log(
    "Reading Store Detail from LS storeInfo:",
    storeInfo === "undefined" ? true : false
  );
  console.log(
    "Reading Store Detail from LS by storeInfoByZipcode :",
    storeInfoByZipcode === "undefined" ? true : false
  );
  const { storeId } =
    storeInfo !== "undefined"
      ? storeInfo
      : storeInfoByZipcode !== "undefined"
      ? storeInfoByZipcode
      : "undefined";
  console.log(
    "Reading Store Id from storeInfo || storeInfoByZipcode:",
    storeId
  );

  const adminEmail = adminInformation.adminEmail;
  const customerEmail = userInfo.email;
  const customerName = userInfo.name;
  const storeManagerEmail = storeInfoByZipcode.managerEmail;

  useEffect(() => {
    if (!userInfo) {
      navigate("/signin");
    }
  }, [userInfo]);

  if (!cart.shippingAddress.address) {
    navigate("/shipping");
  } else if (!cart.paymentDetails) {
    navigate("/payment");
  }
  const polupateProductsQuantityOrdered = () => {
    const itemsInCart = cart.cartItems;
    let resultObj = "";
    let productsQuantityInCart = [];
    productsQuantityInCart = itemsInCart.map((item) => {
      let uomArray = item.uom.toString();
      const hasAstrickChar = uomArray.includes("*");
      if (hasAstrickChar) {
        uomArray = uomArray.split("*");
        resultObj = {
          productId: item.product,
          quantityOrdered:
            Number(uomArray[0]) *
            Number(uomArray[1]) *
            Number(item.quantityOrdered),
        };
      } else {
        resultObj = {
          productId: item.product,
          quantityOrdered: Number(uomArray) * Number(item.quantityOrdered),
        };
      }
      console.log("Product and Weight :", resultObj);
      return resultObj;
    });
    console.log("productsQuantityOrderedInCart : ", productsQuantityInCart);
    return productsQuantityInCart;
  };

  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce(
      (acc, item) => acc + item.unitPrice * item.quantityOrdered,
      0
    )
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
  cart.taxPrice = addDecimals(Number((0.05 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    // Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch(
        generateInvoiceEmail(
          order,
          customerName,
          adminEmail,
          storeManagerEmail,
          customerEmail
        )
      ); // COMMENTED FOR TESTING PURPOSE
    }
  }, [success]);

  const placeOrderHandler = () => {
    console.log("EXEC placeOrderHandler ...!");
    console.log(cart);

    const productsQuantityOrderedInCart = polupateProductsQuantityOrdered();
    if (
      productsQuantityOrderedInCart &&
      productsQuantityOrderedInCart.length > 0
    ) {
      dispatch(
        updateProductStockPostPlacingOrder(productsQuantityOrderedInCart)
      );
    }
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentDetails: cart.paymentDetails,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
        store: storeId,
      })
    );
  };
  const submitHandler = (e) => {
    e.preventDefault();
    console.log("Invoking submitHandler from Place Order --> Payment Screen");
    // console.log(paymentDetails);
    // const { mode, amount, bankName, paidTo, chequeNo } = paymentDetails;
    // dispatch(savePaymentDetails({ mode, amount, bankName, paidTo, chequeNo }));
    navigate("/payment");
  };

  return (
    <React.Fragment>
      {order && <CustomBackdropSpinner />}
      <Box sx={{ flexGrow: 1 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ marginTop: "2rem" }}>
          <Tooltip title="Home" arrow>
                <HomeIcon fontSize="medium"   onClick={() => navigate("/")} style={{float:"right"}}/>
            </Tooltip>
          </GridItem>
        </GridContainer>
        {cart.cartItems.length === 0 ? (
          <Message>Your cart is empty</Message>
        ) : (
          ""
        )}
        <Card style={{ marginTop: "0.25rem" }}>
        <h4 className={classes.cardTitleWhite} style={{ marginLeft: "2rem",marginTop:"1rem" }}>Order Review </h4>
          <CardBody>
            <Section className={classes.section}>
              <div
                className={classes.formContainer}
                style={{ marginBottom: "1rem" }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          container
                          justify="flex-start"
                          marginBottom="1rem"
                        >
                          <Typography variant="h5" style={{fontSize:"1.2rem"}}>Shipping Address</Typography>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5}>
                            <Typography variant="body1">Address:</Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography variant="body1">
                              {cart.shippingAddress.address}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={5}>
                          <Typography variant="body1">City:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography variant="body2">
                            {cart.shippingAddress.city}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid container>
                        <Grid item xs={5}>
                          <Typography variant="body1">Postal Code:</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography variant="body1">
                            {cart.shippingAddress.postalCode}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid
                        item
                        xs={12}
                        container
                        justify="flex-start"
                        marginBottom="1rem"
                      >
                        <Typography variant="h5" style={{fontSize:"1.2rem"}}>Order Details</Typography>
                      </Grid>
                      {cart.cartItems.map((item, index) => (
                        <Grid item key={index}>
                          <Grid container>
                            <Grid item xs={3}>
                              <img
                                className="img-thumbnail"
                                src={item.imageUrl}
                                alt={item.name}
                                style={{
                                  height: "3.5rem",
                                  width: "3.5rem",
                                  marginRight: "5rem",
                                }}
                              />
                            </Grid>
                            <Grid item xs={9}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={3}
                                  style={{
                                    justify: "center",
                                    alignContent: "center",
                                    marginRight: "5rem",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <Typography variant="body1">
                                    {item.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography variant="body1">
                                    {item.quantityOrdered} X
                                    <Icon classes={{ root: classes.iconRoot }}>
                                      <img
                                        alt="curency inr"
                                        src={rupeeSvgIcon}
                                        className={classes.imageIcon}
                                      />
                                    </Icon>
                                    {Number(item.unitPrice).toLocaleString(
                                      "en-IN"
                                    )}{" "}
                                    =
                                  </Typography>
                                </Grid>
                                <Grid item xs={3} align="right">
                                  <Typography variant="body1">
                                    <Icon classes={{ root: classes.iconRoot }}>
                                      <img
                                        alt="curency inr"
                                        src={rupeeSvgIcon}
                                        className={classes.imageIcon}
                                      />
                                    </Icon>
                                    {Number(
                                      item.quantityOrdered * item.unitPrice
                                    ).toLocaleString("en-IN")}
                                    {/* {item.quantityOrdered * item.unitPrice} */}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}></Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                      ))}
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Paper className={classes.paper}>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          container
                          justify="flex-start"
                          marginBottom="1rem"
                        >
                          <Typography variant="h5" style={{fontSize:"1.2rem"}} justify="flex-start">
                            Order Summary
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider />
                      <Grid container spacing={1} justify="center">
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                Items Cost
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <Icon classes={{ root: classes.iconRoot }}>
                                  <img
                                    alt="curency inr"
                                    src={rupeeSvgIcon}
                                    className={classes.imageIcon}
                                  />
                                </Icon>
                                {Number(cart.itemsPrice).toLocaleString(
                                  "en-IN"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant="body1">Tax</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <Icon classes={{ root: classes.iconRoot }}>
                                  <img
                                    alt="curency inr"
                                    src={rupeeSvgIcon}
                                    className={classes.imageIcon}
                                  />
                                </Icon>
                                {Number(cart.taxPrice).toLocaleString("en-IN")}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={6}>
                              <Typography variant="body1">Total</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                <Icon classes={{ root: classes.iconRoot }}>
                                  <img
                                    alt="curency inr"
                                    src={rupeeSvgIcon}
                                    className={classes.imageIcon}
                                  />
                                </Icon>
                                {Number(cart.totalPrice).toLocaleString(
                                  "en-IN"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Divider />
                        </Grid>
                       
                        <Grid item xs={12} align="center">
                          <Button
                             size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            fullWidth = {true}
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                            // onClick={placeOrderHandler}
                            onClick={submitHandler}
                            disabled={cart.cartItems === 0}
                            justify="center"
                          >
                            Proceed Payment
                          </Button>
                        </Grid>
                        <Divider />
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </Section>
          </CardBody>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default PlaceOrderScreen;
