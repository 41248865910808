import axios from "axios";
import {
  VERSION_UPDATES_BY_ID_REQUEST,
  VERSION_UPDATES_BY_ID_SUCCESS,
  VERSION_UPDATES_BY_ID_FAIL,
  VERSION_UPDATES_BY_ID_RESET,

  VERSION_UPDATES_CREATE_REQUEST,
  VERSION_UPDATES_CREATE_FAIL,
  VERSION_UPDATES_CREATE_SUCCESS,
  VERSION_UPDATES_CREATE_RESET,

  VERSION_UPDATES_DELETE_BY_ID_REQUEST,
  VERSION_UPDATES_DELETE_BY_ID_SUCCESS,
  VERSION_UPDATES_DELETE_BY_ID_FAIL,
  VERSION_UPDATES_DELETE_BY_ID_RESET,

  VERSION_UPDATES_LIST_REQUEST,
  VERSION_UPDATES_LIST_SUCCESS,
  VERSION_UPDATES_LIST_RESET,
  VERSION_UPDATES_LIST_FAIL,

  VERSION_UPDATES_UPDATE_BY_ID_REQUEST,
  VERSION_UPDATES_UPDATE_BY_ID_RESET,
  VERSION_UPDATES_UPDATE_BY_ID_SUCCESS,
  VERSION_UPDATES_UPDATE_BY_ID_FAIL,
} from "../constants/versionUpdatesConstants";

export const listVersionUpdates = () => async (dispatch) => {
  try {
    dispatch({ type: VERSION_UPDATES_LIST_REQUEST });

    const { data } = await axios.get(`/api/version-updates`);

    dispatch({
      type: VERSION_UPDATES_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VERSION_UPDATES_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listVersionUpdatesById = (id) => async (dispatch) => {
  try {
    dispatch({ type: VERSION_UPDATES_BY_ID_REQUEST });

    const { data } = await axios.get(`/api/version-updates/${id}`);

    dispatch({
      type: VERSION_UPDATES_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VERSION_UPDATES_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteVersionUpdatesById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VERSION_UPDATES_DELETE_BY_ID_REQUEST,
    });

    await axios.delete(`/api/version-updates/${id}`);

    dispatch({
      type: VERSION_UPDATES_DELETE_BY_ID_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: VERSION_UPDATES_DELETE_BY_ID_FAIL,
      payload: message,
    });
  }
};

export const createVersionUpdates =
  ( versionDescription ) => async (dispatch, getState) => {

    console.log(
      "createBulkByProductId ..", versionDescription);
    try {
      dispatch({
        type: VERSION_UPDATES_CREATE_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `/api/version-updates`,
        { versionDescription },
        config
      );

      dispatch({
        type: VERSION_UPDATES_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: VERSION_UPDATES_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const updateVersionUpdatesById =
  (isRead, versionDescription, versionUpdates, versionUpdatedDate) => async (dispatch, getState) => {
    console.log( "Inside updateBulkByProductId " , id,isRead, versionDescription, versionUpdates, versionUpdatedDate);
    try {
      dispatch({
        type: VERSION_UPDATES_UPDATE_BY_ID_REQUEST,
      });

      const { data } = await axios.put(`/api/version-updates/${id}`, {isRead, versionDescription, versionUpdates, versionUpdatedDate });

      dispatch({
        type: VERSION_UPDATES_UPDATE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
      }
      dispatch({
        type: VERSION_UPDATES_UPDATE_BY_ID_FAIL,
        payload: message,
      });
    }
  };
