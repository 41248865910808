import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../components/Grid/GridItem.js";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import GridContainer from "../../components/Grid/GridContainer.js";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Typography,
  Tooltip,
} from "@mui/material";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { listAllOrders } from "../../actions/orderAction";
import { listAllStores } from "../../actions/storeAction";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import { transformToDDMMYYY } from "../../util/Utility";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "50%",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const OrderTransferScreen = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();
  const showOrderDetails = (id) => {
    navigate(`/admin/order-details/${id}`);
  };
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const filterParams = {
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      console.log(
        "filterLocalDateAtMidnight",
        filterLocalDateAtMidnight,
        "cellValue",
        cellValue
      );
      let dateAsString = cellValue;
      if (dateAsString == null) return -1;
      let dateParts = dateAsString.split("-");
      let cellDate = new Date(
        Number(dateParts[0]),
        Number(dateParts[1]),
        Number(dateParts[2])
      );

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    browserDatePicker: true,
    minValidYear: 2000,
  };
  const columnDefs = [
    { field: "#" },
    { field: "Store Name" },
    { field: "Customer Name" },
    { field: "Total Price" },
    {
      field: "Order Date",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      field: "Order Id",
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Order Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showOrderDetails(params.data.Id)}
              // onClick={() => console.log(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      resizable: true,
      editable: true,
    }),
    []
  );
  let rowData = [];
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const export_label = "Export > CSV";
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listAllStores());
    dispatch(listAllOrders());
  }, [dispatch]);

  const orderList = useSelector((state) => state.orderList);
  const { loading, error_loading_orders, orders } = orderList;

  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;

  const handleOrderItemDetails = (id) => {
    console.log("Clicked handleOrderItemDetails " + id);
    navigate(`/admin/order-details/${id}`);
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  const findStoreById = (storeId) => {
    let storeObj = null;
    let storeName = null;
    console.log("Searching for Store Name by ID : ", storeId);
    // stores.map((e) => console.log("#### ", e, "####"));
    if (stores) {
      storeObj = stores.filter((eachStore) => eachStore._id === storeId);
      if (storeObj && storeObj.length > 0) {
        storeName = storeObj[0].storeName ? storeObj[0].storeName : "";
        console.log("####### Store Name : ", storeName, " #######");
      }
      console.log("####### Unable to find by Store Id  #######", storeId);
    }
    return storeName;
  };

  let renderOrdersContent = "";
  if (orders && orders.length > 0) {
    rowData = orders.map((eachOrder, index) => {
      console.log("eachOrder  : ", eachOrder, eachOrder.user);
      console.log("Store Name Returned  : ", findStoreById(eachOrder.store));
      return {
        "#": index + 1,
        "Store Name": findStoreById(eachOrder.store),
        "Customer Name": eachOrder.user
          ? eachOrder.user.name
            ? eachOrder.user.name
            : ""
          : "",
        "Total Price": eachOrder.totalPrice,
        "Order Date": eachOrder.createdAt
          ? new Date(eachOrder.createdAt).toLocaleString()
          : "",
        Id: eachOrder._id,
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
      </div>
    );
  }
  return (
    <React.Fragment>
      {loading && <Message variant="danger">{error_loading_orders}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography variant="h5" style={{ fontWeight: 500 }}>
                  Order Transfer
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "0.5rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default OrderTransferScreen;
