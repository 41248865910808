import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ListIcon from "@mui/icons-material/List";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ViewModuleIcon from "@mui/icons-material/ViewModule";

const dashboardRoutes = [
  {
    path: "/admin/users",
    name: "Users",
    icon: PeopleAltIcon,
    imageUrl:
      "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Users_ver_2.png",
  },
  {
    path: "/admin/customers",
    name: "Customers",
    icon: PeopleAltIcon,
    imageUrl:
      "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Users_ver_2.png",
  },
  {
    path: "/admin/suppliers",
    name: "Suppliers",
    icon: PeopleAltIcon,
    imageUrl:
      "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Users_ver_2.png",
  },
  {
    path: "/admin/categories",
    name: "Categories",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Categories_ver_2.png",
  },
  {
    path: "/admin/subcategories",
    name: "Sub-Categories",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Sub-Category_ver_2.png",
  },
  {
    path: "/admin/products",
    name: "Products",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Products_ver_2.png",
  },

  // {
  //   path: "/admin/orders",
  //   name: "Orders",
  //   icon: ListIcon,
  //   imageUrl:
  //   "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Orders_ver_2.png",
  // },
  {
    path: "/admin/store-orders",
    name: "Store-Orders",
    icon: ListIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Stores_ver_2.png",
  },
  {
    path: "/admin/store",
    name: "Stores",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Stores_ver_2.png",
  },
  {
    path: "/admin/purchase-orders",
    name: "Purchase Orders",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Purchase+Orders_ver_2.png",
  },
  {
    path: "/admin/wh-info/list",
    name: "Warehouse Info",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/admin/stock-transfer/list",
    name: "Stock Transfers",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_to_store_inv_ver_2.png",
  },
  {
    path: "/admin/vehicle/list",
    name: "Vehicles",
    icon: AssessmentIcon,
    imageUrl:"https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Vehicles_ver_2.png",
  },
  {
    path: "/admin/wh-store-veh-stock/list",
    name: "Track-Stocks",
    icon: AssessmentIcon,
    imageUrl:"https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Track_Stock_ver_2.png",
  },
  {
    path: "/admin/vehicle-sales-invoice/list",
    name: "Vehicle Sales",
    icon: AssessmentIcon,
    imageUrl:"https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Vehicle+Invoice_ver_2.png",
  },
  {
    path: "/admin/online-orders",
    name: "Online Orders",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Payment-Receipts+Icon.png",
  },
  {
    path: "/admin/walkin-sales-invoice/list",
    name: "Walkin Sales",
    icon: AssessmentIcon,
    imageUrl:"https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Walkin_sales_ver_2.png",
  },
  {
    path: "/admin/vehicle-status/list",
    name: "Vehicle Status",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Vehicle+Status_ver_2.png",
  },
  {
    path: "/admin/vehicle-sales-stock/new",
    name: "Vehicle Sales Stock",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Vechle+Sales+Stock_ver_2.png",
  },
  {
    path: "/admin/transit-stock/list",
    name: "Transit Stock List",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Transit+Stock_ver_2.png",
  },
  {
    path: "/admin/new-stock-notifications-list",
    name: "Stock Arrival",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Stock+Arrival_ver_2.png",
  },
  {
    path: "/admin/payments-receipts",
    name: "Payments / Receipts",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Payment-Receipts+Icon.png",
  },
  {
    path: "/admin/analytics",
    name: "Reports",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Reports_Icon.png",
  },
  {
    path: "/admin/analytics/walkin/sales",
    name: "Walkin Sales Reports",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Reports_Icon.png",
  },
  {
    path: "/admin/analytics/walkin/sales/vyapar",
    name: "Walkin Sales Vyapar",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/VYAPAR+REPORTS.png",
  },
  {
    path: "/admin/analytics/walkin/item-sales/vyapar",
    name: "Item wise Sale Vyapar",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/VYAPAR+REPORTS.png",
  },
  {
    path: "/admin/version-updates",
    name: "Version Updates",
    icon: ViewModuleIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/SOFTWARE+UPDATES.png",
  },
  {
    path: "/ctc/tracking/",
    name: "CTC Tracking",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/ctc/cold-storage",
    name: "Cold Storage",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/ctc/factory",
    name: "Factory",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/ctc/variety",
    name: "Variety",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/ctc/bonds",
    name: "CTC Bonds",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/ctc/receipts",
    name: "CTC Receipts",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/ctc/purchase-orders",
    name: "CTC Purchase Orders",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
  {
    path: "/ctc/production",
    name: "CTC Production",
    icon: AssessmentIcon,
    imageUrl:
    "https://taglineimages.s3.amazonaws.com/dashboard_images/version2_MIS_DB_IMAGES/Warehouse_ver-2.png",
  },
];

export default dashboardRoutes;