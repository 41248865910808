import {

TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST,
TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET,
TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST,
TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET,
TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,
TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST,
TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS,
TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET,
TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL,

TRANSIT_STOCK_LIST_BY_VEHICLE_NO_REQUEST,
TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SUCCESS,
TRANSIT_STOCK_LIST_BY_VEHICLE_NO_FAIL,
TRANSIT_STOCK_DETAILS_BY_ID_REQUEST,
TRANSIT_STOCK_DETAILS_BY_ID_SUCCESS,
TRANSIT_STOCK_DETAILS_BY_ID_FAIL,
TRANSIT_STOCK_DETAILS_BY_ID_RESET,
TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_REQUEST,
TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_SUCCESS,
TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_FAIL,
TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_REQUEST,
TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_SUCCESS,
TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_FAIL,
TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_RESET

} from "../constants/transitStockConstants";

export const transitStockListByVehicleNoReducer = (
  state = { transit_stock_by_vehicle_no: [] },
  action
) => {
  switch (action.type) {
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_REQUEST:
      return { transit_stock_by_vehicle_no_loading: true, transit_stock_by_vehicle_no:[]  };
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SUCCESS:
      return {
        transit_stock_by_vehicle_no_loading: false,
        transit_stock_by_vehicle_no: action.payload,
      };
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_FAIL:
      return { transit_stock_by_vehicle_no_loading: false, transit_stock_by_vehicle_no_error: action.payload };
    default:
      return state;
  }
};

export const transitStockDetailsByIdReducer = (
  state = { transit_stock_by_id: {} },
  action
) => {
  switch (action.type) {
    case TRANSIT_STOCK_DETAILS_BY_ID_REQUEST:
      return { transit_stock_by_id_loading: true, transit_stock_by_id:{}  };
    case TRANSIT_STOCK_DETAILS_BY_ID_SUCCESS:
      return {
        transit_stock_by_id_loading: false,
        transit_stock_by_id: action.payload,
      };
    case TRANSIT_STOCK_DETAILS_BY_ID_FAIL:
      return { transit_stock_by_id_loading: false, 
        transit_stock_by_id_error: action.payload };
    case TRANSIT_STOCK_DETAILS_BY_ID_RESET:
      return {};
    default:
      return state;
  }
};

export const transitStockOutUpdateByVehicleNoReducer = (state = { transit_stock: {} }, action) => {
  switch (action.type) {
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST:
      return { transit_stock_out_update_loading: true };
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS:
      return { transit_stock_out_update_loading: false, success: true, transit_stock_out_update_success: action.payload };
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL:
      return { transit_stock_out_update_loading: false, transit_stock_out_update_error: action.payload };
    case TRANSIT_STOCK_OUT_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET:
      return { transit_stock: {} };
    default:
      return state;
  }
};

export const transitStockInUpdateByVehicleNoReducer = (state = { transit_stock: {} }, action) => {
  switch (action.type) {
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST:
      return { transit_stock_in_loading: true };
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS:
      return { transit_stock_in_loading: false, transit_stock_in_success: true, transit_stock_in_data: action.payload };
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL:
      return { transit_stock_in_loading: false, transit_stock_in_error: action.payload };
    case TRANSIT_STOCK_IN_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET:
      return {};
    default:
      return state;
  }
};

export const transitStockConfirmStatusUpdateByVehicleNoReducer = (state = { transit_stock_confirm_status: {} }, action) => {
  switch (action.type) {
    case TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_REQUEST:
      return { transit_stock_confirm_status_loading: true };
    case TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_SUCCESS:
      return { transit_stock_confirm_status_loading: false, transit_stock_confirm_status_success: true, transit_stock_confirm_status_data: action.payload };
    case TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_FAIL:
      return { transit_stock_confirm_status_loading: false, transit_stock_confirm_status_error: action.payload };
    case TRANSIT_STOCK_CONFIRM_STATUS_UPDATE_BY_VEHICLE_NO_SOURCE_DESTINATION_PRODUCT_RESET:
      return {};
    default:
      return state;
  }
};

export const transitStockAwaitingConfirmationStatusUpdateByVehicleNoReducer = (state = { transit_stock_confirm_status: {} }, action) => {
  switch (action.type) {
    case TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_REQUEST:
      return { transit_stock_awaiting_confirmation_status_loading: true };
    case TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_SUCCESS:
      return { transit_stock_awaiting_confirmation_status_loading: false, transit_stock_awaiting_confirmation_status_success: true, transit_stock_awaiting_confirmation_status_data: action.payload };
    case TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_FAIL:
      return { transit_stock_awaiting_confirmation_status_loading: false, transit_stock_awaiting_confirmation_status_error: action.payload };
    case TRANSIT_STOCK_STATUS_UPDATE_AWAITING_CONFIRMATION_RESET:
      return {};
    default:
      return state;
  }
};

export const transitStockListByVehicleNoSourceDestinationReducer = (
  state = { transit_stock_by_vehicle_no_source_dest: [] },
  action
) => {
  switch (action.type) {
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_REQUEST:
      return { transit_stock_by_vehicle_no_source_dest_loading: true, transit_stock_by_vehicle_no_source_dest:[]  };
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_SUCCESS:
      return {
        transit_stock_by_vehicle_no_source_dest_loading: false,
        transit_stock_by_vehicle_no_source_dest: action.payload,
      };
    case TRANSIT_STOCK_LIST_BY_VEHICLE_NO_SOURCE_DESTINATION_FAIL:
      return { transit_stock_by_vehicle_no_source_dest_loading: false, transit_stock_by_vehicle_no_source_dest_error: action.payload };
    default:
      return state;
  }
};

