import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listSuppliers, deleteSupplier, updateSupplier, listSuppliersByParentUser } from "../../actions/supplierAction";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button, Divider, Grid, TextField  } from "@mui/material";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";


const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const SupplierListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const pos = storeName ? storeName : warehouseName ? warehouseName : ''

  let renderContentUsingAGGrid = "";
  const gridRef = useRef();

  const [open, setOpen] = useState(() => {
    return false;
  });
  const [editableRecord, setEditableRecord] = useState(() => {
    return {};
  });

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const handleChange = (event) => {
    event.persist();

    setEditableRecord((prev) => ({
      ...prev,
      [event.target.name] : event.target.value
    }));
  };

  const handleEdit = (params) => {
    console.log("ID SELECTED : ", params);
    console.log("params", params.data.Name, params.data);
    setOpen(true);
    setEditableRecord({
      _id: params.data.Edit,
      name: params.data.name,
      contactNo: params.data.contactNo,
      email: params.data.email,
      address: params.data.address,
    });
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    console.log(editableRecord);
    dispatch(
      updateSupplier(
        editableRecord._id,
        editableRecord.name,
        editableRecord.contactNo,
        editableRecord.email,
        editableRecord.address,
      )
    );
    setOpen(false);
    setEditableRecord({});
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,

    },
    {
      field: "Name",
      headerName: "Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "ContactNo",
      headerName: "Contact No",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      filter: true,
    },
    {
      field: "Email",
      headerName: "Email",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
    },
    {
      field: "Address",
      headerName: "Address",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "Id",
      headerName: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              disabled = {true}
              // onClick={() => console.log("params", params.data)}
              // onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Id",
      headerName: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Delete" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              disabled = {true}
              onClick={() => console.log("params", params.data)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const supplierListByParentUser = useSelector((state) => state.supplierListByParentUser);
  const { supplier_list_by_parent_user_loading, supplier_list_by_parent_user_error, supplier_list_by_parent_user } = supplierListByParentUser;

  const supplierDeleted = useSelector((state) => state.supplierDeleted);
  const { supplier_delete_success, supplier_delete_error } = supplierDeleted;

  useEffect(() => {
    console.log(" Loading Suppliers List By Mapped User ");
    if(name && name.length>0)
      dispatch(listSuppliersByParentUser(name));
  }, [dispatch, name]);

  const deleteHandler = (id) => {
    dispatch(deleteUser(id));
  };

  const handleAddSupplier = (e) => {
    e.preventDefault();
    navigate("/admin/supplier/signup");
  };

  const nameChangeHandler = ()=>{
    set
  }
  /**
   * Start AG Grid
   */
  if (supplier_list_by_parent_user&& supplier_list_by_parent_user.length > 0) {
    rowData = supplier_list_by_parent_user.map((supplier, index) => {
      console.log(supplier);
      return {
        "#": index + 1,
        Name: supplier.name,
        Email: supplier.email,
        ContactNo: supplier.contactNo,
        Address: supplier.address,
        Edit: supplier._id,
        Delete: supplier._id,
      };
    })
    renderContentUsingAGGrid = (
      <>
        <div className="ag-theme-quartz" style={{ width: "100%", height: '65vh' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  }
  /**
   * End AG Grid
   */
  // {role &&
  //   role === "ADMIN_ROLE"}

  return (
    <React.Fragment>
      {supplier_list_by_parent_user_error && <Message variant="danger">{supplier_list_by_parent_user_error}</Message>}
      {supplier_list_by_parent_user_loading && <CustomBackdropSpinner />}
      <GridContainer>
      {role && role === "ADMIN_ROLE" && ( <GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
          <Tooltip title="Create Supplier" arrow>
            <AddCircleIcon
              fontSize="medium"
              style={{ float: "left" }}
              onClick={handleAddSupplier}
            />
          </Tooltip>
        </GridItem>)}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{
                  fontWeight: 500,
                  textAlign: "Center"
                }}>
                  <b>Suppliers</b>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
          {rowData && rowData.length > 0 && (<Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon fontSize="medium" style={{ float: "right", margin: "1rem" }} onClick={onExportClick} />
          </Tooltip>)}
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >

                    </Typography>
                    <h2 align="center"> Edit Supplier Details </h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={submitHandler}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: "1.5rem",
                          marginBottom: "1.5rem",
                        }}
                      >
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Name"
                            variant="outlined"
                            name="name"
                            onChange={(e) =>
                              handleChange(e)
                            }
                            type="text"
                            size="small"
                            value={
                              editableRecord &&
                                editableRecord.name
                                ? editableRecord.name
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Contact No"
                            variant="outlined"
                            name="contactNo"
                            // onChange={(e) => contactNoChangeHandler(e.target.value)}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            size="small"
                            value={
                              editableRecord && editableRecord.contactNo
                                ? editableRecord.contactNo
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="E-mail"
                            variant="outlined"
                            name="email"
                            id="email"
                            onChange={(e) => handleChange(e)}
                            type="number"
                            size="small"
                            value={
                              editableRecord &&
                                editableRecord.email
                                ? editableRecord.email
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Address"
                            variant="outlined"
                            name="address"
                            id="address"
                            onChange={(e) => handleChange(e)}
                            type="text"
                            size="small"
                            value={
                              editableRecord &&
                                editableRecord.address
                                ? editableRecord.address
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          style={{
                            textTransform: "capitalize",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "auto",
                            marginTop: "1.5rem",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default SupplierListPage;
