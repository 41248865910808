import {
  PURCHASE_ORDER_LIST_REQUEST,
  PURCHASE_ORDER_LIST_SUCCESS,
  PURCHASE_ORDER_LIST_FAIL,
  
  PURCHASE_ORDER_BY_ID_REQUEST,
  PURCHASE_ORDER_BY_ID_SUCCESS,
  PURCHASE_ORDER_BY_ID_FAIL,

  PURCHASE_ORDER_DELETE_BY_ID_REQUEST,
  PURCHASE_ORDER_DELETE_BY_ID_SUCCESS,
  PURCHASE_ORDER_DELETE_BY_ID_FAIL,

  PURCHASE_ORDER_CREATE_RESET,
  PURCHASE_ORDER_CREATE_FAIL,
  PURCHASE_ORDER_CREATE_SUCCESS,
  PURCHASE_ORDER_CREATE_REQUEST,

  PURCHASE_ORDER_UPDATE_BY_ID_REQUEST,
  PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS,
  PURCHASE_ORDER_UPDATE_BY_ID_FAIL,
  PURCHASE_ORDER_UPDATE_BY_ID_RESET,
} from "../constants/purchaseOrderConstants";

export const purchaseOrdersListReducer = (state = { purchaseOrders: [] }, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_LIST_REQUEST:
      return { loading: true, purchaseOrders: [] };
    case PURCHASE_ORDER_LIST_SUCCESS:
      return {
        loading: false,
        purchaseOrders: action.payload,
      };
    case PURCHASE_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const purchaseOrderByIdReducer = (
  state = { po_data: {} },
  action
) => {
  switch (action.type) {
    case PURCHASE_ORDER_BY_ID_REQUEST:
      return { po_loading: true, po_data:{}  };
    case PURCHASE_ORDER_BY_ID_SUCCESS:
      return {
        po_loading: false,
        po_data: action.payload,
      };
    case PURCHASE_ORDER_BY_ID_FAIL:
      return { po_loading: false, po_error: action.payload };
    default:
      return state;
  }
};

export const purchaseOrderDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_DELETE_BY_ID_REQUEST:
      return { loading: true };
    case PURCHASE_ORDER_DELETE_BY_ID_SUCCESS:
      return { loading: false, success: true };
    case PURCHASE_ORDER_DELETE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const purchaseOrderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_CREATE_REQUEST:
      return { po_create_loading: true };
    case PURCHASE_ORDER_CREATE_SUCCESS:
      return { po_create_loading: false, po_create_success: true, po_create_data: action.payload };
    case PURCHASE_ORDER_CREATE_FAIL:
      return { po_create_loading: false, po_create_error: action.payload };
    case PURCHASE_ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const purchaseOrderUpdateByIdReducer = (state = { purchaseOrder: {} }, action) => {
  switch (action.type) {
    case PURCHASE_ORDER_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, purchaseOrder: action.payload };
    case PURCHASE_ORDER_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case PURCHASE_ORDER_UPDATE_BY_ID_RESET:
      return { purchaseOrder: {} };
    default:
      return state;
  }
};

