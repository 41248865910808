import {
  CUSTOMER_DETAILS_FAIL,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_DETAILS_RESET,

  CUSTOMER_REGISTER_FAIL,
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_RESET,
 
  CUSTOMER_LIST_FAIL,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_RESET,

  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_DELETE_RESET,

  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_RESET,

  CUSTOMER_LIST_BY_POS_NAME_REQUEST,
  CUSTOMER_LIST_BY_POS_NAME_SUCCESS,
  CUSTOMER_LIST_BY_POS_NAME_FAIL,
  CUSTOMER_LIST_BY_POS_NAME_RESET,

  CUSTOMER_LIST_BY_PARENT_USER_REQUEST,
  CUSTOMER_LIST_BY_PARENT_USER_RESET,
  CUSTOMER_LIST_BY_PARENT_USER_FAIL,
  CUSTOMER_LIST_BY_PARENT_USER_NAME_RESET,
  CUSTOMER_LIST_BY_PARENT_USER_SUCCESS,
} from "../constants/customerConstants";


export const customerRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_REGISTER_REQUEST:
      return { customer_register_loading: true };
    case CUSTOMER_REGISTER_SUCCESS:
      return { customer_register_loading: false,customer_register_success: true, customer_register: action.payload };
    case CUSTOMER_REGISTER_FAIL:
      return { customer_register_loading: false, customer_register_error: action.payload };
    case CUSTOMER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const customerDetailsReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case CUSTOMER_DETAILS_REQUEST:
      return { ...state, customer_details_loading: true };
    case CUSTOMER_DETAILS_SUCCESS:
      return { customer_details_loading: false, customer_details: action.payload };
    case CUSTOMER_DETAILS_FAIL:
      return { customer_details_loading: false, customer_details_error: action.payload };
    case CUSTOMER_DETAILS_RESET:
      return { customer_details: {} };
    default:
      return state;
  }
};

export const customerListReducer = (state = { customers_list: [] }, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_REQUEST:
      return { customers_list_loading: true };
    case CUSTOMER_LIST_SUCCESS:
      return { customers_list_loading: false, customers_list: action.payload };
    case CUSTOMER_LIST_FAIL:
      return { customers_list_loading: false, customers_list_error: action.payload };
    case CUSTOMER_LIST_RESET:
      return { customers_list: [] };
    default:
      return state;
  }
};

export const customerListByPosReducer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_BY_POS_NAME_REQUEST:
      return { customer_list_pos_loading: true };
    case CUSTOMER_LIST_BY_POS_NAME_SUCCESS:
      return { customer_list_pos_loading: false, customer_list_role: action.payload };
    case CUSTOMER_LIST_BY_POS_NAME_FAIL:
      return { customer_list_pos_loading: false, error: action.payload };
    case CUSTOMER_LIST_BY_POS_NAME_RESET:
      return { customer_list_role: [] };
    default:
      return state;
  }
};

export const customerListByParentUserReducer = (state = { customers: [] }, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_BY_PARENT_USER_REQUEST:
      return { customer_list_parent_user_loading: true };
    case CUSTOMER_LIST_BY_PARENT_USER_SUCCESS:
      return { customer_list_parent_user_loading: false, customer_list_parent_user: action.payload , customer_list_parent_user_success: true};
    case CUSTOMER_LIST_BY_PARENT_USER_FAIL:
      return { customer_list_parent_user_loading: false, customer_list_parent_user_error: action.payload };
    case CUSTOMER_LIST_BY_PARENT_USER_RESET:
      return { customer_list_parent_user: [] };
    default:
      return state;
  }
};

export const customerDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOMER_DELETE_REQUEST:
      return { customer_delete_loading: true };
    case CUSTOMER_DELETE_SUCCESS:
      return { customer_delete_loading: false, customer_delete_success: true };
    case CUSTOMER_DELETE_FAIL:
      return { customer_delete_loading: false, customer_delete_error: action.payload };
    default:
      return state;
  }
};

export const customerUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case CUSTOMER_UPDATE_REQUEST:
      return { customer_update_loading: true };
    case CUSTOMER_UPDATE_SUCCESS:
      return { customer_update_loading: false, customer_update_success: true };
    case CUSTOMER_UPDATE_FAIL:
      return { customer_update_loading: false, customer_update_error: action.payload };
    case CUSTOMER_UPDATE_RESET:
      return {
        customer: {},
      };
    default:
      return state;
  }
};
