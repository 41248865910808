export const WAREHOUSE_STORE_INVOICE_LIST_REQUEST = "WAREHOUSE_STORE_INVOICE_LIST_REQUEST";
export const WAREHOUSE_STORE_INVOICE_LIST_SUCCESS = "WAREHOUSE_STORE_INVOICE_LIST_SUCCESS";
export const WAREHOUSE_STORE_INVOICE_LIST_FAIL = "WAREHOUSE_STORE_INVOICE_LIST_FAIL";

export const WAREHOUSE_STORE_INVOICE_BY_ID_REQUEST =
  "WAREHOUSE_STORE_INVOICE_BY_ID_REQUEST";
export const WAREHOUSE_STORE_INVOICE_BY_ID_SUCCESS =
  "WAREHOUSE_STORE_INVOICE_BY_ID_SUCCESS";
export const WAREHOUSE_STORE_INVOICE_BY_ID_FAIL =
  "WAREHOUSE_STORE_INVOICE_BY_ID_FAIL";

  export const WALKIN_SALE_INVOICE_BY_ID_REQUEST =
  "WALKIN_SALE_INVOICE_BY_ID_REQUEST";
export const WALKIN_SALE_INVOICE_BY_ID_SUCCESS =
  "WALKIN_SALE_INVOICE_BY_ID_SUCCESS";
export const WALKIN_SALE_INVOICE_BY_ID_FAIL =
  "WALKIN_SALE_INVOICE_BY_ID_FAIL";

export const WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_REQUEST = "WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_REQUEST";
export const WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_SUCCESS = "WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_SUCCESS";
export const WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_FAIL = "WAREHOUSE_STORE_INVOICE_DELETE_BY_ID_FAIL";

export const WAREHOUSE_STORE_INVOICE_CREATE_REQUEST = "WAREHOUSE_STORE_INVOICE_CREATE_REQUEST";
export const WAREHOUSE_STORE_INVOICE_CREATE_SUCCESS = "WAREHOUSE_STORE_INVOICE_CREATE_SUCCESS";
export const WAREHOUSE_STORE_INVOICE_CREATE_FAIL = "WAREHOUSE_STORE_INVOICE_CREATE_FAIL";
export const WAREHOUSE_STORE_INVOICE_CREATE_RESET = "WAREHOUSE_STORE_INVOICE_CREATE_RESET";

export const WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_REQUEST = "WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_REQUEST";
export const WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_SUCCESS = "WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_SUCCESS";
export const WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_FAIL = "WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_FAIL";
export const WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_RESET = "WAREHOUSE_STORE_INVOICE_UPDATE_BY_ID_RESET";

// export const WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_REQUEST = "WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_REQUEST";
// export const WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_SUCCESS = "WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_SUCCESS";
// export const WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_FAIL = "WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_FAIL";
// export const WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_RESET = "WALKIN_SALE_INVOICE_BY_WAREHOUSE_STORE_NAME_RESET";