import React, { useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listVersionUpdates } from "../../actions/versionUpdatesAction";
import { useNavigate } from "react-router-dom";
import { Typography,Tooltip,Button } from "@mui/material";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { ReadMoreOutlined } from "@mui/icons-material";

const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const VersionUpdatesListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const columnDefs = [
    { field: "#" ,
    resizable: true, 
    suppressSizeToFit: true,
    width: 75, 
    minWidth: 75, 
    maxWidth: 75,
    
  },
    { field: "VersionDescription",
    headerName: "Version Description",
    resizable: true, 
    suppressSizeToFit: true, 
    width: 850,
    minWidth: 850,
    maxWidth: 850,
    filter: true,
   },
   { field: "VersionUpdatedDate",
    headerName: "Release Date",
    resizable: true, 
    suppressSizeToFit: true,
    width: 300, 
    minWidth: 300, 
    maxWidth: 300, 
    filter: true,
  },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const versionUpdatesList = useSelector((state) => state.versionUpdatesList);
  const { version_updates_list_loading, version_updates_list_error, version_updates_list } = versionUpdatesList;
 
  useEffect(() => {
    console.log(" Loading Version Updates List  ");
    dispatch(listVersionUpdates());
  }, [dispatch]);

  const handleNewVersionUpdate = (e) => {
    e.preventDefault();
    navigate("/admin/version-updates/create");
  };


  /**
   * Start AG Grid
   */
  if (version_updates_list && version_updates_list.length > 0) {
    rowData = version_updates_list.map((version_update, index) => {
      console.log(version_update);
      return {
        "#": index + 1,
        VersionDescription: version_update.versionDescription,
        VersionUpdatedDate: version_update.versionUpdatedDate,
      };
    })
    renderContentUsingAGGrid = (
      <>
        <div className="ag-theme-quartz" style={{ width: "100%", height: '65vh' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  }
  /**
   * End AG Grid
   */
  return (
    <React.Fragment>
      {version_updates_list_error && <Message variant="danger">{version_updates_list_error}</Message>}
      {version_updates_list_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
          <Tooltip title="New Version Update" arrow>
            <AddCircleIcon
              fontSize="medium"
              style={{ float: "left" }}
              onClick={handleNewVersionUpdate}
            />
          </Tooltip> 
        </GridItem>
        <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <Typography variant="h5" style={{ fontWeight: 500, 
                  textAlign: "Center"}}>
                     <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em", textAlign:"center" }}
                    />
                  </Tooltip>
                 <b>Software Version Updates</b>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default VersionUpdatesListPage;
