import {
  ORDER_RECEIPT_CREATE_BY_STORE_REQUEST,
  ORDER_RECEIPT_CREATE_BY_STORE_SUCCESS,
  ORDER_RECEIPT_CREATE_BY_STORE_FAIL,
  ORDER_RECEIPT_CREATE_BY_STORE_RESET,
  ORDER_RECEIPT_LIST_BY_STORE_REQUEST,
  ORDER_RECEIPT_LIST_BY_STORE_SUCCESS,
  ORDER_RECEIPT_LIST_BY_STORE_FAIL,
  ORDER_RECEIPT_LIST_BY_STORE_RESET,
  ORDER_RECEIPT_DETAILS_BY_ID_REQUEST,
  ORDER_RECEIPT_DETAILS_BY_ID_SUCCESS,
  ORDER_RECEIPT_DETAILS_BY_ID_FAIL,
  ORDER_RECEIPT_DETAILS_BY_ID_RESET
  } from "../constants/orderReceiptConstants";

export const orderReceiptsListByStoreReducer = (state = { }, action) => {
  switch (action.type) {
    case ORDER_RECEIPT_LIST_BY_STORE_REQUEST:
      return { order_receipts_by_store_list_loading: true, order_receipts_by_store_list: [] };
    case ORDER_RECEIPT_LIST_BY_STORE_SUCCESS:
      return {
        order_receipts_by_store_list_loading: false,
        order_receipts_by_store_list: action.payload,
      };
    case ORDER_RECEIPT_LIST_BY_STORE_FAIL:
      return { order_receipts_by_store_list_loading: false, order_receipts_by_store_list_error: action.payload };
    default:
      return state;
  }
};

export const orderReceiptByIdReducer = (
  state = {},
  action
) => {
  switch (action.type) {
    case ORDER_RECEIPT_DETAILS_BY_ID_REQUEST:
      return { order_receipts_by_id_loading: true, order_receipts_by_id:{}  };
    case ORDER_RECEIPT_DETAILS_BY_ID_SUCCESS:
      return {
        order_receipts_by_id_loading: false,
        order_receipts_by_id : action.payload,
      };
    case ORDER_RECEIPT_DETAILS_BY_ID_FAIL:
      return { order_receipts_by_id_loading : false, order_receipts_by_id_error: action.payload };
    default:
      return state;
  }
};

export const orderReceiptCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_RECEIPT_CREATE_BY_STORE_REQUEST:
      return { order_receipt_create_by_store_loading: true };
    case ORDER_RECEIPT_CREATE_BY_STORE_SUCCESS:
      return { order_receipt_create_by_store_loading: false, order_receipt_create_by_store_success: true, order_receipt_create_by_store : action.payload };
    case ORDER_RECEIPT_CREATE_BY_STORE_FAIL:
      return { order_receipt_create_by_store_loading: false, order_receipt_create_by_store_error: action.payload };
    case ORDER_RECEIPT_CREATE_BY_STORE_RESET:
      return {};
    default:
      return state;
  }
};



