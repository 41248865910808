export const VERSION_UPDATES_LIST_REQUEST = "VERSION_UPDATES_LIST_REQUEST";
export const VERSION_UPDATES_LIST_SUCCESS = "VERSION_UPDATES_LIST_SUCCESS";
export const VERSION_UPDATES_LIST_FAIL = "VERSION_UPDATES_LIST_FAIL";
export const VERSION_UPDATES_LIST_RESET = "VERSION_UPDATES_LIST_RESET";

export const VERSION_UPDATES_CREATE_REQUEST = "VERSION_UPDATES_CREATE_REQUEST";
export const VERSION_UPDATES_CREATE_SUCCESS = "VERSION_UPDATES_CREATE_SUCCESS";
export const VERSION_UPDATES_CREATE_FAIL = "VERSION_UPDATES_CREATE_FAIL";
export const VERSION_UPDATES_CREATE_RESET = "VERSION_UPDATES_CREATE_RESET";

export const VERSION_UPDATES_BY_ID_REQUEST = "VERSION_UPDATES_BY_ID_REQUEST";
export const VERSION_UPDATES_BY_ID_SUCCESS = "VERSION_UPDATES_BY_ID_SUCCESS";
export const VERSION_UPDATES_BY_ID_FAIL = "VERSION_UPDATES_BY_ID_FAIL";
export const VERSION_UPDATES_BY_ID_RESET = "VERSION_UPDATES_BY_ID_RESET";

export const VERSION_UPDATES_UPDATE_BY_ID_REQUEST =
  "VERSION_UPDATES_UPDATE_BY_ID_REQUEST";
export const VERSION_UPDATES_UPDATE_BY_ID_SUCCESS =
  "VERSION_UPDATES_UPDATE_BY_ID_SUCCESS";
export const VERSION_UPDATES_UPDATE_BY_ID_FAIL =
  "VERSION_UPDATES_UPDATE_BY_ID_FAIL";
  export const VERSION_UPDATES_UPDATE_BY_ID_RESET =
  "VERSION_UPDATES_UPDATE_BY_ID_RESET";

  export const VERSION_UPDATES_DELETE_BY_ID_REQUEST =
  "VERSION_UPDATES_DELETE_BY_ID_REQUEST";
export const VERSION_UPDATES_DELETE_BY_ID_SUCCESS =
  "VERSION_UPDATES_DELETE_BY_ID_SUCCESS";
export const VERSION_UPDATES_DELETE_BY_ID_FAIL =
  "VERSION_UPDATES_DELETE_BY_ID_FAIL";
  export const VERSION_UPDATES_DELETE_BY_ID_RESET =
  "VERSION_UPDATES_DELETE_BY_ID_RESET";


export const VERSION_UPDATES_DETAILS_BY_INVOICE_ID_REQUEST = "VERSION_UPDATES_DETAILS_BY_INVOICE_ID_REQUEST";
export const VERSION_UPDATES_DETAILS_BY_INVOICE_ID_SUCCESS = "VERSION_UPDATES_DETAILS_BY_INVOICE_ID_SUCCESS";
export const VERSION_UPDATES_DETAILS_BY_INVOICE_ID_FAIL = "VERSION_UPDATES_DETAILS_BY_INVOICE_ID_FAIL";
export const VERSION_UPDATES_DETAILS_BY_INVOICE_ID_RESET = "VERSION_UPDATES_DETAILS_BY_INVOICE_ID_RESET";

export const VERSION_UPDATES_DETAILS_BY_POS_NAME_REQUEST = "VERSION_UPDATES_DETAILS_BY_POS_NAME_REQUEST";
export const VERSION_UPDATES_DETAILS_BY_POS_NAME_SUCCESS = "VERSION_UPDATES_DETAILS_BY_POS_NAME_SUCCESS";
export const VERSION_UPDATES_DETAILS_BY_POS_NAME_FAIL = "VERSION_UPDATES_DETAILS_BY_POS_NAME_FAIL";
export const VERSION_UPDATES_DETAILS_BY_POS_NAME_RESET = "VERSION_UPDATES_DETAILS_BY_POS_NAME_RESET";
