import React from "react";
import { makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate, useParams } from "react-router-dom";
import SignupForm from "./SignupForm";
import SectionHeader from "../molecules/SectionHeader/SectionHeader";
import Section from "../organisms/Section/Section";
import CardBody from "../Card/CardBody";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import GridItem from "../Grid/GridItem";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import GridContainer from "../Grid/GridContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import { Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Signup = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <GridContainer spacing={2} alignItems="center" justifyContent="center">
        <GridItem xs={12} sm={12} md={12}lg={6}>
          <Card>
          <CardHeader>
          <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em",textAlign:"center"}}
                    />
                  </Tooltip>
              <b><h2 align="center"> Vishudha Tagline Traders </h2>
                <h2 align="center">MIS Sign up </h2></b>
            </CardHeader>
            <CardBody>
              <Section className={classes.section} >
                <div className={classes.formContainer} style={{minHeight:"0px"}}>
                  <SignupForm />
                </div>
              </Section>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Signup;
