import {
  WAREHOUSE_PURCHASE_ORDER_LIST_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_LIST_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_LIST_FAIL,
  
  WAREHOUSE_PURCHASE_ORDER_BY_ID_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_BY_ID_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_BY_ID_FAIL,

  WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_FAIL,

  WAREHOUSE_PURCHASE_ORDER_CREATE_RESET,
  WAREHOUSE_PURCHASE_ORDER_CREATE_FAIL,
  WAREHOUSE_PURCHASE_ORDER_CREATE_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST,

  WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_FAIL,
  WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_RESET,
  WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL,
  WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS,
} from "../constants/warehousePurchaseOrderConstants";

export const warehousePurchaseOrdersListReducer = (state = { warehousePurchaseOrders: [] }, action) => {
  switch (action.type) {
    case WAREHOUSE_PURCHASE_ORDER_LIST_REQUEST:
      return { loading: true, warehousePurchaseOrders: [] };
    case WAREHOUSE_PURCHASE_ORDER_LIST_SUCCESS:
      return {
        loading: false,
        warehousePurchaseOrders: action.payload,
      };
    case WAREHOUSE_PURCHASE_ORDER_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const warehousePurchaseOrderByIdReducer = (
  state = { wh_po_data: {} },
  action
) => {
  switch (action.type) {
    case WAREHOUSE_PURCHASE_ORDER_BY_ID_REQUEST:
      return { wh_po_loading: true, wh_po_data:{}  };
    case WAREHOUSE_PURCHASE_ORDER_BY_ID_SUCCESS:
      return {
        wh_po_loading: false,
        wh_po_data: action.payload,
      };
    case WAREHOUSE_PURCHASE_ORDER_BY_ID_FAIL:
      return { wh_po_loading: false, wh_po_error: action.payload };
    default:
      return state;
  }
};

export const warehousePurchaseOrderByPosReducer = (
  state = { wh_po_pos_data: {} },
  action
) => {
  switch (action.type) {
    case WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST:
      return { wh_po_pos_loading: true, wh_po_pos_data:{}  };
    case WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS:
      return {
        wh_po_pos_loading: false,
        wh_po_pos_data: action.payload,
      };
    case WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL:
      return { wh_po_pos_loading: false, wh_po_pos_error: action.payload };
    default:
      return state;
  }
};

export const warehousePurchaseOrderDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST:
      return { loading: true };
    case WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS:
      return { loading: false, success: true };
    case WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const warehousePurchaseOrderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST:
      return { wh_po_create_loading: true };
    case WAREHOUSE_PURCHASE_ORDER_CREATE_SUCCESS:
      return { wh_po_create_loading: false, wh_po_create_success: true, wh_po_create_data: action.payload };
    case WAREHOUSE_PURCHASE_ORDER_CREATE_FAIL:
      return { wh_po_create_loading: false, wh_po_create_error: action.payload };
    case WAREHOUSE_PURCHASE_ORDER_CREATE_RESET:
      return { wh_po_create_loading: false, wh_po_create_success: false ,wh_po_create_data:{}};
    default:
      return state;
  }
};

export const warehousePurchaseOrderUpdateByIdReducer = (state = { warehousePurchaseOrder: {} }, action) => {
  switch (action.type) {
    case WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_REQUEST:
      return { loading: true };
    case WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS:
      return { loading: false, success: true, warehousePurchaseOrder: action.payload };
    case WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    case WAREHOUSE_PURCHASE_ORDER_UPDATE_BY_ID_RESET:
      return { warehousePurchaseOrder: {},loading: false, success: false };
    default:
      return state;
  }
};

