import React, { useState, useEffect, useMemo , useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../Grid/GridContainer.js";
import GridItem from "../../Grid/GridItem.js";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Card from "../../Card/Card.js";
import CardBody from "../../Card/CardBody.js";
import CardHeader from "../../Card/CardHeader.js";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import { warehouseStoreInvoiceById } from "../../../actions/stockTransferInvoiceAction.js";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const StockTransferDetailsScreen = () => {
  const { id } = useParams();
  console.log("Invoice by Id : ", id, useParams());
  const classes = useStyles();
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log("id is : ", id, "So Calling API");
  //   dispatch(warehouseStoreInvoiceById(id));
  // }, []);
  const stockTransferInvoiceById = useSelector((state) => state.stockTransferInvoiceById);
  const { stock_transfer_invoice_id_loading, stock_transfer_invoice_id_error, stock_transfer_invoice_id, stock_transfer_invoice_id_success } = stockTransferInvoiceById;
  const {
    description,
    vehicleNo,
    invoiceProducts,
    fromWhStoreName,
    toWhStoreName,
    total,
    driverName,
    driverContact,
  } = stock_transfer_invoice_id;

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 1000,
      minWidth: 1000,
      maxWidth: 1000,
    },
    {
      field: "Quantity",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
    },

    // { field: "Price/Unit" },
    // { field: "Tax %" },
    // { field: "Total" },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
    }),
    []
  );
  let rowData = [];
  let gridApi = "";
  let renderContentUsingAGGrid = null;
  const onGridReady = (params) => {
    gridApi = params.api;
  };
  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  if (stock_transfer_invoice_id && stock_transfer_invoice_id._id) {
    rowData = stock_transfer_invoice_id.invoiceProducts.map((prd, index) => {
      return {
        "#": index + 1,
        "Product Name": prd.name,
        Quantity: prd.quantity,
        // "Price/Unit": prd.pricePerUnit,
        // Tax: prd.tax,
        // Total: prd.total,
      };
    });
    renderContentUsingAGGrid = (
      <>
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
        </div>
        <div></div>
      </>
    );
  }

  useEffect(() => {
    if (id && id.length>0)
       dispatch(warehouseStoreInvoiceById(id));
  }, [id]);

  return (
    <React.Fragment>
      {stock_transfer_invoice_id_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>

                <Typography variant="h5" style={{ fontWeight: 500 }}>
                  Stock Transfer Details
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon
              fontSize="medium"
              style={{ float: "right", margin: "0.5rem" }}
              onClick={onExportClick}
            />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Typography variant="h5" style={{ fontWeight: 500 }}>
            Invoice # {id}
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <div>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Vehicle Details
              </Typography>
            </div>
            <CardBody>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3}>
                  Vehicle No #
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={vehicleNo || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  Driver Name :
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={driverName || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  Driver Contact :
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <TextField
                    variant="outlined"
                    size="small"
                    value={driverContact || ""}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default StockTransferDetailsScreen;
