export const STORE_PAYMENT_CREATE_BY_STORE_REQUEST = "STORE_PAYMENT_CREATE_BY_STORE_REQUEST";
export const STORE_PAYMENT_CREATE_BY_STORE_SUCCESS = "STORE_PAYMENT_CREATE_BY_STORE_SUCCESS";
export const STORE_PAYMENT_CREATE_BY_STORE_FAIL = "STORE_PAYMENT_CREATE_BY_STORE_FAIL";
export const STORE_PAYMENT_CREATE_BY_STORE_RESET = "STORE_PAYMENT_CREATE_BY_STORE_RESET";

export const STORE_PAYMENT_LIST_BY_STORE_REQUEST = "STORE_PAYMENT_LIST_BY_STORE_REQUEST";
export const STORE_PAYMENT_LIST_BY_STORE_SUCCESS = "STORE_PAYMENT_LIST_BY_STORE_SUCCESS";
export const STORE_PAYMENT_LIST_BY_STORE_FAIL = "STORE_PAYMENT_LIST_BY_STORE_FAIL";
export const STORE_PAYMENT_LIST_BY_STORE_RESET = "STORE_PAYMENT_LIST_BY_STORE_RESET";

export const STORE_PAYMENT_DETAILS_BY_ID_REQUEST = "STORE_PAYMENT_DETAILS_BY_ID_REQUEST";
export const STORE_PAYMENT_DETAILS_BY_ID_SUCCESS = "STORE_PAYMENT_DETAILS_BY_ID_SUCCESS";
export const STORE_PAYMENT_DETAILS_BY_ID_FAIL = "STORE_PAYMENT_DETAILS_BY_ID_FAIL";
export const STORE_PAYMENT_DETAILS_BY_ID_RESET = "STORE_PAYMENT_DETAILS_BY_ID_RESET";







