import axios from "axios";
import {
  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_REQUEST,
  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_SUCCESS,
  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_FAIL,
  VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET,
  VEHICLE_SALE_INVOICE_LIST_REQUEST,
  VEHICLE_SALE_INVOICE_LIST_SUCCESS,
  VEHICLE_SALE_INVOICE_LIST_FAIL,
  VEHICLE_SALE_INVOICE_LIST_RESET,
  VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_REQUEST,
  VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_FAIL,
  VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_SUCCESS,
  VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_RESET,
  VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_SUCCESS,
  VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_REQUEST,
  VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_FAIL,
  VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_RESET,
  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST,
  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
  VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET,
  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_REQUEST,
  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_SUCCESS,
  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_FAIL,
  VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_RESET,
  VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_REQUEST,
  VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_SUCCESS,
  VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_FAIL
} from "../constants/vehicleSalesInvoiceConstants";
import { groupByProductNameAndSumQuantityForVehicle } from "../util/Utility";

export const listVehicleSalesInvoices = () => async (dispatch) => {
  try {
    dispatch({ type: VEHICLE_SALE_INVOICE_LIST_REQUEST });
    const { data } = await axios.get("/api/vehicle-sales-invoice");
    dispatch({
      type: VEHICLE_SALE_INVOICE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VEHICLE_SALE_INVOICE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const salesInvoiceByVehicleNo = (vehicleNo) => async (dispatch) => {
  console.log('invoiceByVehicleNo : vehicleNo', vehicleNo);
  try {
    dispatch({ type: VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_REQUEST });
    const { data } = await axios.get(`/api/vehicle-sales-invoice/${vehicleNo}`);

    dispatch({
      type: VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ");
    console.log(data);
  } catch (error) {
    dispatch({
      type: VEHICLE_SALE_INVOICE_BY_VEHICLE_NO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const vehicleSalesInvoicesByIncharge = (incharge) => async (dispatch) => {
  console.log('vehicleSalesInvoicesByIncharge : incharge', incharge);
  try {
    dispatch({ type: VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_REQUEST });
    const { data } = await axios.get(`/api/vehicle-sales-invoice/incharge/${incharge}`);

    dispatch({
      type: VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ");
    console.log(data);
  } catch (error) {
    dispatch({
      type: VEHICLE_SALE_INVOICE_LIST_BY_VEHICLE_SALES_INCHARGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSalesInvoiceDetailsByInvoiceId = (invoiceId) => async (dispatch) => {
  console.log('getInvoiceDetailsByInvoiceId : invoiceId', invoiceId);
  try {
    dispatch({ type: VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST });
    const { data } = await axios.get(`/api/vehicle-sales-invoice/id/${invoiceId}`);

    dispatch({
      type: VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ", data);
  } catch (error) {
    dispatch({
      type: VEHICLE_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteVehicleSalesInvoice = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_REQUEST,
    });

    await axios.delete(`/api/vehicle-sales-invoice/${id}`);

    dispatch({
      type: VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message) {
      dispatch({
        type: VEHICLE_SALE_INVOICE_DELETE_BY_VEHICLE_NO_FAIL,
        payload: message,
      });
    }
  }
};

export const createVehicleSalesInvoice =
  (invDetails) => async (dispatch) => {
    const { customerDetails, invoiceDetails, itemsPrice, paymentMadeDetails,  productsInCart, taxPrice, posVehicleNo } = invDetails
    console.log(
      "createVehicleSalesInvoice -start ",
      customerDetails, invoiceDetails, itemsPrice, paymentMadeDetails,  productsInCart, taxPrice, posVehicleNo
    );
   
    try {
      dispatch({
        type: VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_REQUEST,
      });
      console.log(" **** customerDetails **** : ", customerDetails);
      console.log(" **** invoiceDetails **** : ", invoiceDetails);
      console.log(" ****  itemsPrice ****  : ", itemsPrice);
      console.log(" **** paymentMadeDetails **** : ",  paymentMadeDetails)
      console.log(" **** productsInCart **** : ",  productsInCart)
      console.log(" **** taxPrice **** : ",  taxPrice)
      console.log(" **** posVehicleNo **** : ",  posVehicleNo)
      const vehicleno = posVehicleNo
      console.log(` ######### PREPARED URL BEFORE HITTING BACK END API ######### : /api/vehicle-sales-invoice/${vehicleno} `)
      const { data } = await axios.post(`/api/vehicle-sales-invoice/${posVehicleNo}`, { 
        invoiceDetails,
        customerDetails,
        productsInCart,
        paymentMadeDetails,
        taxPrice,
        itemsPrice
       });
      console.log("createVehicleSalesInvoice - end ");
      dispatch({
        type: VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_FAIL,
        payload: message,
      });
    }
  };

export const updateVehicleSalesInvoiceById = (obj) => async (dispatch, getState) => {
  const { id, cashAmount, upiAmount } = obj
  console.log("EXEC update updateVehicleSalesInvoiceById : " , id, cashAmount, upiAmount);
  try {
    dispatch({
      type: VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_REQUEST,
    });

    const { data } = await axios.put(`/api/vehicle-sales-invoice/${id}`, {
      cashAmount, upiAmount
    });

    dispatch({
      type: VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: VEHICLE_SALE_INVOICE_UPDATE_BY_INVOICE_ID_FAIL,
      payload: message,
    });
  }
};


