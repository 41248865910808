import {
   VEHICLE_DETAILS_REQUEST,
  VEHICLE_DETAILS_SUCCESS,
  VEHICLE_DETAILS_FAIL,
  VEHICLE_DETAILS_RESET,
  VEHICLE_CREATE_REQUEST,
  VEHICLE_CREATE_SUCCESS,
  VEHICLE_CREATE_RESET,
  VEHICLE_CREATE_FAIL,
  VEHICLE_LIST_REQUEST,
  VEHICLE_LIST_SUCCESS,
  VEHICLE_LIST_FAIL,
  VEHICLE_LIST_RESET,
  VEHICLE_DELETE_REQUEST,
  VEHICLE_DELETE_SUCCESS,
  VEHICLE_DELETE_FAIL,
  VEHICLE_DELETE_RESET,
  VEHICLE_UPDATE_REQUEST,
  VEHICLE_UPDATE_FAIL,
  VEHICLE_UPDATE_SUCCESS,
  VEHICLE_UPDATE_RESET,
  VEHICLE_AVAILABILITY_LIST_REQUEST,
  VEHICLE_AVAILABILITY_LIST_SUCCESS,
  VEHICLE_AVAILABILITY_LIST_FAIL,
  VEHICLE_AVAILABILITY_LIST_RESET,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_REQUEST,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_RESET,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_SUCCESS,
  VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_FAIL,
  VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_FAIL,
  VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_REQUEST,
  VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_RESET,
  VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_SUCCESS,
  VEHICLE_AVAILABLE_LIST_REQUEST,
  VEHICLE_AVAILABLE_LIST_RESET,
  VEHICLE_AVAILABLE_LIST_FAIL,
  VEHICLE_AVAILABLE_LIST_SUCCESS,
  VEHICLE_ASSIGNED_UPDATE_REQUEST,
  VEHICLE_ASSIGNED_UPDATE_SUCCESS,
  VEHICLE_ASSIGNED_UPDATE_FAIL,
  VEHICLE_ASSIGNED_UPDATE_RESET,
  VEHICLE_FETCH_BY_USER_NAME_REQUEST,
  VEHICLE_FETCH_BY_USER_NAME_RESET,
  VEHICLE_FETCH_BY_USER_NAME_FAIL,
  VEHICLE_FETCH_BY_USER_NAME_SUCCESS
  

} from "../constants/vehicleConstants";

export const vehicleListReducer = (state = { vehicles: [] }, action) => {
  switch (action.type) {
    case VEHICLE_LIST_REQUEST:
      return { loading: true , vehicles: []};
    case VEHICLE_LIST_SUCCESS:
      return { loading: false, vehicles: action.payload };
    case VEHICLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_LIST_RESET:
      return { };
    default:
      return state;
  }
};

export const availableVehicleListReducer = (state = { vehicles_available: [] }, action) => {
  switch (action.type) {
    case VEHICLE_AVAILABLE_LIST_REQUEST:
      return { loading_available_vehicles: true , vehicles_available: []};
    case VEHICLE_AVAILABLE_LIST_SUCCESS:
      return { loading_available_vehicles: false, vehicles_available: action.payload };
    case VEHICLE_AVAILABLE_LIST_FAIL:
      return { loading_available_vehicles: false, available_vehicles_error: action.payload };
    case VEHICLE_AVAILABLE_LIST_RESET:
      return { loading_available_vehicles: false };
    default:
      return state;
  }
};

export const vehicleListByAssignedOrAvailabileListReducer = (state = { vehiclesAssignedOrAvailable: [] }, action) => {
  switch (action.type) {
    case VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_REQUEST:
      return { vehicle_list_assigned_or_available_loading: true , vehiclesAssignedOrAvailable: []};
    case VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_SUCCESS:
      return { vehicle_list_assigned_or_available_loading: false, vehiclesAssignedOrAvailable: action.payload };
    case VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_FAIL:
      return { vehicle_list_assigned_or_available_loading: false, vehicle_list_assigned_or_available_error: action.payload };
    case VEHICLE_LIST_BY_ASSIGNED_OR_AVAILABLE_RESET:
      return { vehicle_list_assigned_or_available_loading: false, vehiclesAssignedOrAvailable: [], vehicle_list_assigned_or_available_error:false };
    default:
      return state;
  }
};

export const vehicleDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_DETAILS_REQUEST:
      return { loading: true };
    case VEHICLE_DETAILS_SUCCESS:
      return { loading: false, vehicle: action.payload };
    case VEHICLE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_DETAILS_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};

export const vehicleUpdateReducer = (
  state = { vehicle: {} },
  action
) => {
  switch (action.type) {
    case VEHICLE_UPDATE_REQUEST:
      return { loading: true };
    case VEHICLE_UPDATE_SUCCESS:
      return { loading: false, success_update: true, vehicle: action.payload };
    case VEHICLE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_UPDATE_RESET:
      return { vehicle: {}, loading: false, success_update: false };
    default:
      return state;
  }
};

export const vehicleUpdateAvailabilityAndAssignedStatusReducer = (
  state = { vehicle: {} },
  action
) => {
  switch (action.type) {
    case VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_REQUEST:
      return { update_avail_assigned_status_loading: true };
    case VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_SUCCESS:
      return { update_avail_assigned_status_loading: false, update_avail_assigned_status_success_update: true, vehicle: action.payload };
    case VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_FAIL:
      return { update_avail_assigned_status_loading: false, update_avail_assigned_status_error: action.payload };
    case VEHICLE_UPDATE_AVAILABILITY_ASSIGNED_STATUS_RESET:
      return { vehicle: {}, update_avail_assigned_status_loading: false, update_avail_assigned_status_success_update: false };
    default:
      return state;
  }
};

export const vehicleCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_CREATE_REQUEST:
      return { loading: true };
    case VEHICLE_CREATE_SUCCESS:
      return { loading: false, success_create: true, vehicle: action.payload };
    case VEHICLE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_CREATE_RESET:
      return { success_create: false, loading: false };
    default:
      return state;
  }
};

export const vehicleDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_DELETE_REQUEST:
      return { loading: true };
    case VEHICLE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case VEHICLE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case VEHICLE_DELETE_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const vehicleUpdateAssignedReducer = (
  state = { },
  action
) => {
  switch (action.type) {
    case VEHICLE_ASSIGNED_UPDATE_REQUEST:
      return { vehicle_update_assigned_loading: true };
    case VEHICLE_ASSIGNED_UPDATE_SUCCESS:
      return { vehicle_update_assigned_loading: false, vehicle_update_assigned_success: true, vehicle_update_assigned_data: action.payload };
    case VEHICLE_ASSIGNED_UPDATE_FAIL:
      return { vehicle_update_assigned_loading: false, vehicle_update_assigned_error: action.payload };
    case VEHICLE_ASSIGNED_UPDATE_RESET:
      return { };
    default:
      return state;
  }
};

export const vehicleSalesInchargeReducer = (state = {}, action) => {
  switch (action.type) {
    case VEHICLE_FETCH_BY_USER_NAME_REQUEST:
      return { fetch_vehicle_by_user_loading: true };
    case VEHICLE_FETCH_BY_USER_NAME_SUCCESS:
      return { fetch_vehicle_by_user_loading: false, fetch_vehicle_by_user_data: action.payload };
    case VEHICLE_FETCH_BY_USER_NAME_FAIL:
      return { fetch_vehicle_by_user_loading: false, fetch_vehicle_by_user_error: action.payload };
    case VEHICLE_FETCH_BY_USER_NAME_RESET:
      return {  };
    default:
      return state;
  }
};