import axios from "axios";
import {
  WALKIN_VYAPAR_SALE_REPORT_LIST_REQUEST,
  WALKIN_VYAPAR_SALE_REPORT_LIST_SUCCESS,
  WALKIN_VYAPAR_SALE_REPORT_LIST_FAIL,
  WALKIN_VYAPAR_SALE_REPORT_CREATE_REQUEST,
  WALKIN_VYAPAR_SALE_REPORT_CREATE_SUCCESS,
  WALKIN_VYAPAR_SALE_REPORT_CREATE_RESET,
  WALKIN_VYAPAR_SALE_REPORT_CREATE_FAIL,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_INVOICE_ID_REQUEST,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_INVOICE_ID_SUCCESS,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_INVOICE_ID_RESET,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_INVOICE_ID_FAIL,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_NAME_REQUEST,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_NAME_SUCCESS,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_NAME_FAIL,
  WALKIN_VYAPAR_SALE_REPORT_SYNC_FAIL,
  WALKIN_VYAPAR_SALE_REPORT_SYNC_SUCCESS,
  WALKIN_VYAPAR_SALE_REPORT_SYNC_REQUEST,
  WALKIN_VYAPAR_SALE_REPORT_BY_STORE_REQUEST,
  WALKIN_VYAPAR_SALE_REPORT_BY_STORE_FAIL,
  WALKIN_VYAPAR_SALE_REPORT_BY_STORE_SUCCESS,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_REQUEST,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_SUCCESS,
  WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_FAIL
} from "../constants/walkinVyaparSaleReportConstants";

export const syncVyaparWalkinSaleInvoices =
  (data,storeName) => async (dispatch) => {
    console.log("syncVyaparWalkinSaleInvoices . data :  ", data, storeName);
    // const {storeName} = storeObj;
    const posName = "DLF_STORE"
    try {
      dispatch({ type: WALKIN_VYAPAR_SALE_REPORT_SYNC_REQUEST });
      const response  = await axios.post(`/api/walkin-vyapar-sale-report/sync/${storeName}`, {
        data
      });
      dispatch({
        type: WALKIN_VYAPAR_SALE_REPORT_SYNC_SUCCESS,
        payload: data,
      });
      // dispatch({ type: WALKIN_VYAPAR_SALE_REPORT_CREATE_RESET });
      console.log("syncVyaparWalkinSaleInvoices - end ");
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WALKIN_VYAPAR_SALE_REPORT_SYNC_FAIL,
        payload: message,
      });
    }
  };

export const listVyaparWalkinSaleByStore = (storeName) => async (dispatch) => {
  try {
    console.log("Exec listVyaparWalkinSaleByStore : storeName ::==>>  ",storeName)
    dispatch({ type: WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_REQUEST });
    const posName = 'VNK_STORE'
    const { data } = await axios.get(`/api/walkin-vyapar-sale-report/${posName}`);
    dispatch({
      type: WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WALKIN_VYAPAR_SALE_INVOICE_DETAILS_BY_POS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};



