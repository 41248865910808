import React from 'react'
import { Grid, TextField , Tooltip, Typography,Divider, InputLabel} from '@mui/material';
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";


const RenderOrderSummary = (props)=> {
    const { data } = props;
    const {
        billNo,
        advanceAmount,
        balanceAmount,
        contactNo,
        description,
        partyName,
        paymentType,
        purchaseOrderStatus,
        stateOfSupply,
        totalPoAmount,
        totalPoQty,
        totalPoTaxAmount} = data;
        console.log("props received into RenderOrderSummary are :  " , props);
    
  return (
    <GridItem xs={12} sm={12} md={12}>
    <Card>
      <Typography
        variant="h6"
        style={{ margin: "0.5rem" }}
        color="black"
      >
        Order Summary
        <Divider></Divider>
      </Typography>
      <CardBody>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Bill No"
              variant="outlined"
              size="small"
              disabled
              InputLabelProps={{ shrink: true }}  
              value={billNo || ''}
            />
          </Grid>   
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Advance Paid"
              variant="outlined"
              size="small"
              disabled
              InputLabelProps={{ shrink: true }}  
              value={advanceAmount || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
          {/* <InputLabel>Balance Paid</InputLabel> */}
            <TextField
            label="Balance Amount"
            variant="outlined"
            size="small"
            disabled
            InputLabelProps={{ shrink: true }}  
            value={balanceAmount || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
          {/* <InputLabel>Total Paid</InputLabel> */}
            <TextField
              label="Total Amount"
              variant="outlined"
              size="small"
              name="totalPoAmount"
              disabled
              InputLabelProps={{ shrink: true }}  
              value={ totalPoAmount || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
          {/* <InputLabel>Total Tax : INR</InputLabel> */}
            <TextField
              label="Total Tax : INR"
              variant="outlined"
              size="small"
              name="totalPoTaxAmount"
              disabled
              InputLabelProps={{ shrink: true }}  
              value={ totalPoTaxAmount || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              label="Total Qty"
              variant="outlined"
              size="small"
              name="totalPoQty"
              disabled
              InputLabelProps={{ shrink: true }}  
              value={ totalPoQty || ''}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <TextField
              variant="outlined"
              label="Payment Status"
              size="small"
              name="purchaseOrderStatus"
              disabled
              InputLabelProps={{ shrink: true }}  
              value={ balanceAmount && balanceAmount > 0 ? 'DUE' : 'PAID'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
          <TextField
              variant="outlined"
              label="Payment Type"
              size="small"
              disabled
              InputLabelProps={{ shrink: true }}  
              value={ paymentType || ''}
            />
          </Grid>
        </Grid>
      </CardBody>
    </Card>
  </GridItem>
  )
}

export default RenderOrderSummary
